import { useFocusEffect } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Icon from '../../components/Icon';
import SISectionList from '../../components/SISectionList';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IDepartment } from '../../types/department.model';
import { IDocument } from '../../types/document.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IDepartmentDocuments extends IDepartment {
  documents?: IDocument[];
}

interface ItemProps {
  document: IDocument;
}

const Item: React.FC<ItemProps> = ({ document }) => {
  const { createdAt, uri, title } = document;

  return (
    <TouchableOpacity
      style={styles.item}
      onPress={async () => {
        if (uri) {
          await WebBrowser.openBrowserAsync(uri);
        }
      }}
    >
      <View>
        <SIText>{title}</SIText>
        <SIText>{`${moment.unix(createdAt!).format('L')}`}</SIText>
      </View>
      <Icon name="doc-text-inv" color={colors.green} size={16} />
    </TouchableOpacity>
  );
};

const DocumentsScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departments, setDepartments] = useState<IDepartmentDocuments[]>([]);
  const authState = useSelector((store: InitialState) => store.auth);

  const refreshData = () => {
    if (!isLoading) {
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);
      Axios.get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/documents/general`,
        {
          params: {
            mobile: true,
            userMainId: authState.userDetails?.id,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          setDepartments(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  return (
    <SafeAreaView style={styles.container}>
      <Back title={t('GENERAL.MY_DOCUMENTS')} />
      <SISectionList
        refreshControl={
          <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
        }
        sections={departments.map((department) => ({ title: department.company!, data: department.documents || [] }))}
        renderItem={({ item }) => <Item document={item} />}
        keyExtractor={(item, index) => `${index}`}
        style={{ padding: 25 }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blueExtraLight,
    flex: 1,
  },
  item: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
});

export default DocumentsScreen;
