import ActionType from '../actions/interventionActions';
import produce from 'immer';
import { IIntervention } from '../../types/intervention.model';
import { nowUnix } from '../../utils';

export type InterventionInitialState = {
  currentIntervention: IIntervention | null;
};

const initialState: InterventionInitialState = {
  currentIntervention: null,
};

export default function reducer(state = initialState, action: ActionType): InterventionInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'INTERVENTION_RESET': {
      return initialState;
    }
    case 'CREATE_NEW_INTERVENTION': {
      return produce(state, (draftState) => {
        draftState.currentIntervention = {
          ...action.payload,
        };
      });
    }
    case 'INTERVENTION_ADD_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const tasks = draftState.currentIntervention?.tasks;
        if (tasks) {
          tasks.push({
            ...action.payload,
          });
        }
      });
    }
    case 'INTERVENTION_REMOVE_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const tasks = draftState.currentIntervention?.tasks;
        if (tasks) {
          const index = tasks.findIndex((task) => task.id === action.payload);
          if (~index) {
            tasks.splice(index, 1);
          }
        }
      });
    }
    case 'INTERVENTION_ADD_CURRENT_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.currentIntervention?.pictures;
        if (pictures) {
          pictures.push({
            uri: action.payload,
            unix: nowUnix(),
          });
        }
      });
    }
    case 'INTERVENTION_REMOVE_CURRENT_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.currentIntervention?.pictures;
        if (pictures) {
          const index = pictures.findIndex((picture) => picture.uri === action.payload);
          if (~index) {
            pictures.splice(index, 1);
          }
        }
      });
    }
    case 'INTERVENTION_ADD_CURRENT_TOOL': {
      return produce(state, (draftState) => {
        if (draftState.currentIntervention) {
          const tools = draftState.currentIntervention?.tools;
          if (tools) {
            tools.push({ ...action.payload });
          } else {
            draftState.currentIntervention.tools = [{ ...action.payload }];
          }
        }
      });
    }
    case 'INTERVENTION_REMOVE_CURRENT_TOOL': {
      return produce(state, (draftState) => {
        const tools = draftState.currentIntervention?.tools;
        if (tools && tools.length) {
          tools.splice(action.payload, 1);
        }
      });
    }
    default: {
      return state;
    }
  }
}
