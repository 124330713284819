import axios from 'axios';
import * as Localization from 'expo-localization';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import H1 from '../../components/H1';
import Icon from '../../components/Icon';
import ShiftCards from '../../components/ShiftCards';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IShift } from '../../types/shift.model';
import Constants from 'expo-constants';
import { IDepartment } from '../../types/department.model';
import ModalSaveSchedule from './components/ModalSaveSchedule';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import SISpinner from '../../components/SISpinner';
import Back from '../../components/Back';

interface IDepartmentSchedule extends IDepartment {
  links?: string[];
}

const shiftMarker = { key: 'shiftMarker', color: colors.green };
const freeShiftMarker = { key: 'freeShiftMarker', color: colors.grey };
const dayoffMarker = { key: 'dayoffMarker', color: colors.red };

LocaleConfig.locales['fr'] = {
  monthNames: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
  monthNamesShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
  dayNames: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  dayNamesShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
};

LocaleConfig.locales['nl'] = {
  monthNames: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  monthNamesShort: 'Jan._Feb._Mrt._Apr._Mei_Jun._Jul._Aug._Sep._Okt._Nov._Dec.'.split('_'),
  dayNames: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  dayNamesShort: 'Zo._Ma._Di._Wo._Do._Vr._Za.'.split('_'),
};

LocaleConfig.locales['de'] = {
  monthNames: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthNamesShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  dayNames: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  dayNamesShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
};

switch (Localization.locale.substring(0, 2)) {
  case 'fr':
    LocaleConfig.defaultLocale = 'fr';
    break;
  case 'nl':
    LocaleConfig.defaultLocale = 'nl';
    break;
  case 'de':
    LocaleConfig.defaultLocale = 'de';
    break;
  default:
    LocaleConfig.defaultLocale = '';
    break;
}

moment.tz.setDefault('Atlantic/Reykjavik');

const CalendarScreen: React.FC = () => {
  const { t } = useTranslation();
  const authState = useSelector((store: InitialState) => store.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Moment>(moment().startOf('month'));

  const dataState = useSelector((state: InitialState) => state.data);
  const [monthShifts, setMonthShifts] = useState<IShift[]>([]);
  const [selectedShifts, setSelectedShifts] = useState<IShift[]>([]);
  const [markedDates, setMarkedDates] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    updatedMonthShifts(true);
  }, [dataState.shifts]);

  useEffect(() => {
    updatedMonthShifts();
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDate) {
      const startDate = moment(selectedDate).unix();
      const endDate = moment(selectedDate).endOf('day').unix();
      const selectedShifts: IShift[] = [];
      const selectedFreeShifts: IShift[] = [];

      getShiftsInRange({ startDate, endDate, shifts: monthShifts }).forEach((shift) => {
        if (shift.userRecordId) {
          selectedShifts.push(shift);
        } else {
          selectedFreeShifts.push(shift);
        }
      });
      setSelectedShifts([...selectedShifts, ...selectedFreeShifts]);
    } else {
      setSelectedShifts(monthShifts);
    }
  }, [selectedDate, monthShifts]);

  const onDayPress = (day: any) => {
    const { dateString } = day;
    if (selectedDate === dateString) {
      setSelectedDate(null);
    } else {
      setSelectedDate(dateString);
    }
  };

  const onMonthChange = (month: any) => {
    setMonthShifts([]);
    setIsLoading(true);
    const selectedMonth = moment(month.dateString);
    setSelectedMonth(selectedMonth);
  };

  const updatedMonthShifts = (updateMarkedDates = false) => {
    const startDate = moment(selectedMonth).startOf('month').unix();
    const endDate = moment(selectedMonth).endOf('month').unix();
    const selectedShifts: IShift[] = [];
    const selectedFreeShifts: IShift[] = [];

    getShiftsInRange({ startDate, endDate, shifts: dataState.shifts, updateMarkedDates }).forEach((shift) => {
      if (shift.userRecordId) {
        selectedShifts.push(shift);
      } else {
        selectedFreeShifts.push(shift);
      }
    });

    setMonthShifts([...selectedShifts, ...selectedFreeShifts]);
    setSelectedDate(null);
    setIsLoading(false);
  };

  const getShiftsInRange = ({
    startDate,
    endDate,
    shifts,
    updateMarkedDates = false,
  }: {
    startDate: number;
    endDate: number;
    shifts: IShift[];
    updateMarkedDates?: boolean;
  }) => {
    const _shifts = [];
    if (updateMarkedDates) {
      const markedDates: any = {};
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];
        const { start, startDate: shiftStartDate } = shift;
        const markedDate = markedDates[shiftStartDate!.substring(0, 10)];
        if (markedDate) {
          if (!shift?.userRecordId) {
            if (!markedDate.dots.includes(freeShiftMarker)) {
              markedDate.dots.push(freeShiftMarker);
            }
          } else {
            if (shift?.dayoff) {
              if (!markedDate.dots.includes(dayoffMarker)) {
                markedDate.dots.push(dayoffMarker);
              }
            } else {
              if (!markedDate.dots.includes(shiftMarker)) {
                markedDate.dots.push(shiftMarker);
              }
            }
          }
        } else {
          markedDates[shiftStartDate!.substring(0, 10)] = {
            dots: shift.userRecordId ? (shift.dayoff ? [dayoffMarker] : [shiftMarker]) : [freeShiftMarker],
          };
        }
        if (start! <= endDate! && start! >= startDate!) {
          _shifts.push(shift);
        }
      }
      setMarkedDates(markedDates);
    } else {
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];
        const { start } = shift;
        if (start! <= endDate! && start! >= startDate!) {
          _shifts.push(shift);
        }
      }
    }

    return _shifts;
  };

  interface IDepartmentSchedule extends IDepartment {
    department: string;
    webcal?: string[];
  }
  const [departments, setDepartments] = useState<IDepartmentSchedule[]>([]);
  const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(false);
  const [modalSaveScheduleVisible, setModalSaveScheduleVisible] = useState<boolean>(false);

  const onSaveSchedule = () => {
    setDepartmentsLoading(true);
    axios
      .get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/calendars`,
        {
          params: {
            platform: Platform.OS === 'android' ? 'android' : undefined,
          },
        },
      )
      .then(({ data }) => {
        if (Platform.OS === 'android') {
          showMessage({
            type: 'success',
            message: t('SCHEDULE.EMAIL_SENT'),
          });
        } else {
          setModalSaveScheduleVisible(true);
          setDepartments(
            data.calendars.map((obj: IDepartmentSchedule) => {
              return { department: obj.department, webcal: [obj.webcal] };
            }),
          );
        }
        setDepartmentsLoading(false);
      })
      .catch((err) => {
        if (Platform.OS === 'android') {
          showMessage({
            type: 'danger',
            message: t('SCHEDULE.AN_ERROR_OCCURED'),
          });
        }
        setModalSaveScheduleVisible(false);
        setDepartmentsLoading(false);
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <FlashMessage position="top" floating style={{ marginTop: Platform.OS === 'web' ? 50 : 0 }} />
      <Back
        title={t('GENERAL.MY_SCHEDULE')}
        icons={
          <View>
            {departmentsLoading ? (
              <ActivityIndicator color={colors.green} />
            ) : (
              <TouchableOpacity onPress={onSaveSchedule}>
                <Icon name="ical" size={24} />
              </TouchableOpacity>
            )}
          </View>
        }
      />
      <ScrollView style={styles.container}>
        <Calendar
          current={selectedMonth.format('YYYY-MM-DD')}
          onDayPress={onDayPress}
          markedDates={selectedDate ? { ...markedDates, [selectedDate]: { selected: true } } : markedDates}
          onMonthChange={onMonthChange}
          style={styles.calendar}
          displayLoadingIndicator={isLoading}
          markingType={'multi-dot'}
          firstDay={1}
          theme={{
            monthTextColor: 'black',
            textMonthFontFamily: 'Poppins_500Medium',
            textDayFontFamily: 'Poppins_500Medium',
            textDayHeaderFontFamily: 'Poppins_500Medium',
            dotColor: colors.green,
            todayTextColor: colors.green,
            arrowColor: colors.green,
            selectedDayBackgroundColor: colors.green,
          }}
        />
        {!isLoading && (
          <ShiftCards
            title={selectedDate ? `${moment(selectedDate).format('LL')}` : undefined}
            shifts={selectedShifts}
          />
        )}
      </ScrollView>
      <ModalSaveSchedule
        visible={modalSaveScheduleVisible}
        setVisible={setModalSaveScheduleVisible}
        departments={departments}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
  },
  container: {
    padding: 25,
    display: 'flex',
    flex: 1,
    backgroundColor: colors.blueExtraLight,
  },
  calendar: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 0,
    paddingBottom: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
});

export default CalendarScreen;
