import React, { useLayoutEffect, useState } from 'react';
import { View, StyleSheet, RefreshControl, FlatList } from 'react-native';
import SIText from '../components/SIText';
import moment from 'moment';
import 'moment-timezone';
import colors from '../styles/colors';
import Axios from 'axios';
import Constants from 'expo-constants';
import { IAlert } from '../types/alert.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface ItemProps {
  alert: IAlert;
}

const Item: React.FC<ItemProps> = ({ alert }) => {
  const { shortcode, start, alert: _alert } = alert;
  return (
    <View style={styles.item}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
        <SIText>{shortcode}</SIText>
        <SIText style={{ fontSize: 11, color: colors.grey }}>{`${moment.unix(start).format('L HH:mm')}`}</SIText>
      </View>
      <SIText style={{ color: colors.grey }}>{_alert}</SIText>
    </View>
  );
};

const MyAlertsScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  const refreshData = () => {
    if (!isLoading) {
      setIsLoading(true);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/oncall`,
      )
        .then((response) => {
          setAlerts(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  useLayoutEffect(() => {
    refreshData();
  }, []);

  return (
    <View style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <View style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
        <FlatList
          contentContainerStyle={styles.contentContainerStyle}
          ItemSeparatorComponent={() => (
            <View style={{ flex: 1, height: 1, backgroundColor: colors.blueExtraLight, marginLeft: 10 }}></View>
          )}
          data={alerts}
          renderItem={({ item }) => <Item alert={item} />}
          refreshControl={
            <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'white',
    padding: 10,
    flex: 1,
  },
  contentContainerStyle: {
    backgroundColor: colors.blueExtraLight,
  },
});

export default MyAlertsScreen;
