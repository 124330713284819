import ActionType from '../actions/holidayActions';
import produce from 'immer';
import { nowUnix } from '../../utils';
import { IPicture } from '../../types/clocking.model';

export type HolidayInitialState = {
  pictures: IPicture[];
};

const initialState: HolidayInitialState = {
  pictures: [],
};

export default function reducer(state = initialState, action: ActionType): HolidayInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'HOLIDAY_RESET': {
      return initialState;
    }
    case 'HOLIDAY_ADD_PICTURE': {
      return produce(state, (draftState) => {
        draftState.pictures.push({
          uri: action.payload,
          unix: nowUnix(),
        });
      });
    }
    case 'HOLIDAY_REMOVE_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.pictures;
        if (pictures?.length) {
          const index = pictures.findIndex((picture) => picture.uri === action.payload);
          if (~index) {
            pictures.splice(index, 1);
          }
        }
      });
    }
    default: {
      return state;
    }
  }
}
