import { useNavigation } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { shallowEqual, useSelector } from 'react-redux';
import Icon from '../../../components/Icon';
import SIText from '../../../components/SIText';
import { InitialState } from '../../../store';
import colors from '../../../styles/colors';

interface IShortcut {
  route: string;
  color: string;
  icon: string;
  name: string;
}

interface Props {
  isOnline: boolean;
}

const Shortcuts: React.FC<Props> = ({ isOnline }) => {
  const navigation = useNavigation();
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });
  const {
    departments,
    fixed_view,
    access_shifts_departments,
    access_documents_departments,
    access_dimona_departments,
    access_global_schedule_departments,
    access_vacation_requests_departments,
    admin_planning_hr_departments,
  } = useSelector((store: InitialState) => store.data, shallowEqual);
  const [shortcuts, setShortcuts] = useState<IShortcut[]>([]);

  useEffect(() => {
    const shortcuts: IShortcut[] = [];
    if (isOnline && access_shifts_departments && access_shifts_departments.length > 0) {
      shortcuts.push({
        route: 'CreateNewShift',
        color: '#7F8894',
        icon: 'ico-shift',
        name: t('SHIFTS.CREATE_SHIFT'),
      });
    } else {
      if (isOnline && departments?.some((department) => !!department.modules?.documents)) {
        shortcuts.push({
          route: 'Contracts',
          color: '#7F8894',
          icon: 'contracts',
          name: t('GENERAL.MY_CONTRACTS'),
        });
      }
    }
    if (isOnline && access_dimona_departments && access_dimona_departments.length > 0) {
      shortcuts.push({
        route: 'CreateNewDimona',
        color: '#FFDE50',
        icon: 'ico-dimona',
        name: t('DIMONA.CREATE_DIMONA'),
      });
    } else {
      if (departments?.some((department) => !!department?.params?.enableAvailability)) {
        shortcuts.push({
          route: 'Availabilities',
          color: '#FFDE50',
          icon: 'availability',
          name: t('GENERAL.UNAVAILABILITIES'),
        });
      }
    }
    if (isOnline && access_documents_departments && access_documents_departments.length > 0) {
      shortcuts.push({
        route: 'CreateNewContract',
        color: '#038BC9',
        icon: 'contracts',
        name: t('CONTRACTS.CREATE_CONTRACT'),
      });
    } else {
      if (access_vacation_requests_departments && access_vacation_requests_departments.length > 0) {
        shortcuts.push({
          route: 'ShiftRequestForHolidays',
          color: colors.red,
          icon: 'dayoff',
          name: t('DAYOFFS.REQUEST_FOR_LEAVE'),
        });
      }
    }

    if (shortcuts?.length === 2) {
      if (
        (admin_planning_hr_departments && admin_planning_hr_departments.length > 0) ||
        (access_global_schedule_departments && access_global_schedule_departments.length > 0)
      ) {
        shortcuts.push({
          route: 'Me',
          color: '#038BC9',
          icon: 'history',
          name: t('GENERAL.MY_INFORMATION'),
        });
      }
    } else if (shortcuts?.length === 1) {
      if (!shortcuts.find((shortcut) => shortcut.route === 'Contracts')) {
        if (isOnline && departments?.some((department) => !!department.modules?.documents)) {
          shortcuts.push({
            route: 'Contracts',
            color: '#7F8894',
            icon: 'contracts',
            name: t('GENERAL.MY_CONTRACTS'),
          });
        }
      } else {
        if (isOnline) {
          shortcuts.push({
            route: 'Contacts',
            color: '#ffb001',
            icon: 'phone',
            name: t('GENERAL.MY_CONTACTS'),
          });
        }
      }
      if (!shortcuts.find((shortcut: IShortcut) => shortcut.name == t('GENERAL.MY_INFORMATION'))) {
        shortcuts.push({
          route: 'Me',
          color: '#038BC9',
          icon: 'history',
          name: t('GENERAL.MY_INFORMATION'),
        });
      }
    }

    setShortcuts(shortcuts);
  }, [language, departments]);

  const lastItemOnFirstRow =
    (admin_planning_hr_departments && admin_planning_hr_departments.length > 0) ||
    (access_global_schedule_departments && access_global_schedule_departments.length > 0) ? (
      <TouchableOpacity disabled={!isOnline} style={styles.shortcut} onPress={() => navigation.navigate('Schedule')}>
        <View style={styles.shortcutContainer}>
          <Icon name="schedule" size={30} style={{ marginRight: 5 }} color={isOnline ? colors.red : colors.grey} />
          <SIText style={styles.shortcutText}>{t('GENERAL.SCHEDULE')}</SIText>
        </View>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity disabled={!isOnline} style={styles.shortcut} onPress={() => navigation.navigate('Me')}>
        <View style={styles.shortcutContainer}>
          <Icon name="information" size={30} style={{ marginRight: 5 }} color={isOnline ? colors.green : colors.grey} />
          <SIText style={styles.shortcutText}>{t('GENERAL.MY_INFORMATION')}</SIText>
        </View>
      </TouchableOpacity>
    );

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <TouchableOpacity style={styles.shortcut} onPress={() => navigation.navigate('Calendar')}>
          <View style={styles.shortcutContainer}>
            <Icon name="calendar" size={30} color={colors.green} />
            <SIText style={styles.shortcutText}>{t('GENERAL.MY_SCHEDULE')}</SIText>
          </View>
        </TouchableOpacity>
        {fixed_view ? (
          <>
            {access_documents_departments && access_documents_departments.length > 0 ? (
              <TouchableOpacity
                disabled={!isOnline}
                onPress={() => navigation.navigate('Contracts')}
                style={[styles.shortcut, { marginHorizontal: 10 }]}
              >
                <View style={styles.shortcutContainer}>
                  <Icon
                    name="contracts"
                    size={30}
                    style={{ marginRight: 5 }}
                    color={isOnline ? '#7F8894' : colors.grey}
                  />
                  <SIText style={styles.shortcutText}>{t('GENERAL.MY_CONTRACTS')}</SIText>
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                disabled={!isOnline}
                onPress={() => navigation.navigate('Contacts')}
                style={[styles.shortcut, { marginHorizontal: 10 }]}
              >
                <View style={styles.shortcutContainer}>
                  <Icon name="phone" size={30} style={{ marginRight: 5 }} color={isOnline ? '#FFA24F' : colors.grey} />
                  <SIText style={styles.shortcutText}>{t('GENERAL.MY_CONTACTS')}</SIText>
                </View>
              </TouchableOpacity>
            )}
          </>
        ) : (
          <TouchableOpacity
            disabled={!isOnline}
            onPress={() => navigation.navigate('FreeShifts')}
            style={[styles.shortcut, { marginHorizontal: 10 }]}
          >
            <View style={styles.shortcutContainer}>
              <Icon
                name="free-shifts"
                size={30}
                style={{ marginRight: 5 }}
                color={isOnline ? '#FFA24F' : colors.grey}
              />
              <SIText style={styles.shortcutText}>{t('SHIFTS.FREE_SHIFTS')}</SIText>
            </View>
          </TouchableOpacity>
        )}
        {lastItemOnFirstRow}
      </View>
      {!fixed_view && !!isOnline && !!shortcuts?.length && (
        <View style={styles.row}>
          {shortcuts?.map((shortcut, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => navigation.navigate(shortcut.route)}
              style={
                index === 1
                  ? [
                      styles.shortcut,
                      {
                        marginHorizontal: shortcuts.length == 2 ? 0 : 10,
                        marginLeft: shortcuts.length == 2 ? 10 : undefined,
                      },
                    ]
                  : styles.shortcut
              }
            >
              <View style={styles.shortcutContainer}>
                <Icon name={shortcut.icon} size={30} color={shortcut.color} />
                <SIText style={styles.shortcutText}>{shortcut.name}</SIText>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: 90,
    marginBottom: 10,
  },
  shortcut: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  shortcutContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    borderRadius: 10,
  },
  shortcutText: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'center',
  },
});

export default Shortcuts;
