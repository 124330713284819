import React, { useEffect, useState } from 'react';
import { ActivityIndicator, AppState, StyleSheet, Text, View } from 'react-native';
import colors from '../styles/colors';
import { nowUnix } from '../utils';

interface Props {
  start?: number;
  color?: string;
}

const Chronometer: React.FC<Props> = ({ start, color = colors.green }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [now, setNow] = useState<number>(0);

  const updateNow = () => {
    setNow(nowUnix());
  };

  useEffect(() => {
    setNow(nowUnix());
    const listener = AppState.addEventListener('change', updateNow);
    return () => {
      listener.remove();
    };
  }, [start]);

  useEffect(() => {
    if (start) {
      let duration = Math.floor(now - start);
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration - hours * 3600) / 60);
      const seconds = duration - minutes * 60 - hours * 3600;

      setSeconds(seconds);
      setMinutes(minutes);
      setHours(hours);
      setIsLoading(false);

      const interval = setInterval(() => {
        duration += 1;

        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);
        const seconds = duration - minutes * 60 - hours * 3600;

        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setIsLoading(true);
      setSeconds(0);
      setMinutes(0);
      setHours(0);
    }
  }, [now]);

  if (!isLoading) {
    return (
      <View style={[styles.container, { borderColor: color }]}>
        <Text style={[styles.text, { color }]}>
          {`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`}
        </Text>
      </View>
    );
  } else {
    return (
      <View
        style={[styles.container, { borderColor: color, height: 65, justifyContent: 'center', alignItems: 'center' }]}
      >
        <ActivityIndicator size="large" color={color} />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    margin: 10,
    borderRadius: 10,
  },
  text: {
    fontSize: 30,
    lineHeight: 60,
    fontFamily: 'Poppins_700Bold',
    textAlign: 'center',
    fontVariant: ['proportional-nums'],
  },
});

export default Chronometer;
