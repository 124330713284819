import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import Axios from 'axios';
import Constants from 'expo-constants';
import { TFunction } from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Icon from '../../components/Icon';
import SIButton from '../../components/SIButton';
import SISectionList from '../../components/SISectionList';
import SIText from '../../components/SIText';
import Tag from '../../components/Tag';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IDepartment } from '../../types/department.model';
import { IShift } from '../../types/shift.model';
import { IUser } from '../../types/user.model';

interface ItemProps {
  shift: IShift;
  t: TFunction;
}

const Item: React.FC<ItemProps> = ({ shift, t }) => {
  const navigation = useNavigation();
  const {
    id,
    user,
    skills,
    start,
    end,
    clocking,
    tasks,
    section,
    dayoff,
    dimona,
    dimonaQualify,
    dimonaValid,
    attributes,
    shyftType,
  } = shift;
  const { clockinAccount, departments } = useSelector((state: InitialState) => state.data);
  const displayName = clockinAccount ? shift.displayName : user?.displayName;

  const department = departments.find((department) => department.id == shift.departmentId);

  return (
    <View
      style={{
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: id ? (clocking ? colors.greenExtraLight : 'white') : colors.redExtraLight,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {department &&
              department.features &&
              department.features.find((feature) => feature.code == 'dimona') &&
              dimona &&
              dimonaQualify &&
              !dimonaValid && <Icon name="attention" size={12} color={colors.red} style={{ marginRight: 5 }} />}
            <SIText style={{ fontSize: 11 }}>{displayName}</SIText>
          </View>
          {(section || skills || attributes) && (
            <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', paddingTop: 4 }}>
              {section && (
                <Tag backgroundColor={section.background} color={section.color}>
                  {section.name!}
                </Tag>
              )}
              {skills?.map((skill) => (
                <Tag key={`shift_${id}_skill_${skill.id}`} backgroundColor={skill.background} color={skill.color}>
                  {skill.name!}
                </Tag>
              ))}
              {attributes?.map((attribute) => (
                <Tag
                  key={`shift_${id}_attribute_${attribute.id}`}
                  backgroundColor={attribute.background}
                  color={attribute.color}
                >
                  {attribute.name!}
                </Tag>
              ))}
            </View>
          )}
        </View>
        <View style={{ justifyContent: 'space-between' }}>
          {!!id && dayoff && (
            <SIText style={{ fontSize: 11, textAlign: 'right', color: colors.grey }}>{dayoff.name}</SIText>
          )}
          {!!id && dayoff && dayoff?.fullDay && (
            <SIText style={{ fontSize: 11, textAlign: 'right' }}>{t('GENERAL.FULL_DAY')}</SIText>
          )}
          {!!id && !dayoff?.fullDay && shyftType !== 3 && (
            <SIText style={{ fontSize: 11, textAlign: 'right' }}>{`${
              start ? moment.unix(start).format('HH:mm') : '__:__'
            } - ${end ? moment.unix(end).format('HH:mm') : '__:__'}`}</SIText>
          )}
          {!!id && shyftType == 3 && (
            <View
              style={{
                backgroundColor: '#f4f4f4',
                borderWidth: 1,
                borderColor: '#d9d9d9',
                paddingVertical: 2,
                paddingHorizontal: 5,
                borderRadius: 5,
              }}
            >
              <SIText
                style={{
                  fontSize: 10,
                  textAlign: 'right',
                  color: '#8c8c8c',
                }}
              >
                {t('GENERAL.UNAVAILABILITY_SHORT')}
              </SIText>
              {moment.unix(start!).format('HH:mm') !== '00:00' && moment.unix(end!).format('HH:mm') !== '23:59' && (
                <SIText style={{ fontSize: 10, textAlign: 'right', color: '#8c8c8c' }}>
                  {moment.unix(start!).format('HH:mm')} - {moment.unix(end!).format('HH:mm')}
                </SIText>
              )}
            </View>
          )}
          {!id && <View />}
          {!!clocking && (
            <>
              {clocking.map((clock) => (
                <View
                  key={`${clock.id}`}
                  style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <View style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Icon name="clock" style={{ marginRight: 5 }} color={colors.green} />
                      <SIText style={{ fontSize: 11, textAlign: 'right', color: colors.green }}>{`${
                        clock?.start ? moment.unix(clock.start).format('HH:mm') : '__:__'
                      } - ${clock?.end ? moment.unix(clock?.end).format('HH:mm') : '__:__'}`}</SIText>
                    </View>
                    <View style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                      {department &&
                        department.tasks &&
                        department.tasks.length > 0 &&
                        clock.tasks &&
                        clock.tasks.map((task) => {
                          const found = department.tasks?.find((t) => t.id == String(task.task_id));
                          if (!found) return;
                          return (
                            <SIText
                              key={`${task.task_id}`}
                              style={{ fontSize: 10, textAlign: 'right', color: colors.grey }}
                            >
                              {found.name}: {task.startdate ? moment.unix(task.startdate).format('HH:mm') : '__:__'} -{' '}
                              {task.enddate ? moment.unix(task.enddate).format('HH:mm') : null}
                            </SIText>
                          );
                        })}
                    </View>
                  </View>
                </View>
              ))}
            </>
          )}
        </View>
      </View>
      {/* {active && (
        <View>
          {!!tasks?.length && (
            <React.Fragment>
              <View style={{ marginTop: 6 }}>
                {tasks?.map((task) => (
                  <View
                    key={`shift_${shift.id}_task_${task.id}`}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <SIText style={styles.tasks}>{task.name}</SIText>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <SIText style={styles.tasks}>{`${moment.unix(task.start!).format('HH:mm')}`}</SIText>
                      <SIText style={styles.tasks}>{` - ${moment.unix(task.end!).format('HH:mm')}`}</SIText>
                    </View>
                  </View>
                ))}
              </View>
            </React.Fragment>
          )}
        </View>
      )} */}
    </View>
  );
};

type Props = StackScreenProps<never, 'Dashboard'>;

const DashboardScreen: React.FC<Props> = () => {
  const navigation = useNavigation();
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { access_shifts_departments, access_clocking_departments, clockinAccount } = useSelector(
    (store: InitialState) => store.data,
  );
  const offlineState = useSelector((store: InitialState) => store.offline);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departmentsAdded, setDepartmentsAdded] = useState<IDepartment[]>([]);
  const isOnline = !!offlineState?.online;

  const refresh = () => {
    const departments = clockinAccount ? access_clocking_departments : access_shifts_departments;
    if (departments) {
      if (departments.length > 1) {
        departments.forEach((department) => {
          getData(department.id);
        });
      } else {
        getData(departments[0].id);
      }
    }
  };

  useEffect(() => {
    setDepartments(
      departmentsAdded.reduce((acc: IDepartment[], current) => {
        const x = acc.find((item: IDepartment) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []),
    );
  }, [departmentsAdded]);

  const getData = (departmentId: string | undefined) => {
    if (clockinAccount) {
      Axios.get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/get-clockings`,
        {
          params: {
            departmentId,
            start: moment().startOf('day').unix(),
            end: moment().endOf('day').unix(),
          },
        },
      )
        .then(({ data }) => {
          const departments = clockinAccount ? access_clocking_departments : access_shifts_departments;
          const department = departments.find((department) => department.id == departmentId);
          if (!department) return;
          const departmentsMap = new Map<string, IDepartment>();

          const dept = {
            ...department,
            clockings: data.map((clocking: any) => {
              return {
                ...clocking,
                displayName: department.users
                  ? department.users.find((user) => user.recordId == clocking.ressource_record_id)?.displayName || ''
                  : '',
                start: moment(clocking.startdate).unix(),
                end: moment(clocking.enddate).unix(),
                section: department.sections
                  ? department.sections.find((section) => section.id == clocking.section)
                  : '',
              };
            }),
          };
          departmentsMap.set(department.id!, dept);
          setDepartmentsAdded((prev) => [...prev, dept]);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    setIsLoading(true);
    Axios.get(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts`,
      {
        params: {
          departmentId,
          startDate: moment().startOf('day').unix(),
          endDate: moment().endOf('day').unix(),
          picker: 'week',
          forceClocking: true,
        },
      },
    )
      .then((response) => {
        const {
          departments,
          users,
          shifts,
          clockings,
        }: { departments: IDepartment[]; users: IUser[]; shifts: IShift[]; clockings: any } = response.data;
        const departmentsMap = new Map<string, IDepartment>();
        const usersMap = new Map<string, IUser>();

        for (let i = 0; i < departments.length; i++) {
          const department = departments[i];
          department.shifts = [];
          departmentsMap.set(department.id!, department);
        }

        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          usersMap.set(user.recordId!, user);
        }

        for (let i = 0; i < shifts.length; i++) {
          const shift = shifts[i];
          const department = departmentsMap.get(shift.departmentId!);
          const userRecordId = shift.userRecordId;

          if (userRecordId) {
            const user = usersMap.get(shift.userRecordId!);
            shift.user = user;
            department?.shifts?.push(shift);
          }
        }

        if (clockings) {
          for (let i = 0; i < clockings.length; i++) {
            const clocking = clockings[i];
            const department = departmentsMap.get(clocking.departmentId!);
            const userRecordId = clocking.userRecordId;

            if (department && userRecordId) {
              const user = usersMap.get(clocking.userRecordId!);
              clocking.user = user;
              const existingShift = department?.shifts?.find((shift) => shift.userRecordId == user?.recordId);

              if (existingShift) {
                const newShift = { ...existingShift };
                if (newShift.clocking) {
                  newShift.clocking = [...newShift.clocking, ...clockings].sort((a: any, b: any) => a.start - b.start);
                } else {
                  newShift.clocking = clockings;
                }

                newShift.clocking = newShift.clocking
                  ?.reduce((unique: any, o: any) => {
                    if (!unique.some((obj: any) => obj.id === o.id)) {
                      unique.push(o);
                    }
                    return unique;
                  }, [])
                  .filter((record: any) => record.userRecordId == newShift.userRecordId);

                // if (newShift.clocking && newShift.clocking.length > 0) {
                //   const first = newShift.clocking[0];
                //   const last = newShift.clocking[newShift.clocking.length - 1];

                //   newShift.start = first.start;
                //   newShift.end = last.end;
                // }

                department.shifts = department?.shifts?.map((shift) => (shift.id === newShift.id ? newShift : shift));
              } else {
                const result = clockings.reduce((acc: any, curr: any) => {
                  if (!acc[curr.userRecordId]) {
                    acc[curr.userRecordId] = { start: curr.start, end: curr.end };
                  } else {
                    if (curr.start < acc[curr.userRecordId].start) {
                      acc[curr.userRecordId].start = curr.start;
                    }
                    if (curr.end > acc[curr.userRecordId].end) {
                      acc[curr.userRecordId].end = curr.end;
                    }
                  }
                  return acc;
                }, {});
                const values = result[clocking.userRecordId];

                if (values) {
                  clocking.start = values.start;
                  clocking.end = values.end;
                }

                department?.shifts?.unshift(clocking);
              }
            }
          }
        }

        setDepartments((prev) => [...prev, ...departments]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useFocusEffect(
    useCallback(() => {
      setDepartments([]);
      refresh();
    }, []),
  );

  const onCreateShift = () => {
    navigation.navigate('CreateNewShift');
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={clockinAccount ? t('GENERAL.CLOCKING_DASHBOARD') : t('GENERAL.DASHBOARD')} />
      <View style={{ backgroundColor: colors.blueExtraLight, flex: 1, padding: 25, paddingHorizontal: 20 }}>
        {isOnline && !clockinAccount && (
          <SIButton onPress={onCreateShift} title={t('SHIFTS.CREATE_SHIFT')} style={{ marginBottom: 25 }} />
        )}
        <View style={{ backgroundColor: 'white', flex: 1 }}>
          <SISectionList
            sections={departments.map((department) => ({
              title: department.company!,
              data: clockinAccount ? department.clockings || [] : department.shifts || [],
            }))}
            renderItem={({ item }) => <Item shift={item} t={t} />}
            keyExtractor={(item, index) => `${index}`}
            contentContainerStyle={{
              flexGrow: 0,
              backgroundColor: '#fff',
              borderRadius: 10,
              paddingBottom: 5,
            }}
            noSectionSeparator
            noSeparator
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  tasks: {
    fontSize: 11,
    lineHeight: 22,
    color: colors.grey,
  },
});

export default DashboardScreen;
