import React from 'react';
import { StyleSheet, View } from 'react-native';
import SIText from './SIText';

interface Props {
  backgroundColor?: string;
  color?: string;
  children: string;
}

const Tag: React.FC<Props> = ({ children, color, backgroundColor }) => (
  <View style={[styles.container, { backgroundColor, borderRadius: 4 }]}>
    <SIText style={[styles.text, { color }]}>{children}</SIText>
  </View>
);

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    display: 'flex',
    paddingHorizontal: 10,
    paddingVertical: 2,
    overflow: 'hidden',
    marginBottom: 5,
    marginRight: 5,
  },
  text: {
    fontSize: 11,
  },
});

export default Tag;
