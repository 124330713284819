import React, { useEffect } from 'react';
import { Text, SafeAreaView, ScrollView, StyleSheet, TextInput, View, Platform, Alert } from 'react-native';
import { IScanCode } from '../types/clocking.model';
import { StackScreenProps } from '@react-navigation/stack';
import { useNavigation, useScrollToTop } from '@react-navigation/native';
import colors from '../styles/colors';
import { Controller, useForm } from 'react-hook-form';
import SIButton from '../components/SIButton';
import SIText from '../components/SIText';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';

type NavigationParams = {
  ToolQuantityScreen: {
    qrcode?: IScanCode;
    callbackView: string;
    callbackScreen?: string;
  };
};

type Props = StackScreenProps<NavigationParams, 'ToolQuantityScreen'>;

const ToolQuantityScreen: React.FC<Props> = ({ route }) => {
  const { handleSubmit, control, getValues, setValue } = useForm();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const ref = React.useRef(null);
  const navigation = useNavigation();
  const { qrcode, callbackView, callbackScreen } = route.params;
  useScrollToTop(ref);

  useEffect(() => {
    navigation.setOptions({
      title: qrcode?.name,
    });
  }, [qrcode]);

  const addQuantity = (value: number) => {
    const originalValue: number = parseInt(getValues('quantity')) || 0;
    const result = originalValue + value;
    setValue('quantity', (result < 1 ? 1 : result).toString());
  };

  const onSubmit = (data: any) => {
    const { quantity } = data;
    const parsedQuantity = parseFloat(quantity.replace(',', '.')) || 0;
    if (parsedQuantity <= 0) {
      if (Platform.OS === 'web') {
        alert(`${t('GENERAL.ERROR')}. ${t('CLOCKING.ALERTS.TOOL_MIN_QUANTITY_MESSAGE')}`);
      } else {
        Alert.alert(t('GENERAL.ERROR'), t('CLOCKING.ALERTS.TOOL_MIN_QUANTITY_MESSAGE'));
      }
    } else {
      if (callbackScreen) {
        navigation.navigate(callbackView, {
          screen: callbackScreen,
          params: {
            qrcode: {
              ...qrcode,
              quantity: parsedQuantity,
            },
          },
        });
      } else {
        navigation.navigate(callbackView, {
          qrcode: {
            ...qrcode,
            quantity: parsedQuantity,
          },
        });
      }
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView ref={ref} style={styles.scrollView} contentContainerStyle={styles.contentContainerStyle}>
        <View style={styles.counterContainer}>
          <TouchableOpacity
            style={styles.counterButton}
            onPress={() => {
              addQuantity(-1);
            }}
          >
            <Text style={styles.counterButtonText}>-</Text>
          </TouchableOpacity>
          <View style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Controller
              control={control}
              name={'quantity'}
              defaultValue={'1'}
              render={({ onChange, value }) => {
                return (
                  <View>
                    <TextInput
                      style={{ fontSize: 32 }}
                      textAlign="center"
                      keyboardType={'numeric'}
                      underlineColorAndroid="transparent"
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                );
              }}
            />
            <SIText style={{ textAlign: 'center', color: colors.grey, fontSize: 12 }}>Quantity</SIText>
          </View>
          <TouchableOpacity
            style={styles.counterButton}
            onPress={() => {
              addQuantity(1);
            }}
          >
            <Text style={styles.counterButtonText}>+</Text>
          </TouchableOpacity>
        </View>
        <SIButton title={t('GENERAL.CONFIRM')} size="large" onPress={handleSubmit(onSubmit)} />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
  },
  scrollView: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  contentContainerStyle: {
    padding: 10,
    paddingTop: 20,
  },
  counterContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: 20,
  },
  counterButton: {
    backgroundColor: colors.blueLight,
    width: 75,
    height: 75,
    borderRadius: 75,
    margin: 10,
  },
  counterButtonText: {
    fontSize: 55,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
});

export default ToolQuantityScreen;
