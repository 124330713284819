import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Camera, CameraCapturedPicture } from 'expo-camera';
import * as FileSystem from 'expo-file-system';
import * as ScreenOrientation from 'expo-screen-orientation';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Alert,
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import H1 from '../components/H1';
import Icon from '../components/Icon';
import colors from '../styles/colors';

moment.tz.setDefault('Atlantic/Reykjavik');

type NavigationParams = {
  TakePictureScreen: {
    addActionType?: string;
  };
};

type Props = StackScreenProps<NavigationParams, 'TakePictureScreen'>;

const TakePictureScreen: React.FC<Props> = ({ route }) => {
  const [mode, setMode] = useState<string>('normal');
  const [picture, setPicture] = useState<CameraCapturedPicture>();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const cameraInstance = useRef<Camera>(null);

  const navigation = useNavigation();
  const reduxDispatch: Dispatch<any> = useDispatch();

  async function askPermission() {
    try {
      const { status } = await Camera.requestPermissionsAsync();
      if (status !== 'granted') {
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ALERTS.CAMERA_PERMISSION_FAILURE_MESSAGE')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ALERTS.CAMERA_PERMISSION_FAILURE_MESSAGE'));
        }
      } else {
        setMode('camera');
      }
    } catch (e) {
      console.log(e);
      console.log('Permissions.askAsync failed');
    }
  }

  useFocusEffect(
    useCallback(() => {
      askPermission();
      const subscription = ScreenOrientation.addOrientationChangeListener(
        (event: ScreenOrientation.OrientationChangeEvent) => {},
      );

      return () => {
        ScreenOrientation.removeOrientationChangeListener(subscription);
      };
    }, []),
  );

  const onPressPicture = async () => {
    try {
      const photo = await (cameraInstance.current as Camera).takePictureAsync({
        quality: 0.5,
      });
      setPicture(photo);
      setMode('picture');

      const uri = `${FileSystem.documentDirectory}${new Date().getTime()}`;

      try {
        await FileSystem.copyAsync({
          from: photo.uri,
          to: uri,
        });

        reduxDispatch({
          type: route.params.addActionType,
          payload: uri,
        });

        navigation.goBack();
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (mode === 'normal') {
    return (
      <View style={styles.container}>
        <H1>{t('GENERAL.NO_CAMERA_ACCESS')}</H1>
      </View>
    );
  } else if (mode === 'camera') {
    return (
      <View style={{ flex: 1 }}>
        <Camera style={{ flex: 1 }} ref={cameraInstance}>
          <SafeAreaView
            style={{ flex: 1, flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'flex-end' }}
          >
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <TouchableOpacity
                onPress={onPressPicture}
                style={{ backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 20, borderRadius: 100 }}
              >
                <Icon name="camera" size={30} color={colors.green} />
              </TouchableOpacity>
            </View>
          </SafeAreaView>
        </Camera>
      </View>
    );
  } else if (mode === 'picture') {
    if (picture) {
      return (
        <View>
          <Image style={{ flex: 1, width: 'auto' }} source={picture} resizeMode="cover" />
          <ActivityIndicator style={{ flex: 1, backgroundColor: 'transparent' }} size="large" />
        </View>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: 'white',
    minHeight: '100%',
  },
});

export default TakePictureScreen;
