import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextInput, View, ViewStyle, TouchableOpacity } from 'react-native';
import colors from '../styles/colors';
import Icon from './Icon';

interface Props {
  placeholder?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  style?: StyleProp<ViewStyle>;
}

const SearchBar: React.FC<Props> = ({ placeholder, style, value, setValue }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <View style={[styles.container, style]}>
      <Icon name="search" size={18} color={colors.grey} style={{ marginRight: 10 }} />
      <TextInput
        style={styles.input}
        underlineColorAndroid="transparent"
        placeholder={placeholder || t('GENERAL.SEARCH')}
        onChangeText={setValue}
        value={value}
      />
      {!!value && (
        <TouchableOpacity onPress={() => setValue('')}>
          <Icon name="delete" size={18} color={colors.grey} style={{ marginLeft: 10 }} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 7,
  },
  input: {
    display: 'flex',
    flexGrow: 1,
    flex: 1,
  },
});

export default SearchBar;
