import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import jwtDecode from 'jwt-decode';
import React, { Dispatch, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Dimensions,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from 'sentry-expo';
import logo from '../../../assets/shyfter-logo.png';
import InputPassword from '../../../components/InputPassword';
import SIButton from '../../../components/SIButton';
import SIText from '../../../components/SIText';
import { InitialState } from '../../../store';
import AuthActionsType from '../../../store/actions/authActions';
import { AuthInitialState } from '../../../store/reducers/authReducer';
import colors from '../../../styles/colors';
import { generateRandomString } from '../../../utils';

type FormValues = {
  email: string;
  password: string;
};

const LoginScreen: React.FC = () => {
  const navigation = useNavigation();
  const reduxDispatch: Dispatch<AuthActionsType> = useDispatch();
  const { features } = useSelector((store: InitialState) => store.data);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  const { register, handleSubmit, setValue } = useForm<FormValues>();

  useEffect(() => {
    register('email');
    register('password');
  }, [register]);

  useEffect(() => {
    const didShowListener = Keyboard.addListener('keyboardDidShow', () => setKeyboardVisible(true));
    const didHideListener = Keyboard.addListener('keyboardDidHide', () => setKeyboardVisible(false));
    return () => {
      didShowListener.remove();
      didHideListener.remove();
    };
  }, []);

  const onPressForgotPassword = () => {
    navigation.navigate('Forgot password');
  };

  const onLogin: SubmitHandler<FormValues> = (data) => {
    setIsLoading(true);
    const { email, password } = data;
    Axios.post(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/login`,
      {
        email,
        password,
        mobile: true,
        os: Platform.OS,
      },
    )
      .then(async (response) => {
        const { token } = response.data;
        const decoded = jwtDecode(token);
        const deviceId = await AsyncStorage.getItem('device-id');
        if (!deviceId) {
          await AsyncStorage.setItem('device-id', generateRandomString(100));
        }
        setIsLoading(false);
        reduxDispatch({
          type: 'AUTH_SET_TOKEN',
          payload: token || null,
        });
        const userDetails = (decoded as any).user as AuthInitialState['userDetails'];
        reduxDispatch({
          type: 'AUTH_SET_USER_DETAILS',
          payload: userDetails,
        });
        registerForPushNotificationsAsync();
      })
      .catch((error) => {
        console.log(error);
        if (Platform.OS === 'web') {
          alert(`${t('AUTH.AUTHENTIFICATION_ISSUE')} ${error.response?.data?.message}`);
        } else {
          Alert.alert(t('AUTH.AUTHENTIFICATION_ISSUE'), error.response?.data?.message);
        }
        setIsLoading(false);
      });
  };

  const staffRegisterFeature = features && features.find((feature) => feature.key == 'staff_register');
  const onRegister = () => {
    if (staffRegisterFeature) {
      Linking.openURL(staffRegisterFeature.details.link![i18n.language]);
    }
  };

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>
      <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined} accessible={false}>
        <SafeAreaView style={styles.container}>
          <View style={styles.shape} />
          <Image
            style={{ width: '100%', height: 45, top: keyboardVisible ? 25 : Dimensions.get('screen').height * 0.1 }}
            resizeMode="contain"
            source={logo}
          />
          <View style={styles.form}>
            <View style={styles.email_container}>
              <TextInput
                placeholder={t('AUTH.EMAIL')}
                keyboardType="email-address"
                autoCapitalize="none"
                style={styles.email}
                onChangeText={(text) => {
                  setValue('email', text);
                }}
                placeholderTextColor={colors.grey}
                autoCorrect={false}
              />
            </View>
            <InputPassword
              placeholder={t('AUTH.PASSWORD')}
              setValue={(text) => {
                setValue('password', text);
              }}
              inputStyle={styles.password}
            />
            <SIButton
              title={t('AUTH.LOGIN')}
              size="large"
              style={styles.login}
              onPress={handleSubmit(onLogin)}
              loading={isLoading}
            />
            {staffRegisterFeature && (
              <View style={styles.register_container}>
                <SIText style={{ textAlign: 'center', fontSize: 11, color: colors.grey }}>
                  {staffRegisterFeature.details.title![i18n.language]}
                </SIText>
                <TouchableOpacity onPress={onRegister}>
                  <SIText style={styles.forgot}>{t('AUTH.CREATE_NEW_ACCOUNT')}</SIText>
                </TouchableOpacity>
              </View>
            )}
          </View>
          <View style={styles.footer}>
            <TouchableOpacity onPress={onPressForgotPassword} style={styles.forgot_container}>
              <SIText style={styles.forgot}>{t('AUTH.FORGOT_PASSWORD')}</SIText>
            </TouchableOpacity>
            <SIText style={styles.version}>
              Shyfter {Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}
            </SIText>
          </View>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  shape: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 400,
    top: -150,
    left: -10,
    borderRadius: 64,
    transform: [{ rotate: '20deg' }],
    position: 'absolute',
  },
  container: {
    flex: 1,
    backgroundColor: colors.blueExtraLight,
    justifyContent: 'center',
  },
  form: {
    flex: 1,
    justifyContent: 'center',
    padding: 25,
  },
  email_container: {
    borderBottomWidth: 1,
    borderColor: '#D8D8D8',
  },
  email: {
    paddingVertical: 20,
    fontSize: 17,
  },
  password: {
    fontSize: 17,
  },
  login: {
    marginTop: 25,
  },
  footer: {
    alignItems: 'center',
  },
  register_container: {
    paddingVertical: 25,
  },
  forgot_container: {
    position: 'absolute',
    padding: 25,
    bottom: 0,
  },
  forgot: {
    color: colors.green,
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 10,
  },
  version: {
    color: colors.grey,
    marginBottom: 10,
  },
});

const registerForPushNotificationsAsync = async () => {
  if (Constants.isDevice && Platform.OS !== 'web') {
    try {
      const { status: existingStatus } = await Notifications.requestPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }

      const deviceId = await AsyncStorage.getItem('device-id');

      try {
        const token = await Notifications.getExpoPushTokenAsync();
        const { brand, modelName, osName, osVersion, deviceName } = Device;
        Axios.post(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/notificationToken`,
          {
            deviceId,
            brand,
            modelName,
            osName,
            osVersion,
            deviceName,
            token,
            appType: 'mobile_app',
          },
        )
          .then(({ data }) => {
            AsyncStorage.setItem('expo-token', token.data);
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response.data);
          });
      } catch (e) {
        console.log(e);
        console.log(`Couldn't get the notification token`);
        Sentry.Native.captureException(e);
      }
    } catch (e) {
      console.log(e);
      Sentry.Native.captureException(e);
    }
  } else if (Platform.OS !== 'web') {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
};

export default LoginScreen;
