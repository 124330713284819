import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Select from '../../components/Select';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import SIButton from '../../components/SIButton';
import { useNavigation } from '@react-navigation/native';
import { IContractTemplate } from '../../types/contracts/template.model';
import axios from 'axios';
import Constants from 'expo-constants';
import DateInput from '../../components/DateInput';
import { ISignature } from '../../types/contracts/signature.model';
import SIText from '../../components/SIText';
import Input from '../../components/Input';
import { CONTRACTS_CREATE_DIMONA_USER_TYPES, CONTRACTS_RENEW_PERIODS, CONTRACTS_SALARY_PERIODS } from '../../utils';
import { showMessage } from 'react-native-flash-message';
import moment from 'moment';
import Checkbox from '../../components/Checkbox';

const CreateNewContractScreen: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { handleSubmit, errors, control, watch, setValue, getValues } = useForm();
  const watch_department = watch('departmentId', null);
  const watch_user = watch('userRecordId', null);
  const watch_template = watch('templateId', null);
  const watch_renew = watch('renew', null);
  const watch_dimona = watch('dimona', null);
  const watch_dimona_user_type = watch('workerType', null);
  const { access_documents_departments } = useSelector((store: InitialState) => store.data);

  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IContractTemplate[]>([]);
  const [signatures, setSignatures] = useState<ISignature[]>([]);

  const activeDepartment =
    access_documents_departments &&
    access_documents_departments.find((department) => department.id === watch_department);
  const active_user = activeDepartment?.users?.find((user) => user.recordId === watch_user);

  useEffect(() => {
    if (access_documents_departments && access_documents_departments.length == 1) {
      setValue('departmentId', access_documents_departments[0].id);
    }
  }, []);

  useEffect(() => {
    if (watch_user) {
      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/users/${watch_user}/params`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          const { params } = data;
          const { automatic_contract_template_id, dimona, automatic_contracts, workerType, hr_hours_week } = params;

          setValue('templateId', automatic_contract_template_id ? automatic_contract_template_id : null);
          setValue('renew', automatic_contracts ? automatic_contracts : null);
          setValue('dimona', dimona ? dimona : null);
          setValue(
            'workerType',
            workerType ? CONTRACTS_CREATE_DIMONA_USER_TYPES.find((user_type) => user_type.id === workerType)?.id : null,
          );
          setValue('dimonaPlannedHours', hr_hours_week ? hr_hours_week.toString() : null);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/users/${watch_user}/hr/active`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          const { price, wage_type, hr_max_hours_week, hr_days_week, legal_leaves } = data.data;

          setValue('weekHours', hr_max_hours_week ? hr_max_hours_week.toString() : null);
          setValue('salary', price ? price.toString() : null);
          setValue('salaryPeriod', wage_type ? wage_type.toString() : null);
          setValue('weekDays', hr_days_week ? hr_days_week.toString() : null);
          setValue('yearDayoffs', legal_leaves ? legal_leaves.toString() : null);
          setValue(
            'maxDailyHours',
            hr_max_hours_week && hr_days_week ? Math.floor(hr_max_hours_week / hr_days_week).toString() : null,
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [watch_user]);

  useEffect(() => {
    if (activeDepartment) {
      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/documents/templates`,
          {
            params: {
              departmentId: activeDepartment.id,
              filter: 'CONTRACT',
            },
          },
        )
        .then(({ data }) => {
          setTemplates(data.templates);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/documents/signatures`,
          {
            params: {
              departmentId: activeDepartment.id,
            },
          },
        )
        .then(({ data }) => {
          setSignatures(data);
          const default_signature = data.find((signature: ISignature) => signature.default);
          if (default_signature) {
            setValue('signatory', default_signature.id);
          } else {
            setValue('signatory', null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [activeDepartment]);

  useEffect(() => {
    const template = getValues('templateId');

    if (template) {
      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/documents/templates/${template}`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          const { signatory } = data;
          if (signatory) {
            setValue('signatory', signatory);
          } else {
            setValue('signatory', null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [watch_user, watch_template]);

  const onSubmit = (values: any) => {
    setLoading(true);
    const parsed_values = {
      ...values,
      start: moment(values.start).unix(),
      end: moment(values.end).unix(),
    };

    const formData = new FormData();
    for (const key in parsed_values) {
      if (parsed_values[key]) {
        if (key == 'renew' || key == 'dimona') {
          formData.append(`formValues[${key}]`, parsed_values[key] ? '1' : '0');
        } else {
          formData.append(`formValues[${key}]`, parsed_values[key]);
        }
      }
    }

    axios({
      url: `${
        Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/documents/contracts`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartment?.id,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        navigation.navigate('Home');
        showMessage({
          message: t('CONTRACTS.FLASH_MESSAGES.CREATED', { name: active_user?.displayName }),
          type: 'success',
        });
        setLoading(false);
      })
      .catch((err) => {
        navigation.navigate('Home');
        showMessage({
          message: t('CONTRACTS.FLASH_MESSAGES.ERROR'),
          type: 'danger',
        });
        setLoading(false);
      });
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('CONTRACTS.CREATE_CONTRACT')} />
      <ScrollView style={styles.container}>
        <View style={styles.form}>
          <SIText style={styles.title}>{t('CONTRACTS.GENERAL_INFORMATION')}</SIText>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={
              access_documents_departments &&
              access_documents_departments.map((department) => ({
                label: department.company!,
                value: department.id!,
              }))
            }
          />
          <Select
            placeholder={t('GENERAL.USER')}
            label={t('GENERAL.USER')}
            control={control}
            defaultValue={null}
            name="userRecordId"
            items={activeDepartment?.users?.map((user) => ({ label: user.displayName!, value: user.recordId! })) || []}
            rules={{ required: true }}
            errors={errors}
          />
          <Select
            placeholder={t('CONTRACTS.TEMPLATE')}
            label={t('CONTRACTS.TEMPLATE')}
            control={control}
            defaultValue={null}
            name="templateId"
            items={templates.map((template) => ({ label: template.name, value: template.id })) || []}
            rules={{ required: true }}
            errors={errors}
          />
          <View>
            <DateInput
              control={control}
              name="start"
              label={t('DIMONA.START_PERIOD')}
              rules={{ required: true }}
              mode="date"
              watch={watch}
              errors={errors}
            />
            <DateInput
              control={control}
              name="end"
              label={t('DIMONA.END_PERIOD')}
              rules={{ required: true }}
              mode="date"
              watch={watch}
              errors={errors}
            />
            <Select
              placeholder={t('CONTRACTS.SIGNATORY')}
              label={t('CONTRACTS.SIGNATORY')}
              control={control}
              defaultValue={null}
              name="signatory"
              items={
                signatures.map((signature) => ({
                  label: `${signature.name} - ${signature.title}`,
                  value: signature.id,
                })) || []
              }
              rules={{ required: true }}
              errors={errors}
            />
          </View>
          <SIText style={styles.title}>{t('CONTRACTS.ADDITIONAL_INFORMATION')}</SIText>
          <Input
            defaultValue={null}
            label={t('CONTRACTS.WEEK_HOURS')}
            placeholder={t('CONTRACTS.WEEK_HOURS')}
            rules={{ required: true }}
            control={control}
            name="weekHours"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.SALARY')}
            placeholder={t('CONTRACTS.SALARY')}
            rules={{ required: true }}
            control={control}
            name="salary"
            keyboardType="numeric"
            errors={errors}
          />
          <Select
            placeholder={t('CONTRACTS.SALARY_PERIOD')}
            label={t('CONTRACTS.SALARY_PERIOD')}
            control={control}
            defaultValue={null}
            name="salaryPeriod"
            items={CONTRACTS_SALARY_PERIODS.map((period) => ({
              label: t(`CONTRACTS.SALARY_PERIODS.${period}`),
              value: period,
            }))}
            rules={{ required: true }}
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.WEEK_DAYS')}
            placeholder={t('CONTRACTS.WEEK_DAYS')}
            control={control}
            name="weekDays"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.YEAR_DAYOFFS')}
            placeholder={t('CONTRACTS.YEAR_DAYOFFS')}
            control={control}
            name="yearDayoffs"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.MAX_DAILY_HOURS')}
            placeholder={t('CONTRACTS.MAX_DAILY_HOURS')}
            control={control}
            name="maxDailyHours"
            keyboardType="numeric"
            errors={errors}
          />
          <Checkbox
            control={control}
            label={t('CONTRACTS.AUTO_RENEW.TITLE')}
            name="renew"
            style={{ marginBottom: 15 }}
          />
          {watch_renew && (
            <View>
              <Input
                defaultValue={null}
                label={t('CONTRACTS.AUTO_RENEW.RENEW_NUMBER')}
                placeholder={t('CONTRACTS.AUTO_RENEW.RENEW_NUMBER')}
                control={control}
                name="renewNumber"
                keyboardType="numeric"
                errors={errors}
              />
              <Select
                placeholder={t('CONTRACTS.AUTO_RENEW.RENEW_PERIOD')}
                label={t('CONTRACTS.AUTO_RENEW.RENEW_PERIOD')}
                control={control}
                defaultValue={null}
                name="salaryPeriod"
                items={CONTRACTS_RENEW_PERIODS.map((period) => ({
                  label: t(`CONTRACTS.AUTO_RENEW.RENEW_PERIODS.${period}`),
                  value: period,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
            </View>
          )}
          {activeDepartment?.modules?.dimona && (
            <View>
              <Checkbox
                control={control}
                label={t('CONTRACTS.CREATE_DIMONA.TITLE')}
                name="dimona"
                style={{ marginBottom: 15 }}
              />
              {watch_dimona && (
                <View>
                  <Select
                    placeholder={t('CONTRACTS.CREATE_DIMONA.WORKER_TYPE')}
                    label={t('CONTRACTS.CREATE_DIMONA.WORKER_TYPE')}
                    control={control}
                    defaultValue={null}
                    name="workerType"
                    items={CONTRACTS_CREATE_DIMONA_USER_TYPES.map((user_type) => ({
                      label: user_type.name,
                      value: user_type.id,
                    }))}
                    rules={{ required: true }}
                    errors={errors}
                  />
                  {watch_dimona_user_type == 'STU' && (
                    <Input
                      defaultValue={null}
                      label={t('CONTRACTS.CREATE_DIMONA.HOURS_NUMBER')}
                      placeholder={t('CONTRACTS.CREATE_DIMONA.HOURS_NUMBER')}
                      rules={{ required: true }}
                      control={control}
                      name="dimonaPlannedHours"
                      keyboardType="numeric"
                      errors={errors}
                    />
                  )}
                </View>
              )}
            </View>
          )}
          <SIButton title={t('GENERAL.CONFIRM')} size="large" loading={loading} onPress={handleSubmit(onSubmit)} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  form: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    marginBottom: Platform.OS != 'web' ? 150 : 50,
  },
  title: {
    marginBottom: 10,
    color: colors.green,
    fontWeight: 'bold',
    fontSize: 18,
  },
});

export default CreateNewContractScreen;
