import { useNavigation } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IDepartment } from '../types/department.model';
import { IShift } from '../types/shift.model';
import { minutesToHoursAndOrMinutes, nowUnix } from '../utils';
import Icon from './Icon';
import SIButton from './SIButton';
import SIText from './SIText';
import Tag from './Tag';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  shift: IShift;
  timestamp: number;
}

const ShiftCard: React.FC<Props> = ({ shift, timestamp }) => {
  const { departments, fixed_view } = useSelector((state: InitialState) => state.data);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const colon = Localization.locale.substring(0, 2) === 'fr' ? ' :' : ':';
  const [department, setDepartment] = useState<IDepartment | null>(null);
  const [now, setNow] = useState<number>(nowUnix());
  const isOnline = !!offlineState?.online;

  useEffect(() => {
    const department = departments.find((department) => department.id === shift.departmentId!);
    setDepartment(department || null);
  }, [departments, shift.departmentId]);

  const onShiftDetails = () => {
    navigation.navigate('ShiftDetails', { shift });
  };

  const { package: shiftPackage, id } = shift;
  const shiftStyle = shift.userRecordId
    ? !shift.dayoff
      ? styles.shiftContainer
      : styles.dayoffContainer
    : !!shift.processing
    ? [styles.freeShiftContainer, { backgroundColor: 'rgb(229, 245, 255)', borderColor: colors.blueLight }]
    : styles.freeShiftContainer;

  const { start, end, pause } = shift;
  const duration = moment.duration(end! - start!, 'seconds');
  duration.subtract(pause!.unpaid!, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationAsMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

  const no_offline_clockings_departments_ids = dataState.no_offline_clockings_departments
    ? dataState.no_offline_clockings_departments.map((department) => department.id)
    : [];

  const TopRight = !shift?.dayoff ? (
    <View>
      {!!department?.params?.showOnlyStartDate && (
        <SIText style={[styles.topRightText, { fontSize: 14, fontWeight: 'bold' }]}>
          {`${t('SHIFTS.START')}${colon} ${moment.unix(shift.start!).format('HH:mm')}`}
        </SIText>
      )}
      {!department?.params?.showOnlyStartDate && (
        <React.Fragment>
          <SIText style={[styles.topRightText, { fontSize: 14, fontWeight: 'bold' }]}>{`${moment
            .unix(shift.start!)
            .format('HH:mm')} ${t('SHIFTS.HOURS_TO')} ${moment.unix(shift.end!).format('HH:mm')}`}</SIText>
        </React.Fragment>
      )}
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon name="clock" />
          <SIText style={styles.topRightText}> {minutesToHoursAndOrMinutes(durationInMinutes)}</SIText>
        </View>
        {pauseDurationAsMinutes > 0 && (
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
            <Icon name="pause" size={14} />
            <SIText style={styles.topRightText}> {minutesToHoursAndOrMinutes(pauseDurationAsMinutes)}</SIText>
          </View>
        )}
      </View>
    </View>
  ) : (
    <View>
      {shift.dayoff?.name && (
        <View style={{ marginRight: -5, alignSelf: 'flex-end' }}>
          <Tag key={`shift_${shift.id}_dayoff_type`} backgroundColor={colors.red} color={'#fff'}>
            {shift.dayoff.name}
          </Tag>
        </View>
      )}
      {shift?.dayoff?.fullDay && <SIText style={styles.topRightText}>{t('GENERAL.FULL_DAY')}</SIText>}
      {!shift?.dayoff?.fullDay && (
        <SIText style={styles.topRightText}>{`${moment.unix(shift.start!).format('HH:mm')} ${t(
          'SHIFTS.HOURS_TO',
        )} ${moment.unix(shift.end!).format('HH:mm')}`}</SIText>
      )}
    </View>
  );

  if (shiftPackage) {
    return (
      <View style={shiftStyle}>
        <TouchableOpacity onPress={onShiftDetails}>
          <View style={styles.top}>
            <View>
              <SIText>{moment.unix(shift.start!).format('LL')}</SIText>
              <SIText style={{ color: colors.grey, fontSize: 11 }}>{department?.company}</SIText>
            </View>
            {TopRight}
          </View>
          <View style={[styles.packageParentContainer, { marginTop: shiftPackage.name ? 6 : 0 }]}>
            {shiftPackage.name && (
              <View style={[styles.packageContainer, { backgroundColor: shiftPackage.background }]}>
                <SIText style={[styles.package, { color: shiftPackage.color }]}>{shiftPackage.name}</SIText>
              </View>
            )}
            {isOnline &&
              shift?.userRecordId &&
              !shift?.dayoff &&
              shift.start! > timestamp + (department?.clockinParams?.requestModificationMaxHour || 0) * 3600 && (
                <SIButton
                  title={t('GENERAL.MODIFY')}
                  backgroundColor={colors.red}
                  width="auto"
                  onPress={onShiftDetails}
                />
              )}
            {!shift?.userRecordId && !shift.processing && (
              <SIButton title={t('SHIFTS.BOOK')} width="auto" onPress={onShiftDetails} />
            )}
            {!shift?.userRecordId && !!shift.processing && (
              <View>
                <SIText style={{ color: colors.grey }}>{t('SHIFTS.PENDING_REQUEST')}</SIText>
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  } else {
    return (
      <View style={shiftStyle}>
        <TouchableOpacity onPress={onShiftDetails}>
          <View style={styles.top}>
            <View>
              <SIText>{moment.unix(shift.start!).format('dddd')}</SIText>
              <SIText>{moment.unix(shift.start!).format('LL')}</SIText>
              <SIText style={{ color: colors.grey, fontSize: 11 }}>{department?.company}</SIText>
            </View>
            {TopRight}
          </View>
          {shift.tasks && (
            <View style={{ marginTop: 6 }}>
              {shift.tasks?.map((task) => (
                <SIText key={`shift_${id}_task_${task.id}`} style={styles.bottomText}>{`${task.name} ${moment
                  .unix(task.start!)
                  .format('HH:mm')} - ${moment.unix(task.end!).format('HH:mm')}`}</SIText>
              ))}
            </View>
          )}
          <View
            style={{
              marginTop:
                shift.section || shift.skills || shift.attributes || shift?.pendingRequest || !fixed_view ? 6 : 0,
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {shift?.section && (
                  <Tag
                    key={`shift_${shift.id}_section`}
                    backgroundColor={shift?.section?.background}
                    color={shift?.section?.color}
                  >
                    {shift?.section.name || ''}
                  </Tag>
                )}
                {shift.skills?.map((skill) => (
                  <Tag
                    key={`shift_${shift.id}_skill_${skill.id}`}
                    backgroundColor={skill.background}
                    color={skill.color}
                  >
                    {skill.name || ''}
                  </Tag>
                ))}
                {shift.attributes?.map((attribut) => (
                  <Tag
                    key={`shift_${shift.id}_attribut_${attribut.id}`}
                    backgroundColor={attribut.background}
                    color={attribut.color}
                  >
                    {attribut.name || ''}
                  </Tag>
                ))}
              </View>
            </View>
            {shift?.userRecordId && shift?.pendingRequest && (
              <View>
                <SIText style={{ color: colors.grey }}>{t('SHIFTS.PENDING_REQUEST')}</SIText>
              </View>
            )}
            {isOnline &&
              !fixed_view &&
              shift?.userRecordId &&
              !shift?.dayoff &&
              !shift.pendingRequest &&
              shift.start! > timestamp + (department?.clockinParams?.requestModificationMaxHour || 0) * 3600 && (
                <SIButton
                  title={t('GENERAL.MODIFY')}
                  backgroundColor={colors.red}
                  width="auto"
                  onPress={onShiftDetails}
                />
              )}
            {((isOnline &&
              shift?.userRecordId &&
              !shift?.dayoff &&
              !shift.pendingRequest &&
              department?.clockin &&
              department?.clockinParams?.enableMobileClockin &&
              moment
                .unix(shift.start! - department!.clockinParams!.authorizeCheckingBefore!)
                .isSame(moment.unix(now), 'day')) ||
              (!isOnline &&
                shift?.userRecordId &&
                !shift?.dayoff &&
                !shift.pendingRequest &&
                department?.clockin &&
                department?.clockinParams?.enableMobileClockin &&
                !no_offline_clockings_departments_ids.includes(department?.id) &&
                moment
                  .unix(shift.start! - department!.clockinParams!.authorizeCheckingBefore!)
                  .isSame(moment.unix(now), 'day') &&
                dataState.no_offline_clockings_departments &&
                dataState.freeclockin_departments &&
                dataState.no_offline_clockings_departments.length < dataState.freeclockin_departments.length)) && (
              <SIButton
                title={t('CLOCKING.CHECK_IN')}
                backgroundColor={colors.green}
                width="auto"
                onPress={onShiftDetails}
              />
            )}
            {!shift?.userRecordId && !shift.processing && (
              <SIButton title={t('SHIFTS.BOOK')} width="auto" onPress={onShiftDetails} />
            )}
            {!shift?.userRecordId && !!shift.processing && (
              <View>
                <SIText style={{ color: colors.grey }}>{t('SHIFTS.PENDING_REQUEST')}</SIText>
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
};
const styles = StyleSheet.create({
  shiftContainer: {
    width: '100%',
    padding: 25,
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: '#fff',
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
    elevation: 2,
  },
  freeShiftContainer: {
    width: '100%',
    backgroundColor: colors.greenExtraLight,
    padding: 25,
    paddingVertical: 15,
    borderRadius: 10,
    borderColor: colors.greenLight,
    borderWidth: 1,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
    elevation: 2,
  },
  dayoffContainer: {
    width: '100%',
    backgroundColor: colors.redExtraLight,
    padding: 25,
    paddingVertical: 15,
    borderRadius: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
    elevation: 2,
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },
  topRightText: {
    textAlign: 'right',
    fontSize: 11,
  },
  bottomText: {
    textAlign: 'left',
    fontSize: 11,
  },
  packageParentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  packageContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignSelf: 'flex-start',
  },
  package: {
    fontFamily: 'Poppins_700Bold',
  },
});

export { styles };
export default ShiftCard;
