import {
  DatePicker as MuiDatePicker,
  DateTimePicker as MuiDateTimePicker,
  TimePicker as MuiTimePicker,
} from '@material-ui/pickers';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Platform, StyleProp, TextStyle } from 'react-native';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  control: Control<Record<string, any>>;
  mode?: 'time' | 'date' | 'datetime' | 'countdown';
  name: string;
  rules?: {
    required?: boolean;
    validate?: (value: any) => boolean;
  };
  defaultValue?: Date;
  style?: StyleProp<TextStyle>;
  display?: 'spinner' | 'default' | 'clock' | 'calendar';
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  show?: boolean;
  setDate?: React.Dispatch<React.SetStateAction<any>>;
}

const DatePicker: React.FC<Props> = ({
  control,
  name,
  rules,
  defaultValue,
  style,
  mode,
  show,
  setShow,
  display = 'default',
  setDate,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue || moment().toDate()}
      render={({ value, onChange }) => {
        if (!show) {
          return null;
        }
        if (Platform.OS === 'web') {
          switch (mode) {
            case 'time':
              return <MuiTimePicker format="LT" value={value} onChange={onChange} />;
            case 'datetime':
              return <MuiDateTimePicker format="LLL" value={value} onChange={onChange} />;
            default:
              return <MuiDatePicker format="L" value={value} onChange={onChange} />;
          }
        } else {
          return (
            <DateTimePicker
              display={display}
              style={style}
              mode={mode}
              value={typeof value == 'number' ? moment.unix(value / 1000).toDate() : value}
              onChange={(event, date) => {
                if (Platform.OS === 'android' && setShow) {
                  setShow(false);
                }
                if (date) {
                  onChange(date);
                  setDate ? setDate(date) : null;
                }
              }}
              timeZoneOffsetInMinutes={0}
            />
          );
        }
      }}
    />
  );
};

export default DatePicker;
