import { useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import colors from '../styles/colors';
import { IClocking } from '../types/clocking.model';
import Icon from './Icon';
import SIButton from './SIButton';
import SIText from './SIText';

let Modal: any;

if (Platform.OS !== 'web') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  Modal = require('react-native').Modal;
} else {
  Modal = require('modal-react-native-web');
}

interface Props {
  pictures: IClocking['pictures'];
  addActionType: string;
  removeActionType: string;
}

const Pictures: React.FC<Props> = ({ pictures, addActionType, removeActionType }) => {
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const reduxDispatch: Dispatch<any> = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loadingPicture, setLoadingPicture] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          // alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const onPickImage = async () => {
    setLoadingPicture(true);
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 0.5,
    });

    const uri = `${FileSystem.documentDirectory}${new Date().getTime()}`;

    if (!result.cancelled) {
      try {
        await FileSystem.copyAsync({
          from: result.uri,
          to: uri,
        });

        reduxDispatch({
          type: addActionType,
          payload: uri,
        });

        setModalVisible(false);
        setLoadingPicture(false);
      } catch (e) {
        setLoadingPicture(false);
        console.log(e);
      }
    } else {
      setLoadingPicture(false);
    }
  };

  const onAddPicture = () => {
    navigation.navigate('TakePicture', { addActionType });
  };

  const onOpenPicture = (uri: string) => {
    navigation.navigate('ViewPicture', {
      uri,
      removeActionType,
    });
  };

  return (
    <View style={styles.container}>
      <SIText>{t('CLOCKING.PICTURES')}</SIText>
      <View style={{ marginTop: 10 }}>
        <FlatList
          horizontal={true}
          data={pictures}
          inverted={true}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity onPress={() => onOpenPicture(item.uri)}>
                <Image style={styles.picture} resizeMode="cover" source={{ uri: item.uri }} />
              </TouchableOpacity>
            );
          }}
          ListHeaderComponent={!!pictures?.length ? () => <AddPicture setModalVisible={setModalVisible} /> : undefined}
          ListEmptyComponent={!pictures?.length ? () => <AddPicture setModalVisible={setModalVisible} /> : undefined}
          keyExtractor={(item, index) => `${index}`}
        />
      </View>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View style={styles.centeredView}>
          {!loadingPicture && (
            <View style={styles.modalView}>
              <SIButton
                size="large"
                onPress={() => {
                  setModalVisible(!modalVisible);
                  onAddPicture();
                }}
                title={t('CLOCKING.TAKE_PICTURE')}
              />
              <SIButton
                backgroundColor={colors.grey}
                style={{ marginTop: 20 }}
                size="large"
                onPress={() => {
                  onPickImage();
                }}
                title={t('CLOCKING.PICK_PICTURE')}
              />
              <SIButton
                backgroundColor={'#ffffff'}
                color={colors.grey}
                style={{ marginTop: 20 }}
                size="large"
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
                title={t('GENERAL.CANCEL')}
              />
            </View>
          )}
          {loadingPicture && (
            <View style={styles.modalView}>
              <ActivityIndicator size="large" />
            </View>
          )}
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'white',
    // borderRadius: 10,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,
    // elevation: 2,
    // padding: 25,
  },
  picture: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
  },
  modalView: {
    display: 'flex',
    alignSelf: 'stretch',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default Pictures;

function AddPicture({ setModalVisible }: { setModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  return (
    <View
      style={[
        styles.picture,
        {
          display: 'flex',
          borderColor: colors.green,
          borderWidth: 1,
          borderStyle: 'dashed',
          marginRight: 0,
          flex: 1,
        },
      ]}
    >
      <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
      >
        <Icon name="camera" size={40} color={colors.green} />
      </TouchableOpacity>
    </View>
  );
}
