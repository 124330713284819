import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { IUserDetails } from '../types/userDetails.model';
import H1 from './H1';
import SIButton from './SIButton';
import SIText from './SIText';
import Constants from 'expo-constants';
import { IShift } from '../types/shift.model';
import axios from 'axios';
import { useNavigation } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';
import colors from '../styles/colors';

interface Props {
  visible: boolean;
  loading: boolean;
  constraints?: string[];
  constraintsDetails?: any;
  setForce: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalForceShift: React.FC<Props> = ({
  visible,
  loading,
  constraints,
  constraintsDetails,
  setForce,
  setVisible,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onBackdropPress = () => {
    setVisible(false);
  };

  const onForce = () => {
    setForce(true);
  };

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible} onBackdropPress={onBackdropPress}>
      <View style={styles.container}>
        {constraints &&
          constraints.map((constraint, i) => {
            const details = constraintsDetails[i];
            return (
              <SIText key={`${constraint}`} style={styles.text}>
                {details
                  ? t(`SHIFTS.CONSTRAINTS.${constraint}`, { constraint: details.constraint })
                  : t(`SHIFTS.CONSTRAINTS.${constraint}`)}
              </SIText>
            );
          })}
        <SIButton
          style={styles.button}
          backgroundColor={colors.red}
          title={t('GENERAL.FORCE')}
          onPress={onForce}
          loading={loading}
          disabled={loading}
        />
        <SIButton
          style={styles.button}
          backgroundColor="transparent"
          color={colors.black}
          title={t('GENERAL.CANCEL')}
          onPress={() => setVisible(false)}
          disabled={loading}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    marginTop: 15,
  },
});

export default ModalForceShift;
