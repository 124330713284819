import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import SIButton from '../../../components/SIButton';
import SIText from '../../../components/SIText';
import colors from '../../../styles/colors';
import { IClocking } from '../../../types/clocking.model';
import { IDepartment } from '../../../types/department.model';
import { IShift } from '../../../types/shift.model';
import { ITask } from '../../../types/task.model';
import Task from './Task';

interface Props {
  tasks: ITask[];
  activeDepartment: IDepartment;
  currentClocking?: IClocking;
  shifts?: IShift[];
}

const Tasks: React.FC<Props> = ({ tasks, activeDepartment, currentClocking, shifts }) => {
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onPressAddTask = () => {
    navigation.navigate('ClockingAddTask');
  };

  const foundShift = currentClocking && shifts ? shifts?.find((shift) => shift.id == currentClocking.shiftId) : null;

  const allTasks =
    foundShift && foundShift.tasks
      ? [...tasks, ...foundShift.tasks!.filter((t) => !tasks?.map((task) => task.taskTypeId).includes(t.taskTypeId))]
      : tasks;

  return (
    <View style={styles.container}>
      <View>
        {allTasks && allTasks.map((task) => <Task currentClocking={currentClocking} task={task} key={task.id} />)}
        {activeDepartment && activeDepartment.clockinParams && activeDepartment.clockinParams.enableMobileClockin && (
          <View style={styles.subContainer}>
            <View style={{ justifyContent: 'center' }}>
              <SIText>{t('CLOCKING.ADD_TASK')}</SIText>
            </View>
            <View>
              <SIButton
                title={t('GENERAL.ADD')}
                backgroundColor="white"
                color={colors.green}
                borderColor={colors.green}
                onPress={onPressAddTask}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  subContainer: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default Tasks;
