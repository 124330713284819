import { applyMiddleware, createStore } from 'redux';
import dataReducer, { DataInitialState } from './reducers/dataReducer';
import authReducer, { AuthInitialState } from './reducers/authReducer';
import clockingReducer, { ClockingInitialState } from './reducers/clockingReducer';
import signatureReducer, { SignatureInitialState } from './reducers/signatureReducer';
import interventionReducer, { InterventionInitialState } from './reducers/interventionReducer';
import holidayReducer, { HolidayInitialState } from './reducers/holidayReducer';
import { combineReducers } from 'redux';
import AuthActionsType from './actions/authActions';
import DataActionsType from './actions/dataActions';
import ClockingActionType from './actions/clockingActions';
import SignatureActionType from './actions/signatureActions';
import { createOffline } from '@redux-offline/redux-offline';
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults';
import Axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import FormData from 'form-data';
import { Platform } from 'react-native';
import { setAxiosHeaders } from '../utils';
import * as Sentry from 'sentry-expo';
import { showMessage } from 'react-native-flash-message';
import i18next from 'i18next';

const VERSION_REDUCER_KEY = 'appVersion';

// For migrations, change the persistConfig.version number and put the changes here
// const migrations = {
//   1: (state: InitialState) => {
//     return {
//       ...state,
//       auth: {
//         ...state.auth,
//         userDetails: {
//           ...state.auth.userDetails,
//           role: 'USER',
//         },
//       },
//     };
//   },
// };

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 1,
  // migrate: createMigrate(migrations),
  blacklist: ['signatures', 'intervention', 'holiday'],
};

const getStoreInstance = () => store;

const customEffect = (effect: any, action: any) => {
  try {
    const { optimisticId, formData } = effect;
    const { meta } = action;
    const store = getStoreInstance();
    const state = store.getState();
    setAxiosHeaders(state.auth.accessToken);

    if (optimisticId) {
      const id = state.clocking.optimisticIdsToIds[Object.keys(state.clocking.optimisticIdsToIds)[0]];
      if (id) {
        effect.url = (effect.url as string).replace('$id', id);
      } else {
        if (Platform.OS !== 'web') {
          Sentry.Native.captureException(new Error('no optimistic id'), {
            extra: {
              optimisticId,
              url: effect.url,
              effect: JSON.stringify(effect),
              action: JSON.stringify(action),
              optimisticIdsToIds: state.clocking.optimisticIdsToIds,
            },
          });
        } else {
          Sentry.Browser.captureException(new Error('no optimistic id'), {
            extra: {
              optimisticId,
              url: effect.url,
              effect: JSON.stringify(effect),
              action: JSON.stringify(action),
              optimisticIdsToIds: state.clocking.optimisticIdsToIds,
            },
          });
        }
        return Promise.reject();
      }
    }

    if (formData) {
      const myFormData = new FormData();
      switch (formData.type) {
        case 'CLOCKING_CHECK_OUT': {
          const { pictures, tools, checkOut, infos } = formData.data;

          if (checkOut) {
            myFormData.append('checkOut', JSON.stringify(checkOut));
          }

          if (tools && tools.length) {
            myFormData.append('tools', JSON.stringify(tools));
          }

          if (pictures) {
            const picturesLength = pictures.length;
            for (let i = 0; i < picturesLength; i++) {
              const { uri, unix } = pictures[i];
              myFormData.append('pictures[]', {
                name: `${unix}`,
                uri: Platform.OS === 'android' ? uri : uri.replace('file://', ''),
                type: 'image/jpeg',
              });
            }
          }

          if (infos) {
            const keys = Object.keys(infos).map((key) => `infos[${key}]`);
            const results = Object.values(infos);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = results[i];
              if (!value) continue;
              myFormData.append(key, value);
            }
          }

          break;
        }
      }
      const request = Axios({
        url: effect.url,
        method: effect.method,
        data: myFormData,
        headers: {
          ...Axios.defaults.headers.common,
          'Content-Type': 'multipart/form-data',
        },
      });
      request.catch((error) => {
        if (meta && meta.showError && meta.dispatch) {
          if (error.response && error.response.data && error.response.data.errors) {
            const err = Object.values(error.response.data.errors).flat(1)[0];
            if (typeof err == 'string') {
              showMessage({
                message: err,
                type: 'danger',
                duration: 2500,
              });
              if (meta.dispatch) {
                meta.dispatch({
                  type: 'CLOCKING_SET_CURRENT_CLOCKING',
                  payload: null,
                });
              }
            }
          } else {
            showMessage({
              message: i18next.t('CLOCKING.CHECK_IN_ERROR'),
              type: 'danger',
              duration: 2500,
            });
            if (meta.dispatch) {
              meta.dispatch({
                type: 'CLOCKING_SET_CURRENT_CLOCKING',
                payload: null,
              });
            }
          }
        }
        if (Platform.OS !== 'web') {
          Sentry.Native.captureException(error, {
            extra: {
              optimisticId,
              url: effect.url,
              data: myFormData.toString(),
              effect: JSON.stringify(effect),
              action: JSON.stringify(action),
              optimisticIdsToIds: state.clocking.optimisticIdsToIds,
            },
          });
        } else {
          Sentry.Browser.captureException(error, {
            extra: {
              optimisticId,
              url: effect.url,
              data: myFormData.toString(),
              effect: JSON.stringify(effect),
              action: JSON.stringify(action),
              optimisticIdsToIds: state.clocking.optimisticIdsToIds,
            },
          });
        }
      });
      return request;
    }

    const request = Axios({
      ...effect,
    });

    request.catch((error) => {
      if (meta && meta.showError) {
        if (error.response && error.response.data && error.response.data.errors) {
          const err = Object.values(error.response.data.errors).flat(1)[0];
          if (typeof err == 'string') {
            showMessage({
              message: err,
              type: 'danger',
              duration: 2500,
            });
            if (meta.dispatch) {
              meta.dispatch({
                type: 'CLOCKING_SET_CURRENT_CLOCKING',
                payload: null,
              });
            }
          }
        } else {
          showMessage({
            message: i18next.t('CLOCKING.CHECK_IN_ERROR'),
            type: 'danger',
            duration: 2500,
          });
          if (meta.dispatch) {
            meta.dispatch({
              type: 'CLOCKING_SET_CURRENT_CLOCKING',
              payload: null,
            });
          }
        }
      }
      if (Platform.OS !== 'web') {
        Sentry.Native.captureException(error, {
          extra: {
            optimisticId,
            url: effect.url,
            effect: JSON.stringify(effect),
            action: JSON.stringify(action),
            optimisticIdsToIds: state.clocking.optimisticIdsToIds,
          },
        });
      } else {
        Sentry.Browser.captureException(error, {
          extra: {
            optimisticId,
            url: effect.url,
            effect: JSON.stringify(effect),
            action: JSON.stringify(action),
            optimisticIdsToIds: state.clocking.optimisticIdsToIds,
          },
        });
      }
    });

    return request;
  } catch (error) {
    if (Platform.OS !== 'web') {
      Sentry.Native.captureException(error, {
        extra: {
          url: effect.url,
          effect: JSON.stringify(effect),
          action: JSON.stringify(action),
          error: JSON.stringify(error),
        },
      });
    } else {
      Sentry.Browser.captureException(error, {
        extra: {
          url: effect.url,
          effect: JSON.stringify(effect),
          action: JSON.stringify(action),
          error: JSON.stringify(error),
        },
      });
    }
  }
};

export type InitialState = {
  [VERSION_REDUCER_KEY]: any;
  offline: any;
  auth: AuthInitialState;
  data: DataInitialState;
  clocking: ClockingInitialState;
  signatures: SignatureInitialState;
  intervention: InterventionInitialState;
  holiday: HolidayInitialState;
};

const rootReducer = combineReducers<InitialState>({
  [VERSION_REDUCER_KEY]: (state = {}) => state,
  offline: (state = {}) => state,
  auth: authReducer,
  data: dataReducer,
  clocking: clockingReducer,
  signatures: signatureReducer,
  intervention: interventionReducer,
  holiday: holidayReducer,
});

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...defaultOfflineConfig,
  effect: customEffect,
  persist: false,
});

const persistedReducer = persistReducer(persistConfig, offlineEnhanceReducer(rootReducer));

export const store = createStore(
  persistedReducer,
  composeWithDevTools(offlineEnhanceStore, applyMiddleware(offlineMiddleware)),
);

export const persistor = persistStore(store as any); // purge() for removing store on real devices

export type ActionType = AuthActionsType | DataActionsType | ClockingActionType | SignatureActionType;
