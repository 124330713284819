import React from 'react';
import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import colors from '../styles/colors';
import Icon from './Icon';
import SIText from './SIText';

type Size = 'normal' | 'large' | 'small';
interface Props {
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  title?: string;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  width?: string;
  size?: Size;
  style?: StyleProp<TextStyle>;
  loading?: boolean;
  icon?: string;
  disabled?: boolean;
}

const SIButton: React.FC<Props> = ({
  onPress,
  title,
  icon,
  color = 'white',
  backgroundColor = colors.green,
  borderColor = backgroundColor,
  width = '100%',
  size = 'normal',
  style,
  loading,
  disabled,
}) => {
  return (
    <View style={[{ width }, style]}>
      <TouchableOpacity
        disabled={disabled}
        onPress={loading ? undefined : onPress}
        style={[
          styles.buttonContainer,
          styles[`btn-${size}`],
          { backgroundColor: backgroundColor, borderColor, borderWidth: 1 },
        ]}
      >
        {title && <SIText style={[styles.buttonText, styles[`text-${size}`], { color }]}>{title}</SIText>}
        {icon && (
          <Icon
            style={[styles.buttonText, styles[`text-${size}`], { color, marginLeft: 10, alignSelf: 'center' }]}
            name={icon}
          />
        )}
        {loading && <ActivityIndicator style={{ paddingLeft: 10 }} color="white" size="small" />}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  'btn-large': {
    paddingVertical: 10,
    paddingHorizontal: 18,
  },
  'btn-normal': {
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  'btn-small': {
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  'text-large': {
    fontSize: 16,
    lineHeight: 32,
  },
  'text-normal': {
    fontSize: 13,
    lineHeight: 26,
  },
  'text-small': {
    fontSize: 11,
    lineHeight: 22,
  },
  buttonContainer: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Poppins_700Bold',
  },
});

export default SIButton;
