import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { offline } from '@redux-offline/redux-offline';
import axios from 'axios';
import Axios from 'axios';
import Constants from 'expo-constants';
import { difference } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../components/Back';
import Checkbox from '../components/Checkbox';
import DateInput from '../components/DateInput';
import Icon from '../components/Icon';
import Input from '../components/Input';
import ModalDeleteShift from '../components/ModalDeleteShift';
import ModalForceShift from '../components/ModalForceShift';
import Select from '../components/Select';
import SIButton from '../components/SIButton';
import TextArea from '../components/TextArea';
import { ActionType, InitialState } from '../store';
import colors from '../styles/colors';
import { IShift } from '../types/shift.model';
import { IUser } from '../types/user.model';
import { localDateToUnixUTC } from '../utils';

moment.tz.setDefault('Atlantic/Reykjavik');

type NavigationParams = {
  CreateNewShift: {
    shift?: IShift;
  };
};

type Props = StackScreenProps<NavigationParams, 'CreateNewShift'>;

const CreateNewShiftScreen: React.FC<Props> = ({ route }) => {
  const { params } = route;
  const active_shift = params && params.shift;
  const { departments, access_shifts_departments } = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const authState = useSelector((store: InitialState) => store.auth);
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const navigation = useNavigation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [availableUsers, setAvailableUsers] = useState<IUser[]>([]);
  const [modalDeleteShiftVisible, setModalDeleteShiftVisible] = useState<boolean>(false);
  const [modalForceShiftVisible, setModalForceShiftVisible] = useState<boolean>(false);
  const [force, setForce] = useState<boolean>(false);
  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const [constraints, setConstraints] = useState<string[]>([]);
  const [constraintsDetails, setConstraintsDetails] = useState<any>({});
  const isOnline = !!offlineState?.online;

  const defaultValues = (): any => {
    return {
      departmentId: active_shift ? active_shift.departmentId : null,
      date: active_shift ? moment.unix(active_shift.start!).startOf('day').toDate() : moment().startOf('day').toDate(),
      start: active_shift ? moment.unix(active_shift.start!).toDate() : moment().startOf('day').toDate().setUTCHours(9),
      end: active_shift ? moment.unix(active_shift.end!).toDate() : moment().startOf('day').toDate().setUTCHours(17),
      userRecordId: active_shift ? active_shift.userRecordId : null,
      sectionId: active_shift ? (active_shift.section ? active_shift.section.id : null) : null,
      paid: active_shift && active_shift.pause && active_shift.pause.paid ? String(active_shift.pause.paid / 60) : null,
      unpaid:
        active_shift && active_shift.pause && active_shift.pause.unpaid ? String(active_shift.pause.unpaid / 60) : null,
      comment: active_shift && active_shift.comment ? active_shift.comment : null,
    };
  };
  const { handleSubmit, getValues, errors, control, watch, setValue } = useForm({
    defaultValues: defaultValues(),
  });
  const watchDepartment = watch('departmentId', null);
  const watchDate = watch('date', null);
  const watchStart = watch('start', null);
  const watchEnd = watch('end', null);
  const watchUser = watch('userRecordId', null);
  const watchSection = watch('sectionId', null);
  const watchSkill = watch('skillId', null);
  const activeDepartment = departments.find((department) => department.id === watchDepartment);
  const activeUser = availableUsers.find((user) => user.recordId === watchUser);

  useEffect(() => {
    const activeDepartment = access_shifts_departments.find((department) => department.id == watchDepartment);
    if (!activeDepartment) return;

    if (activeDepartment.scheduleParams.default_shift_start_time && !active_shift) {
      setValue(
        'start',
        moment().startOf('day').toDate().setUTCHours(activeDepartment.scheduleParams.default_shift_start_time),
      );
    }
    if (activeDepartment.scheduleParams.default_shift_end_time && !active_shift) {
      setValue(
        'end',
        moment().startOf('day').toDate().setUTCHours(activeDepartment.scheduleParams.default_shift_end_time),
      );
    }
  }, [watchDepartment]);

  useEffect(() => {
    const date = moment(watchDate).format('YYYY-MM-DD');
    let startHour = watchStart;
    let endHour = watchEnd;

    if (`${startHour}`) {
      startHour = moment(startHour).format();
    }
    if (`${endHour}`) {
      endHour = moment(endHour).format();
    }

    if (`${startHour}`.includes('T')) {
      startHour = startHour.split('T')[1];
    } else {
      startHour = moment(startHour).format('HH:mm');
    }
    if (`${endHour}`.includes('T')) {
      endHour = endHour.split('T')[1];
    } else {
      endHour = moment(endHour).format('HH:mm');
    }

    if (activeDepartment) {
      const start = activeDepartment.timezone
        ? moment.tz(`${date} ${startHour}`, activeDepartment?.timezone)
        : moment(`${date} ${startHour}`);
      const end = activeDepartment.timezone
        ? moment.tz(`${date} ${endHour}`, activeDepartment?.timezone)
        : moment(`${date} ${endHour}`);

      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/operations/get-users`,
        {
          params: {
            departmentId: activeDepartment.id,
            start: active_shift ? active_shift.start : start ? start.unix() : moment().startOf('day').unix(),
            end: active_shift ? active_shift.end : end ? end.unix() : moment().endOf('day').unix(),
            userRecordId: active_shift ? active_shift.userRecordId : undefined,
            pause: active_shift ? active_shift.pause?.unpaid : undefined,
            shiftId: active_shift ? active_shift.id : undefined,
            section: watchSection ? watchSection : undefined,
            skills: watchSkill ? [watchSkill] : undefined,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then(({ data }) => {
          setAvailableUsers(data);
        })
        .catch((err) => {});
    }
  }, [activeDepartment, watchSection, watchUser, watchSkill, watchDate, watchStart, watchEnd]);

  useFocusEffect(
    useCallback(() => {
      if (!watchDepartment && access_shifts_departments && access_shifts_departments?.length === 1) {
        setTimeout(() => {
          setValue('departmentId', access_shifts_departments[0].id);
        }, 0);
      }
    }, []),
  );

  useEffect(() => {
    if (force) {
      setForceLoading(true);
      onSubmit(getValues());
    }
  }, [force]);

  const onSubmit = (data: any) => {
    setIsSaving(true);
    const { sectionId, departmentId, comment, userRecordId, skillId, paid = 0, unpaid = 0, notification } = data;
    const attributes = Object.entries(data)
      .filter((entry) => entry[0].startsWith('resource_') && !!entry[1])
      .map((resource) => resource[1] as string);
    const dateMoment = moment(watchDate);
    const startMoment = moment(watchStart);
    const endMoment = moment(watchEnd);
    const startUnix = moment(`${dateMoment.format('YYYY-MM-DD')}T${startMoment.format('HH:mm:ss')}`).unix();
    const endUnix = moment(`${dateMoment.format('YYYY-MM-DD')}T${endMoment.format('HH:mm:ss')}`).unix();

    if (!active_shift) {
      Axios.post(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts`,
        {
          start: startUnix,
          end: endUnix < startUnix ? startUnix + 86400 : endUnix,
          attributes,
          departmentId,
          section: sectionId,
          comment,
          userRecordId,
          skills: skillId ? [skillId] : [],
          pause: { paid: parseInt(paid || 0) * 60, unpaid: parseInt(unpaid || 0) * 60 },
          approved: true,
          notification,
          force,
        },
      )
        .then((response) => {
          navigation.navigate('Dashboard');
          if (userRecordId) {
            const active_department = departments.find((department) => department.id === departmentId);
            if (active_department) {
              const user = activeDepartment?.users?.find((user) => user.recordId == userRecordId);
              showMessage({
                message: t('SHIFTS.FLASH_MESSAGES.CREATED', { name: user?.displayName }),
                type: 'success',
              });
            }
          } else {
            showMessage({
              message: t('SHIFTS.FLASH_MESSAGES.FREE_SHIFT_CREATED'),
              type: 'success',
            });
          }
          const { shifts } = response.data;
          reduxDispatch({
            type: 'DATA_ADD_SHIFTS',
            payload: (shifts || []).filter(
              (shift: IShift) => shift.available || shift.userMainId === authState.userDetails?.id,
            ),
          });
          setForceLoading(false);
          setIsSaving(false);
        })
        .catch((error) => {
          const constraints = Object.values(error.response.data.message)
            .flat(1)
            .map((obj: any) => obj.title) as string[];
          const constraints_details = Object.values(error.response.data.message)
            .flat(1)
            .map((obj: any) => obj.details);
          setIsSaving(false);
          setConstraints(constraints);
          setConstraintsDetails(constraints_details);
          if (!constraints.includes('WORKING')) {
            setModalForceShiftVisible(data);
          } else {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('SHIFTS.ALERTS.SHIFT_CREATION_FAILURE_MESSAGE')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('SHIFTS.ALERTS.SHIFT_CREATION_FAILURE_MESSAGE'));
            }
          }
        });
    } else {
      const dateMoment = moment(watchDate);
      const startMoment = moment(watchStart);
      const endMoment = moment(watchEnd);
      const startUnix = moment(`${dateMoment.format('YYYY-MM-DD')}T${startMoment.format('HH:mm:ss')}`).unix();
      const endUnix = moment(`${dateMoment.format('YYYY-MM-DD')}T${endMoment.format('HH:mm:ss')}`).unix();
      //EDIT SHIFT
      Axios.patch(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts/${
          active_shift.id
        }`,
        {
          start: startUnix,
          end: endUnix < startUnix ? startUnix + 86400 : endUnix,
          departmentId,
          section: sectionId,
          comment,
          userRecordId,
          pause: { paid: parseInt(paid || 0) * 60, unpaid: parseInt(unpaid || 0) * 60 },
          approved: true,
          force,
        },
      )
        .then(({ data }) => {
          setForceLoading(false);
          navigation.goBack();
          showMessage({
            message: t('SHIFTS.FLASH_MESSAGES.EDITED'),
            type: 'success',
          });
          setIsSaving(false);
        })
        .catch((error) => {
          console.log(error.response.data);

          const constraints = Object.values(error.response.data.message)
            .flat(1)
            .map((obj: any) => obj.title) as string[];
          const constraints_details = Object.values(error.response.data.message)
            .flat(1)
            .map((obj: any) => obj.details);
          setIsSaving(false);
          setConstraints(constraints);
          setConstraintsDetails(constraints_details);
          if (!constraints.includes('WORKING')) {
            setModalForceShiftVisible(data);
          } else {
            showMessage({
              message: t('SHIFTS.FLASH_MESSAGES.EDIT_ERROR'),
              type: 'danger',
            });
          }
        });
    }
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      {active_shift ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 25,
          }}
        >
          <Back title={active_shift ? t('SHIFTS.SHIFT_EDIT') : t('SHIFTS.SHIFT_CREATION')} />
          {loadingDelete ? (
            <ActivityIndicator color={colors.green} style={{ marginTop: 25, marginRight: 25 }} />
          ) : (
            <TouchableOpacity
              onPress={() => setModalDeleteShiftVisible(true)}
              style={{ marginTop: 25, marginRight: 25 }}
            >
              <Icon name="trash-empty" size={24} color={colors.red} />
            </TouchableOpacity>
          )}
        </View>
      ) : (
        <Back title={active_shift ? t('SHIFTS.SHIFT_EDIT') : t('SHIFTS.SHIFT_CREATION')} />
      )}
      <ScrollView style={styles.container}>
        {isOnline &&
          activeDepartment?.modules?.dimona &&
          active_shift &&
          active_shift.dimona &&
          active_shift.dimonaQualify &&
          !active_shift.dimonaValid && (
            <SIButton
              title={t('DIMONA.CREATE_DIMONA')}
              backgroundColor={colors.orange}
              onPress={() => navigation.navigate('CreateNewDimona', { shift: active_shift })}
              style={{ marginBottom: 25, marginTop: -25 }}
            />
          )}
        <View style={styles.form}>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={access_shifts_departments.map((department) => ({
              label: department.company!,
              value: department.id!,
            }))}
            style={{ display: active_shift ? 'none' : 'flex' }}
          />
          {!!activeDepartment?.params?.enableSection && !!activeDepartment?.sections?.length && (
            <Select
              label={t('SHIFTS.SECTION')}
              control={control}
              defaultValue={null}
              name="sectionId"
              items={
                watchUser
                  ? activeDepartment?.sections
                      ?.filter((section) => activeUser?.sections?.find((s) => s.id == section.id))
                      .map((section) => ({ label: section.name!, value: section.id! })) || []
                  : activeDepartment?.sections?.map((section) => ({ label: section.name!, value: section.id! })) || []
              }
            />
          )}
          {!!activeDepartment?.users?.length && (
            <Select
              placeholder={t('SHIFTS.FREE_SHIFT')}
              label={t('SHIFTS.EMPLOYEE')}
              control={control}
              defaultValue={null}
              name="userRecordId"
              items={
                !active_shift
                  ? availableUsers
                      ?.filter((user) => !user.constraints)
                      .map((user) => ({ label: user.displayName!, value: user.recordId! })) || []
                  : availableUsers?.map((user) => ({ label: user.displayName!, value: user.recordId! })) || []
              }
            />
          )}
          {!active_shift && !!activeDepartment?.modules?.skills && !!activeDepartment?.skills?.length && (
            <Select
              label={t('SHIFTS.SKILL')}
              control={control}
              defaultValue={null}
              name="skillId"
              items={activeDepartment?.skills?.map((skill) => ({ label: skill.name!, value: skill.id! })) || []}
            />
          )}
          {!active_shift &&
            !!activeDepartment?.modules?.resources &&
            activeDepartment?.resources?.map((resource) => (
              <Select
                key={`resource_${resource.id!}`}
                label={resource.name!}
                control={control}
                defaultValue={null}
                name={`resource_${resource.id!}`}
                items={resource?.attributes?.map((attribut) => ({ label: attribut.name!, value: attribut.id! })) || []}
              />
            ))}
          {activeDepartment && (
            <View>
              <Input
                label={`${t('SHIFTS.PAID_PAUSE')} (${t('GENERAL.MINUTES')})`}
                control={control}
                defaultValue={null}
                keyboardType={'numeric'}
                name="paid"
              />
              <Input
                label={`${t('SHIFTS.UNPAID_PAUSE')} (${t('GENERAL.MINUTES')})`}
                control={control}
                defaultValue={null}
                keyboardType={'numeric'}
                name="unpaid"
              />
              <TextArea label={t('GENERAL.COMMENT')} control={control} name="comment" defaultValue={null} />
              <DateInput
                control={control}
                name="date"
                label={t('GENERAL.DATE')}
                rules={{ required: true }}
                mode={'date'}
                watch={watch}
                errors={errors}
              />
              <DateInput
                control={control}
                name="start"
                label={t('SHIFTS.START')}
                rules={{ required: true }}
                mode={'time'}
                watch={watch}
                errors={errors}
              />
              <DateInput
                control={control}
                name="end"
                label={t('SHIFTS.END')}
                rules={{
                  required: true,
                }}
                mode={'time'}
                errors={errors}
                watch={watch}
              />
            </View>
          )}
          {!active_shift && activeDepartment && !watchUser && (
            <Checkbox
              control={control}
              label={t('SHIFTS.SEND_THE_SHIFT_TO_QUALIFIED_PEOPLE')}
              name="notification"
              style={{ marginBottom: 15 }}
            />
          )}
          <SIButton title={t('GENERAL.CONFIRM')} size="large" loading={isSaving} onPress={handleSubmit(onSubmit)} />
        </View>
      </ScrollView>
      {active_shift && (
        <ModalDeleteShift
          shift={active_shift}
          visible={modalDeleteShiftVisible}
          setVisible={setModalDeleteShiftVisible}
          loadingDelete={loadingDelete}
          setLoadingDelete={setLoadingDelete}
        />
      )}
      <ModalForceShift
        visible={modalForceShiftVisible}
        loading={forceLoading}
        setVisible={setModalForceShiftVisible}
        setForce={setForce}
        constraints={constraints}
        constraintsDetails={constraintsDetails}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  form: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    marginBottom: Platform.OS !== 'web' ? 150 : 50,
  },
});

export default CreateNewShiftScreen;
