import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import H1 from '../../components/H1';
import SIButton from '../../components/SIButton';
import colors from '../../styles/colors';

interface Props {
  unavailability: any;
  onRemoveUnavailability: (id: string) => Promise<void>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalConfirmDelete: React.FC<Props> = ({ unavailability, visible, onRemoveUnavailability, setVisible }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const onDelete = () => {
    setLoading(true);
    onRemoveUnavailability(unavailability.id).finally(() => {
      setLoading(false);
    });
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <Modal animationIn="slideInUp" isVisible={visible} onBackdropPress={hideModal}>
      <View style={styles.container}>
        <H1>{t('UNAVAILABILITIES.DELETE_CONFIRM')}</H1>
        <View style={styles.buttons}>
          <SIButton
            style={{ marginRight: Platform.OS === 'web' ? 10 : 0 }}
            backgroundColor="transparent"
            color="#000"
            title={t('GENERAL.CANCEL')}
            onPress={hideModal}
          />
          <SIButton
            style={{ marginLeft: Platform.OS === 'web' ? 10 : 0, marginTop: Platform.OS !== 'web' ? 10 : 0 }}
            backgroundColor={colors.red}
            title={t('GENERAL.REMOVE')}
            onPress={onDelete}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  buttons: {
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
});

export default ModalConfirmDelete;
