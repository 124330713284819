import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { StyleSheet, TextInput, View } from 'react-native';
import colors from '../styles/colors';
import SIText from './SIText';

interface Props {
  label?: string;
  control: Control<Record<string, any>>;
  name: string;
  rules?: {
    required?: boolean;
  };
  maxLength?: number;
  placeholder?: string;
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue: string | null;
}

const TextAreaController: React.FC<Props> = ({
  label,
  control,
  name,
  rules,
  placeholder,
  defaultValue,
  maxLength,
  errors = {},
}) => {
  const error = errors && errors[name]?.type === 'required';
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <View style={[styles.textAreaContainer, error ? { borderColor: 'red' } : null]}>
          {label && (
            <View style={styles.label_container}>
              {rules?.required && <SIText style={error ? styles.red : null}>* </SIText>}
              <SIText style={styles.label}>{label}</SIText>
            </View>
          )}
          <TextInput
            textAlignVertical="top"
            style={styles.textArea}
            underlineColorAndroid="transparent"
            placeholder={placeholder}
            numberOfLines={10}
            multiline={true}
            maxLength={maxLength}
            value={value}
            onChangeText={onChange}
          />
        </View>
      )}
    />
  );
};

const TextArea: React.FC<Props> = (props) => {
  const { label, name, rules, errors = {} } = props;
  if (label) {
    const error = errors && errors[name]?.type === 'required';
    return (
      <View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {/* <SIText style={error ? styles.red : null}>{label}</SIText> */}
          {/* {rules?.required && <SIText style={error ? styles.red : null}> *</SIText>} */}
        </View>
        <TextAreaController {...props} />
      </View>
    );
  } else {
    return <TextAreaController {...props} />;
  }
};

const styles = StyleSheet.create({
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
  red: {
    color: 'red',
  },
  label_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    color: colors.grey,
    marginRight: 5,
  },
});

export default TextArea;
