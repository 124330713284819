import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import Constants from 'expo-constants';
import { map, maxBy, pick } from 'lodash';
import React, { Dispatch, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../components/Checkbox';
import H1 from '../../components/H1';
import Input from '../../components/Input';
import SIButton from '../../components/SIButton';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import { InitialState } from '../../store';
import ClockingActionType from '../../store/actions/clockingActions';
import colors from '../../styles/colors';
import { IClockingTask } from '../../types/clocking.model';
import { nowUnix } from '../../utils';

type NavigationParams = {
  TaskFlowScreen: {
    _clockingTask?: IClockingTask;
  };
};

type Props = StackScreenProps<NavigationParams, 'TaskFlowScreen'>;

const TaskFlowScreen: React.FC<Props> = ({ route }) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const reduxDispatch: Dispatch<ClockingActionType> = useDispatch();
  const { currentClocking } = clockingState;
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const watches = watch();
  const isOnline = !!offlineState?.online;

  const _clockingTask = route.params._clockingTask;

  const endedTasksWithFlows = currentClocking?.tasks?.filter((task) => task.end && task.flowsResponse) || [];
  const lastEndedTaskWithFlows = maxBy(endedTasksWithFlows, 'end');

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const now = nowUnix();
    const tasks = [...currentClocking!.tasks!];
    const clockingTaskIndex = tasks.findIndex((task) => task.id === _clockingTask!.id);
    if (~clockingTaskIndex) {
      const clockingTask = { ...tasks[clockingTaskIndex]! };
      clockingTask.end = now;

      const flowsResponse: any = {};

      Object.entries(data).map(([key, value]) => {
        const [flowId, questionId] = key.split('_');
        const flowResponse = flowsResponse[flowId];
        if (flowResponse) {
          flowResponse[questionId] = value;
        } else {
          flowsResponse[flowId] = { [questionId]: value };
        }
      });

      clockingTask.flowsResponse = flowsResponse;
      tasks.splice(clockingTaskIndex, 1, clockingTask);
      const clockingId = currentClocking?.id || '$id';

      reduxDispatch({
        type: 'CLOCKING_END_CURRENT_TASK',
        payload: {
          id: clockingTask!.id!,
          end: now,
          flowsResponse,
        },
        meta: {
          wasOnline: isOnline,
          offline: {
            effect: {
              method: 'PATCH',
              url: `${
                Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
              }/v3/clockings/${clockingId}?mobile=true`,
              data: {
                mobile: true,
                os: Platform.OS,
                tasks: map(tasks, (task) =>
                  pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
                ),
              },
              optimisticId: currentClocking!.optimisticId,
            },
          },
        },
      });
    }

    setIsLoading(false);
    navigation.goBack();
  };

  const onPrefill = () => {
    if (lastEndedTaskWithFlows?.flowsResponse) {
      const flowsReponse = lastEndedTaskWithFlows!.flowsResponse;
      Object.keys(flowsReponse).map((flowId: string) => {
        Object.keys(flowsReponse[flowId]).map((questionId: string) => {
          const value = flowsReponse[flowId][questionId];
          if (!Array.isArray(value)) {
            setValue(`${flowId}_${questionId}`, value);
          }
        });
      });
    }
  };

  return (
    <SafeAreaView>
      <ScrollView style={styles.container}>
        {lastEndedTaskWithFlows?.flowsResponse && (
          <SIButton
            title={t('GENERAL.PREFILL')}
            onPress={onPrefill}
            backgroundColor={colors.greyLight}
            color={colors.grey}
            style={{ marginBottom: 10 }}
          />
        )}
        {_clockingTask?.task?.flows?.map((flow) => {
          return (
            <View key={`flow_${flow.id}`}>
              <H1 style={{ textAlign: 'left', marginBottom: 10 }}>{flow.label!}</H1>
              {flow.flow?.map((question) => {
                switch (question.type) {
                  case 'SELECT': {
                    let items = question.items || [];
                    const { parentQuestionId } = question;
                    if (parentQuestionId) {
                      const parentItemId = watches[`${flow.id}_${parentQuestionId}`];
                      if (parentItemId) {
                        items = items.filter((item) => item.parentItemId === parentItemId) || [];
                      }
                    }
                    return (
                      <Select
                        key={`${flow.id}_${question.id}`}
                        label={question.label}
                        control={control}
                        rules={{ required: question.required }}
                        errors={errors}
                        defaultValue={null}
                        name={`${flow.id}_${question.id}`}
                        items={items.map((item) => ({
                          label: item.label!,
                          value: item.value!,
                        }))}
                      />
                    );
                  }
                  case 'TEXTAREA': {
                    return (
                      <TextArea
                        key={`${flow.id}_${question.id}`}
                        label={question.label}
                        control={control}
                        rules={{ required: question.required }}
                        errors={errors}
                        defaultValue={null}
                        name={`${flow.id}_${question.id}`}
                        maxLength={question.maxLength}
                      />
                    );
                  }
                  case 'TEXTINPUT': {
                    return (
                      <Input
                        key={`${flow.id}_${question.id}`}
                        label={question.label}
                        control={control}
                        rules={{ required: question.required }}
                        errors={errors}
                        defaultValue={null}
                        name={`${flow.id}_${question.id}`}
                        maxLength={question.maxLength}
                      />
                    );
                  }
                  case 'NUMBERINPUT': {
                    return (
                      <Input
                        key={`${flow.id}_${question.id}`}
                        label={question.label}
                        control={control}
                        rules={{ required: question.required }}
                        errors={errors}
                        defaultValue={null}
                        keyboardType={'numeric'}
                        name={`${flow.id}_${question.id}`}
                        maxLength={question.maxLength}
                      />
                    );
                  }
                  case 'CHECKBOX': {
                    return (
                      <Checkbox
                        key={`${flow.id}_${question.id}`}
                        control={control}
                        label={question.label!}
                        name={`${flow.id}_${question.id}`}
                      />
                    );
                  }
                }
              })}
            </View>
          );
        })}
        <SIButton title={t('GENERAL.CONFIRM')} size="large" onPress={handleSubmit(onSubmit)} loading={isLoading} />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: 'white',
    minHeight: '100%',
  },
});

export default TaskFlowScreen;
