import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { IUserDetails } from '../types/userDetails.model';
import H1 from './H1';
import SIButton from './SIButton';
import SIText from './SIText';
import Constants from 'expo-constants';

interface Props {
  user: IUserDetails | null;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalNewUpdate: React.FC<Props> = ({ user, visible, setVisible }) => {
  const { t } = useTranslation();

  const onBackdropPress = () => {
    setVisible(false);
  };

  const onUpdate = async () => {
    if (Platform.OS == 'ios') {
      Linking.openURL(
        Constants.manifest?.extra?.APPSTORE_URL || Constants.manifest2?.extra?.expoClient?.extra?.APPSTORE_URL,
      );
    }
    if (Platform.OS == 'android') {
      Linking.openURL(
        Constants.manifest?.extra?.GOOGLE_PLAY_URL || Constants.manifest2?.extra?.expoClient?.extra?.GOOGLE_PLAY_URL,
      );
    }
  };

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible} onBackdropPress={onBackdropPress}>
      <View style={styles.container}>
        {user && user.firstname && (
          <H1>
            {t('GENERAL.HELLO')} {user.firstname}
          </H1>
        )}
        <View style={styles.text_container}>
          <H1 style={{ textAlign: 'center' }}>{t('NEW_UPDATE.TITLE')} &#128640;</H1>
          <SIText style={{ textAlign: 'center', marginTop: 10 }}>{t('NEW_UPDATE.DESCRIPTION')}</SIText>
        </View>
        <SIButton title={t('GENERAL.UPDATE')} onPress={onUpdate} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  text_container: {
    marginVertical: 25,
  },
});

export default ModalNewUpdate;
