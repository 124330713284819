import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import colors from '../styles/colors';
import H1 from './H1';
import SIText from './SIText';

interface Props {
  noSectionSeparator?: boolean;
  noSeparator?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  sections: readonly SectionListData<any>[];
  renderItem: SectionListRenderItem<any>;
  keyExtractor?: ((item: any, index: number) => string) | undefined;
  refreshControl?: SectionList['props']['refreshControl'];
  ListHeaderComponent?: SectionList['props']['ListHeaderComponent'];
  ListFooterComponent?: SectionList['props']['ListFooterComponent'];
  ListEmptyComponent?: SectionList['props']['ListEmptyComponent'];
}

const ListEmptyComponent: React.FC = () => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <View style={{ flex: 1, paddingTop: 20 }}>
      <H1 style={{ color: colors.grey }}>{t('GENERAL.NO_DATA')}</H1>
    </View>
  );
};

const SISectionList: React.FC<Props> = ({
  noSectionSeparator,
  noSeparator,
  contentContainerStyle,
  headerStyle,
  style,
  sections,
  refreshControl,
  renderItem,
  keyExtractor,
  ListHeaderComponent,
  ListFooterComponent,
  ListEmptyComponent: CustomListEmptyComponent,
}) => {
  return (
    <SectionList
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListFooterComponent}
      refreshControl={refreshControl}
      sections={sections}
      style={[styles.container, style]}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      contentContainerStyle={[{ flexGrow: 1 }, contentContainerStyle]}
      stickySectionHeadersEnabled={true}
      ListEmptyComponent={CustomListEmptyComponent || ListEmptyComponent}
      renderSectionHeader={({ section }) => (
        <View style={[styles.sectionHeader, headerStyle]}>
          <SIText style={{ color: colors.grey }}>{section.title?.toUpperCase()}</SIText>
        </View>
      )}
      renderSectionFooter={() => (noSectionSeparator ? null : <View style={styles.sectionFooter}></View>)}
      ItemSeparatorComponent={() => (noSeparator ? null : <View style={styles.itemSeparator}></View>)}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blueExtraLight,
    flex: 1,
  },
  sectionHeader: {
    padding: 10,
    borderBottomColor: colors.greyLight,
    borderBottomWidth: 1,
    backgroundColor: colors.blueExtraLight,
    borderRadius: 10,
  },
  sectionFooter: {
    height: 20,
    borderTopColor: colors.greyLight,
    borderTopWidth: 1,
  },
  itemSeparator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.greyLight,
    marginHorizontal: 10,
  },
});

export default SISectionList;
