import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import axios from 'axios';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { MultipleSelectList } from 'react-native-dropdown-select-list';
import { showMessage } from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../components/Back';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import TextArea from '../../components/TextArea';
import { ActionType, InitialState } from '../../store';
import { checkLastClockingsWithCurrentClocking } from '../../store/actions/clockingActions';
import colors from '../../styles/colors';
import { IShift } from '../../types/shift.model';
import { IAvailableUser } from '../../types/swap-shift/available.model';

type NavigationParams = {
  SwapShiftScreen: {
    shift: IShift;
  };
};

type Props = StackScreenProps<NavigationParams, 'SwapShiftScreen'>;

const SwapShiftScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const authState = useSelector((store: InitialState) => store.auth);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const { shift } = route.params;
  const { handleSubmit, errors, control } = useForm();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [usersCopy, setUsersCopy] = useState<any[]>([]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const isOnline = !!offlineState?.online;

  useEffect(() => {
    let mounted = true;

    const cancelTokenSource = axios.CancelToken.source();
    if (mounted) {
      setUsersLoading(true);
      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/operations/get-users`,
          {
            params: {
              departmentId: shift.departmentId,
              shiftId: shift.id,
            },
            cancelToken: cancelTokenSource.token,
          },
        )
        .then(({ data }) => {
          const res = data
            .filter((el: IAvailableUser) => el.mainId !== authState.userDetails?.id && el.constraints == undefined)
            .map((el: IAvailableUser) => {
              return { key: el.recordId, value: el.displayName };
            });
          setUsers(res);
          setUsersCopy(res);
          setUsersLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setUsersLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedItems.length == 3) {
      setUsers(
        users.map((el) => {
          if (!selectedItems.includes(el.key)) {
            return { ...el, disabled: true };
          }
          return el;
        }),
      );
    } else {
      setUsers(usersCopy);
    }
  }, [selectedItems]);

  const onUserSelect = (values: any) => {
    setSelectedItems(values);
  };

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      recordId: shift.userRecordId,
      shiftId: shift.id,
      usersSelected: selectedItems,
      mobile: true,
      os: Platform.OS,
    };
    axios
      .post(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/swap-shift`,
        data,
        {
          params: {
            departmentId: shift.departmentId,
          },
        },
      )
      .then(async (res) => {
        showMessage({
          message: t('SHIFTS.SWAP_SHIFT.CREATED_MESSAGE'),
          type: 'success',
        });
        navigation.navigate('Home');

        const expoPushToken = await AsyncStorage.getItem('expo-token');
        const { status } = await Notifications.getPermissionsAsync();
        axios
          .get(
            `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/datas`,
            {
              params: {
                userMainId: authState.userDetails?.id,
                mobile: true,
                sections: true,
                tasks: true,
                skills: true,
                notifications: status,
                platform: dataState.platform,
                version: dataState.version,
                brand: dataState.brand,
                modelName: dataState.modelName,
                os: dataState.os,
              },
              headers: expoPushToken
                ? {
                    'x-expo-token': expoPushToken,
                  }
                : undefined,
            },
          )
          .then((response) => {
            const {
              departments,
              shifts,
              lastClockings,
              currentClockings,
              signaturesRequired,
              userDetails,
              clockinAccount,
              oncall,
              indicators = [],
              swapShiftsRequests,
              features,
            } = response.data;
            checkLastClockingsWithCurrentClocking(lastClockings || [], clockingState, isOnline, reduxDispatch);
            reduxDispatch({ type: 'SIGNATURE_SET_REQUIRED_SIGNATURES', payload: signaturesRequired });
            reduxDispatch({ type: 'AUTH_UPDATE_USER_DETAILS', payload: userDetails });
            reduxDispatch({
              type: 'DATA_SET_DATA',
              payload: {
                departments,
                shifts,
                currentClockings,
                clockinAccount,
                oncall,
                indicators,
                swapShiftsRequests,
                features,
                userDetails,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          if (Object.values(err.response.data.errors)[0]) {
            showMessage({
              message: Object.values(err.response.data.errors)[0] as string,
              type: 'danger',
            });
          }
        } else {
          showMessage({
            message: t('SHIFTS.SWAP_SHIFT.CREATE_ERROR_MESSAGE'),
            type: 'danger',
          });
        }
        navigation.navigate('Home');
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('SHIFTS.SWAP_SHIFT.TITLE')} />
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <View style={{ padding: 25 }}>
            <TextArea
              label={t('SHIFTS.SWAP_SHIFT.REASON')}
              control={control}
              name="reason"
              defaultValue={null}
              rules={{ required: true }}
              errors={errors}
            />
            <SIText>{t('SHIFTS.SWAP_SHIFT.SELECT_USER')}</SIText>
            {usersLoading ? (
              <ActivityIndicator style={{ marginTop: 15 }} color={colors.green} />
            ) : (
              <MultipleSelectList
                setSelected={onUserSelect}
                data={users}
                save="key"
                label={t('GENERAL.COLLABORATORS')}
                placeholder={t('GENERAL.SELECT_AN_ITEM')}
              />
            )}
            <SIButton
              onPress={handleSubmit(onSubmit)}
              title={t('GENERAL.SUBMIT')}
              size="large"
              backgroundColor={colors.green}
              style={{ marginTop: 25 }}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 25,
    backgroundColor: colors.blueExtraLight,
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
});

export default SwapShiftScreen;
