import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import SIText from '../../../components/SIText';
import { IIndicator } from '../../../types/indicators';

interface Props {
  indicators: IIndicator[];
}

const Indicators: React.FC<Props> = ({ indicators }) => {
  return (
    <ScrollView style={styles.container} horizontal={true} showsHorizontalScrollIndicator={false}>
      {indicators?.map((indicator, index) => {
        const { departmentName, title, value, unit } = indicator;
        return (
          <View key={index} style={styles.indicator}>
            {!!departmentName && <SIText style={styles.department}>{departmentName}</SIText>}
            <SIText style={styles.title}>{title}</SIText>
            <View style={styles.bottom}>
              <SIText style={styles.value}>{value}</SIText>
              <SIText style={styles.unit}>{unit}</SIText>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: -10,
    marginHorizontal: -25,
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 25,
  },
  indicator: {
    backgroundColor: '#059149',
    borderRadius: 10,
    padding: 10,
    marginRight: 10,
    minWidth: 160,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  department: {
    color: 'white',
    fontSize: 8,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
  title: {
    color: 'white',
    fontSize: 10,
  },
  value: {
    color: 'white',
    opacity: 0.8,
    fontSize: 24,
    fontFamily: 'Poppins_700Bold',
    marginBottom: -5,
    marginRight: 5,
  },
  unit: {
    color: 'white',
    opacity: 0.5,
    fontSize: 10,
  },
});

export default Indicators;
