import { useFocusEffect } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Platform, SafeAreaView, StyleSheet, Switch, View } from 'react-native';
import Back from '../components/Back';
import SIButton from '../components/SIButton';
import SISectionList from '../components/SISectionList';
import SIText from '../components/SIText';
import colors from '../styles/colors';
import { IDepartment } from '../types/department.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IMyDepartment extends IDepartment {
  settings: {
    app: boolean;
    email: boolean;
    sms: boolean;
  };
}

const SettingsScreen: React.FC = () => {
  const { handleSubmit, control } = useForm();

  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [departments, setDepartments] = useState<IMyDepartment[]>([]);

  const refreshData = () => {
    if (!isLoading) {
      let mounted = true;
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);

      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/settings`,
        {
          params: {
            mobile: true,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          if (mounted) {
            const { departments } = response.data;
            setDepartments(departments);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (mounted) {
            setIsLoading(false);
          }
          if (!Axios.isCancel(error)) {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
            }
          }
          console.log(error);
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  };

  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  const onSubmit = async (data: any) => {
    setIsSaving(true);
    const departments = Object.entries(data).map((entry) => {
      const [id, settings] = entry;
      return {
        id,
        settings,
      };
    });

    Axios.post(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/settings`,
      {
        departments,
      },
      {
        params: {
          mobile: true,
        },
      },
    )
      .then((response) => {
        const { departments } = response.data;
        setDepartments(departments);
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('GENERAL.SETTINGS')} />
      {!!isLoading && <ActivityIndicator size="large" />}
      {!isLoading && (
        <View style={{ padding: 25, flex: 1 }}>
          <SISectionList
            sections={departments?.map((department) => ({
              title: department.company!,
              data: [{ department }],
            }))}
            renderItem={({ item }) => <Item department={item.department} control={control} />}
            keyExtractor={(item) => item.id}
            ListFooterComponent={
              <SIButton
                style={{ marginTop: 10 }}
                title={t('GENERAL.CONFIRM')}
                size="large"
                onPress={handleSubmit(onSubmit)}
                loading={isSaving}
              />
            }
          />
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
});

export default SettingsScreen;

interface IItemProps {
  department: IMyDepartment;
  control: Control<FieldValues>;
}

const Item: React.FC<IItemProps> = ({ department, control }) => {
  const {
    settings: { email, app, sms },
  } = department;
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <View style={{ backgroundColor: '#fff', borderRadius: 10, padding: 25 }}>
      <View style={styles.row}>
        <SIText>{t('SETTINGS.ENABLE_EMAIL_ALERT')}</SIText>
        <Controller
          defaultValue={email || false}
          control={control}
          name={`${department.id}.email`}
          render={({ onChange, value }) => <Switch value={value} onValueChange={onChange} />}
        />
      </View>
      <View style={styles.row}>
        <SIText>{t('SETTINGS.ENABLE_APP_ALERT')}</SIText>
        <Controller
          defaultValue={app || false}
          control={control}
          name={`${department.id}.app`}
          render={({ onChange, value }) => <Switch value={value} onValueChange={onChange} />}
        />
      </View>
      <View style={[styles.row, { display: 'none' }]}>
        <SIText>{t('SETTINGS.ENABLE_SMS_ALERT')}</SIText>
        <Controller
          defaultValue={sms || false}
          control={control}
          name={`${department.id}.sms`}
          render={({ onChange, value }) => <Switch value={value} onValueChange={onChange} />}
        />
      </View>
    </View>
  );
};
