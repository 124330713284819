import React, { Dispatch, useEffect, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, FlatList } from 'react-native';
import SIText from '../components/SIText';
import colors from '../styles/colors';
import SearchBar from '../components/SearchBar';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IScanCode } from '../types/clocking.model';
import { StackScreenProps } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import Icon from '../components/Icon';

interface ItemProps {
  tool: IScanCode;
  onPress: () => void;
}

const Item: React.FC<ItemProps> = ({ tool, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.item}>
        <View>
          <SIText>{tool.name}</SIText>
        </View>
      </View>
    </TouchableOpacity>
  );
};

interface ListHeaderComponentProps {
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
  navigation: any;
  attributes?: IScanCode[];
  callbackView: string;
  callbackScreen?: string;
}

const ListHeaderComponent: React.FC<ListHeaderComponentProps> = ({
  setSearch,
  search,
  navigation,
  attributes,
  callbackView,
  callbackScreen,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <View style={styles.listHeaderComponent}>
      <SearchBar style={{ flexGrow: 1 }} placeholder={t('GENERAL.SEARCH')} setValue={setSearch} value={search} />
      <TouchableOpacity
        style={styles.listHeaderComponentIconContainer}
        onPress={() => {
          navigation.navigate('ScanQrCode', {
            destination: 'ToolQuantity',
            callbackView,
            callbackScreen,
            scanCodes: attributes,
            cancelText: t('GENERAL.CANCEL'),
            cancelPopUp: null,
          });
        }}
      >
        <Icon name="qrcode" size={20} color={colors.green} />
      </TouchableOpacity>
    </View>
  );
};

type NavigationParams = {
  ToolsListScreen: {
    attributes?: IScanCode[];
    callbackView: string;
    callbackScreen?: string;
  };
};

type Props = StackScreenProps<NavigationParams, 'ToolsListScreen'>;

const ToolsListScreen: React.FC<Props> = ({ route }) => {
  const [search, setSearch] = useState<string>('');
  const { attributes } = route.params;
  const [filteredAttributes, setFilteredAttributes] = useState<IScanCode[]>([]);
  const navigation = useNavigation();
  const { callbackView, callbackScreen } = route.params;

  useEffect(() => {
    if (search) {
      setFilteredAttributes(
        attributes?.filter((attribute) => attribute.name.toLowerCase().includes(search.toLowerCase())) || [],
      );
    } else {
      setFilteredAttributes(attributes || []);
    }
  }, [search, attributes]);

  return (
    <View style={{ backgroundColor: 'white', flex: 1 }}>
      <FlatList
        data={filteredAttributes}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <Item
            tool={item as IScanCode}
            onPress={() => {
              navigation.navigate('ToolQuantity', { qrcode: item, callbackView, callbackScreen });
            }}
          />
        )}
        ItemSeparatorComponent={() => (
          <View style={{ flex: 1, height: 1, backgroundColor: colors.greyLight, marginLeft: 10 }}></View>
        )}
        ListHeaderComponent={
          <ListHeaderComponent
            search={search}
            setSearch={setSearch}
            navigation={navigation}
            attributes={attributes}
            callbackView={callbackView}
            callbackScreen={callbackScreen}
          />
        }
        keyExtractor={(item, index) => `${index}`}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  listHeaderComponent: {
    padding: 10,
    backgroundColor: colors.greyLight,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  listHeaderComponentIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: 10,
  },
  container: {
    padding: 10,
    backgroundColor: 'white',
    minHeight: '100%',
  },
  item: {
    marginVertical: 5,
    backgroundColor: 'white',
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default ToolsListScreen;
