import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import colors from '../styles/colors';
import SIText from './SIText';

interface Props {
  number: number;
}

const NotificationBadge: React.FC<Props> = ({ number }) => {
  return (
    <View style={styles.container}>
      <SIText style={styles.text}>{number <= 9 ? number : '9+'}</SIText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.red,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
  },
  text: {
    color: '#FFF',
  },
});

export default NotificationBadge;
