import { useNavigation, useRoute } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { showLocation } from 'react-native-map-link';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../components/Back';
import H1 from '../components/H1';
import SIButton from '../components/SIButton';
import SIText from '../components/SIText';
import Tag from '../components/Tag';
import { ActionType, InitialState } from '../store';
import colors from '../styles/colors';
import { ICoworker } from '../types/coworker.model';
import { IShift } from '../types/shift.model';
import { IUser } from '../types/user.model';
import { clockingFromShift, minutesToHoursAndOrMinutes, nowUnix } from '../utils';
import NoOfflineDepartmentWarning from './home/components/NoOfflineDepartmentWarning';

moment.tz.setDefault('Atlantic/Reykjavik');

const ShiftDetailsScreen: React.FC = () => {
  const { departments } = useSelector((state: InitialState) => state.data);
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const authState = useSelector((state: InitialState) => state.auth);
  const dataState = useSelector((state: InitialState) => state.data);
  const offlineState = useSelector((state: InitialState) => state.offline);
  const { params } = useRoute();
  const shift = (params as any).shift as IShift;
  const { start, end, pause, package: shiftPackage } = shift;
  const isOnline = !!offlineState?.online;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coWorkers, setCoWorkers] = useState<ICoworker[]>([]);

  const startMoment = moment.unix(start!);
  const endMoment = moment.unix(end!);
  const duration = moment.duration(endMoment.diff(startMoment));
  duration.subtract(pause!.unpaid!, 'seconds');
  const durationAsMinutes = duration.asMinutes();
  const pauseDurationAsMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();
  const department = departments.find((department) => department.id === shift.departmentId!);
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const navigation = useNavigation();
  const [now, setNow] = useState<number>(nowUnix());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(nowUnix());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const can_swap_shift =
    isOnline &&
    shift.userRecordId !== null &&
    moment().format('YYYY-MM-DD') !== startMoment.format('YYYY-MM-DD') &&
    dataState.departments.find(
      (department) =>
        department.swapShifts &&
        department.id == shift.departmentId &&
        department.swapShifts.enabled &&
        startMoment.subtract(department.swapShifts.daysBeforeShiftLimit, 'days').isSameOrAfter(moment()),
    );

  useEffect(() => {
    if (isOnline && shift.userMainId && shift.userMainId !== 'null') {
      if (isOnline) {
        getCoWorkers();
      }
    }
  }, [isOnline]);

  useEffect(() => {
    const beforeRemove = (e: any) => {
      if (!isLoading) {
        return;
      }
      e.preventDefault();
    };
    navigation.addListener('beforeRemove', beforeRemove);
    return () => {
      navigation.removeListener('beforeRemove', beforeRemove);
    };
  }, [navigation, isLoading]);

  const onPressRequestForModifications = () => {
    navigation.navigate('ShiftRequestForModifications', { shift });
  };

  const getCoWorkers = () => {
    // setIsLoading(true);
    Axios.get(
      `${
        Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/operations/get-coworkers`,
      {
        params: {
          departmentId: department?.id,
          shiftId: shift.id,
        },
      },
    )
      .then((response) => {
        setIsLoading(false);
        setCoWorkers(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        setIsLoading(false);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
        console.log(error);
      });
  };

  const requestForBooking = () => {
    setIsLoading(true);
    Axios.patch(
      `${
        Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/operations/book-shift/${shift.id}`,
      {
        mobile: true,
        os: Platform.OS,
      },
    )
      .then((response) => {
        setIsLoading(false);
        const { shifts } = response.data;
        reduxDispatch({
          type: 'DATA_UPDATE_SHIFT',
          payload: (shifts && shifts[0]) || response.data[0],
        });
        if (response.data[0] && response.data[0].id) {
          reduxDispatch({
            type: 'DATA_REMOVE_SHIFT',
            payload: response.data[0].id,
          });
        }
        navigation.navigate('Home');
      })
      .catch((error) => {
        setIsLoading(false);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}. ${t('SHIFTS.ALERTS.SHIFT_BOOKING_FAILURE_MESSAGE')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'), t('SHIFTS.ALERTS.SHIFT_BOOKING_FAILURE_MESSAGE'));
        }
        console.log(error);
      });
  };

  const onPressRequestForBooking = () => {
    if (Platform.OS === 'web') {
      const confirmation = confirm(
        `${t('SHIFTS.ALERTS.SHIFT_BOOKING_CONFIRMATION_TITLE')}. ${t(
          'SHIFTS.ALERTS.SHIFT_BOOKING_CONFIRMATION_MESSAGE',
        )}`,
      );
      if (confirmation === true) {
        requestForBooking();
      }
    } else {
      Alert.alert(
        t('SHIFTS.ALERTS.SHIFT_BOOKING_CONFIRMATION_TITLE'),
        t('SHIFTS.ALERTS.SHIFT_BOOKING_CONFIRMATION_MESSAGE'),
        [
          {
            text: t('GENERAL.CANCEL'),
            style: 'cancel',
          },
          {
            text: t('GENERAL.CONFIRM'),
            onPress: requestForBooking,
          },
        ],
      );
    }
  };

  const onPressCheckIn = async () => {
    if (!clockingState?.currentClocking) {
      if (department) {
        setIsLoading(true);
        const currentClocking = await clockingFromShift(department, shift, authState.userDetails!.id!);
        navigation.navigate('ClockingCheckIn', { clocking: currentClocking, shift });
      }
    } else {
      if (Platform.OS === 'web') {
        alert(`${t('GENERAL.ERROR')}. ${t('CLOCKING.ALERTS.CLOCKING_ALREADY_IN_PROCESS')}`);
      } else {
        Alert.alert(t('GENERAL.ERROR'), t('CLOCKING.ALERTS.CLOCKING_ALREADY_IN_PROCESS'));
      }
    }
  };

  const usersMap = new Map<string, IUser>();
  if (department) {
    const { users } = department;
    if (users) {
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        if (user && user.recordId) {
          usersMap.set(user.recordId, user);
        }
      }
    }
  }

  const onSwapShift = () => {
    navigation.navigate('SwapShift', { shift });
  };

  const no_offline_clockings_departments_ids = dataState.no_offline_clockings_departments
    ? dataState.no_offline_clockings_departments.map((department) => department.id)
    : [];

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('SHIFTS.SHIFT_DETAIL')} />
      {department && !isOnline && !department?.clockinParams?.offlineClockings && (
        <View style={{ marginTop: 25 }}>
          <NoOfflineDepartmentWarning departmentId={department.id ?? ''} />
        </View>
      )}
      <View style={styles.container}>
        <ScrollView style={styles.content} showsVerticalScrollIndicator={false}>
          <View style={{ padding: 25 }}>
            <H1 style={{ textAlign: 'left', paddingBottom: 10 }}>{`${moment.unix(shift.start!).format('dddd LL')}`}</H1>
            {!shiftPackage && (
              <View style={{ marginBottom: 25 }}>
                {!!department?.params?.showOnlyStartDate && (
                  <SIText>{`${t('SHIFTS.START')} ${startMoment.format('HH:mm')}`}</SIText>
                )}
                {!department?.params?.showOnlyStartDate && (
                  <React.Fragment>
                    <SIText>{`${startMoment.format('HH:mm')} ${t('SHIFTS.HOURS_TO')} ${endMoment.format(
                      'HH:mm',
                    )}`}</SIText>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignContent: 'center',
                      }}
                    >
                      <SIText style={styles.details}>{`${t('SHIFTS.DURATION')} ${minutesToHoursAndOrMinutes(
                        durationAsMinutes,
                      )}`}</SIText>
                      {pauseDurationAsMinutes > 0 && (
                        <SIText style={styles.details}>{`${t('CLOCKING.PAUSE')} ${minutesToHoursAndOrMinutes(
                          pauseDurationAsMinutes,
                        )}`}</SIText>
                      )}
                    </View>
                  </React.Fragment>
                )}
              </View>
            )}
            {shiftPackage && shiftPackage.name && (
              <View style={[styles.packageContainer, { backgroundColor: shiftPackage.background }]}>
                <SIText style={[styles.package, { color: shiftPackage.color }]}>{shiftPackage.name}</SIText>
              </View>
            )}
            <SIText style={styles.smallText}>{department!.company}</SIText>
            <TouchableOpacity
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
              }}
              onPress={() => {
                if (shift?.address) {
                  const { lat, lng } = shift?.address;
                  if (lat && lng) {
                    showLocation({
                      latitude: lat,
                      longitude: lng,
                    });
                  }
                }
              }}
            >
              <SIText style={styles.smallText}>{shift!.fullAddress}</SIText>
            </TouchableOpacity>
            {shift.dayoff && shift.dayoff.name && (
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                <Tag key={`shift_${shift.id}`} backgroundColor={colors.red} color={'#fff'}>
                  {shift.dayoff.name}
                </Tag>
              </View>
            )}
            {shift?.section && (
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                <Tag
                  key={`shift_${shift.id}_section_${shift?.section.id}`}
                  backgroundColor={shift?.section.background}
                  color={shift?.section.color}
                >
                  {shift?.section.name || ''}
                </Tag>
              </View>
            )}
            {!!shift.skills?.length && (
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                {shift.skills?.map((skill) => (
                  <Tag
                    key={`shift_${shift.id}_skill_${skill.id}`}
                    backgroundColor={skill.background}
                    color={skill.color}
                  >
                    {skill.name || ''}
                  </Tag>
                ))}
              </View>
            )}
            {!!shift.attributes?.length && (
              <React.Fragment>
                {shift.attributes?.some((x) => !x.extra?.length) && (
                  <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                    {shift.attributes
                      ?.filter((x) => !x.extra?.length)
                      ?.map((attribute) => (
                        <Tag
                          key={`shift_${shift.id}_attribute_${attribute.id}`}
                          backgroundColor={attribute.background}
                          color={attribute.color}
                        >
                          {attribute.name || ''}
                        </Tag>
                      ))}
                  </View>
                )}
                {shift.attributes?.some((x) => !!x.extra?.length) && (
                  <View>
                    {shift.attributes
                      ?.filter((x) => !!x.extra?.length)
                      .map((attribute) => (
                        <View key={attribute.id} style={{ marginTop: 10 }}>
                          <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Tag
                              key={`shift_${shift.id}_attribute_${attribute.id}`}
                              backgroundColor={attribute.background}
                              color={attribute.color}
                            >
                              {attribute.name || ''}
                            </Tag>
                          </View>
                          {attribute?.extra?.map((x) => (
                            <SIText key={`${attribute.id}_${x.label}`} style={styles.smallText}>
                              {x.label} : {x.value}
                            </SIText>
                          ))}
                        </View>
                      ))}
                  </View>
                )}
              </React.Fragment>
            )}
            {!!shift.comment && (
              <View style={styles.comment}>
                <SIText style={{ fontSize: 11 }}>{shift.comment}</SIText>
              </View>
            )}
            {!!shift.tasks?.length && (
              <React.Fragment>
                <View style={{ borderBottomWidth: 1, borderBottomColor: colors.greyLight, marginVertical: 10 }}></View>
                <SIText style={{ fontSize: 13 }}>{t('SHIFTS.TASKS')}</SIText>
                <View style={{ marginTop: 6 }}>
                  {shift.tasks?.map((task) => (
                    <View
                      key={`shift_${shift.id}_task_${task.id}`}
                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                      <SIText style={styles.tasks}>{task.name}</SIText>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <SIText style={styles.tasks}>{`${moment.unix(task.start!).format('HH:mm')}`}</SIText>
                        <SIText style={styles.tasks}>{` - ${moment.unix(task.end!).format('HH:mm')}`}</SIText>
                      </View>
                    </View>
                  ))}
                </View>
              </React.Fragment>
            )}
            {!!coWorkers?.length && (
              <React.Fragment>
                <View style={{ borderBottomWidth: 1, borderBottomColor: colors.greyLight, marginVertical: 10 }}></View>
                <SIText style={{ fontSize: 13 }}>{t('SHIFTS.COWORKERS')}</SIText>
                <View style={{ marginTop: 6 }}>
                  {coWorkers?.map((coworker, coworkerIndex) => (
                    <View
                      key={`shift_${shift.id}_coworker_${coworker.id}_${coworkerIndex}`}
                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                      <SIText style={styles.tasks}>{usersMap.get(coworker.id!)?.displayName || ''}</SIText>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <SIText style={styles.tasks}>{`${moment.unix(coworker.start!).format('HH:mm')}`}</SIText>
                        <SIText style={styles.tasks}>{` - ${moment.unix(coworker.end!).format('HH:mm')}`}</SIText>
                      </View>
                    </View>
                  ))}
                </View>
              </React.Fragment>
            )}
            {shift.userRecordId && shift.pendingRequest && (
              <React.Fragment>
                <View style={{ borderBottomWidth: 1, borderBottomColor: colors.greyLight, marginVertical: 10 }}></View>
                <View style={{ flexDirection: 'row' }}>
                  <SIText style={{ fontSize: 13 }}>{t('SHIFTS.PENDING_REQUEST')}</SIText>
                  <SIText style={{ color: colors.grey }}>{` (${moment
                    .unix(shift.pendingRequest!.created!)
                    .format('L')})`}</SIText>
                </View>
                <View style={styles.pendingContainer}>
                  <SIText style={{ fontSize: 13, color: colors.red }}>
                    {shift.pendingRequest?.cancellationType
                      ? department?.dayoffs?.find((dayoff) => dayoff.id === shift.pendingRequest?.cancellationType)
                          ?.name
                      : t('SHIFTS.SHIFT_CANCELLATION')}
                  </SIText>
                  <SIText style={{ fontSize: 11, color: colors.grey }}>
                    {shift.pendingRequest.comment?.replace(/\\n/g, '\n')}
                  </SIText>
                </View>
              </React.Fragment>
            )}
            {isOnline &&
              !dataState.fixed_view &&
              shift?.userRecordId &&
              !shift?.pendingRequest &&
              shift.start! > moment().unix() + (department?.clockinParams?.requestModificationMaxHour || 0) * 3600 && (
                <SIButton
                  style={{ marginTop: 25 }}
                  size={Platform.OS !== 'web' ? 'large' : 'normal'}
                  title={t('SHIFTS.REQUEST_FOR_CHANGE')}
                  backgroundColor={colors.red}
                  onPress={onPressRequestForModifications}
                />
              )}
            {((isOnline &&
              shift?.userRecordId &&
              !shift?.dayoff &&
              !shift.pendingRequest &&
              department?.clockin &&
              moment
                .unix(shift.start! - department!.clockinParams!.authorizeCheckingBefore!)
                .isSame(moment.unix(now), 'day') &&
              department?.clockinParams?.enableMobileClockin) ||
              (!isOnline &&
                dataState.no_offline_clockings_departments &&
                dataState.freeclockin_departments &&
                dataState.no_offline_clockings_departments.length < dataState.freeclockin_departments.length &&
                shift?.userRecordId &&
                !shift?.dayoff &&
                !shift.pendingRequest &&
                department?.clockin &&
                moment
                  .unix(shift.start! - department!.clockinParams!.authorizeCheckingBefore!)
                  .isSame(moment.unix(now), 'day') &&
                !no_offline_clockings_departments_ids.includes(department?.id) &&
                department?.clockinParams?.enableMobileClockin &&
                moment.unix(shift.start!).isSame(moment(), 'day'))) && (
              <SIButton
                style={{ marginTop: 25 }}
                size={Platform.OS !== 'web' ? 'large' : 'normal'}
                title={t('CLOCKING.CHECK_IN')}
                backgroundColor={colors.green}
                width="auto"
                loading={isLoading}
                onPress={onPressCheckIn}
              />
            )}
            {!shift.userRecordId && (
              <SIButton
                style={{ marginTop: 25 }}
                size={Platform.OS !== 'web' ? 'large' : 'normal'}
                title={t('SHIFTS.BOOK')}
                backgroundColor={colors.green}
                loading={isLoading}
                onPress={onPressRequestForBooking}
              />
            )}
            {shift.shyftType == 1 && !dataState.fixed_view && can_swap_shift && (
              <SIButton
                style={{ marginTop: 25 }}
                size={Platform.OS !== 'web' ? 'large' : 'normal'}
                title={shift.swap_shift !== null ? t('SHIFTS.SWAP_SHIFT.IN_PROGRESS') : t('SHIFTS.SWAP_SHIFT.TITLE')}
                backgroundColor={shift.swap_shift !== null ? colors.grey : colors.blue}
                loading={isLoading}
                onPress={onSwapShift}
                disabled={shift.swap_shift !== null}
              />
            )}
            {shift.swap_shift !== null &&
              shift.swap_shift?.users.map((user) => {
                let background = '#000';
                let status = t('SHIFTS.SWAP_SHIFT.STATUSES.NO_STATUS');

                if (user.admin_approved_at) {
                  background = colors.green;
                  status = t('SHIFTS.SWAP_SHIFT.STATUSES.APPROVED_BY_ADMIN');
                }
                if (!user.user_approved_at && !user.user_denied_at) {
                  background = colors.orange;
                  status = t('SHIFTS.SWAP_SHIFT.STATUSES.WAITING_FOR_USER');
                }
                if (user.user_approved_at) {
                  background = colors.green;
                  status = t('SHIFTS.SWAP_SHIFT.STATUSES.WAITING_FOR_ADMIN');
                }
                if (user.user_denied_at) {
                  background = colors.red;
                  status = t('SHIFTS.SWAP_SHIFT.STATUSES.USER_DENIED');
                }

                return (
                  <View
                    key={`${shift.swap_shift?.id}_${user.id}`}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginTop: 15,
                    }}
                  >
                    <SIText style={{ fontSize: 12, flex: 2 }}>{user.name}</SIText>
                    <View style={{ flex: 3, marginRight: -5, alignItems: 'flex-end' }}>
                      <Tag key={`shift_${shift.id}_dayoff_type`} backgroundColor={background} color={'#fff'}>
                        {status}
                      </Tag>
                    </View>
                  </View>
                );
              })}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 25,
    backgroundColor: colors.blueExtraLight,
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  comment: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: colors.greyLight,
  },
  details: {
    fontSize: 11,
    marginRight: 8,
  },
  tasks: {
    fontSize: 11,
    lineHeight: 22,
    color: colors.grey,
  },
  pendingContainer: {
    backgroundColor: colors.redExtraLight,
    borderRadius: 10,
    padding: 10,
    marginTop: 6,
    marginBottom: 20,
  },
  packageContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  package: {
    fontFamily: 'Poppins_700Bold',
  },
  smallText: {
    color: colors.grey,
    fontSize: 11,
  },
  badge: {
    marginLeft: 10,
    borderRadius: 50,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  badge_text: {
    color: '#fff',
  },
});

export default ShiftDetailsScreen;
