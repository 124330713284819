import React, { useState } from 'react';
import { StyleProp, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native';
import colors from '../styles/colors';
import Icon from './Icon';

interface Props {
  placeholder?: string;
  value?: string;
  setValue: ((text: string) => void) | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
}

const InputPassword: React.FC<Props> = ({ placeholder, containerStyle, inputStyle, value, setValue }) => {
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);

  return (
    <View style={[styles.container, containerStyle]}>
      <TextInput
        placeholder={placeholder}
        autoCompleteType="password"
        secureTextEntry={secureTextEntry}
        style={[styles.input, inputStyle]}
        onChangeText={setValue}
        autoCapitalize="none"
        value={value}
        placeholderTextColor={colors.grey}
      />
      <TouchableOpacity style={styles.eye} onPress={() => setSecureTextEntry(!secureTextEntry)}>
        <Icon name={secureTextEntry ? 'eye' : 'eye-off'} size={18} color={colors.grey} style={{ marginLeft: 10 }} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#D8D8D8',
  },
  input: {
    flex: 1,
    paddingVertical: 20,
  },
  eye: {
    paddingVertical: 20,
  },
  // container: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   padding: 10,
  //   backgroundColor: 'white',
  //   borderRadius: 7,
  // },
  // input: {
  //   display: 'flex',
  //   flexGrow: 1,
  //   flex: 1,
  // },
});

export default InputPassword;
