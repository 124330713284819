import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import SIText from '../../../components/SIText';
import colors from '../../../styles/colors';

interface Props {}

const OfflineClockingsWarning: React.FC<Props> = ({}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <View style={styles.container}>
      <SIText>{t('OFFLINE.OFFLINE_CLOCKING_WARNING')}</SIText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.redLight,
    padding: 25,
    marginTop: Platform.OS == 'web' ? 25 : 0,
    marginHorizontal: 25,
    borderRadius: 10,
  },
});

export default OfflineClockingsWarning;
