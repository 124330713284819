import produce from 'immer';
import { IUserDetails } from '../../types/userDetails.model';
import { setAxiosHeaders } from '../../utils';
import ActionType from '../actions/authActions';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export type AuthInitialState = {
  accessToken: string | null;
  userDetails: null | IUserDetails;
};

const initialState: AuthInitialState = {
  accessToken: null,
  userDetails: null,
};

export default function reducer(state = initialState, action: ActionType): AuthInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'REFRESH_DATA_COMMIT': {
      return produce(state, (draftState) => {
        const userDetails = draftState.userDetails;
        if (userDetails) {
          const { role, email, pinCode } = action.payload.data.userDetails!;
          draftState.userDetails = {
            ...draftState.userDetails,
            role,
            email,
            pinCode,
          };
        } else {
          draftState.userDetails = action.payload;
        }
      });
    }
    case 'AUTH_SET_TOKEN': {
      return produce(state, (draftState) => {
        if (action.payload) {
          const accessToken = action.payload;
          draftState.accessToken = accessToken;
          setAxiosHeaders(accessToken);
        } else {
          draftState.accessToken = null;
          draftState.userDetails = null;
          setAxiosHeaders(null);
        }
      });
    }
    case 'AUTH_SET_USER_DETAILS': {
      return produce(state, (drafState) => {
        drafState.userDetails = action.payload;
      });
    }
    case 'AUTH_UPDATE_USER_DETAILS': {
      return produce(state, (drafState) => {
        const userDetails = drafState.userDetails;
        if (userDetails) {
          const { mainId, role, email, pinCode, firstname, picture } = action.payload!;
          if (state.userDetails) {
            const { userName, userLang } = state.userDetails;
            if (Platform.OS == 'web') {
              Sentry.Browser.setUser({
                mainId,
                username: userName,
                email,
                pin: pinCode,
                role,
                userLang,
              });
            } else {
              Sentry.Native.setUser({
                mainId,
                username: userName,
                email,
                pin: pinCode,
                role,
                userLang,
              });
            }
            drafState.userDetails = {
              ...drafState.userDetails,
              role,
              email,
              pinCode,
              firstname,
              picture,
            };
          }
        } else {
          drafState.userDetails = action.payload;
        }
      });
    }
    default: {
      return state;
    }
  }
}
