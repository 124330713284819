import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { IUserDetails } from '../types/userDetails.model';
import H1 from './H1';
import SIButton from './SIButton';
import SIText from './SIText';
import Constants from 'expo-constants';
import { IShift } from '../types/shift.model';
import axios from 'axios';
import { useNavigation } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';
import colors from '../styles/colors';

interface Props {
  shift: IShift;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  loadingDelete: boolean;
  setLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDeleteShift: React.FC<Props> = ({ shift, visible, loadingDelete, setVisible, setLoadingDelete }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onBackdropPress = () => {
    setVisible(false);
  };

  const onDelete = () => {
    setLoadingDelete(true);
    axios
      .delete(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts`,
        {
          params: {
            departmentId: shift.departmentId,
            startDate: shift.start,
            endDate: shift.end,
            mobile: true,
          },
          data: [{ id: shift.id }],
        },
      )
      .then(({ data }) => {
        setVisible(false);
        setLoadingDelete(false);
        navigation.goBack();
        showMessage({
          message: t('SHIFTS.FLASH_MESSAGES.DELETED'),
          type: 'success',
        });
      })
      .catch((err) => {
        setLoadingDelete(false);
        console.log(err);
        showMessage({
          message: t('SHIFTS.FLASH_MESSAGES.ERROR'),
          type: 'danger',
        });
      });
  };

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible} onBackdropPress={onBackdropPress}>
      <View style={styles.container}>
        <SIText style={styles.text}>{t('SHIFTS.MODAL.ARE_U_SURE_TO_DELETE')}</SIText>
        <SIButton
          style={styles.button}
          backgroundColor={colors.red}
          title={t('GENERAL.REMOVE')}
          onPress={onDelete}
          loading={loadingDelete}
          disabled={loadingDelete}
        />
        <SIButton
          style={styles.button}
          backgroundColor="transparent"
          color={colors.black}
          title={t('GENERAL.CANCEL')}
          onPress={() => setVisible(false)}
          disabled={loadingDelete}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    marginTop: 15,
  },
});

export default ModalDeleteShift;
