import produce, { enableMapSet } from 'immer';
import { IClocking } from '../../types/clocking.model';
import { nowUnix } from '../../utils';
import ActionType from '../actions/clockingActions';

enableMapSet();

export type ClockingInitialState = {
  currentClocking: IClocking | null;
  optimisticIdsToIds: { [key: string]: string };
  clockoutDone: boolean;
};

const initialState: ClockingInitialState = {
  currentClocking: null,
  optimisticIdsToIds: {},
  clockoutDone: false,
};

export default function reducer(state = initialState, action: ActionType): ClockingInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'REFRESH_DATA_COMMIT': {
      return produce(state, (draftState) => {
        const { currentClockings } = action.payload.data;
        if (!draftState.currentClocking) {
          if (!!currentClockings?.length) {
            for (let i = 0; i < currentClockings!.length; i++) {
              const currentClockingId = currentClockings[i].id;
              let alreadyExisted = false;
              for (const [key, value] of Object.entries(draftState.optimisticIdsToIds)) {
                if (value === currentClockingId) {
                  alreadyExisted = true;
                  break;
                }
              }
              if (!alreadyExisted) {
                draftState.currentClocking = currentClockings[i];
                break;
              }
            }
          }
        }
        return draftState;
      });
    }
    case 'DATA_SET_DATA': {
      return produce(state, (draftState) => {
        const { currentClockings } = action.payload;
        if (!draftState.currentClocking) {
          if (!!currentClockings?.length) {
            for (let i = 0; i < currentClockings!.length; i++) {
              const currentClockingId = currentClockings[i].id;
              let alreadyExisted = false;
              for (const [key, value] of Object.entries(draftState.optimisticIdsToIds)) {
                if (value === currentClockingId) {
                  alreadyExisted = true;
                  break;
                }
              }
              if (!alreadyExisted) {
                draftState.currentClocking = currentClockings[i];
                break;
              }
            }
          }
        }
      });
    }
    case 'CLOCKING_HARD_RESET': {
      return produce(state, (draftState) => {
        draftState.currentClocking = initialState.currentClocking;
        draftState.optimisticIdsToIds = initialState.optimisticIdsToIds;
        draftState.clockoutDone = false;
      });
    }
    case 'CLOCKING_SET_CURRENT_CLOCKING': {
      return produce(state, (draftState) => {
        const { payload } = action;
        if (payload) {
          draftState.currentClocking = {
            tasks: [],
            pictures: [],
            tools: [],
            pauses: [],
            ...payload,
          };
        } else {
          draftState.currentClocking = null;
        }
      });
    }
    case 'CLOCKING_SET_CURRENT_CLOCKING_COMMIT': {
      return produce(state, (draftState) => {
        const { meta, payload } = action;
        if (meta && meta.optimisticId) {
          const { data } = payload;
          if (draftState.currentClocking?.optimisticId === meta.optimisticId) {
            draftState.currentClocking!.id = data.id;
          }
          draftState.optimisticIdsToIds[meta.optimisticId] = data.id;
        }
      });
    }
    case 'CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK': {
      return produce(state, (draftState) => {
        return state;
      });
    }
    case 'CLOCKING_END_CURRENT_CLOCKING_COMMIT': {
      return produce(state, (draftState) => {
        const { meta } = action;
        if (meta && meta.optimisticId) {
          const optimisticIdsToIds = { ...state.optimisticIdsToIds };
          delete optimisticIdsToIds[meta.optimisticId];
          draftState.optimisticIdsToIds = optimisticIdsToIds;
        }
        if (meta && meta.setClockoutDone) {
          draftState.clockoutDone = true;
        }
      });
    }
    case 'CLOCKING_END_CURRENT_CLOCKING_ROLLBACK': {
      return produce(state, (draftState) => {
        const { meta } = action;
        if (meta && meta.optimisticId) {
          delete draftState.optimisticIdsToIds[meta.optimisticId];
        }
        if (meta && meta.setClockoutDone) {
          draftState.clockoutDone = true;
        }
      });
    }
    case 'CLOCKING_UPDATE_CURRENT_CLOCKING_ID': {
      return produce(state, (draftState) => {
        if (draftState.currentClocking) {
          draftState.currentClocking.id = action.payload;
        }
      });
    }
    case 'CLOCKING_UPDATE_HOMEWORKING': {
      return produce(state, (draftState) => {
        if (draftState.currentClocking) {
          draftState.currentClocking.homeworking = action.payload;
        }
      });
    }
    case 'CLOCKING_ADD_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const tasks = draftState.currentClocking?.tasks;
        if (tasks) {
          tasks.push({
            ...action.payload,
          });
        }
      });
    }
    case 'CLOCKING_START_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const { id, start } = action.payload;
        const task = draftState.currentClocking?.tasks?.find((task) => task.id === id);
        if (task) {
          delete task.end;
          task.start = start;
          task.notClocked = false;
        }
      });
    }
    case 'CLOCKING_EDIT_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const { id, start, end } = action.payload;
        const task = draftState.currentClocking?.tasks?.find((task) => task.id === id);
        if (task) {
          task.start = start;
          task.end = end;
          task.edited = true;
        }
      });
    }
    case 'CLOCKING_END_CURRENT_TASK': {
      return produce(state, (draftState) => {
        const { id, end, flowsResponse } = action.payload;
        const task = draftState.currentClocking?.tasks?.find((task) => task.id === id);
        if (task) {
          task.end = end;
          if (flowsResponse) {
            task.flowsResponse = flowsResponse;
          }
        }
      });
    }
    case 'CLOCKING_ADD_CURRENT_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.currentClocking?.pictures;
        if (pictures) {
          pictures.push({
            uri: action.payload,
            unix: nowUnix(),
          });
        }
      });
    }
    case 'CLOCKING_REMOVE_CURRENT_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.currentClocking?.pictures;
        if (pictures) {
          const index = pictures.findIndex((picture) => picture.uri === action.payload);
          if (~index) {
            pictures.splice(index, 1);
          }
        }
      });
    }
    case 'CLOCKING_ADD_CURRENT_TOOL': {
      return produce(state, (draftState) => {
        if (draftState.currentClocking) {
          const tools = draftState.currentClocking?.tools;
          if (tools) {
            tools.push({ ...action.payload });
          } else {
            draftState.currentClocking.tools = [{ ...action.payload }];
          }
        }
      });
    }
    case 'CLOCKING_REMOVE_CURRENT_TOOL': {
      return produce(state, (draftState) => {
        const tools = draftState.currentClocking?.tools;
        if (tools && tools.length) {
          tools.splice(action.payload, 1);
        }
      });
    }
    case 'CLOCKING_START_PAUSE': {
      return produce(state, (draftState) => {
        const pauses = draftState.currentClocking?.pauses;
        if (pauses) {
          pauses.push(action.payload);
        }
      });
    }
    case 'CLOCKING_END_PAUSE': {
      return produce(state, (draftState) => {
        const pauses = draftState.currentClocking?.pauses;
        if (pauses) {
          const pausesLength = pauses.length;
          if (pausesLength) {
            const pause = pauses[pausesLength - 1];
            pause.end = action.payload;
          }
        }
      });
    }
    case 'SET_CLOCKOUT_DONE': {
      return produce(state, (draftState) => {
        draftState.clockoutDone = action.payload;
      });
    }
    default: {
      return state;
    }
  }
}
