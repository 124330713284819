import MomentUtils from '@date-io/moment';
import { Poppins_500Medium, Poppins_700Bold, useFonts } from '@expo-google-fonts/poppins';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'expo-dev-client';
import * as Device from 'expo-device';
import { loadAsync } from 'expo-font';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import i18n from 'i18next';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Platform, StatusBar } from 'react-native';
import 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';
import Navigation from './Navigation';
import SISpinner from './components/SISpinner';
import { persistor, store } from './store';
moment.tz.setDefault('Atlantic/Reykjavik');

import FlashMessage from 'react-native-flash-message';
import Iphone14ProMax from './components/DeviceFrame';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import nl from './locales/nl/translation.json';

Sentry.init({
  dsn: 'https://ad83e8423562404db9ec28a4cf4289b9@o564498.ingest.sentry.io/4504474402422784',
  enableInExpoDevelopment: true,
  debug: false,
  release: process.env.SENTRY_RELEASE,
});

const LANGAGES = ['fr', 'en', 'nl', 'de'];
const locale = Localization.locale.substring(0, 2);
let translationLocale = {};
switch (locale) {
  case 'fr':
    require('moment/locale/fr');
    require(`./locales/fr/translation.json`);
    moment.locale('fr');
    break;
  case 'nl':
    require('moment/locale/nl');
    require(`./locales/nl/translation.json`);
    moment.locale('nl');
    break;
  case 'es':
    require('moment/locale/es');
    require(`./locales/es/translation.json`);
    moment.locale('es');
    break;
  default:
    moment.locale('en');
    break;
}

Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    };
  },
});

const Container: React.FC<{ appIsReady: boolean; fontsLoaded: boolean }> = ({ appIsReady, fontsLoaded }) => {
  if (!appIsReady || !fontsLoaded) {
    return <SISpinner />;
  } else if (Platform.OS === 'web') {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
        <StatusBar />
        <Navigation />
      </MuiPickersUtilsProvider>
    );
  } else {
    return (
      <React.Fragment>
        <StatusBar barStyle="dark-content" />
        <Navigation />
      </React.Fragment>
    );
  }
};

const App: React.FC = () => {
  const [fontsLoaded] = useFonts({
    Poppins_500Medium,
    Poppins_700Bold,
  });

  const [appIsReady, setAppIsReady] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState(Device.DeviceType.DESKTOP);
  const notificationListener = useRef() as React.MutableRefObject<any>;

  useEffect(() => {
    (async function () {
      try {
        await SplashScreen.preventAutoHideAsync();
      } catch (e) {
        console.log(e);
      }

      try {
        if (Platform.OS === 'web') {
          await getTranslationsWebAsync();
        } else {
          await getTranslationsAsync();
        }
      } catch (e) {
        console.log(e);
      }

      try {
        await prepareResources();
      } catch (e) {
        console.log('Issue while preparing ressources');
        console.log(e);
      }

      // This listener is fired whenever a notification is received while the app is foregrounded
      // notificationListener.current = Notifications.addNotificationReceivedListener((notification: any) => {
      //   console.log(notification?.request?.content?.body);
      // });

      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
      };
    })();
  }, []);

  const prepareResources = async () => {
    try {
      await loadAsync({
        'shyfter-icons': require('./assets/font/shyfter-icons.ttf'),
      });
    } catch (e) {
      console.log(`shyfter-icons couldn't load'`);
      console.log(e);
    }

    try {
      await SplashScreen.hideAsync();
    } catch (e) {
      console.log(`SplashScreen couldn't hide'`);
      console.log(e);
    }
    setAppIsReady(true);
  };

  useEffect(() => {
    const getDeviceType = async () => {
      const deviceType = await Device.getDeviceTypeAsync();
      setDeviceType(deviceType);
    };

    getDeviceType();
  }, []);

  if (Platform.OS == 'web' && (deviceType == Device.DeviceType.DESKTOP || deviceType == Device.DeviceType.UNKNOWN)) {
    return (
      <Provider store={store}>
        <PersistGate loading={<SISpinner />} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <Iphone14ProMax i18n={i18n}>
              <FlashMessage position="top" floating style={{ marginTop: Platform.OS === 'web' ? 50 : 0 }} />
              <Container appIsReady={appIsReady} fontsLoaded={fontsLoaded} />
            </Iphone14ProMax>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<SISpinner />} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <FlashMessage position="top" floating style={{ marginTop: Platform.OS === 'web' ? 50 : 0 }} />
          <Container appIsReady={appIsReady} fontsLoaded={fontsLoaded} />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

const getTranslationsAsync = async () => {
  const languageDetector: any = {
    type: 'languageDetector',
    async: true,
    detect: (cb: (locale: string) => void) => {
      cb(Localization.locale.split('-')[0]);
    },
    init: () => {},
    cacheUserLanguage: () => {},
  };

  i18n.use(languageDetector).init({
    fallbackLng: 'en',
    resources: {
      ['en']: {
        translation: en,
      },
      ['fr']: {
        translation: fr,
      },
      ['nl']: {
        translation: nl,
      },
      ['es']: {
        translation: es,
      },
    },
    initImmediate: true,
    interpolation: {
      escapeValue: false,
    },
  });
};

const getTranslationsWebAsync = async () => {
  const languageDetector: any = {
    type: 'languageDetector',
    async: true,
    detect: (cb: (locale: string) => void) => {
      cb(Localization.locale.split('-')[0]);
    },
    init: () => {},
    cacheUserLanguage: () => {},
  };

  i18n.use(languageDetector).init({
    fallbackLng: 'en',
    resources: {
      ['en']: {
        translation: en,
      },
      ['fr']: {
        translation: fr,
      },
      ['nl']: {
        translation: nl,
      },
      ['es']: {
        translation: es,
      },
    },
    initImmediate: true,
    interpolation: {
      escapeValue: false,
    },
  });
};
