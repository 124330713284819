import Constants from 'expo-constants';
import { cloneDeep } from 'lodash';
import { Dispatch } from 'react';
import { Platform } from 'react-native';
import { ActionType } from '..';
import { IClocking, IClockingPause, IClockingTask, IScanCode } from '../../types/clocking.model';
import { ITask } from '../../types/task.model';
import { ClockingInitialState } from '../reducers/clockingReducer';
import { DATA_SET_DATA } from './dataActions';

interface RESET_STORE {
  type: 'RESET_STORE';
}

interface REFRESH_DATA {
  type: 'REFRESH_DATA';
  payload?: any;
}

interface REFRESH_DATA_COMMIT {
  type: 'REFRESH_DATA_COMMIT';
  payload?: any;
}

interface CLOCKING_SET_CURRENT_CLOCKING {
  type: 'CLOCKING_SET_CURRENT_CLOCKING';
  payload: IClocking | null;
  meta?: any;
}

interface CLOCKING_SET_CURRENT_CLOCKING_COMMIT {
  type: 'CLOCKING_SET_CURRENT_CLOCKING_COMMIT';
  payload?: any;
  meta?: any;
}
interface CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK {
  type: 'CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK';
  payload?: any;
  meta?: any;
}

interface CLOCKING_END_CURRENT_CLOCKING_COMMIT {
  type: 'CLOCKING_END_CURRENT_CLOCKING_COMMIT';
  payload?: any;
  meta?: any;
  fn?: any;
}

interface CLOCKING_END_CURRENT_CLOCKING_ROLLBACK {
  type: 'CLOCKING_END_CURRENT_CLOCKING_ROLLBACK';
  meta?: any;
}

interface CLOCKING_UPDATE_CURRENT_CLOCKING_ID {
  type: 'CLOCKING_UPDATE_CURRENT_CLOCKING_ID';
  payload: string;
}

interface CLOCKING_UPDATE_HOMEWORKING {
  type: 'CLOCKING_UPDATE_HOMEWORKING';
  payload: boolean;
}

interface CLOCKING_ADD_CURRENT_TASK {
  type: 'CLOCKING_ADD_CURRENT_TASK';
  payload: ITask;
  meta?: any;
}

interface CLOCKING_START_CURRENT_TASK {
  type: 'CLOCKING_START_CURRENT_TASK';
  payload: {
    id: string;
    start: number;
  };
  meta?: any;
}

interface CLOCKING_EDIT_CURRENT_TASK {
  type: 'CLOCKING_EDIT_CURRENT_TASK';
  payload: {
    id: string;
    start: number;
    end: number;
  };
  meta?: any;
}
interface CLOCKING_END_CURRENT_TASK {
  type: 'CLOCKING_END_CURRENT_TASK';
  payload: {
    id: string;
    end: number;
    flowsResponse?: IClockingTask['flowsResponse'];
  };
  meta?: any;
}

interface CLOCKING_ADD_CURRENT_PICTURE {
  type: 'CLOCKING_ADD_CURRENT_PICTURE';
  payload: string;
}

interface CLOCKING_REMOVE_CURRENT_PICTURE {
  type: 'CLOCKING_REMOVE_CURRENT_PICTURE';
  payload: string;
}

interface CLOCKING_ADD_CURRENT_TOOL {
  type: 'CLOCKING_ADD_CURRENT_TOOL';
  payload: IScanCode;
}

interface CLOCKING_REMOVE_CURRENT_TOOL {
  type: 'CLOCKING_REMOVE_CURRENT_TOOL';
  payload: number;
}

interface CLOCKING_START_PAUSE {
  type: 'CLOCKING_START_PAUSE';
  payload: IClockingPause;
  meta?: any;
}

interface CLOCKING_END_PAUSE {
  type: 'CLOCKING_END_PAUSE';
  payload: number;
  meta?: any;
}

interface CLOCKING_HARD_RESET {
  type: 'CLOCKING_HARD_RESET';
}

interface SET_CLOCKOUT_DONE {
  type: 'SET_CLOCKOUT_DONE';
  payload: boolean;
}

type ClockingActionType =
  | RESET_STORE
  | CLOCKING_HARD_RESET
  | REFRESH_DATA
  | REFRESH_DATA_COMMIT
  | CLOCKING_SET_CURRENT_CLOCKING
  | CLOCKING_SET_CURRENT_CLOCKING_COMMIT
  | CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK
  | CLOCKING_END_CURRENT_CLOCKING_COMMIT
  | CLOCKING_END_CURRENT_CLOCKING_ROLLBACK
  | CLOCKING_UPDATE_CURRENT_CLOCKING_ID
  | CLOCKING_UPDATE_HOMEWORKING
  | CLOCKING_ADD_CURRENT_TASK
  | CLOCKING_START_CURRENT_TASK
  | CLOCKING_EDIT_CURRENT_TASK
  | CLOCKING_END_CURRENT_TASK
  | CLOCKING_ADD_CURRENT_PICTURE
  | CLOCKING_REMOVE_CURRENT_PICTURE
  | CLOCKING_ADD_CURRENT_TOOL
  | CLOCKING_REMOVE_CURRENT_TOOL
  | CLOCKING_START_PAUSE
  | CLOCKING_END_PAUSE
  | DATA_SET_DATA
  | SET_CLOCKOUT_DONE;

export default ClockingActionType;

export const checkLastClockingsWithCurrentClocking = (
  lastClockings: IClocking[],
  clockingState: ClockingInitialState,
  isOnline: boolean,
  dispatch: Dispatch<ActionType>,
): void => {
  const currentClocking = clockingState.currentClocking;
  if (currentClocking) {
    if (!!lastClockings?.length) {
      const optimisticId = currentClocking.optimisticId!;
      const currentClockingId = currentClocking?.id || clockingState.optimisticIdsToIds[optimisticId];
      const lastClocking = lastClockings.find((lastClocking: IClocking) => lastClocking.id === currentClockingId);
      if (lastClocking) {
        const { pictures, tools } = { ...currentClocking };
        const data = cloneDeep({ pictures, tools });

        dispatch({
          type: 'CLOCKING_SET_CURRENT_CLOCKING',
          payload: null,
          meta: {
            wasOnline: isOnline,
            offline: {
              effect: {
                url: `${
                  Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
                }/v3/clockings/checkout/${currentClockingId ? currentClockingId : 0}?mobile=true&os=${Platform.OS}`,
                method: 'POST',
                formData: {
                  type: 'CLOCKING_CHECK_OUT',
                  data,
                },
              },
              commit: {
                type: 'CLOCKING_END_CURRENT_CLOCKING_COMMIT',
                meta: { optimisticId, setClockoutDone: true },
              },
              rollback: {
                type: 'CLOCKING_END_CURRENT_CLOCKING_ROLLBACK',
                meta: { optimisticId, setClockoutDone: true },
              },
            },
          },
        });
      }
    }
  }
};
