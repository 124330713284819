import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import colors from '../styles/colors';
import Icon from './Icon';
import SIText from './SIText';

interface Props {
  label?: string;
  title?: string;
  control: Control<Record<string, any>>;
  name: string;
  defaultValue?: boolean;
  style?: StyleProp<TextStyle>;
  rules?: {
    required?: boolean;
  };
  errors?: DeepMap<Record<string, any>, FieldError>;
  parent?: string;
}

const Checkbox: React.FC<Props> = ({
  control,
  name,
  label,
  title,
  style,
  rules,
  parent,
  errors,
  defaultValue = false,
}) => {
  const error =
    errors &&
    (parent
      ? errors[parent]?.find((el: any) => el?.type === 'required' && el['ref'].name == name)
      : errors[name]?.type === 'required');

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return (
          <View>
            {title && (
              <View style={{ flexDirection: 'row' }}>
                {rules?.required && <SIText style={styles.red}> *</SIText>}
                <SIText style={error ? styles.red : null}>{title}</SIText>
              </View>
            )}
            <TouchableOpacity
              style={[styles.container, style, error ? { borderColor: 'red' } : null]}
              onPress={() => onChange(!value)}
            >
              <View style={[styles.checkbox, value ? styles.checkboxSeletected : null]}>
                <Icon name="ok" size={12} color="#ffffff" />
              </View>
              {label && <SIText style={[styles.text, value ? styles.textSelected : null]}>{`${label}`}</SIText>}
            </TouchableOpacity>
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,
    marginBottom: 5,
    alignItems: 'center',
    borderColor: colors.greyLight,
    borderWidth: 1,
  },
  checkbox: {
    backgroundColor: '#ffffff',
    borderRadius: 14,
    padding: 5,
    borderWidth: 1,
    borderColor: colors.grey,
  },
  checkboxSeletected: {
    borderColor: colors.green,
    backgroundColor: colors.green,
  },
  text: {
    marginLeft: 10,
    color: colors.grey,
    fontSize: 12,
  },
  textSelected: {
    color: colors.green,
  },
  red: {
    color: 'red',
  },
});

export default Checkbox;
