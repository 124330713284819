import { useFocusEffect, useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import axios from 'axios';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Checkbox from '../../components/Checkbox';
import DateInput from '../../components/DateInput';
import SIButton from '../../components/SIButton';
import Select from '../../components/Select';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IShift } from '../../types/shift.model';
import { IUnavailability } from '../../types/unavailability.model';
import UnavailabilityCards from './UnavailabilityCards';

const shiftMarker = { key: 'shiftMarker', color: colors.green };
const dayoffMarker = { key: 'dayoffMarker', color: colors.red };
const unavailabilityMarker = { key: 'unavailabilityMarker', color: colors.grey };

LocaleConfig.locales['fr'] = {
  monthNames: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
  monthNamesShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
  dayNames: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  dayNamesShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
};

LocaleConfig.locales['nl'] = {
  monthNames: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  monthNamesShort: 'Jan._Feb._Mrt._Apr._Mei_Jun._Jul._Aug._Sep._Okt._Nov._Dec.'.split('_'),
  dayNames: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  dayNamesShort: 'Zo._Ma._Di._Wo._Do._Vr._Za.'.split('_'),
};

LocaleConfig.locales['de'] = {
  monthNames: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthNamesShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  dayNames: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  dayNamesShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
};

switch (Localization.locale.substring(0, 2)) {
  case 'fr':
    LocaleConfig.defaultLocale = 'fr';
    break;
  case 'nl':
    LocaleConfig.defaultLocale = 'nl';
    break;
  case 'de':
    LocaleConfig.defaultLocale = 'de';
    break;
  default:
    LocaleConfig.defaultLocale = '';
    break;
}

moment.tz.setDefault('Atlantic/Reykjavik');

const AvailabilityCalendar: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string | null>();
  const [unavailabilities, setUnavailabilities] = useState<IUnavailability>();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dataState = useSelector((store: InitialState) => store.data);
  const { setValue, errors, control, watch, handleSubmit } = useForm();
  const watchDepartmentId = watch('departmentId', null);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [markedDates, setMarkedDates] = useState<any>(null);
  const [monthShifts, setMonthShifts] = useState<IShift[]>([]);
  const [selectedShifts, setSelectedShifts] = useState<IShift[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const watchFullDay = watch('fullDay', false);

  const [minDate, setMinDate] = useState<string | null>(null);

  useEffect(() => {
    if (!watchFullDay && selectedDates && selectedDates.length) {
      setValue('start', moment().startOf('day').toDate().setUTCHours(9));
      setValue('end', moment().startOf('day').toDate().setUTCHours(17));
    }
  }, [watchFullDay, selectedDates]);

  useEffect(() => {
    updatedMonthShifts(true);
  }, [unavailabilities]);

  useEffect(() => {
    updatedMonthShifts();
  }, [selectedMonth]);

  const updatedMonthShifts = (updateMarkedDates = false) => {
    const startDate = moment(selectedMonth).startOf('month').unix();
    const endDate = moment(selectedMonth).endOf('month').unix();
    const selectedShifts: IShift[] = [];
    const selectedFreeShifts: IShift[] = [];

    if (unavailabilities?.shifts) {
      getShiftsInRange({ startDate, endDate, shifts: unavailabilities.shifts, updateMarkedDates }).forEach((shift) => {
        if (shift.userRecordId) {
          selectedShifts.push(shift);
        } else {
          selectedFreeShifts.push(shift);
        }
      });
    }

    setMonthShifts([...selectedShifts, ...selectedFreeShifts]);
    setSelectedDates([]);
    setIsLoading(false);
  };

  useEffect(() => {
    // if (selectedDates.length > 0) {
    const markedDatesCopy = { ...markedDates };

    for (const [key, _] of Object.entries(markedDatesCopy)) {
      delete markedDatesCopy[key];
    }

    selectedDates.forEach((date: string) => {
      markedDatesCopy[date] = {
        ...markedDatesCopy[date],
        selected: true,
      };
    });

    setMarkedDates(markedDatesCopy);
    const startDate = moment().startOf('month').unix();
    const endDate = moment().endOf('month').unix();
    const selectedShifts: IShift[] = [];
    const selectedFreeShifts: IShift[] = [];

    getShiftsInRange({ startDate, endDate, shifts: monthShifts }).forEach((shift) => {
      if (shift.userRecordId) {
        selectedShifts.push(shift);
      } else {
        selectedFreeShifts.push(shift);
      }
    });
    setSelectedShifts([...selectedShifts, ...selectedFreeShifts]);
  }, [selectedDates, monthShifts]);

  const getShiftsInRange = ({
    startDate,
    endDate,
    shifts,
    updateMarkedDates = false,
  }: {
    startDate: number;
    endDate: number;
    shifts: any[];
    updateMarkedDates?: boolean;
  }) => {
    const _shifts = [];
    if (updateMarkedDates) {
      const markedDates: any = {};
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];
        const { start, startDate: shiftStartDate } = shift;
        const markedDate = markedDates[shiftStartDate!.substring(0, 10)];
        if (markedDate) {
          if (shift.shyftType == 1) {
            markedDate.dots.push(shiftMarker);
          }
          if (shift.shyftType == 2) {
            markedDate.dots.push(dayoffMarker);
          }
          if (shift.shyftType == 3) {
            markedDate.dots.push(unavailabilityMarker);
          }
        } else {
          markedDates[shiftStartDate!.substring(0, 10)] = {
            dots: shift.shyftType == 1 ? [shiftMarker] : shift.shyftType == 2 ? [dayoffMarker] : [unavailabilityMarker],
          };
        }
        if (
          (start! <= endDate! && start! >= startDate!) ||
          (shift.shyftType == 3 &&
            moment(shift.startDate).unix() >= moment().startOf('month').unix() &&
            moment(shift.endDate).unix() <= moment().startOf('month').unix())
        ) {
          _shifts.push(shift);
        }
      }
      setMarkedDates(markedDates);
    } else {
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];
        const { start } = shift;
        if (start! <= endDate! && start! >= startDate!) {
          _shifts.push(shift);
        }
      }
    }

    return _shifts;
  };

  const onDayPress = (day: any) => {
    const { dateString } = day;
    if (selectedDates.includes(dateString)) {
      setSelectedDates(selectedDates.filter((date: string) => date !== dateString));
    } else {
      setSelectedDates([...selectedDates, dateString]);
    }
  };

  useEffect(() => {
    const lastDay =
      dataState.departments.find((department) => department.id === watchDepartmentId)?.params?.availabilityEnd || 10;

    let minDate;
    if (moment().date() > lastDay) {
      minDate = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    } else {
      minDate = moment().startOf('month').format('YYYY-MM-DD');
    }
    setMinDate(minDate);
  }, [watchDepartmentId, dataState.departments]);

  useFocusEffect(
    useCallback(() => {
      setTimestamp(moment().unix());
      const departments = dataState.departments.filter((department) => department.params?.enableAvailability);
      if (!watchDepartmentId && departments.length === 1) {
        setTimeout(() => {
          setValue('departmentId', departments[0].id);
        }, 0);
      }
    }, []),
  );

  useEffect(() => {
    let mounted = true;
    if (watchDepartmentId && mounted) {
      getUnavailabilities();
    }

    return () => {
      mounted = false;
    };
  }, [watchDepartmentId, selectedMonth, timestamp]);

  const getUnavailabilities = () => {
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/unavailabilities`,
        {
          params: {
            departmentId: watchDepartmentId,
            month: selectedMonth,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        const result: any[] = [];
        const shifts = data.shifts;
        const keys = Object.keys(shifts);

        keys.map((key) => {
          const values = Array.from(shifts[key]);
          values.map((value: any) => {
            const start = value.startHour ? value.startHour : '00:00:00';
            const end = value.endHour ? value.endHour : '00:00:00';
            const start_date = `${key} ${start}`;
            const end_date = `${key} ${end}`;
            const start_timestamp = moment(`${key}T${start}Z`).unix();
            const end_timestamp = moment(`${key}T${end}Z`).unix();
            result.push({
              ...value,
              start: start_timestamp,
              end: end_timestamp,
              startDate: start_date,
              endDate: end_date,
            });
          });
        });

        // setSelectedMonth(moment(data.dates.startMonth).format('YYYY-MM'));
        setUnavailabilities({ ...data, shifts: result });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!axios.isCancel(error)) {
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
          }
        }
        console.log(error);
      });
  };

  const onRemoveUnavailability = (id: string) => {
    return axios
      .delete(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/unavailabilities/${id}`,
        {
          params: {
            departmentId: watchDepartmentId,
            month: selectedMonth,
          },
        },
      )
      .then(() => {
        setTimestamp(moment().unix());
      })
      .catch((error) => {
        setIsLoading(false);
        if (!axios.isCancel(error)) {
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_SENDING_DATA')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_SENDING_DATA'));
          }
        }
        console.log(error);
      });
  };

  const onSubmit = async (data: any) => {
    const { fullDay, start, end } = data;
    setIsSaving(true);
    axios
      .post(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/unavailabilities`,
        {
          departmentId: watchDepartmentId,
          days: selectedDates,
          fullDay,
          startHour: moment.unix(start / 1000).format('HH:mm'),
          endHour: moment.unix(end / 1000).format('HH:mm'),
          mobile: true,
          os: Platform.OS,
        },
      )
      .then((res) => {
        setIsSaving(false);
        getUnavailabilities();
        // navigation.goBack();
        if (res.data.errors) {
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('UNAVAILABILITIES.MESSAGES.COULD_NOT_BE_CREATED')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('UNAVAILABILITIES.MESSAGES.COULD_NOT_BE_CREATED'));
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_SENDING_DATA')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_SENDING_DATA'));
        }
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('GENERAL.UNAVAILABILITIES')} />
      <ScrollView style={styles.container}>
        <View
          style={[
            styles.select_container,
            {
              display:
                dataState.departments.filter((department) => department.params?.enableAvailability).length == 1
                  ? 'none'
                  : 'flex',
            },
          ]}
        >
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={dataState.departments
              .filter((department) => department.params?.enableAvailability)
              .map((department) => ({
                label: department.company!,
                value: department.id!,
              }))}
          />
        </View>
        {watchDepartmentId && (
          <React.Fragment>
            <Calendar
              current={unavailabilities && unavailabilities.dates.delayStart}
              minDate={unavailabilities && unavailabilities.dates.delayStart}
              markedDates={markedDates}
              onDayPress={onDayPress}
              onMonthChange={(month) => setSelectedMonth(month.dateString.substring(0, 7))}
              style={styles.calendar}
              displayLoadingIndicator={isLoading}
              firstDay={1}
              markingType={'multi-dot'}
              theme={{
                monthTextColor: 'black',
                textMonthFontFamily: 'Poppins_500Medium',
                textDayFontFamily: 'Poppins_500Medium',
                textDayHeaderFontFamily: 'Poppins_500Medium',
                dotColor: colors.red,
                todayTextColor: colors.green,
                arrowColor: colors.green,
                selectedDayBackgroundColor: colors.green,
              }}
            />
            {!isLoading && (
              <View style={{ marginBottom: Platform.OS !== 'web' ? 50 : 0 }}>
                {/* <SIButton
                  style={{ marginVertical: 10 }}
                  title={t('GENERAL.ADD')}
                  onPress={() => navigation.navigate('SecondStep', { departmentId: watchDepartmentId })}
                /> */}
                {selectedDates.length <= 0 && (
                  <Checkbox
                    control={control}
                    label={t('GENERAL.FULL_DAY')}
                    name="fullDay"
                    style={{ display: 'none' }}
                  />
                )}
                {selectedDates.length > 0 && (
                  <View style={styles.footer}>
                    <Checkbox
                      control={control}
                      label={t('GENERAL.FULL_DAY')}
                      name="fullDay"
                      style={{ marginBottom: 15 }}
                    />
                    {!watchFullDay && (
                      <React.Fragment>
                        <DateInput
                          control={control}
                          name="start"
                          label={t('SHIFTS.START')}
                          rules={{ required: true }}
                          mode={'time'}
                          errors={errors}
                          watch={watch}
                        />
                        <DateInput
                          control={control}
                          name="end"
                          label={t('SHIFTS.END')}
                          rules={{ required: true }}
                          mode={'time'}
                          errors={errors}
                          watch={watch}
                        />
                      </React.Fragment>
                    )}
                    <SIButton
                      style={{ marginTop: 10 }}
                      title={t('GENERAL.CONFIRM')}
                      onPress={handleSubmit(onSubmit)}
                      loading={isSaving}
                    />
                  </View>
                )}
                <View style={styles.cards_container}>
                  <UnavailabilityCards
                    minDate={minDate}
                    unavailabilities={monthShifts}
                    onRemoveUnavailability={onRemoveUnavailability}
                  />
                </View>
              </View>
            )}
          </React.Fragment>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    display: 'flex',
    flex: 1,
    backgroundColor: colors.blueExtraLight,
  },
  select_container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    paddingVertical: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 25,
  },
  calendar: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 0,
    paddingBottom: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
  cards_container: {
    marginTop: 25,
  },
  footer: {
    marginTop: 25,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    borderRadius: 10,
    padding: 25,
  },
});

export default AvailabilityCalendar;
