import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as Location from 'expo-location';
import * as Network from 'expo-network';
import * as Notifications from 'expo-notifications';
import { map, pick } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from 'sentry-expo';
import Back from '../../components/Back';
import SIButton from '../../components/SIButton';
import Select from '../../components/Select';
import { InitialState } from '../../store';
import ClockingActionType from '../../store/actions/clockingActions';
import colors from '../../styles/colors';
import { IClocking, IScanCode } from '../../types/clocking.model';
import { IDepartment } from '../../types/department.model';
import { IShift } from '../../types/shift.model';
import { generateOptimisticId, nowUnix } from '../../utils';

moment.tz.setDefault('Atlantic/Reykjavik');

type NavigationParams = {
  CheckInScreen: {
    qrcode?: IScanCode;
    cancelQrCode?: boolean;
    clocking?: IClocking;
    shift?: IShift;
  };
};

type Props = StackScreenProps<NavigationParams, 'CheckInScreen'>;

const CheckInScreen: React.FC<Props> = ({ route }) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clocking, setClocking] = useState<IClocking | null>(null);
  const reduxDispatch: Dispatch<ClockingActionType> = useDispatch();
  const authState = useSelector((store: InitialState) => store.auth);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [activeDepartment, setActiveDepartment] = useState<IDepartment | null>(null);
  const isOnline = !!offlineState?.online;

  const watchDepartment = watch('departmentId', null);

  const free_clocking_departments = dataState.departments.filter(
    (department) =>
      department.clockinParams?.enableMobileClockin &&
      (department.clockinAccount || (department.clockin && department.clockinParams?.freeclockin)),
  );
  const no_offline_departments_ids = dataState.no_offline_clockings_departments
    ? dataState.no_offline_clockings_departments.map((department) => department.id)
    : [];

  const departments = isOnline
    ? free_clocking_departments
    : free_clocking_departments.filter((department) => !no_offline_departments_ids.includes(department.id));

  useFocusEffect(
    useCallback(() => {
      if (!watchDepartment && dataState.departments?.length === 1) {
        setTimeout(() => {
          setValue('departmentId', dataState.departments[0].id);
        }, 0);
        const activeDepartment = dataState.departments.find(
          (department) => department.id === dataState.departments[0].id,
        );
        setActiveDepartment(activeDepartment || null);
      }

      if (departments.length == 1) {
        setTimeout(() => {
          setValue('departmentId', departments[0].id);
        }, 0);
        setActiveDepartment(departments[0]);
      }
    }, []),
  );

  useEffect(() => {
    if (!activeDepartment || !activeDepartment.sections) return;
    if (activeDepartment.sections.length == 1) {
      setValue('sectionId', activeDepartment.sections[0].id);
    }
  }, [activeDepartment]);

  useEffect(() => {
    try {
      if (route.params) {
        const { qrcode, cancelQrCode, clocking: _clocking, shift } = route.params;
        if (clocking) {
          setClocking(null);
          if (qrcode) {
            setCheckIn({
              ...clocking,
              checkIn: {
                ...clocking.checkIn!,
                qrcode,
              },
            });
          } else if (cancelQrCode) {
            setCheckIn({
              ...clocking,
            });
          }
        } else if (_clocking) {
          const department = dataState.departments.find((department) => department.id === _clocking.departmentId)!;
          const scanCodes = department.scanCodes;
          if (Platform.OS !== 'web' && scanCodes?.places?.length) {
            let filteredScanCodes = scanCodes?.places;
            if (shift) {
              if (shift?.attributes?.length) {
                const attributes = shift?.attributes?.map((attribute) => attribute.id);
                filteredScanCodes = scanCodes?.places?.filter((place) => attributes.includes(place.id)) || [];
              } else {
                const scanCode = scanCodes?.places?.find((place) => shift.departmentId === place.id);
                filteredScanCodes = scanCode ? [scanCode] : [];
              }
            }
            setClocking(_clocking);
            navigation.navigate('ScanQrCode', {
              destination: 'ClockingCheckIn',
              cancelPopUp: {
                title: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_TITLE'),
                message: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_MESSAGE'),
              },
              cancelText: t('CLOCKING.CLOCKING_WITHOUT_SCAN'),
              scanCodes: filteredScanCodes,
              activeDepartment: department,
            });
          } else {
            setClocking(null);
            setCheckIn({
              ..._clocking,
            });
          }
        }
      }
    } catch (error) {
      if (Platform.OS == 'web') {
        Sentry.Browser.captureException(error);
        Sentry.Browser.setTag('err', 'useEffect');
      } else {
        Sentry.Native.captureException(error);
        Sentry.Native.setTag('err', 'useEffect');
      }
    }
  }, [route.params]);

  useEffect(() => {
    const beforeRemove = (e: any) => {
      if (!isLoading) {
        return;
      }
      e.preventDefault();
    };
    navigation.addListener('beforeRemove', beforeRemove);
    return () => {
      navigation.removeListener('beforeRemove', beforeRemove);
    };
  }, [navigation, isLoading]);

  const setCheckIn = async (payload: IClocking) => {
    try {
      const tasks = payload.tasks || [];
      const optimisticId = generateOptimisticId();
      const network = await Network.getNetworkStateAsync();
      const { granted } = await Camera.getCameraPermissionsAsync();
      const expoPushToken = await AsyncStorage.getItem('expo-token');
      const { status } = await Notifications.getPermissionsAsync();

      reduxDispatch({
        type: 'CLOCKING_SET_CURRENT_CLOCKING',
        payload: {
          optimisticId,
          ...payload,
        },
        meta: {
          wasOnline: isOnline,
          showError: true,
          dispatch: reduxDispatch,
          offline: {
            effect: {
              url: `${
                Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
              }/v3/clockings?mobile=true`,
              method: 'POST',
              data: {
                ...payload,
                mobile: true,
                os: Platform.OS,
                tasks: map([...tasks], (task) =>
                  pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
                ),
                infos: {
                  version: `${Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}`,
                  os: Platform.OS,
                  model_name: Device.modelName,
                  online: isOnline,
                  camera_granted: granted,
                  total_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getTotalDiskCapacityAsync()}` : null,
                  free_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getFreeDiskStorageAsync()}` : null,
                  ram: Device.totalMemory ? `${Device.totalMemory}` : null,
                  token: authState.accessToken,
                  notifications: status,
                },
              },
            },
            commit: {
              type: 'CLOCKING_SET_CURRENT_CLOCKING_COMMIT',
              meta: { optimisticId },
            },
            rollback: { type: 'CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK', meta: { optimisticId } },
          },
        },
      });

      navigation.navigate('Home', { screen: 'Main', params: null });
    } catch (error) {
      if (Platform.OS == 'web') {
        Sentry.Browser.captureException(error);
        Sentry.Browser.setTag('err', 'setCheckIn');
      } else {
        Sentry.Native.captureException(error);
        Sentry.Native.setTag('err', 'setCheckIn');
      }
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);

      const { departmentId, sectionId, skillId, taskId } = data;
      const attributeIds = Object.entries(data)
        .filter((entry) => entry[0].startsWith('resource_'))
        .map((resource) => resource[1] as string);
      const now = nowUnix();

      const department = dataState.departments.find((_department) => _department.id === departmentId)!;
      const { scanCodes } = department;

      let location: Location.LocationObject | null = null;
      const enableGps = department.clockinParams?.enableGps;

      if (enableGps) {
        const { status } = await Location.requestForegroundPermissionsAsync();
        if (status === 'granted') {
          location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced });
        }
      }

      const currentClocking: IClocking = {
        departmentId,
        skillIds: skillId ? [skillId] : [],
        attributeIds: attributeIds.filter((attribute) => attribute !== null),
        userMainId: authState.userDetails?.id,
        sectionId,
        tasks: taskId
          ? [
              {
                id: generateOptimisticId(),
                start: now,
                taskTypeId: taskId,
                task: department?.tasks?.find((task) => task.id === taskId),
              },
            ]
          : [],
        checkIn: {
          start: now,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
          qrcode: undefined,
        },
      };

      if (Platform.OS !== 'web' && scanCodes?.places?.length) {
        setClocking(currentClocking);
        navigation.navigate('ScanQrCode', {
          destination: 'ClockingCheckIn',
          cancelPopUp: {
            title: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_TITLE'),
            message: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_MESSAGE'),
          },
          cancelText: t('CLOCKING.CLOCKING_WITHOUT_SCAN'),
          scanCodes: scanCodes.places,
        });
      } else {
        setCheckIn({ ...currentClocking });
      }
      setIsLoading(false);
    } catch (error) {
      if (Platform.OS == 'web') {
        Sentry.Browser.captureException(error);
        Sentry.Browser.setTag('err', 'onSubmit');
      } else {
        Sentry.Native.captureException(error);
        Sentry.Native.setTag('err', 'onSubmit');
      }
    }
  };

  useEffect(() => {
    if (watchDepartment) {
      const activeDepartment = dataState.departments.find((department) => department.id === watchDepartment);
      setActiveDepartment(activeDepartment || null);
    }
  }, [watchDepartment]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('CLOCKING.CHECK_IN')} />
      <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 20 }}>
        <View style={styles.content}>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={departments.map((department) => ({
              label: department.company!,
              value: department.id!,
            }))}
            style={{ display: departments.length == 1 ? 'none' : 'flex' }}
          />
          {!!activeDepartment?.params?.enableSection && !!activeDepartment?.sections?.length && (
            <Select
              label={t('SHIFTS.SECTION')}
              control={control}
              defaultValue={null}
              name="sectionId"
              items={activeDepartment?.sections?.map((section) => ({ label: section.name!, value: section.id! })) || []}
              style={{ display: activeDepartment?.sections.length == 1 ? 'none' : 'flex' }}
            />
          )}
          {!!activeDepartment?.modules?.skills && !!activeDepartment?.skills?.length && (
            <Select
              label={t('SHIFTS.SKILL')}
              control={control}
              defaultValue={null}
              name="skillId"
              items={activeDepartment?.skills?.map((skill) => ({ label: skill.name!, value: skill.id! })) || []}
            />
          )}
          {!!activeDepartment?.modules?.resources &&
            activeDepartment?.resources
              ?.filter((resource) => resource.displayWhenFreeClocking && !!resource?.attributes?.length)
              ?.map((resource) => (
                <Select
                  key={`resource_${resource.id!}`}
                  label={resource.name!}
                  control={control}
                  defaultValue={null}
                  name={`resource_${resource.id!}`}
                  items={
                    resource?.attributes?.map((attribut) => ({ label: attribut.name!, value: attribut.id! })) || []
                  }
                />
              ))}
          <SIButton
            title={dataState.clockinAccount ? t('CLOCKING.START_MY_CLOCKING') : t('SHIFTS.START_MY_SHIFT')}
            size="large"
            loading={isLoading}
            onPress={handleSubmit(onSubmit)}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
    flex: 1,
  },
  content: {
    backgroundColor: '#fff',
    padding: 25,
    borderRadius: 10,
  },
});

export default CheckInScreen;
