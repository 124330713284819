import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, BackHandler, View, Platform, Text, Image, ScrollView, FlatList, Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import { IDocument } from '../../types/document.model';

type NavigationParams = {
  SignatureView: {
    document: IDocument;
  };
};

type Props = StackScreenProps<NavigationParams, 'SignatureView'>;

const SignatureView: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const [document, setDocument] = useState<IDocument>();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    if (!route.params) {
      navigation.navigate('Home');
    } else {
      setDocument(route.params.document);
    }
  }, [route.params]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };
      const listener = BackHandler.addEventListener('hardwareBackPress', onBackPress);
      return () => {
        listener.remove();
      };
    }, []),
  );

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      {Platform.OS === 'web' && <iframe srcDoc={document?.render.html} style={{ height: 'calc(100vh - 100px)' }} />}
      {Platform.OS !== 'web' && <WebView originWhitelist={['*']} source={{ html: document?.render.html || '' }} />}
      <View style={{ paddingHorizontal: 20, paddingTop: 20 }}>
        <SIButton
          title={t('GENERAL.SIGN')}
          size="large"
          onPress={() => {
            navigation.navigate('SignatureSign', { selectedDocument: document });
          }}
        />
      </View>
    </SafeAreaView>
  );
};

export default SignatureView;
