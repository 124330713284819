import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { default as Axios, default as axios } from 'axios';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as Location from 'expo-location';
import * as Network from 'expo-network';
import * as Notifications from 'expo-notifications';
import { cloneDeep } from 'lodash';
import React, { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Sentry from 'sentry-expo';
import Back from '../../components/Back';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import TextArea from '../../components/TextArea';
import { ActionType, InitialState } from '../../store';
import colors from '../../styles/colors';
import { IClocking, IScanCode } from '../../types/clocking.model';
import { ICost } from '../../types/cost.model';
import { nowUnix } from '../../utils';

type NavigationParams = {
  CheckOutScreen: {
    qrcode?: IScanCode;
    cancelQrCode?: boolean;
    removeLoading?: boolean;
  };
};

type Props = StackScreenProps<NavigationParams, 'CheckOutScreen'>;

const CheckOutScreen: React.FC<Props> = ({ route }) => {
  const { handleSubmit, errors, control, setValue } = useForm({ shouldUnregister: false });
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const navigation = useNavigation();
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const dataState = useSelector((store: InitialState) => store.data);
  const [clocking, setClocking] = useState<IClocking | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [costs, setCosts] = useState<ICost[]>([]);
  const [costsLoading, setCostsLoading] = useState<boolean>(false);
  const offlineSate = useSelector((store: InitialState) => store.offline, shallowEqual);
  const [dataAreLoading, setDataAreLoading] = useState<boolean>(false);
  const [networkStatus, setNetworkStatus] = useState<Network.NetworkState | null>(null);
  const authState = useSelector((store: InitialState) => store.auth, shallowEqual);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const isOnline = !!offlineSate?.online;

  const departmentId = clockingState.currentClocking?.departmentId;
  const department = dataState.departments.find((_department) => _department.id === departmentId);

  useEffect(() => {
    if (!route.params || !route.params.removeLoading) return;
    setIsLoading(false);
  }, [route.params]);

  useEffect(() => {
    let mounted = true;
    const cancleTokenSource = axios.CancelToken.source();
    setCostsLoading(true);

    if (mounted) {
      axios
        .get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/costs`,
          {
            params: {
              departmentId,
            },
            cancelToken: cancleTokenSource.token,
          },
        )
        .then(({ data }: { data: ICost[] }) => {
          const costs =
            department && department.status
              ? data.filter((cost) => {
                  if (cost.statuses.length > 0) {
                    if (cost.statuses.includes(department.status!.toString())) {
                      return cost;
                    } else {
                      return null;
                    }
                  } else {
                    return cost;
                  }
                })
              : data;
          setCosts(costs);
          setCostsLoading(false);
          costs.forEach((cost: ICost) => {
            switch (cost.format) {
              case 'text':
                setValue(`costs.${cost.id}`, null);
                break;
              case 'number':
                setValue(`costs.${cost.id}`, null);
                break;
              case 'boolean':
                setValue(`costs.${cost.id}`, false);
                break;
              default:
                break;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      mounted = false;
      setCostsLoading(false);
    };
  }, []);

  useEffect(() => {
    process();
  }, [route.params]);

  const process = async () => {
    if (route.params) {
      const { qrcode, cancelQrCode } = route.params;
      if (clocking) {
        setClocking(null);
        if (qrcode) {
          setCheckOut({
            ...clocking,
            checkOut: {
              ...clocking.checkOut,
              qrcode: qrcode,
            },
          });
        } else if (cancelQrCode) {
          setCheckOut({
            ...clocking,
          });
        }
      }
    }
  };

  const beforeRemove = (e: any) => {
    if (!isLoading) {
      return;
    }
    e.preventDefault();
  };

  useEffect(() => {
    navigation.addListener('beforeRemove', beforeRemove);
    return () => {
      navigation.removeListener('beforeRemove', beforeRemove);
    };
  }, [navigation, isLoading]);

  const { clockoutDone } = clockingState;
  useEffect(() => {
    if (clockoutDone) {
      (async () => {
        await refreshData(true);
      })();
    }
  }, [clockoutDone]);

  const setCheckOut = async (clocking: IClocking) => {
    try {
      const { granted } = await Camera.getCameraPermissionsAsync();
      const { pictures, tools, optimisticId, checkOut } = { ...clocking };
      const { status } = await Notifications.getPermissionsAsync();

      const data = cloneDeep({
        pictures,
        tools,
        checkOut,
        infos: {
          version: `${Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}`,
          os: Platform.OS,
          model_name: Device.modelName,
          online: isOnline,
          camera_granted: granted,
          total_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getTotalDiskCapacityAsync()}` : null,
          free_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getFreeDiskStorageAsync()}` : null,
          ram: Device.totalMemory ? `${Device.totalMemory}` : null,
          token: authState.accessToken,
          notifications: status,
        },
      });
      const optimisticIdsToIds = cloneDeep(clockingState.optimisticIdsToIds);

      reduxDispatch({
        type: 'CLOCKING_SET_CURRENT_CLOCKING',
        payload: null,
        meta: {
          wasOnline: isOnline,
          offline: {
            effect: {
              url: `${
                Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
              }/v3/clockings/checkout/${clocking.id || '$id'}?mobile=true&os=${Platform.OS}`,
              method: 'POST',
              optimisticId,
              formData: {
                type: 'CLOCKING_CHECK_OUT',
                data,
              },
            },
            commit: {
              type: 'CLOCKING_END_CURRENT_CLOCKING_COMMIT',
              meta: {
                optimisticId,
                setClockoutDone: true,
              },
            },
            rollback: {
              type: 'CLOCKING_END_CURRENT_CLOCKING_ROLLBACK',
              meta: { optimisticId, clocking: cloneDeep({ ...clocking }), optimisticIdsToIds, setClockoutDone: true },
            },
          },
        },
      });

      if (!offlineSate.online) {
        setIsLoading(false);
        setDataAreLoading(false);
        navigation.goBack();
      }
    } catch (error) {
      if (Platform.OS !== 'web') {
        Sentry.Native.captureException(error);
      } else {
        Sentry.Browser.captureException(error);
      }
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const now = nowUnix();

      let location: Location.LocationObject | null = null;
      const enableGps = department?.clockinParams?.enableGps;

      if (enableGps) {
        const { status } = await Location.requestForegroundPermissionsAsync();
        if (status === 'granted') {
          location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced });
        }
      }

      const currentClocking = { ...clockingState.currentClocking! };
      currentClocking.checkOut = {
        end: now,
        comment: data.comment,
        location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
      };

      const data_costs = data.costs && data.costs.filter((cost: any) => cost !== undefined && cost !== null);

      currentClocking.checkOut.costs =
        data_costs &&
        data_costs
          .map((cost: any, index: number) => {
            const element = costs[index];
            return {
              id: element.id,
              value: cost,
            };
          })
          .filter((value: any) => value !== null);

      const scanCodes = department?.scanCodes;
      if (Platform.OS !== 'web' && !!scanCodes?.places?.length) {
        setClocking({ ...currentClocking });
        navigation.navigate('ScanQrCode', {
          destination: 'ClockingCheckOut',
          cancelPopUp: {
            title: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_TITLE'),
            message: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_MESSAGE'),
          },
          cancelText: t('CLOCKING.CHECK_OUT_WITHOUT_SCAN'),
          scanCodes: scanCodes.places,
          customClocking: currentClocking,
          onSuccess: (clocking: IClocking) => {
            setCheckOut({
              ...currentClocking,
              ...clocking,
            });
          },
        });
      } else {
        setCheckOut({
          ...currentClocking,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setDataAreLoading(false);
      if (Platform.OS !== 'web') {
        Sentry.Native.captureException(new Error('Error while trying to check out on native'));
        Sentry.Native.captureException(error);
      } else {
        Sentry.Browser.captureException(new Error('Error while trying to check out on web'));
        Sentry.Browser.captureException(error);
      }
    }
  };

  const refreshData = async (force = false) => {
    try {
      if ((offlineSate.busy || offlineSate.outbox?.length) && !force) {
        if (
          offlineSate &&
          offlineSate.outbox &&
          offlineSate.outbox[offlineSate.outbox.length] &&
          offlineSate.outbox[offlineSate.outbox.length].type !== 'REFRESH_DATA'
        ) {
          setTimeout(() => {
            refreshData();
          }, 2000);
        }
        return;
      }
      if (!dataAreLoading || force) {
        setDataAreLoading(true);
        const expoPushToken = await AsyncStorage.getItem('expo-token');
        const { status } = await Notifications.getPermissionsAsync();
        Axios.get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/datas`,
          {
            params: {
              userMainId: authState.userDetails?.id,
              mobile: true,
              sections: true,
              tasks: true,
              skills: true,
              notifications: status,
              platform: dataState.platform,
              version: dataState.version,
              brand: dataState.brand,
              modelName: dataState.modelName,
              os: dataState.os,
            },
            headers: expoPushToken
              ? {
                  'x-expo-token': expoPushToken,
                }
              : undefined,
          },
        )
          .then((response) => {
            const {
              departments,
              shifts,
              lastClockings,
              currentClockings,
              signaturesRequired,
              userDetails,
              clockinAccount,
              oncall,
              indicators = [],
              swapShiftsRequests,
              features,
            } = response.data;
            // checkLastClockingsWithCurrentClocking(lastClockings || [], clockingState, isOnline, reduxDispatch);
            reduxDispatch({ type: 'SIGNATURE_SET_REQUIRED_SIGNATURES', payload: signaturesRequired });
            reduxDispatch({ type: 'AUTH_UPDATE_USER_DETAILS', payload: userDetails });
            reduxDispatch({
              type: 'DATA_SET_DATA',
              payload: {
                departments,
                shifts,
                currentClockings,
                clockinAccount,
                oncall,
                indicators,
                swapShiftsRequests,
                features,
                userDetails,
              },
            });
            setIsLoading(false);
            reduxDispatch({
              type: 'SET_CLOCKOUT_DONE',
              payload: false,
            });
            navigation.removeListener('beforeRemove', beforeRemove);
            navigation.goBack();
            setDataAreLoading(false);
          })
          .catch((error) => {
            setDataAreLoading(false);
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setDataAreLoading(false);
      showMessage({
        type: 'danger',
        message: t('CLOCKING.CLOCKOUT_ERROR'),
      });
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('CLOCKING.CHECK_OUT')} />
      <ScrollView style={styles.container}>
        {!costsLoading && costs.length > 0 ? (
          <View style={[styles.content, { paddingVertical: 10 * 2 }]}>
            {costs.map((cost) => (
              <View key={cost.id} style={styles.cost_container}>
                {cost.format == 'text' ? (
                  <View style={styles.cost_field}>
                    <Input
                      defaultValue={null}
                      placeholder={cost.name}
                      control={control}
                      parent="costs"
                      name={`costs.${cost.id}`}
                      style={{ paddingVertical: 15 }}
                      rules={{ required: cost.mandatory }}
                      title={cost.name}
                      errors={errors}
                    />
                  </View>
                ) : null}
                {cost.format == 'number' ? (
                  <View style={styles.cost_field}>
                    <Input
                      defaultValue={null}
                      placeholder={cost.name}
                      control={control}
                      parent="costs"
                      name={`costs.${cost.id}`}
                      style={{ paddingVertical: 15 }}
                      keyboardType="numeric"
                      rules={{ required: cost.mandatory }}
                      title={cost.name}
                      errors={errors}
                    />
                  </View>
                ) : null}
                {cost.format == 'boolean' ? (
                  <View style={[styles.cost_field, styles.checkbox_field]}>
                    <SIText>{cost.name}</SIText>
                    <Checkbox
                      defaultValue={false}
                      control={control}
                      name={`costs.${cost.id}`}
                      rules={{ required: cost.mandatory }}
                      parent="costs"
                      style={{ borderWidth: 0, padding: 0, marginBottom: 0 }}
                    />
                  </View>
                ) : null}
              </View>
            ))}
          </View>
        ) : null}
        <View style={styles.content}>
          <TextArea
            label={t('GENERAL.COMMENT')}
            control={control}
            rules={{ required: !!department?.clockinParams?.commentMandatory }}
            errors={errors}
            defaultValue={null}
            name="comment"
          />
          <SIButton title={t('GENERAL.CONFIRM')} size="large" onPress={handleSubmit(onSubmit)} loading={isLoading} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
    marginBottom: 25,
  },
  cost_container: {
    marginVertical: 10 / 2,
  },
  cost_name: {
    flex: 1,
  },
  cost_field: {
    flex: 1,
  },
  checkbox_field: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default CheckOutScreen;
