import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { TouchableOpacity, View, StyleSheet, Text, Platform } from 'react-native';
import colors from '../styles/colors';
import DatePicker from './DatePicker';
import SIText from './SIText';
import moment from 'moment';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  label: string;
  control: Control<Record<string, any>>;
  name: string;
  rules?: {
    required?: boolean;
    validate?: (value: any) => boolean;
  };
  errors?: DeepMap<Record<string, any>, FieldError>;
  mode?: 'time' | 'date' | 'datetime' | 'countdown';
  watch?: any;
}

const DateInput: React.FC<Props> = ({ label, control, name, rules, errors, mode, watch }) => {
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const watchDate = watch ? watch(name, null) : null;
  useEffect(() => {
    const date: Date | undefined = watch(name, control.getValues(name));
    if (date) {
      if (Platform.OS === 'web') {
        switch (mode) {
          case 'datetime': {
            setValue(moment(date).format('LLL'));
            break;
          }
          case 'time': {
            setValue(moment(date).format('LT'));
            break;
          }
          default: {
            setValue(moment(date).format('L'));
            break;
          }
        }
      } else {
        switch (mode) {
          case 'datetime': {
            setValue(moment(date).format('LLL'));
            break;
          }
          case 'time': {
            setValue(moment(date).format('LT'));
            break;
          }
          default: {
            setValue(moment(date).format('L'));
            break;
          }
        }
      }
    }
  }, [watchDate]);

  const error = ['required', 'validate'].includes(errors?.[name]?.type || '');

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => setShow(!show)}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <SIText style={error ? styles.red : { fontSize: 12, color: colors.grey }}>{label}</SIText>
            {rules?.required && <SIText style={error ? styles.red : null}> *</SIText>}
          </View>
          <Text style={{ color: error ? colors.red : colors.black }}>{value}</Text>
        </View>
      </TouchableOpacity>
      <DatePicker
        control={control}
        name={name}
        rules={rules}
        defaultValue={watch(name, null) || new Date()}
        style={{
          borderTopColor: colors.greyLight,
          borderTopWidth: 1,
          display: 'flex',
        }}
        mode={mode}
        show={show}
        setShow={setShow}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  red: {
    color: 'red',
  },
});

export default DateInput;
