import Axios from 'axios';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, TextInput, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Back from '../../../components/Back';
import SIButton from '../../../components/SIButton';
import SIText from '../../../components/SIText';
import colors from '../../../styles/colors';

type FormValues = {
  email: string;
};

const ForgotPasswordScreen: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<FormValues>();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    register('email');
  }, [register]);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setIsSaving(true);
    const { email } = data;
    Axios.post(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/password`,
      {
        email: email.toLowerCase(),
      },
    )
      .then(() => {
        if (Platform.OS === 'web') {
          alert(
            `${t('AUTH.ALERTS.FORGOT_PASSWORD_SUCCESS_TITLE')}. ${t('AUTH.ALERTS.FORGOT_PASSWORD_SUCCESS_MESSAGE')}`,
          );
        } else {
          Alert.alert(t('AUTH.ALERTS.FORGOT_PASSWORD_SUCCESS_TITLE'), t('AUTH.ALERTS.FORGOT_PASSWORD_SUCCESS_MESSAGE'));
        }
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
        setIsSaving(false);
      });
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <ScrollView>
        <Back title={t('AUTH.FORGOT_PASSWORD')} />
        <View style={styles.container}>
          <View style={styles.header}>
            <SIText style={styles.title}>{t('AUTH.RESET_PASSWORD.TITLE')}</SIText>
            <SIText style={styles.description}>{t('AUTH.RESET_PASSWORD.DESCRIPTION')}</SIText>
          </View>
          <View style={styles.email_container}>
            <TextInput
              placeholder={t('AUTH.EMAIL')}
              keyboardType="email-address"
              autoCapitalize="none"
              style={styles.email}
              onChangeText={(text) => {
                setValue('email', text);
              }}
              placeholderTextColor={colors.grey}
              autoCorrect={false}
            />
          </View>
          <SIButton
            title={t('GENERAL.CONFIRM')}
            size="large"
            style={styles.confirm}
            onPress={handleSubmit(onSubmit)}
            loading={isSaving}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 25,
  },
  header: {
    marginBottom: 25,
  },
  title: {
    fontSize: 16,
  },
  description: {
    color: colors.grey,
    fontSize: 12,
  },
  email_container: {
    borderBottomWidth: 1,
    borderColor: '#D8D8D8',
  },
  email: {
    paddingVertical: 20,
    fontSize: 18,
  },
  confirm: { marginTop: 25 },
});

export default ForgotPasswordScreen;
