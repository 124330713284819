import { useNavigation } from '@react-navigation/native';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as Location from 'expo-location';
import * as Notifications from 'expo-notifications';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from 'sentry-expo';
import { InitialState } from '../store';
import ClockingActionType from '../store/actions/clockingActions';
import colors from '../styles/colors';
import { IClocking } from '../types/clocking.model';
import { IDepartment } from '../types/department.model';
import { IShift } from '../types/shift.model';
import { clockingFromShift, generateOptimisticId, minutesToHoursAndOrMinutes, nowUnix } from '../utils';
import H1 from './H1';
import HomeWorking from './HomeWorking';
import SIButton from './SIButton';
import SIText from './SIText';
import Tag from './Tag';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  shifts: IShift[];
  title?: string;
  isOnline: boolean;
}

const NextShift: React.FC<Props> = ({ shifts, title, isOnline }) => {
  const navigation = useNavigation();
  const dataState = useSelector((state: InitialState) => state.data);
  const authState = useSelector((state: InitialState) => state.auth);
  const reduxDispatch: Dispatch<ClockingActionType> = useDispatch();

  const [nextShift, setNextShift] = useState<IShift | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [now, setNow] = useState<number>(nowUnix());
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const newNextShift = shifts.find((shift) => {
      if (shift.userRecordId && !shift.dayoff && shift.end! > now) {
        return shift;
      }
    });
    setIsLoading(false);
    setNextShift(newNextShift || null);
  }, [shifts, now]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(nowUnix());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const setCheckIn = async (payload: IClocking) => {
    const tasks = payload.tasks || [];
    const optimisticId = generateOptimisticId();
    const { granted } = await Camera.getCameraPermissionsAsync();
    const { status } = await Notifications.getPermissionsAsync();

    reduxDispatch({
      type: 'CLOCKING_SET_CURRENT_CLOCKING',
      payload: {
        optimisticId,
        ...payload,
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings?mobile=true`,
            method: 'POST',
            data: {
              ...payload,
              mobile: true,
              os: Platform.OS,
              infos: {
                version: `${Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}`,
                os: Platform.OS,
                model_name: Device.modelName,
                online: isOnline,
                camera_granted: granted,
                total_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getTotalDiskCapacityAsync()}` : null,
                free_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getFreeDiskStorageAsync()}` : null,
                ram: Device.totalMemory ? `${Device.totalMemory}` : null,
                token: authState.accessToken,
                notifications: status,
              },
            },
          },
          commit: {
            type: 'CLOCKING_SET_CURRENT_CLOCKING_COMMIT',
            meta: { optimisticId },
          },
          rollback: { type: 'CLOCKING_SET_CURRENT_CLOCKING_ROLLBACK', meta: { optimisticId } },
        },
      },
    });

    navigation.navigate('Home', { screen: 'Main', params: null });
  };

  const onPressFreeCheckIn = async () => {
    if (dataState.departments.length == 1 && dataState.clockinAccount) {
      const department = dataState.departments[0];
      let location: Location.LocationObject | null = null;
      const enableGps = department.clockinParams?.enableGps;

      try {
        if (enableGps) {
          const { status } = await Location.requestForegroundPermissionsAsync();
          if (status === 'granted') {
            location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced });
          }
        }
      } catch (error) {
        console.log(error);
      }

      const currentClocking: IClocking = {
        departmentId: department.id,
        userMainId: authState.userDetails?.id,
        sectionId: department.sections && department.sections.length == 1 ? department.sections[0].id : undefined,
        checkIn: {
          start: now,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
          qrcode: undefined,
        },
      };

      if (department.clockinParams?.qrCodeMandatory) {
        const scanCodes = department.scanCodes;
        if (Platform.OS !== 'web' && scanCodes?.places?.length) {
          navigation.navigate('ScanQrCode', {
            destination: 'ClockingCheckIn',
            cancelPopUp: {
              title: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_TITLE'),
              message: t('CLOCKING.ALERTS.CLOCKING_CANCEL_SCAN_MESSAGE'),
            },
            cancelText: t('CLOCKING.CLOCKING_WITHOUT_SCAN'),
            scanCodes: scanCodes.places,
            activeDepartment: department,
            customClocking: currentClocking,
            onSuccess: (clocking: IClocking) => setCheckIn({ ...currentClocking, ...clocking }),
          });
          return;
        }
      }

      if (
        (!!department?.params?.enableSection && !!department?.sections?.length) ||
        (!!department?.modules?.skills && !!department?.skills?.length) ||
        (!!department?.modules?.resources &&
          !!department?.resources?.filter(
            (resource) => resource.displayWhenFreeClocking && !!resource?.attributes?.length,
          ).length)
      ) {
        navigation.navigate('ClockingCheckIn');
      } else {
        setCheckIn({ ...currentClocking });
      }
    } else {
      navigation.navigate('ClockingCheckIn');
    }
  };

  const onPressClocking = async (department: IDepartment) => {
    try {
      setIsLoading(true);
      const currentClocking = await clockingFromShift(department, nextShift!, authState.userDetails!.id!);
      navigation.navigate('ClockingCheckIn', { clocking: currentClocking, shift: nextShift! });
    } catch (error) {
      if (Platform.OS == 'web') {
        Sentry.Browser.captureException(error);
        Sentry.Browser.setTag('err', 'onPressClocking');
      } else {
        Sentry.Native.captureException(error);
        Sentry.Native.setTag('err', 'onPressClocking');
      }
    }
  };

  let clockingButton = null;
  if (
    (isOnline &&
      ((dataState.freeclockin_departments &&
        dataState.freeclockin_departments.length > 0 &&
        dataState.freeclockin_departments.some((obj) => obj.clockin === true)) ||
        dataState.clockinAccount)) ||
    (!isOnline &&
      dataState.no_offline_clockings_departments &&
      dataState.freeclockin_departments &&
      dataState.no_offline_clockings_departments.some((obj) => obj.clockin === true) &&
      dataState.freeclockin_departments.some((obj) => obj.clockin === true) &&
      dataState.no_offline_clockings_departments.length < dataState.freeclockin_departments.length &&
      (dataState.freeclockin_departments.length > 0 || dataState.clockinAccount))
  ) {
    if (dataState.mobile_clocking_departments && dataState.mobile_clocking_departments.length == 0) return;
    clockingButton = (
      <SIButton
        onPress={onPressFreeCheckIn}
        title={dataState.clockinAccount ? t('CLOCKING.START_MY_CLOCKING') : t('CLOCKING.FREE_CHECK_IN')}
        size="large"
        style={styles.button}
      />
    );
  }

  if (nextShift) {
    const { start, end, pause, package: shiftPackage } = nextShift;
    const startMoment = moment.unix(start!);
    const endMoment = moment.unix(end!);
    const duration = moment.duration(endMoment.diff(startMoment));
    duration.subtract(pause!.unpaid!, 'seconds');
    const durationAsMinutes = duration.asMinutes();
    const pauseDurationAsMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();
    const department = dataState.departments.find((department) => department.id === nextShift.departmentId);
    const no_offline_clockings_departments_ids = dataState.no_offline_clockings_departments
      ? dataState.no_offline_clockings_departments.map((department) => department.id)
      : [];

    if (
      (isOnline &&
        moment
          .unix(nextShift.start! - department!.clockinParams!.authorizeCheckingBefore!)
          .isSame(moment.unix(now), 'day') &&
        department?.clockin &&
        department?.clockinParams?.enableMobileClockin) ||
      (!isOnline &&
        dataState.no_offline_clockings_departments &&
        dataState.freeclockin_departments &&
        dataState.no_offline_clockings_departments.length < dataState.freeclockin_departments.length &&
        department &&
        !no_offline_clockings_departments_ids.includes(department?.id) &&
        moment
          .unix(nextShift.start! - department!.clockinParams!.authorizeCheckingBefore!)
          .isSame(moment.unix(now), 'day') &&
        department?.clockin &&
        department?.clockinParams?.enableMobileClockin)
    ) {
      clockingButton = (
        <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <SIButton
            onPress={() => onPressClocking(department)}
            title={t('CLOCKING.CHECK_IN')}
            size="large"
            style={[styles.button, { flexGrow: 1 }]}
            loading={isLoading}
            width={'auto'}
          />
          {!!clockingButton && (
            <SIButton
              width={'auto'}
              onPress={onPressFreeCheckIn}
              title={'+'}
              size="large"
              backgroundColor={colors.greyLight}
              color={colors.grey}
              style={[styles.button, { marginLeft: 10 }]}
            />
          )}
        </View>
      );
    }

    const currentShift = nextShift.start! < nowUnix();

    const onPress = () => {
      navigation.navigate('ShiftDetails', { shift: nextShift });
    };

    return (
      <View style={[styles.root, { paddingTop: isOnline ? 0 : 20 }]}>
        <TouchableOpacity style={styles.container} onPress={onPress}>
          <>
            <H1 style={{ fontSize: 20, marginBottom: 5, textTransform: 'uppercase' }}>
              {currentShift ? t('SHIFTS.ONGOING_SHIFT') : t('SHIFTS.NEXT_SHIFT')}
            </H1>
            <SIText style={{ fontSize: 13, textAlign: 'center' }}>{moment.unix(start!).format('LL')}</SIText>
            <SIText style={styles.department}>{department?.company?.toLocaleUpperCase()}</SIText>
            {!shiftPackage && (
              <View>
                <View style={styles.details}>
                  {!!department?.params?.showOnlyStartDate && (
                    <SIText style={[styles.detail, { fontSize: 13 }]}>{`${t('SHIFTS.START')} ${moment
                      .unix(start!)
                      .format('HH:mm')}`}</SIText>
                  )}
                  {!department?.params?.showOnlyStartDate && (
                    <SIText style={[styles.detail, { fontSize: 13 }]}>{`${moment.unix(start!).format('HH:mm')} ${t(
                      'SHIFTS.HOURS_TO',
                    )} ${moment.unix(end!).format('HH:mm')}`}</SIText>
                  )}
                </View>
                {!department?.params?.showOnlyStartDate && (
                  <View style={styles.details}>
                    <SIText style={[styles.detail]}>{`${t('SHIFTS.DURATION')} ${minutesToHoursAndOrMinutes(
                      durationAsMinutes,
                    )}`}</SIText>
                    {pauseDurationAsMinutes > 0 && (
                      <SIText style={[styles.detail]}>{`${t('CLOCKING.PAUSE')} ${minutesToHoursAndOrMinutes(
                        pauseDurationAsMinutes,
                      )}`}</SIText>
                    )}
                  </View>
                )}
              </View>
            )}
            {shiftPackage && (
              <View style={[styles.packageContainer, { backgroundColor: shiftPackage.background }]}>
                <SIText style={[styles.package, { color: shiftPackage.color }]}>{shiftPackage.name}</SIText>
              </View>
            )}
            {!!nextShift.section && (
              <View style={styles.tags}>
                <Tag
                  key={`shift_${nextShift.id}_section_${nextShift.section.id}`}
                  backgroundColor={nextShift.section.background}
                  color={nextShift.section.color}
                >
                  {nextShift.section.name || ''}
                </Tag>
              </View>
            )}
            {!!nextShift.skills?.length && (
              <View style={styles.tags}>
                {nextShift.skills?.map((skill) => (
                  <Tag
                    key={`shift_${nextShift.id}_skill_${skill.id}`}
                    backgroundColor={skill.background}
                    color={skill.color}
                  >
                    {skill.name || ''}
                  </Tag>
                ))}
              </View>
            )}
            {!!nextShift.attributes?.length && (
              <View style={styles.tags}>
                {nextShift.attributes?.map((attribute) => (
                  <Tag
                    key={`shift_${nextShift.id}_attribute_${attribute.id}`}
                    backgroundColor={attribute.background}
                    color={attribute.color}
                  >
                    {attribute.name || ''}
                  </Tag>
                ))}
              </View>
            )}
            {!!nextShift.tasks?.length && (
              <React.Fragment>
                <View style={{ borderBottomWidth: 1, borderBottomColor: colors.greyLight, marginVertical: 5 }}></View>
                <SIText style={{ fontSize: 13 }}>{t('SHIFTS.TASKS')}</SIText>
                <View style={{ marginTop: 6 }}>
                  {nextShift.tasks?.map((task) => (
                    <View
                      key={`shift_${nextShift.id}_task_${task.id}`}
                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                      <SIText style={styles.tasks}>{task.name}</SIText>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <SIText style={styles.tasks}>{`${moment.unix(task.start!).format('HH:mm')}`}</SIText>
                        <SIText style={styles.tasks}>{` - ${moment.unix(task.end!).format('HH:mm')}`}</SIText>
                      </View>
                    </View>
                  ))}
                </View>
              </React.Fragment>
            )}
          </>
          {!isOnline && !department?.clockinParams?.offlineClockings ? null : clockingButton}
          {isOnline &&
            currentShift &&
            !!department?.homeworking &&
            department &&
            department.clockinParams &&
            department.clockinParams.enableMobileClockin && (
              <HomeWorking
                shiftId={nextShift?.id}
                afterWorkingHome={() => setNextShift({ ...nextShift, homeworking: true })}
                homeworking={!!nextShift?.homeworking}
              />
            )}
        </TouchableOpacity>
        {!isOnline && !department?.clockinParams?.offlineClockings && (
          <View style={{ marginTop: 10 }}>{clockingButton}</View>
        )}
      </View>
    );
  } else {
    return (
      <View style={[styles.root, { paddingTop: isOnline ? 0 : 20 }]}>
        <View style={styles.container}>
          <H1>{moment().format('DD MMMM YYYY')}</H1>
          <SIText style={{ textAlign: 'center', fontSize: 24 }}>{moment().local().format('HH:mm')}</SIText>
          <H1 style={{ color: colors.red, paddingHorizontal: 50, marginVertical: 10 }}>
            {title || t('SHIFTS.NO_SHIFT_PLANNED')}
          </H1>
          {clockingButton}
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  root: {
    padding: 25,
    paddingBottom: 10,
    backgroundColor: colors.blueExtraLight,
  },
  container: {
    borderRadius: 10,
    padding: 25,
    paddingTop: 15,
    backgroundColor: '#fff',
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
    zIndex: 2,
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 5,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  detail: {
    fontSize: 11,
    textAlign: 'center',
    marginHorizontal: 4,
  },
  department: {
    fontSize: 17,
    textAlign: 'center',
    color: colors.green,
    fontFamily: 'Poppins_700Bold',
  },
  button: {
    marginTop: 10,
  },
  packageContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  package: {
    fontFamily: 'Poppins_700Bold',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
  },
  modalView: {
    display: 'flex',
    alignSelf: 'stretch',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  tasks: {
    fontSize: 11,
    lineHeight: 22,
    color: colors.grey,
  },
});
export { styles };

export default NextShift;
