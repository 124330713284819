import { createStackNavigator } from '@react-navigation/stack';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddUnavailabilityCalendar from './AddUnavailabilityCalendar';
import AvailabilityCalendar from './AvailabilityCalendar';

moment.tz.setDefault('Atlantic/Reykjavik');

const Stack = createStackNavigator();

const InterventionStack: React.FC = () => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Stack.Navigator initialRouteName="Main">
      <Stack.Screen name="FirstStep" component={AvailabilityCalendar} options={{ headerShown: false }} />
      <Stack.Screen name="SecondStep" component={AddUnavailabilityCalendar} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

export default InterventionStack;
