import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import Constants from 'expo-constants';
import { map, pick } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from '../../components/DateInput';
import SIButton from '../../components/SIButton';
import { ActionType, InitialState } from '../../store';
import { IClocking, IClockingTask } from '../../types/clocking.model';
import { localDateToUnixUTC } from '../../utils';

type NavigationParams = {
  TaskEditScreen: {
    clockingTask?: IClockingTask;
    currentClocking?: IClocking;
  };
};

type Props = StackScreenProps<NavigationParams, 'TaskEditScreen'>;

const TaskEditScreen: React.FC<Props> = ({ route }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clockingTask, currentClocking } = route.params;
  const navigation = useNavigation();
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const offlineState = useSelector((store: InitialState) => store.offline);
  const isOnline = !!offlineState?.online;

  const utcOffset = moment.unix(clockingTask!.start!).local().utcOffset();

  const { handleSubmit, watch, control, errors } = useForm({
    defaultValues: {
      start: moment.unix(clockingTask!.start!).toDate(),
      end: moment.unix(clockingTask!.end!).toDate(),
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    const tasks = [...currentClocking!.tasks!];
    const clockingId = currentClocking?.id || '$id';
    const clockingTaskIndex = tasks.findIndex((task) => task.id === clockingTask!.id!)!;
    const editedClockingTask = { ...tasks[clockingTaskIndex]! };
    editedClockingTask.start = localDateToUnixUTC(data.start);
    editedClockingTask.end = localDateToUnixUTC(data.end);
    editedClockingTask.edited = true;
    tasks.splice(clockingTaskIndex, 1, editedClockingTask);

    reduxDispatch({
      type: 'CLOCKING_EDIT_CURRENT_TASK',
      payload: {
        id: clockingTask!.id!,
        start: editedClockingTask.start!,
        end: editedClockingTask.end!,
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              mobile: true,
              os: Platform.OS,
              tasks: map([...tasks], (task) =>
                pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
              ),
            },
            optimisticId: currentClocking!.optimisticId,
          },
        },
      },
    });

    setIsLoading(false);
    navigation.goBack();
  };

  return (
    <SafeAreaView>
      <ScrollView style={styles.container}>
        <DateInput
          control={control}
          name="start"
          label={t('SHIFTS.START')}
          rules={{ required: true }}
          mode={'time'}
          watch={watch}
          errors={errors}
        />
        <DateInput
          control={control}
          name="end"
          label={t('SHIFTS.END')}
          rules={{ required: true }}
          mode={'time'}
          errors={errors}
          watch={watch}
        />
        <SIButton title={t('GENERAL.CONFIRM')} size="large" onPress={handleSubmit(onSubmit)} loading={isLoading} />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: 'white',
    minHeight: '100%',
  },
});

export default TaskEditScreen;
