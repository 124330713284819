import { useNavigation, useRoute } from '@react-navigation/native';
import React, { Dispatch, useLayoutEffect } from 'react';
import { View, Image, Button } from 'react-native';
import { useDispatch } from 'react-redux';
import * as FileSystem from 'expo-file-system';
import { useTranslation } from 'react-i18next';
import { StackScreenProps } from '@react-navigation/stack';

type NavigationParams = {
  ViewPictureScreen: {
    removeActionType: string;
    uri: string;
  };
};

type Props = StackScreenProps<NavigationParams, 'ViewPictureScreen'>;

const ViewPictureScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const { uri, removeActionType } = route.params;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const reduxDispatch: Dispatch<any> = useDispatch();

  const onDelete = async () => {
    reduxDispatch({
      type: removeActionType,
      payload: uri,
    });
    navigation.goBack();
    try {
      await FileSystem.deleteAsync(uri);
    } catch (e) {
      console.log(e);
      console.log(`FileSystem.deleteAsync(${uri}) failed`);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerRight: () => <Button color="red" title={t('GENERAL.REMOVE')} onPress={onDelete} />,
      headerTitle: null,
      headerBackTitle: t('GENERAL.BACK'),
    });
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Image style={{ flex: 1, width: 'auto' }} source={{ uri }} resizeMode="cover" />
    </View>
  );
};

export default ViewPictureScreen;
