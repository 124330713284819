import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import Constants from 'expo-constants';
import SISectionList from '../../../components/SISectionList';
import { InitialState } from '../../../store';
import { useSelector } from 'react-redux';
import { IDepartment } from '../../../types/department.model';
import SIText from '../../../components/SIText';
import axios from 'axios';

interface IDepartmentSchedule extends IDepartment {
  department: string;
  webcal?: string[];
}

interface Props {
  departments: IDepartmentSchedule[];
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ItemProps {
  link: string;
}

const Item: React.FC<ItemProps> = ({ link }) => {
  const { t } = useTranslation();
  const onPress = () => {
    Linking.openURL(link);
  };

  return (
    <TouchableOpacity onPress={onPress} style={{ marginVertical: 10 }}>
      <SIText>{t('SCHEDULE.CALENDAR_LINK')}</SIText>
    </TouchableOpacity>
  );
};

const ModalSaveSchedule: React.FC<Props> = ({ departments, visible, setVisible }) => {
  const onBackdropPress = () => {
    setVisible(false);
  };

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible} onBackdropPress={onBackdropPress}>
      <View style={styles.container}>
        <SISectionList
          sections={departments.map((department) => ({
            title: department.department!,
            data: department.webcal || [],
          }))}
          renderItem={({ item }) => <Item link={item} />}
          keyExtractor={(item, index) => `${index}`}
          style={{ width: '100%', backgroundColor: '#fff' }}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 25,
    paddingTop: 25,
  },
});

export default ModalSaveSchedule;
