import { useFocusEffect } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import { TFunction } from 'i18next';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, RefreshControl, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import H1 from '../../components/H1';
import SISectionList from '../../components/SISectionList';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IClocking } from '../../types/clocking.model';
import { IDepartment } from '../../types/department.model';
import { minutesToHoursAndOrMinutes } from '../../utils';
import { styles as meScreenStyle } from '../MeScreen';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IBasicInfos {
  email?: string;
  id?: string;
  totalMinutes?: number | null;
}

interface IListerHeaderComponentProps {
  basicInfos: IBasicInfos;
  t: TFunction;
}

interface IMyDepartment extends IDepartment {
  clockings?: IClocking[];
}

const ListHeaderComponent: React.FC<IListerHeaderComponentProps> = ({ basicInfos, t }) => {
  const { totalMinutes } = basicInfos;
  return (
    <View>
      <View style={styles.header}>
        <SIText style={{ textAlign: 'center' }}>{t('SHIFTS.TOTAL_WORK_HOURS')}</SIText>
        <H1 style={{ fontSize: 28, lineHeight: 36, marginTop: 5 }}>
          {totalMinutes ? `${minutesToHoursAndOrMinutes(totalMinutes)}` : '0 min'}
        </H1>
      </View>
      <SIText style={{ fontSize: 16, marginBottom: 15, fontWeight: 'bold' }}>{t('GENERAL.CLOCKING_HISTORY')}</SIText>
    </View>
  );
};

interface IItemProps {
  data: {
    clockings: IClocking[];
  };
  t: TFunction;
}

const Item: React.FC<IItemProps> = ({ data, t }) => {
  const { clockings } = data;
  return (
    <View style={[styles.item, { backgroundColor: 'white' }]}>
      {!!clockings?.length && (
        <View>
          <View style={{ paddingHorizontal: 10 }}>
            {clockings.map((clocking, index) => (
              <View key={`clocking_${clocking.id}`} style={[styles.itemClocking, index ? { borderTopWidth: 1 } : null]}>
                <Text style={styles.itemClockingText}>
                  {clocking.checkIn ? `${moment.unix(clocking.checkIn.start!).format('L HH:mm')}` : ``}
                  {clocking.checkOut && clocking.checkOut.end ? (
                    ` - ${moment.unix(clocking.checkOut.end).format('HH:mm')}`
                  ) : (
                    <SIText style={{ color: colors.orange }}>{t('CLOCKING.ONGOING')}</SIText>
                  )}
                </Text>
              </View>
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

const OnlyClockin: React.FC<{ padding?: boolean }> = ({ padding = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departments, setDepartments] = useState<IMyDepartment[]>([]);
  const [basicInfos, setBasicInfos] = useState<IBasicInfos>({ email: '', id: '', totalMinutes: 0 });
  const dataState = useSelector((store: InitialState) => store.data);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const refreshData = () => {
    if (!isLoading) {
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/me`,
        {
          params: {
            mobile: true,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          const { departments, email, id, totalMinutes } = response.data;
          setDepartments(departments);
          setBasicInfos({ email, id, totalMinutes });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
      return () => {
        cancelTokenSource.cancel();
      };
    }
  };

  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  const allClockings = departments.flatMap((department) => department.clockings || []);
  if (allClockings.length == 0) {
    return (
      <View style={{ paddingHorizontal: padding ? 25 : 0 }}>
        <SIText style={{ fontSize: 16, marginBottom: 15, fontWeight: 'bold' }}>{t('GENERAL.CLOCKING_HISTORY')}</SIText>
        <SIText>{t('CLOCKING.NO_REGISTERED_CLOCKING')}</SIText>
      </View>
    );
  }

  return (
    <SISectionList
      refreshControl={
        <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
      }
      ListHeaderComponent={<ListHeaderComponent basicInfos={basicInfos} t={t} />}
      sections={departments.map((department) => ({
        title: department.company!,
        data: [{ clockings: department.clockings || [] }],
      }))}
      headerStyle={{ backgroundColor: 'white', borderRadius: 0 }}
      renderItem={({ item }) => <Item data={item} t={t} />}
      keyExtractor={(item, index) => `${index}`}
      style={{ paddingHorizontal: padding ? (Platform.OS !== 'web' ? 25 : 12.5) : 0 }}
    />
  );
};

const styles = {
  ...meScreenStyle,
};

export default OnlyClockin;
