import * as Linking from 'expo-linking';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../components/Back';
import Icon from '../components/Icon';
import SearchBar from '../components/SearchBar';
import SISectionList from '../components/SISectionList';
import SIText from '../components/SIText';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IUser } from '../types/user.model';

interface ItemProps {
  user: IUser;
  onPress: () => void;
}

const Item: React.FC<ItemProps> = ({ user: { displayName, email, phone }, onPress }) => {
  return (
    <View style={styles.item}>
      <View>
        <SIText>{displayName}</SIText>
        <SIText style={{ fontSize: 11 }}>{email}</SIText>
        <SIText style={{ fontSize: 11, color: colors.grey }}>{phone}</SIText>
      </View>
      <TouchableOpacity style={{ paddingRight: 10 }} onPress={onPress}>
        <Icon name="phone" size={25} color={colors.green} />
      </TouchableOpacity>
    </View>
  );
};

interface ListHeaderComponentProps {
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
}

const ListHeaderComponent: React.FC<ListHeaderComponentProps> = ({ setSearch, search }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <View style={{ padding: 25, backgroundColor: '#fff', borderRadius: 10, marginBottom: 15 }}>
      <SearchBar
        style={{ backgroundColor: colors.greyLight }}
        placeholder={t('CONTACTS.SEARCH_FOR_A_CONTACT')}
        setValue={setSearch}
        value={search}
      />
    </View>
  );
};

const ContactsScreen: React.FC = () => {
  const { t } = useTranslation();
  const dataState = useSelector((store: InitialState) => store.data);
  const { departments } = dataState;
  const [search, setSearch] = useState<string>('');

  const onPressCall = (phone: string) => {
    Linking.openURL(`tel:${phone}`);
  };

  const filteredUsersDepartments = departments.reduce((_filteredUsersDepartments: any[], department) => {
    const { company, users } = department;
    const filteredUsers = users!.filter(
      (user) => user.displayContact && user.displayName!.toLowerCase().includes(search.toLowerCase()),
    );
    if (filteredUsers.length) {
      _filteredUsersDepartments.push({
        title: company!,
        data: filteredUsers!,
      });
    }
    return _filteredUsersDepartments;
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('GENERAL.MY_CONTACTS')} />
      <View style={styles.container}>
        <SISectionList
          sections={filteredUsersDepartments}
          renderItem={({ item }) => <Item user={item as IUser} onPress={() => onPressCall(item.phone)} />}
          keyExtractor={(item, index) => `${index}`}
          ListHeaderComponent={<ListHeaderComponent search={search} setSearch={setSearch} />}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  item: {
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default ContactsScreen;
