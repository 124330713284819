import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import SIText from '../../../components/SIText';
import colors from '../../../styles/colors';
import { InitialState } from '../../../store';
import { useSelector } from 'react-redux';

interface Props {
  departmentId: string;
}

const NoOfflineDepartmentWarning: React.FC<Props> = ({ departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dataState = useSelector((store: InitialState) => store.data);
  const department = dataState.departments.find((department) => department.id == departmentId);

  return (
    <View style={styles.container}>
      <SIText>{t('OFFLINE.NO_OFFLINE_DEPARTMENT_WARNING', { department: department?.company })}</SIText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.redLight,
    padding: 25,
    marginTop: Platform.OS == 'web' ? 25 : 0,
    marginHorizontal: 25,
    borderRadius: 10,
  },
});

export default NoOfflineDepartmentWarning;
