import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, StyleProp, View, ViewStyle } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import colors from '../styles/colors';
import SIText from './SIText';

interface Props {
  label?: string;
  control: Control<Record<string, any>>;
  name: string;
  rules?: {
    required?: boolean;
  };
  defaultValue: string | null;
  placeholder?: string;
  items: { label: string; value: string }[];
  errors?: DeepMap<Record<string, any>, FieldError>;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
}

const SelectController: React.FC<Props> = ({
  label,
  control,
  name,
  rules,
  defaultValue,
  placeholder,
  items,
  loading,
  errors = {},
  style,
}) => {
  const error = errors && errors[name]?.type === 'required';
  const { t } = useTranslation(undefined, { useSuspense: false });
  if (loading) {
    return (
      <View style={styles.selectContainer}>
        <ActivityIndicator size="small" />
      </View>
    );
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value }) => (
        <View
          style={[
            {
              borderColor: colors.greyLight,
              borderWidth: 1,
              borderRadius: 10,
              marginBottom: 15,
              marginTop: 5,
              paddingTop: 10,
            },
            style,
          ]}
        >
          <SIText
            style={{ position: 'absolute', left: 10, fontSize: 12, color: error ? colors.red : colors.grey, top: 10 }}
          >
            {rules?.required && <SIText style={error ? styles.red : null}>* </SIText>}
            {label}
          </SIText>
          <RNPickerSelect
            placeholder={
              placeholder ? { label: placeholder, value: null } : { label: t('GENERAL.SELECT_AN_ITEM'), value: null }
            }
            style={{
              inputAndroid: { color: 'black', padding: 15, paddingLeft: 10, paddingTop: 17 },
              viewContainer: [
                Platform.OS === 'web' ? styles.selectContainer : null,
                error ? { borderColor: 'red' } : null,
                value ? { padding: 15, paddingLeft: 10, paddingTop: 17 } : null,
              ],
              placeholder: { padding: 15, paddingLeft: 10, paddingTop: 17 },
              inputIOSContainer: {
                paddingTop: 25,
                marginTop: -25,
                paddingBottom: 15,
                marginBottom: -15,
              },
              inputAndroidContainer: { paddingTop: 25, marginTop: -25, paddingBottom: 15, marginBottom: -15 },
              inputWeb: {
                paddingVertical: 8,
                paddingHorizontal: 3,
                borderRadius: 8,
                marginTop: 10,
                borderWidth: 2,
                borderColor: '#ccc',
                backgroundColor: '#F8F8F8',
                fontFamily: 'Poppins_500Medium',
              },
            }}
            items={items}
            value={value}
            onDonePress={() => {
              onBlur();
            }}
            onValueChange={(value) => {
              onChange(value);
            }}
            textInputProps={{
              multiline: true,
            }}
            pickerProps={Platform.OS === 'ios' ? { style: { height: 214, overflow: 'hidden' } } : {}}
            useNativeAndroidPickerStyle={false}
          />
        </View>
      )}
    />
  );
};

const Select: React.FC<Props> = (props) => {
  const { label, name, rules, errors = {} } = props;
  if (label) {
    const error = errors && errors[name]?.type === 'required';
    return (
      <View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {/* <SIText style={error ? styles.red : null}>{label}</SIText> */}
        </View>
        <SelectController {...props} />
      </View>
    );
  } else {
    return <SelectController {...props} />;
  }
};

const styles = {
  selectContainer: {
    padding: 15,
    paddingLeft: 10,
  },
  red: {
    color: 'red',
  },
};

export default Select;
