import { useNavigation } from '@react-navigation/core';
import { StackScreenProps } from '@react-navigation/stack';
import axios from 'axios';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Checkbox from '../../components/Checkbox';
import DateInput from '../../components/DateInput';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IUnavailability } from '../../types/unavailability.model';
import { localDateToUnixUTC } from '../../utils';

const shiftMarker = { key: 'shiftMarker', color: colors.green };
const dayoffMarker = { key: 'dayoffMarker', color: colors.red };
const unavailabilityMarker = { key: 'unavailabilityMarker', color: colors.grey };

type NavigationParams = {
  AddUnavailabilityCalendar: {
    departmentId: string;
  };
};

type Props = StackScreenProps<NavigationParams, 'AddUnavailabilityCalendar'>;

LocaleConfig.locales['fr'] = {
  monthNames: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
  monthNamesShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
  dayNames: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  dayNamesShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
};

LocaleConfig.locales['nl'] = {
  monthNames: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  monthNamesShort: 'Jan._Feb._Mrt._Apr._Mei_Jun._Jul._Aug._Sep._Okt._Nov._Dec.'.split('_'),
  dayNames: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  dayNamesShort: 'Zo._Ma._Di._Wo._Do._Vr._Za.'.split('_'),
};

LocaleConfig.locales['de'] = {
  monthNames: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthNamesShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  dayNames: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  dayNamesShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
};

switch (Localization.locale.substring(0, 2)) {
  case 'fr':
    LocaleConfig.defaultLocale = 'fr';
    break;
  case 'nl':
    LocaleConfig.defaultLocale = 'nl';
    break;
  case 'de':
    LocaleConfig.defaultLocale = 'de';
    break;
  default:
    LocaleConfig.defaultLocale = '';
    break;
}

moment.tz.setDefault('Atlantic/Reykjavik');

const defaultValues = () => {
  const startDate = moment().startOf('day').toDate();
  startDate.setHours(9);

  const endDate = moment().startOf('day').toDate();
  endDate.setHours(17);

  return {
    fullDay: false,
    start: startDate,
    end: endDate,
  };
};

const AvailabilityCalendar: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const [unavailabilities, setUnavailabilities] = useState<IUnavailability>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [markedDates, setMarkedDates] = useState<any>({});
  const [markedDatesWithSelectedDates, setMarkedDatesWithSelectedDates] = useState<any>({});
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { errors, control, watch, handleSubmit, reset } = useForm({
    defaultValues: defaultValues(),
  });
  const watchFullDay = watch('fullDay', false);
  const [selectedMonth, setSelectedMonth] = useState<string | null>();
  const dataState = useSelector((store: InitialState) => store.data);
  const [minDate, setMinDate] = useState<string | null>(null);
  const activeDepartment = dataState.departments.find((department) => department.id === route.params.departmentId);

  // useEffect(() => {
  //   const lastDay =
  //     dataState.departments.find((department) => department.id === route.params.departmentId)?.params
  //       ?.availabilityEnd || 10;

  //   let minDate;
  //   if (moment().date() > lastDay) {
  //     minDate = moment().add(2, 'month').startOf('month').format('YYYY-MM-DD');
  //   } else {
  //     minDate = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
  //   }
  //   setMinDate(minDate);
  //   setSelectedMonth(minDate);
  // }, [route.params.departmentId]);

  useEffect(() => {
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();

    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/unavailabilities`,
        {
          params: {
            departmentId: route.params.departmentId,
            month: selectedMonth,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        const result: any[] = [];
        const shifts = data.shifts;
        const keys = Object.keys(shifts);

        keys.map((key) => {
          const values = Array.from(shifts[key]);
          values.map((value: any) => {
            const start = value.startHour ? value.startHour : '00:00:00';
            const end = value.endHour ? value.endHour : '00:00:00';
            const start_date = `${key} ${start}`;
            const end_date = `${key} ${end}`;
            const start_timestamp = moment(`${key}T${start}Z`).unix();
            const end_timestamp = moment(`${key}T${end}Z`).unix();
            result.push({
              ...value,
              start: start_timestamp,
              end: end_timestamp,
              startDate: start_date,
              endDate: end_date,
            });
          });
        });

        setSelectedMonth(moment(data.dates.startMonth).format('YYYY-MM'));
        setUnavailabilities({ ...data, shifts: result });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!axios.isCancel(error)) {
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
          }
        }
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [route.params.departmentId, selectedMonth]);

  useEffect(() => {
    // const markedDates = Object.entries(unavailabilities).reduce(
    //   (obj: any, item) => ((obj[item[0]] = { marked: true }), obj),
    //   {},
    // );
    // setMarkedDates(markedDates);

    if (unavailabilities?.shifts) {
      const shifts = unavailabilities?.shifts;
      const markedDates: any = {};
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];
        const { start, startDate: shiftStartDate } = shift;
        const markedDate = markedDates[shiftStartDate!.substring(0, 10)];
        if (markedDate) {
          if (shift.shyftType == 1) {
            markedDate.dots.push(shiftMarker);
          }
          if (shift.shyftType == 2) {
            markedDate.dots.push(dayoffMarker);
          }
          if (shift.shyftType == 3) {
            markedDate.dots.push(unavailabilityMarker);
          }
        } else {
          markedDates[shiftStartDate!.substring(0, 10)] = {
            dots: shift.shyftType == 1 ? [shiftMarker] : shift.shyftType == 2 ? [dayoffMarker] : [unavailabilityMarker],
          };
        }
      }
      setMarkedDates(markedDates);
    }
  }, [unavailabilities]);

  const onDayPress = (day: any) => {
    const markedDatesCopy = { ...markedDates };
    const { dateString } = day;
    const selectedDatesCopy = [...selectedDates];

    const dateIndex = selectedDatesCopy.findIndex((x) => x === dateString);
    if (~dateIndex) {
      selectedDatesCopy.splice(dateIndex, 1);
    } else {
      selectedDatesCopy.push(dateString);
    }
    selectedDatesCopy.sort();

    if (markedDates[dateString]) {
      markedDates[dateString] = undefined;
    } else {
      markedDates[dateString] = {
        selected: true,
      };
    }

    setSelectedDates(selectedDatesCopy);
    setMarkedDates(markedDates);
  };

  const onMonthChange = (month: any) => {
    reset();
    setSelectedDates([]);
    setSelectedMonth(month.dateString.substring(0, 7));
  };

  const onSubmit = async (data: any) => {
    const { fullDay, start, end } = data;
    setIsSaving(true);
    axios
      .post(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/unavailabilities`,
        {
          departmentId: route.params.departmentId,
          days: selectedDates,
          fullDay,
          startHour: moment.unix(localDateToUnixUTC(start)).format('HH:mm'),
          endHour: moment.unix(localDateToUnixUTC(end)).format('HH:mm'),
          mobile: true,
          os: Platform.OS,
        },
      )
      .then(() => {
        setIsSaving(false);
        navigation.goBack();
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_SENDING_DATA')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_SENDING_DATA'));
        }
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('GENERAL.UNAVAILABILITIES')} />
      <ScrollView style={styles.container}>
        <SIText style={{ fontSize: 16, color: colors.grey, marginBottom: 5 }}>{activeDepartment?.company}</SIText>
        {unavailabilities && unavailabilities.dates.delayStart && (
          <Calendar
            current={unavailabilities && unavailabilities.dates.delayStart}
            minDate={unavailabilities && unavailabilities.dates.delayStart}
            onDayPress={onDayPress}
            markedDates={markedDates}
            onMonthChange={onMonthChange}
            style={styles.calendar}
            displayLoadingIndicator={isLoading}
            firstDay={1}
            markingType={'multi-dot'}
            theme={{
              monthTextColor: 'black',
              textMonthFontFamily: 'Poppins_500Medium',
              textDayFontFamily: 'Poppins_500Medium',
              textDayHeaderFontFamily: 'Poppins_500Medium',
              dotColor: colors.red,
              todayTextColor: colors.red,
              arrowColor: colors.green,
              selectedDayBackgroundColor: colors.red,
            }}
          />
        )}
        {!!selectedDates.length && (
          <View style={styles.footer}>
            <Checkbox control={control} label={t('GENERAL.FULL_DAY')} name="fullDay" style={{ marginBottom: 15 }} />
            {!watchFullDay && (
              <React.Fragment>
                <DateInput
                  control={control}
                  name="start"
                  label={t('SHIFTS.START')}
                  rules={{ required: true }}
                  mode={'time'}
                  errors={errors}
                  watch={watch}
                />
                <DateInput
                  control={control}
                  name="end"
                  label={t('SHIFTS.END')}
                  rules={{ required: true }}
                  mode={'time'}
                  errors={errors}
                  watch={watch}
                />
              </React.Fragment>
            )}
            <SIButton
              style={{ marginTop: 10 }}
              title={t('GENERAL.CONFIRM')}
              onPress={handleSubmit(onSubmit)}
              loading={isSaving}
            />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    display: 'flex',
    flex: 1,
    backgroundColor: colors.blueExtraLight,
  },
  calendar: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 0,
    paddingBottom: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
  footer: {
    marginVertical: 25,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    borderRadius: 10,
    padding: 25,
  },
});

export default AvailabilityCalendar;
