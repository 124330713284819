import axios from 'axios';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { ScrollView } from 'react-native-gesture-handler';
import Back from '../../components/Back';
import ShiftCards from '../../components/ShiftCards';
import colors from '../../styles/colors';
import { IShift } from '../../types/shift.model';

LocaleConfig.locales['fr'] = {
  monthNames: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
  monthNamesShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
  dayNames: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  dayNamesShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
};

LocaleConfig.locales['nl'] = {
  monthNames: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  monthNamesShort: 'Jan._Feb._Mrt._Apr._Mei_Jun._Jul._Aug._Sep._Okt._Nov._Dec.'.split('_'),
  dayNames: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  dayNamesShort: 'Zo._Ma._Di._Wo._Do._Vr._Za.'.split('_'),
};

LocaleConfig.locales['de'] = {
  monthNames: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthNamesShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  dayNames: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  dayNamesShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
};

switch (Localization.locale.substring(0, 2)) {
  case 'fr':
    LocaleConfig.defaultLocale = 'fr';
    break;
  case 'nl':
    LocaleConfig.defaultLocale = 'nl';
    break;
  case 'de':
    LocaleConfig.defaultLocale = 'de';
    break;
  default:
    LocaleConfig.defaultLocale = '';
    break;
}

moment.tz.setDefault('Atlantic/Reykjavik');

const FreeShiftsScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(moment().startOf('month').format('YYYY-MM'));
  const [freeShifts, setFreeShifts] = useState<{ [index: string]: IShift[] }>({});
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onDayPress = (day: any) => {
    const { dateString } = day;
    if (selectedDate === dateString) {
      setSelectedDate(null);
    } else {
      setSelectedDate(dateString);
    }
  };

  const markedDates = Object.entries(freeShifts).reduce(
    (obj: any, item) => ((obj[item[0]] = { marked: true }), obj),
    {},
  );

  useEffect(() => {
    setIsLoading(true);
    setFreeShifts({});
    const cancelTokenSource = axios.CancelToken.source();

    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/free-shifts`,
        {
          params: {
            month: selectedMonth?.substr(0, 7),
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        setFreeShifts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!axios.isCancel(error)) {
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
          }
        }
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [selectedMonth]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('SHIFTS.FREE_SHIFTS')} />
      <ScrollView style={styles.container}>
        <Calendar
          current={moment().startOf('month').format('YYYY-MM-DD')}
          minDate={moment().startOf('month').format('YYYY-MM-DD')}
          onDayPress={onDayPress}
          markedDates={selectedDate ? { ...markedDates, [selectedDate]: { selected: true } } : markedDates}
          onMonthChange={(month) => setSelectedMonth(month.dateString.substring(0, 7))}
          style={styles.calendar}
          displayLoadingIndicator={isLoading}
          firstDay={1}
          disableArrowLeft={moment(selectedMonth).subtract(1, 'month').isBefore(moment().startOf('month'))}
          theme={{
            monthTextColor: 'black',
            textMonthFontFamily: 'Poppins_500Medium',
            textDayFontFamily: 'Poppins_500Medium',
            textDayHeaderFontFamily: 'Poppins_500Medium',
            dotColor: colors.green,
            todayTextColor: colors.green,
            arrowColor: colors.green,
            selectedDayBackgroundColor: colors.green,
          }}
        />
        {!isLoading && (
          <ShiftCards
            title={selectedDate ? `${moment(selectedDate).format('LL')}` : undefined}
            // eslint-disable-next-line prefer-spread
            shifts={
              selectedDate
                ? freeShifts[selectedDate] || []
                : ([] as IShift[]).concat.apply([], Object.values(freeShifts)).sort((a, b) => a.start! - b.start!)
            }
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    marginBottom: 25,
  },
  container: {
    padding: 25,
    display: 'flex',
    flex: 1,
    backgroundColor: colors.blueExtraLight,
  },
  calendar: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 0,
    paddingBottom: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
});

export default FreeShiftsScreen;
