import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import H1 from '../../components/H1';
import SIButton from '../../components/SIButton';
import colors from '../../styles/colors';
import axios from 'axios';
import Constants from 'expo-constants';
import { showMessage } from 'react-native-flash-message';
import { IDepartment } from '../../types/department.model';

interface Props {
  application: any;
  activeDepartment: IDepartment | null | undefined;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getApplications: () => void;
}

const ModalConfirmDeleteShyftsMarketApplication: React.FC<Props> = ({
  application,
  visible,
  activeDepartment,
  setVisible,
  getApplications,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    if (!activeDepartment) return;

    setLoading(true);
    axios
      .delete(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/shyfts-market/user/${application.shyft_market.id}`,
        {
          params: {
            departmentId: activeDepartment.id,
          },
        },
      )
      .then(({ data }) => {
        getApplications();
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
        setLoading(false);
        showMessage({
          message: t('SHYFTS_MARKET.DELETE_ERROR'),
          type: 'danger',
        });
      });
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <Modal animationIn="slideInUp" isVisible={visible} onBackdropPress={hideModal}>
      <View style={styles.container}>
        <H1>{t('SHYFTS_MARKET.DELETE_CONFIRM')}</H1>
        <View style={styles.buttons}>
          <SIButton
            style={{ marginRight: Platform.OS === 'web' ? 10 : 0 }}
            backgroundColor="transparent"
            color="#000"
            title={t('GENERAL.CANCEL')}
            onPress={hideModal}
          />
          <SIButton
            style={{ marginLeft: Platform.OS === 'web' ? 10 : 0, marginTop: Platform.OS !== 'web' ? 10 : 0 }}
            backgroundColor={colors.red}
            title={t('GENERAL.REMOVE')}
            onPress={onDelete}
            loading={loading}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  buttons: {
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
});

export default ModalConfirmDeleteShyftsMarketApplication;
