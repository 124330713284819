import Constants from 'expo-constants';
import React from 'react';
import { ActivityIndicator, Image, StyleSheet, Text, View } from 'react-native';
import logo from '../assets/shyfter-logo.png';
import colors from '../styles/colors';

const SISpinner: React.FC = () => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', backgroundColor: colors.blueExtraLight }}>
      <View style={styles.shape} />
      <View style={styles.content}>
        <ActivityIndicator style={styles.spinner} size="large" color="#000" />
        <Image style={{ width: '100%', height: 30, marginVertical: 50 }} resizeMode="contain" source={logo} />
        <Text style={styles.version}>
          Shyfter {Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  shape: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 300,
    top: -150,
    right: -150,
    borderRadius: 64,
    transform: [{ rotate: '20deg' }, { scale: 1.3 }],
    flex: 0.6,
  },
  content: {
    flex: 0.4,
    alignItems: 'center',
    padding: 25,
  },
  spinner: {
    flex: 1,
  },
  version: {
    color: colors.grey,
    marginBottom: 10,
  },
});

export default SISpinner;
