import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { Dispatch, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StyleSheet, View, PanResponder, ScrollView, Platform } from 'react-native';
import Svg, { Polyline } from 'react-native-svg';
import { Dimensions } from 'react-native';
import colors from '../../styles/colors';
import SIButton from '../../components/SIButton';
import { StackScreenProps } from '@react-navigation/stack';
import { captureRef } from 'react-native-view-shot';
import { Alert } from 'react-native';
import Axios from 'axios';
import FormData from 'form-data';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType, InitialState } from '../../store';
import H1 from '../../components/H1';
import { useTranslation } from 'react-i18next';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { IDocument } from '../../types/document.model';
import * as ScreenOrientation from 'expo-screen-orientation';
import SIText from '../../components/SIText';

interface Point {
  x: number;
  y: number;
}

const GesturePath = forwardRef((props: any, ref: any) => {
  const { paths, color, layout } = props;
  const { width } = Dimensions.get('window');
  return (
    <Svg ref={ref} height="100%" width="100%" viewBox={`0 0 ${layout.width} ${layout.height}`} id="svg_element">
      {paths.map((path: Point[], index: number) => {
        const points = path.map((p) => `${p.x},${p.y}`).join(' ');
        return <Polyline key={`path_${index}`} points={points} fill="none" stroke={color} strokeWidth="2" />;
      })}
    </Svg>
  );
});
GesturePath.displayName = 'GesturePathComponent';

const GestureRecorder = forwardRef((props: any, ref: any) => {
  const pathRef = useRef([[]]);
  const { onPathChanged } = props;

  useImperativeHandle(ref, () => ({
    clearSignature() {
      pathRef.current = [[]];
    },
  }));

  const panResponder = useRef(
    PanResponder.create({
      // Next line is needed to work on android
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pathRef.current.push([]);
      },
      onPanResponderMove: (event: any) => {
        const length = pathRef.current.length;
        pathRef.current[length - 1].push({
          x: event.nativeEvent.locationX,
          y: event.nativeEvent.locationY,
        });
        // Uncomment the next line to draw the path as the user is performing the touch. (A new array must be created so setState recognises the change and re-renders the App)
        onPathChanged([...pathRef.current]);
      },
      onPanResponderRelease: () => {
        onPathChanged([...pathRef.current]);
      },
    }),
  ).current;

  return (
    <View
      style={[StyleSheet.absoluteFill, { backgroundColor: 'rgba(255,255,255,0.1)' }]}
      {...panResponder.panHandlers}
    />
  );
});
GestureRecorder.displayName = 'GestureRecorderComponent';

type NavigationParams = {
  SignScreen: {
    selectedDocument: IDocument;
  };
};

type Props = StackScreenProps<NavigationParams, 'SignScreen'>;

const SignScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const [paths, setPaths] = useState<Point[][]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const gestureRecorder = useRef(null);
  const svgRef = useRef(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [layout, setLayout] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { departments } = useSelector((store: InitialState) => store.data);

  useEffect(() => {
    if (!route.params) {
      navigation.goBack();
    }
    if (Platform.OS !== 'web') {
      changeScreenOrientation();
    }
  }, [route.params]);

  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS !== 'web') {
        changeScreenOrientation();
      }

      return async () => {
        if (Platform.OS !== 'web') {
          await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        }
      };
    }, []),
  );

  async function changeScreenOrientation() {
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
  }

  const onPathChanged = (paths: Point[][]) => {
    setPaths(paths);
  };

  const onClearSignature = () => {
    if (gestureRecorder.current) {
      (gestureRecorder.current as any).clearSignature();
    }
    setPaths([]);
  };

  const onSaveSignature = async () => {
    // TODO: save signature
    setIsSaving(true);
    try {
      const { selectedDocument } = route.params;
      const formData = new FormData();
      formData.append('signed', true);
      formData.append('documentType', selectedDocument.documentType.toLocaleUpperCase());
      if (selectedDocument.userDocId) {
        formData.append('userDocId', selectedDocument.userDocId);
      }
      if (Platform.OS === 'web') {
        const svgElement = document.getElementById('svg_element');
        if (svgElement) {
          await new Promise((resolve, reject) => {
            const image = new Image();
            const xml = new XMLSerializer().serializeToString(svgElement);
            image.src = `data:image/svg+xml;utf8,${xml}`;
            image.onload = () => {
              const { width, height } = (svgElement as any).getBBox();
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const context = canvas.getContext('2d')!;
              context.drawImage(image, 0, 0, width, height);
              canvas.toBlob((blob) => {
                formData.append('signature', blob, `${selectedDocument.id}_signature.png`);
                resolve(true);
              }, 'image/png');
            };
            image.onerror = () => {
              reject();
            };
          });
        }
      } else {
        const uri = await captureRef(svgRef, {
          result: 'tmpfile',
          quality: 1,
          format: 'png',
        });
        formData.append('signature', {
          name: `${selectedDocument.id}_signature`,
          uri: Platform.OS === 'android' ? uri : uri.replace('file://', ''),
          type: 'image/png',
        });
      }

      Axios({
        method: 'POST',
        url: `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/documents/sign-document/${selectedDocument.id}`,
        data: formData,
        params: {
          departmentId: selectedDocument.departmentId,
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          if (Platform.OS !== 'web') {
            Notifications.scheduleNotificationAsync({
              content: {
                title: t('SIGNATURE.SIGNATURE_SUCCESS_MESSAGE'),
              },
              trigger: {
                seconds: 1,
              },
            });
          }
          setIsSaving(false);
          reduxDispatch({
            type: 'SIGNATURE_CONTRACT_SIGNED',
            payload: selectedDocument.id,
          });
        })
        .catch((error) => {
          setIsSaving(false);
          if (Platform.OS === 'web') {
            alert(`${t('GENERAL.ERROR')}. ${t('SIGNATURE.SIGNATURE_FAILURE_MESSAGE')}`);
          } else {
            Alert.alert(t('GENERAL.ERROR'), t('SIGNATURE.SIGNATURE_FAILURE_MESSAGE'));
          }
          console.log(error?.response?.data);
        });
    } catch (error) {
      setIsSaving(false);
      if (Platform.OS === 'web') {
        alert(`${t('GENERAL.ERROR')}. ${t('SIGNATURE.SIGNATURE_FAILURE_MESSAGE')}`);
      } else {
        Alert.alert(t('GENERAL.ERROR'), t('SIGNATURE.SIGNATURE_FAILURE_MESSAGE'));
      }
      console.log(error);
      console.log(error?.response);
      return;
    }
  };

  return (
    <View style={styles.safeArea}>
      {/* <ScrollView style={styles.container} ref={scrollView}> */}
      <View
        style={styles.signatureContainer}
        onLayout={(e) => {
          setLayout({
            height: e.nativeEvent.layout.height,
            width: e.nativeEvent.layout.width,
          });
        }}
      >
        <GesturePath paths={paths} color="blue" ref={svgRef} layout={layout} />
        <GestureRecorder onPathChanged={onPathChanged} ref={gestureRecorder} />
      </View>
      {!paths?.length && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 100,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          pointerEvents="none"
        >
          <H1 style={{ color: colors.grey }}>{t('SIGNATURE.SIGN_HERE')}</H1>
        </View>
      )}

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 50 }}>
        <SIButton
          backgroundColor={colors.greyLight}
          color={colors.grey}
          title={t('GENERAL.ERASE')}
          width="auto"
          onPress={onClearSignature}
          style={{ margin: 10 }}
        />
        {!!paths?.length && (
          <SIButton
            style={{ margin: 10 }}
            title={t('GENERAL.SIGN')}
            width="auto"
            onPress={onSaveSignature}
            loading={isSaving}
          />
        )}
      </View>

      {/* </ScrollView> */}
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: 'white',
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'yellow',
  },
  signatureContainer: {
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.greyLight,
    backgroundColor: '#EFEFEF',
    marginBottom: 10,
  },
});

export default SignScreen;
