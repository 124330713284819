import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import { map, pick } from 'lodash';
import React, { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../components/Back';
import SIButton from '../../components/SIButton';
import Select from '../../components/Select';
import { InitialState } from '../../store';
import ClockingActionType from '../../store/actions/clockingActions';
import colors from '../../styles/colors';
import { IClockingTask } from '../../types/clocking.model';
import { generateOptimisticId, nowUnix } from '../../utils';

const ClockingAddTaskScreen: React.FC = () => {
  const { handleSubmit, errors, control } = useForm();
  const reduxDispatch: Dispatch<ClockingActionType> = useDispatch();
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const { currentClocking } = clockingState;
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const isOnline = !!offlineState?.online;

  const departmentId = currentClocking!.departmentId;
  const department = dataState.departments.find((department) => department.id === departmentId);

  const onSubmit = (data: any) => {
    const now = nowUnix();
    const tasks = [...currentClocking!.tasks!];
    const { taskTypeId } = data;
    const task = department?.tasks?.find((task) => task.id === taskTypeId);
    const clockingId = currentClocking?.id || '$id';

    const newTask: IClockingTask = {
      start: now,
      taskTypeId,
      task: task ? { ...task } : undefined,
    };

    reduxDispatch({
      type: 'CLOCKING_ADD_CURRENT_TASK',
      payload: {
        id: generateOptimisticId(),
        ...newTask,
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              tasks: map([...tasks, newTask], (task) =>
                pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
              ),
              mobile: true,
              os: Platform.OS,
            },
            optimisticId: currentClocking!.optimisticId,
          },
        },
      },
    });
    navigation.goBack();
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('CLOCKING.ADD_TASK')} />
      <ScrollView style={{ backgroundColor: colors.blueExtraLight, padding: 25 }}>
        <View style={styles.content}>
          <Select
            label={t('CLOCKING.NEW_TASK')}
            control={control}
            defaultValue={null}
            errors={errors}
            name="taskTypeId"
            rules={{ required: true }}
            items={(department?.tasks || []).map((task) => ({ label: task.name!, value: task.id! }))}
          />
          <SIButton title={t('CLOCKING.START_TASK')} onPress={handleSubmit(onSubmit)} size="large" />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: 25,
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
});

export default ClockingAddTaskScreen;
