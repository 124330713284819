import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import moment from 'moment';
import 'moment-timezone';
import H1 from '../components/H1';
import { useSelector } from 'react-redux';
import { InitialState } from '../store';
moment.tz.setDefault('Atlantic/Reykjavik');

const DevScreen: React.FC = () => {
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const offlineState = useSelector((store: InitialState) => store.offline);
  return (
    <View>
      <H1>OptimisticIds</H1>
      <Text>{JSON.stringify(clockingState.optimisticIdsToIds)}</Text>
      <H1>Offline</H1>
      <Text>{JSON.stringify(offlineState)}</Text>
    </View>
  );
};

const styles = StyleSheet.create({});

export { styles };
export default DevScreen;
