import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  DrawerContentComponentProps,
  DrawerContentOptions,
  DrawerContentScrollView,
  DrawerItem,
} from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/native';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import Axios from 'axios';
import Constants from 'expo-constants';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBadge from './components/NotificationBadge';
import SIText from './components/SIText';
import { ActionType, InitialState } from './store';
import colors from './styles/colors';

const DrawerContent: React.FC<DrawerContentComponentProps<DrawerContentOptions>> = (props) => {
  const authState = useSelector((store: InitialState) => store.auth);
  const dataState = useSelector((store: InitialState) => store.data);
  const {
    clockinAccount,
    oncall,
    departments,
    access_shifts_departments,
    access_documents_departments,
    access_dimona_departments,
    access_swap_shifts_departments,
    shyfts_market_departments,
  } = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const reduxDispatch: Dispatch<ActionType | any> = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const ADMIN = ['SUPER-ADMIN', 'ADMIN'].includes(authState.userDetails?.role || '');

  const onResetStore = async () => {
    const deviceId = await AsyncStorage.getItem('device-id');
    Axios.post(
      `${
        Constants.manifest?.extra?.API_URL ||
        Constants.manifest?.extra?.API_URL ||
        Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/logout`,
      {
        deviceId,
      },
    );
    reduxDispatch({ type: RESET_STATE });
    reduxDispatch({
      type: 'RESET_STORE',
    });

    await AsyncStorage.removeItem('device-id');
  };

  if (authState.userDetails) {
    const { email, id, userName } = authState.userDetails;
    return (
      <View style={{ flex: 1 }}>
        <DrawerContentScrollView {...props}>
          <View
            style={{
              //borderBottomColor: colors.greyLight,
              //borderBottomWidth: 1,
              paddingHorizontal: 15,
              paddingBottom: 15,
            }}
          >
            <SIText style={{ marginBottom: 5 }}>{`${userName}`}</SIText>
            <SIText style={{ fontSize: 11 }}>{email}</SIText>
            <SIText style={{ fontSize: 11, color: colors.grey }}>{`User ID: ${id}`}</SIText>
            <SIText style={{ fontSize: 11, color: colors.grey, marginTop: 10 }}>
              {`App version: ${Constants.manifest?.version || Constants?.manifest2?.extra?.expoClient?.version}`}
            </SIText>
          </View>

          {/* <DrawerItem
            style={styles.drawerItem}
            label={t('GENERAL.HOME')}
            onPress={() => {
              props.navigation.navigate('Tab', {
                screen: 'Home',
              });
            }}
          />

          <DrawerItem
            style={styles.drawerItem}
            label={t('GENERAL.MY_INFORMATION')}
            onPress={() => {
              props.navigation.navigate('Me');
              props.navigation.dispatch(DrawerActions.closeDrawer());
            }}
          /> */}
          {/** only visible for dev */}
          {authState.userDetails.id == '37670' && (
            <DrawerItem
              label={'DEV'}
              onPress={() => {
                props.navigation.navigate('Dev');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {oncall && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.MY_ALERTS')}
              onPress={() => {
                props.navigation.navigate('MyAlerts');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {!dataState.clockinAccount && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.MY_CONTACTS')}
              onPress={() => {
                props.navigation.navigate('Contacts');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {!dataState.clockinAccount && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.MY_CONTRACTS')}
              onPress={() => {
                props.navigation.navigate('Contracts');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {!dataState.clockinAccount && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.MY_DOCUMENTS')}
              onPress={() => {
                props.navigation.navigate('Documents');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {dataState.departments.find((department) => department.params?.enableAvailability) &&
            !dataState.fixed_view && (
              <DrawerItem
                style={styles.drawerItem}
                label={t('GENERAL.UNAVAILABILITIES')}
                onPress={() => {
                  props.navigation.navigate('Availabilities');
                  props.navigation.dispatch(DrawerActions.closeDrawer());
                }}
              />
            )}
          {!dataState.fixed_view && access_swap_shifts_departments && access_swap_shifts_departments.length ? (
            <DrawerItem
              style={styles.drawerItem}
              label={({ color }) => (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <SIText style={{ color, marginRight: 10 }}>{t('SHIFTS.SWAP_SHIFT.REQUESTS')}</SIText>
                  {dataState.swap_shift_requests > 0 && <NotificationBadge number={dataState.swap_shift_requests} />}
                </View>
              )}
              onPress={() => {
                props.navigation.navigate('SwapShiftRequest');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          ) : null}
          {shyfts_market_departments && shyfts_market_departments.length > 0 ? (
            <DrawerItem
              style={styles.drawerItem}
              label={({ color }) => (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <SIText style={{ color, marginRight: 10 }}>{t('SHYFTS_MARKET.TITLE')}</SIText>
                </View>
              )}
              onPress={() => {
                props.navigation.navigate('ShyftsMarket');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          ) : null}
          {!dataState.clockinAccount && access_shifts_departments && access_shifts_departments.length > 0 && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.DASHBOARD')}
              onPress={() => {
                props.navigation.navigate('Dashboard');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {!dataState.clockinAccount && access_shifts_departments && access_shifts_departments.length > 0 && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.TOMORROW')}
              onPress={() => {
                props.navigation.navigate('Tomorrow');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {/* {!!departments.filter((x) => x.interventionSheets).length && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('INTERVENTION.INTERVENTION_SHEET')}
              onPress={() => {
                props.navigation.navigate('Intervention');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )} */}
          {!clockinAccount && access_shifts_departments && access_shifts_departments.length > 0 && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('SHIFTS.CREATE_SHIFT')}
              onPress={() => {
                props.navigation.navigate('CreateNewShift');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {access_dimona_departments && access_dimona_departments.length > 0 && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('DIMONA.CREATE_DIMONA')}
              onPress={() => {
                props.navigation.navigate('CreateNewDimona');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {access_documents_departments && access_documents_departments.length > 0 && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('CONTRACTS.CREATE_CONTRACT')}
              onPress={() => {
                props.navigation.navigate('CreateNewContract');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {ADMIN && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.LOGS')}
              onPress={() => {
                props.navigation.navigate('Logs');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {!dataState.clockinAccount && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('GENERAL.SETTINGS')}
              onPress={() => {
                props.navigation.navigate('Settings');
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          )}
          {offlineState.online && (
            <DrawerItem
              style={styles.drawerItem}
              label={t('AUTH.LOGOUT')}
              onPress={() => {
                if (!!offlineState.outbox?.length) {
                  if (Platform.OS === 'web') {
                    const confirmation = confirm(
                      `${t('GENERAL.WARNING')}.${t('CLOCKING.ALERTS.ALL_DATA_WILL_BE_LOST')}`,
                    );
                    if (confirmation === true) {
                      onResetStore();
                    }
                  } else {
                    Alert.alert(
                      t('GENERAL.WARNING'),
                      t('CLOCKING.ALERTS.ALL_DATA_WILL_BE_LOST'),
                      [
                        {
                          text: t('GENERAL.CANCEL'),
                          style: 'cancel',
                        },
                        { text: t('GENERAL.CONFIRM'), onPress: onResetStore },
                      ],
                      { cancelable: false },
                    );
                  }
                } else {
                  onResetStore();
                }
              }}
            />
          )}
        </DrawerContentScrollView>
      </View>
    );
  } else {
    return null;
  }
};

export default DrawerContent;

const styles = StyleSheet.create({
  drawerItem: {
    borderBottomColor: colors.greyLight,
    borderBottomWidth: 0.5,
  },
});
