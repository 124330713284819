import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Select from '../../components/Select';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import Constants from 'expo-constants';
import {
  DIMONA_EXT_DECLARATION_TYPES,
  DIMONA_FLX_DECLARATION_TYPES,
  DIMONA_USER_STATUS,
  DIMONA_USER_TYPE,
  DIMONA_WORK_TYPES,
} from '../../utils';
import DateInput from '../../components/DateInput';
import Input from '../../components/Input';
import axios from 'axios';
import SIButton from '../../components/SIButton';
import moment from 'moment';
import { showMessage } from 'react-native-flash-message';
import { useNavigation } from '@react-navigation/native';
import { IShift } from '../../types/shift.model';
import { StackScreenProps } from '@react-navigation/stack';

type NavigationParams = {
  CreateNewDimonaScreen: {
    shift?: IShift;
  };
};

type Props = StackScreenProps<NavigationParams, 'CreateNewDimonaScreen'>;

const CreateNewDimonaScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const { params } = route;
  const active_shift = params && params.shift;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { handleSubmit, errors, control, watch, setValue } = useForm();
  const watch_department = watch('departmentId', null);
  const watch_user = watch('userRecord', null);
  const watch_worker_type = watch('workerType', null);
  const watch_period_type = watch('periodType', null);
  const { departments, access_dimona_departments } = useSelector((store: InitialState) => store.data);
  const [loading, setLoading] = useState<boolean>(false);

  const activeDepartment =
    access_dimona_departments && access_dimona_departments.find((department) => department.id === watch_department);

  useEffect(() => {
    if (access_dimona_departments && access_dimona_departments.length == 1) {
      setValue('departmentId', access_dimona_departments[0].id);
    }
  }, []);

  useEffect(() => {
    if (active_shift) {
      setValue('departmentId', active_shift.departmentId);
      setValue('userRecord', active_shift.userRecordId);
      // setValue('workerType', active_shift.user);
    }
  }, [active_shift]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (watch_user || active_shift) {
      axios
        .get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/users/${
            active_shift ? active_shift.userRecordId : watch_user
          }/worker-type`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
            cancelToken: cancelTokenSource.token,
          },
        )
        .then(({ data }) => {
          if (data.workerType) {
            setValue('workerType', data.workerType);
            if (active_shift) {
              setValue('startingDate', moment.unix(active_shift.start!).toDate());
              setValue('startingHour', moment.unix(active_shift.start!).toDate());
              setValue('endingDate', moment.unix(active_shift.start!).toDate());
              setValue('endingHour', moment.unix(active_shift.start!).toDate());
              setValue('plannedHoursNbr', String((active_shift.end! - active_shift.start!) / 3600));
            }
          } else {
            setValue('workerType', null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [watch_user, active_shift]);

  const onSubmit = (values: any) => {
    setLoading(true);
    const parsed_values = {
      ...values,
      startingDate: values.startingDate
        ? moment(
            Platform.OS == 'android' && values.startingHour
              ? `${values.startingDate.toString().split('T')[0]}T${values.startingHour.toString().split('T')[1]}`
              : values.startingDate,
          ).unix()
        : null,
      endingDate: values.endingDate
        ? moment(
            Platform.OS == 'android' && values.endingHour
              ? `${values.endingDate.toString().split('T')[0]}T${values.endingHour.toString().split('T')[1]}`
              : values.endingDate,
          ).unix()
        : null,
    };
    axios
      .post(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/dimona`,
        {
          ...parsed_values,
        },
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(({ data }) => {
        navigation.navigate('Home');
        showMessage({
          message: t('DIMONA.FLASH_MESSAGES.CREATED', { name: data.dimona.user.displayName }),
          type: 'success',
        });
        setLoading(false);
      })
      .catch((error) => {
        const has_error = error && error.response && error.response.data && error.response.data.errors;
        showMessage({
          message: has_error
            ? `${Object.values(error.response.data.errors).flat(1)[0]}`
            : t('DIMONA.FLASH_MESSAGES.ERROR'),
          type: 'danger',
        });
        setLoading(false);
      });
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('DIMONA.CREATE_DIMONA')} />
      <ScrollView style={styles.container}>
        <View style={styles.form}>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={
              access_dimona_departments &&
              access_dimona_departments.map((department) => ({
                label: department.company!,
                value: department.id!,
              }))
            }
          />
          <Select
            placeholder={t('GENERAL.USER')}
            label={t('GENERAL.USER')}
            control={control}
            defaultValue={null}
            name="userRecord"
            items={activeDepartment?.users?.map((user) => ({ label: user.displayName!, value: user.recordId! })) || []}
            rules={{ required: true }}
            errors={errors}
          />
          <Select
            placeholder={t('DIMONA.WORKER_TYPE')}
            label={t('DIMONA.WORKER_TYPE')}
            control={control}
            defaultValue={null}
            name="workerType"
            items={DIMONA_WORK_TYPES.map((worker_type) => ({ label: worker_type.name, value: worker_type.id }))}
            rules={{ required: true }}
            errors={errors}
          />
          {watch_worker_type == 'A17' && (
            <DateInput
              control={control}
              name="startingDate"
              label={t('DIMONA.START_PERIOD')}
              rules={{ required: true }}
              mode="date"
              watch={watch}
              errors={errors}
            />
          )}
          {(watch_worker_type == 'OTH' || watch_worker_type == 'PMP' || watch_worker_type == 'DWD') && (
            <View>
              <DateInput
                control={control}
                name="startingDate"
                label={t('DIMONA.START_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
              <DateInput
                control={control}
                name="endingDate"
                label={t('DIMONA.END_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
            </View>
          )}
          {watch_worker_type == 'STU' && (
            <View>
              <DateInput
                control={control}
                name="startingDate"
                label={t('DIMONA.START_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
              <DateInput
                control={control}
                name="endingDate"
                label={t('DIMONA.END_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
              <Input
                defaultValue={null}
                label={t('DIMONA.TOTAL_HOURS')}
                placeholder={t('DIMONA.TOTAL_HOURS')}
                rules={{ required: true }}
                control={control}
                name="plannedHoursNbr"
                keyboardType="numeric"
                errors={errors}
              />
            </View>
          )}
          {watch_worker_type == 'FLX' && (
            <View>
              <Select
                placeholder={t('DIMONA.PERIOD_TYPE')}
                label={t('DIMONA.PERIOD_TYPE')}
                control={control}
                defaultValue={null}
                name="periodType"
                items={DIMONA_FLX_DECLARATION_TYPES.map((declaration) => ({
                  label: t(`DIMONA.DECLARATION_TYPES.FLX.${declaration.toUpperCase()}`),
                  value: declaration,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
              {watch_period_type == 'range' && (
                <View>
                  <DateInput
                    control={control}
                    name="startingDate"
                    label={t('DIMONA.START_PERIOD')}
                    rules={{ required: true }}
                    mode="date"
                    watch={watch}
                    errors={errors}
                  />
                  <DateInput
                    control={control}
                    name="endingDate"
                    label={t('DIMONA.END_PERIOD')}
                    rules={{ required: true }}
                    mode="date"
                    watch={watch}
                    errors={errors}
                  />
                </View>
              )}
              {watch_period_type == 'day' && (
                <View>
                  {Platform.OS == 'android' ? (
                    <>
                      <DateInput
                        control={control}
                        name="startingDate"
                        label={t('DIMONA.START_DATE')}
                        rules={{ required: true }}
                        mode="date"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="startingHour"
                        label={t('DIMONA.START_HOUR')}
                        rules={{ required: true }}
                        mode="time"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingDate"
                        label={t('DIMONA.END_DATE')}
                        rules={{ required: true }}
                        mode="date"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingHour"
                        label={t('DIMONA.END_HOUR')}
                        rules={{ required: true }}
                        mode="time"
                        watch={watch}
                        errors={errors}
                      />
                    </>
                  ) : (
                    <>
                      <DateInput
                        control={control}
                        name="startingDate"
                        label={t('DIMONA.START_HOUR')}
                        rules={{ required: true }}
                        mode="datetime"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingDate"
                        label={t('DIMONA.END_HOUR')}
                        rules={{ required: true }}
                        mode="datetime"
                        watch={watch}
                        errors={errors}
                      />
                    </>
                  )}
                </View>
              )}
            </View>
          )}
          {(watch_worker_type == 'STG' || watch_worker_type == 'TRI' || watch_worker_type == 'IVT') && (
            <View>
              <Select
                placeholder={t('DIMONA.USER_TYPE')}
                label={t('DIMONA.USER_TYPE')}
                control={control}
                defaultValue={null}
                name="userType"
                items={DIMONA_USER_TYPE.map((user_type) => ({
                  label: t(`DIMONA.USER_TYPES.${user_type.toUpperCase()}`),
                  value: user_type,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
              <Select
                placeholder={t('DIMONA.USER_STATUS')}
                label={t('DIMONA.USER_STATUS')}
                control={control}
                defaultValue={null}
                name="userStatus"
                items={DIMONA_USER_STATUS.map((user_status) => ({
                  label: t(`DIMONA.USER_STATUSES.${user_status.toUpperCase()}`),
                  value: user_status,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
              <DateInput
                control={control}
                name="startingDate"
                label={t('DIMONA.START_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
              <DateInput
                control={control}
                name="endingDate"
                label={t('DIMONA.END_PERIOD')}
                rules={{ required: true }}
                mode="date"
                watch={watch}
                errors={errors}
              />
            </View>
          )}
          {watch_worker_type == 'EXT' && (
            <View>
              <Select
                placeholder={t('DIMONA.PERIOD_TYPE')}
                label={t('DIMONA.PERIOD_TYPE')}
                control={control}
                defaultValue={null}
                name="periodType"
                items={DIMONA_EXT_DECLARATION_TYPES.map((declaration) => ({
                  label: t(`DIMONA.DECLARATION_TYPES.EXT.${declaration.toUpperCase()}`),
                  value: declaration,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
              {watch_period_type == 'hourly' && (
                <View>
                  {Platform.OS == 'android' ? (
                    <>
                      <DateInput
                        control={control}
                        name="startingDate"
                        label={t('DIMONA.START_DATE')}
                        rules={{ required: true }}
                        mode="date"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="startingHour"
                        label={t('DIMONA.START_HOUR')}
                        rules={{ required: true }}
                        mode="time"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingDate"
                        label={t('DIMONA.END_DATE')}
                        rules={{ required: true }}
                        mode="date"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingHour"
                        label={t('DIMONA.END_HOUR')}
                        rules={{ required: true }}
                        mode="time"
                        watch={watch}
                        errors={errors}
                      />
                    </>
                  ) : (
                    <>
                      <DateInput
                        control={control}
                        name="startingDate"
                        label={t('DIMONA.START_DATE')}
                        rules={{ required: true }}
                        mode="datetime"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="endingDate"
                        label={t('DIMONA.END_DATE')}
                        rules={{ required: true }}
                        mode="datetime"
                        watch={watch}
                        errors={errors}
                      />
                    </>
                  )}
                </View>
              )}
              {watch_period_type == 'daily' && (
                <>
                  {Platform.OS == 'android' ? (
                    <>
                      <DateInput
                        control={control}
                        name="startingDate"
                        label={t('DIMONA.START_DATE')}
                        rules={{ required: true }}
                        mode="date"
                        watch={watch}
                        errors={errors}
                      />
                      <DateInput
                        control={control}
                        name="startingHour"
                        label={t('DIMONA.START_HOUR')}
                        rules={{ required: true }}
                        mode="time"
                        watch={watch}
                        errors={errors}
                      />
                    </>
                  ) : (
                    <DateInput
                      control={control}
                      name="startingDate"
                      label={t('DIMONA.START_HOUR')}
                      rules={{ required: true }}
                      mode="datetime"
                      watch={watch}
                      errors={errors}
                    />
                  )}
                </>
              )}
            </View>
          )}
          <SIButton
            title={t('GENERAL.CONFIRM')}
            size="large"
            loading={loading}
            onPress={handleSubmit(onSubmit)}
            style={{ marginTop: 15 }}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  form: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    marginBottom: 150,
  },
});

export default CreateNewDimonaScreen;
