import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { IUserDetails } from '../types/userDetails.model';
import SIText from './SIText';
import Icon from './Icon';
import colors from '../styles/colors';
import H1 from './H1';

interface Props {
  visible: boolean;
}

const ModalTransferringData: React.FC<Props> = ({ visible }) => {
  const { t } = useTranslation();

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible}>
      <View style={styles.container}>
        <Icon name="attention" color={colors.orange} size={50} />
        <H1 style={styles.title}>{t('OFFLINE.MODAL_TRANSFERRING_DATA.TITLE')}</H1>
        <SIText style={styles.description}>{t('OFFLINE.MODAL_TRANSFERRING_DATA.DESCRIPTION')}</SIText>
        <SIText style={styles.description}>{t('OFFLINE.MODAL_TRANSFERRING_DATA.WAITING')}</SIText>
        <ActivityIndicator style={styles.spinner} size="large" color={colors.green} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    padding: 25,
  },
  title: {
    marginVertical: 25,
  },
  description: {
    textAlign: 'center',
  },
  spinner: {
    marginTop: 25,
  },
});

export default ModalTransferringData;
