import { i18n } from 'i18next';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet, Platform, Dimensions } from 'react-native';
import colors from '../styles/colors';
import SIButton from './SIButton';
import SIText from './SIText';

interface Props {
  children: any;
  i18n: i18n;
}

const Iphone14ProMax: React.FC<Props> = ({ children, i18n }) => {
  return (
    <View style={styles.container}>
      <View style={styles.shape} />
      <View style={{ height: Dimensions.get('window').height - 50, minHeight: 750, maxHeight: 850 }}>
        <Image style={styles.frame} resizeMode="stretch" source={require('../assets/iphone.png')} />
        <View style={styles.contentContainer}>{children}</View>
        <Image style={styles.notch} resizeMode="center" source={require('../assets/notch.png')} />
      </View>
      <View style={styles.right}>
        <View style={styles.icon_container}>
          <Image style={styles.icon} resizeMode="stretch" source={require('../assets/icon.png')} />
        </View>
        <SIText style={styles.app_name}>Shyfter Staff</SIText>
        <SIText>{i18n.t('FRAME.DESCRIPTION_1')}</SIText>
        <SIText>{i18n.t('FRAME.DESCRIPTION_2')}</SIText>
        <SIText>{i18n.t('FRAME.DESCRIPTION_3')}</SIText>
        <Image
          style={{ width: 150, height: 150, marginLeft: -7, marginTop: 25 }}
          resizeMode="center"
          source={require('../assets/appstores-qr-code.png')}
        />
        <Image
          style={styles.download_button}
          resizeMode="center"
          source={require('../assets/app-stores-download.png')}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  shape: {
    backgroundColor: '#efefef',
    width: 500,
    height: 500,
    position: 'absolute',
    top: -100,
    right: -100,
    borderRadius: 64,
    transform: [{ rotate: '20deg' }],
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  right: {
    marginLeft: 50,
  },
  icon_container: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    borderRadius: 25,
    alignSelf: 'flex-start',
  },
  icon: {
    width: 75,
    height: 75,
    borderRadius: 15,
  },
  app_name: {
    fontWeight: 'bold',
    fontSize: 32,
    marginVertical: 15,
  },
  actions: {
    marginVertical: 25,
  },
  frame: {
    width: 400,
    height: '100%',
    position: 'relative',
  },
  contentContainer: {
    width: 'calc(100% - 24px)',
    height: Dimensions.get('window').height - 70,
    minHeight: 730,
    maxHeight: 830,
    top: 11,
    position: 'absolute',
    left: 14,
    overflow: 'hidden',
    borderRadius: 45,
    zIndex: 1,
    paddingTop: Platform.OS === 'web' ? 25 : 0,
    backgroundColor: colors.blueExtraLight,
  },
  notch: {
    width: 100,
    height: 50,
    zIndex: 2,
    position: 'absolute',
    top: 10,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
  download_button: {
    width: 300,
    height: 100,
    marginLeft: -4,
  },
});

export default Iphone14ProMax;
