import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import H1 from '../../components/H1';
import SIText from '../../components/SIText';
import colors from '../../styles/colors';
import AvailabilityCard from './UnavailabilityCard';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  title?: string;
  style?: StyleProp<TextStyle>;
  unavailabilities: any[];
  noDataTitle?: string;
  noDataMessage?: string;
  minDate: string | null;
  onRemoveUnavailability: (id: string) => Promise<void>;
}

const UnavailabilityCards: React.FC<Props> = ({
  title,
  style,
  unavailabilities,
  noDataTitle,
  noDataMessage,
  minDate,
  onRemoveUnavailability,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  if (!noDataTitle) {
    noDataTitle = title;
  }

  if (unavailabilities.length) {
    return (
      <View style={[style, styles.viewContainerMain]}>
        {title && <H1 style={{ textAlign: 'left', width: '100%', marginBottom: 10 }}>{title}</H1>}
        {unavailabilities.map((unavailability, idx) => (
          <AvailabilityCard
            key={`availability_${idx!}`}
            unavailability={unavailability}
            deleteable={moment(unavailability.start * 1000) >= moment(minDate)}
            onRemoveUnavailability={onRemoveUnavailability}
          />
        ))}
      </View>
    );
  } else {
    return (
      <View style={[style, styles.viewContainerMain]}>
        {noDataTitle && <H1 style={{ textAlign: 'center', width: '100%', marginBottom: 10 }}>{noDataTitle}</H1>}
        {!noDataMessage && (
          <SIText style={{ textAlign: 'center', color: colors.grey }}>
            {t('UNAVAILABILITIES.NO_UNAVAILABILITIES')}
          </SIText>
        )}
        {noDataMessage && (
          <View>
            <SIText style={{ color: colors.grey }}>{noDataMessage}</SIText>
          </View>
        )}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  viewContainerMain: {
    paddingTop: 0,
  },
});

export default UnavailabilityCards;
