import { useFocusEffect, useNavigation } from '@react-navigation/native';
import axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../components/Back';
import H1 from '../../components/H1';
import Select from '../../components/Select';
import SIText from '../../components/SIText';
import { ActionType, InitialState } from '../../store';
import colors from '../../styles/colors';
import { ISwapShiftData } from '../../types/swap-shift/data.model';
import { ISentSwapShift } from '../../types/swap-shift/sent-swap-shift.model';
import { ISwapShift } from '../../types/swap-shift/swap-shift.model';
import SentSwapShift from './components/SentSwapShift';
import SwapShift from './components/SwapShift';

let Pusher: any = null;

if (Platform.OS !== 'web') {
  Pusher = require('pusher-js/react-native');
} else {
  Pusher = require('pusher-js');
}

const SwapShiftRequestsScreen: React.FC = () => {
  const { t } = useTranslation();
  const authState = useSelector((store: InitialState) => store.auth);
  const { access_swap_shifts_departments, swap_shifts_data } = useSelector((store: InitialState) => store.data);
  const dispatch: Dispatch<ActionType> = useDispatch();
  const { handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues: {
      departmentId: null,
    },
  });
  const watchDepartment = watch('departmentId', null);
  const [swapShiftsFiltered, setSwapShiftsFiltered] = useState<ISwapShiftData | null>(null);

  const has_data =
    swapShiftsFiltered &&
    (swapShiftsFiltered.swapShiftReceived.length > 0 || swapShiftsFiltered.swapShiftSent.length > 0);

  const pusher = Pusher.instances ? Pusher.instances[0] : null;

  useEffect(() => {
    if (pusher) {
      const channel = pusher.channel(`private-App.User.${authState.userDetails?.id}`);
      if (channel) {
        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', async (message: any) => {
          if (message.type == 'refresh' && message.resource == 'SWAP_SHIFTS' && !isNaN(Number(watchDepartment))) {
            getData();
          }
        });
      }
    }

    return () => {
      if (pusher) {
        pusher.unsubscribe(`private-App.User.${authState.userDetails?.id}`);
      }
    };
  }, [pusher]);

  const getData = () => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/swap-shift/user`,
        {
          params: {
            departmentId:
              access_swap_shifts_departments.length === 1 ? access_swap_shifts_departments[0].id : watchDepartment,
            status: 'PENDING',
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        dispatch({
          type: 'DATA_SET_SWAP_SHIFTS_DATA',
          payload: data,
        });
        setSwapShiftsFiltered(data);
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          if (Object.values(err.response.data.errors)[0]) {
            showMessage({
              message: Object.values(err.response.data.errors)[0] as string,
              type: 'danger',
            });
          }
        } else {
          showMessage({
            message: t('SHIFTS.SWAP_SHIFT.GET_ERROR'),
            type: 'danger',
          });
        }
        dispatch({
          type: 'DATA_SET_SWAP_SHIFTS_DATA',
          payload: null,
        });
      });
  };

  useFocusEffect(
    useCallback(() => {
      if (!watchDepartment && access_swap_shifts_departments && access_swap_shifts_departments?.length === 1) {
        setTimeout(() => {
          setValue('departmentId', access_swap_shifts_departments[0].id);
        }, 0);
      }
    }, []),
  );

  useEffect(() => {
    if (watchDepartment && swap_shifts_data && !isNaN(Number(watchDepartment))) {
      const newSwapShiftsFiltered: ISwapShiftData = {
        swapShiftReceived: [],
        swapShiftSent: [],
      };

      newSwapShiftsFiltered.swapShiftReceived =
        swap_shifts_data.swapShiftReceived &&
        swap_shifts_data.swapShiftReceived.filter((ss) => ss.shift.departmentId == watchDepartment);
      newSwapShiftsFiltered.swapShiftSent =
        swap_shifts_data.swapShiftSent &&
        swap_shifts_data.swapShiftSent.filter((ss) => ss.swapShift.department.id === watchDepartment);

      setSwapShiftsFiltered(newSwapShiftsFiltered);
    } else {
      setSwapShiftsFiltered(swap_shifts_data);
    }
  }, [watchDepartment]);

  useEffect(() => {
    let mounted = true;

    if (isNaN(Number(watchDepartment))) {
      dispatch({
        type: 'DATA_SET_SWAP_SHIFTS_DATA',
        payload: {
          swapShiftReceived: [],
          swapShiftSent: [],
        },
      });
      setSwapShiftsFiltered({
        swapShiftReceived: [],
        swapShiftSent: [],
      });
    }
    if (
      ((watchDepartment && !isNaN(Number(watchDepartment))) || access_swap_shifts_departments.length == 1) &&
      mounted
    ) {
      getData();
    }

    return () => {
      mounted = false;
    };
  }, [watchDepartment]);

  const renderSwapShift = ({ item }: { item: ISentSwapShift }) => {
    return (
      <SwapShift
        departmentId={
          access_swap_shifts_departments.length === 1 ? access_swap_shifts_departments[0].id : watchDepartment
        }
        row={item}
      />
    );
  };

  const renderSentSwapShift = ({ item }: { item: ISwapShift }) => {
    return (
      <SentSwapShift
        departmentId={
          access_swap_shifts_departments.length === 1 ? access_swap_shifts_departments[0].id : watchDepartment
        }
        row={item}
      />
    );
  };

  const keyExtractor = (item: ISwapShift, index: any) => {
    return item.swapShift.id;
  };

  const keyExtractorSent = (item: ISentSwapShift, index: any) => {
    return item.swapShift.id;
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('SHIFTS.SWAP_SHIFT.REQUESTS')} />
      <View style={styles.container}>
        {access_swap_shifts_departments && access_swap_shifts_departments.length > 1 && (
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={
              access_swap_shifts_departments &&
              access_swap_shifts_departments.map((department) => ({
                label: department.company!,
                value: department.id!,
              }))
            }
          />
        )}
        <View style={styles.content}>
          {swapShiftsFiltered && (
            <View
              style={{
                alignItems: has_data ? 'flex-start' : 'center',
              }}
            >
              {swapShiftsFiltered.swapShiftReceived.length > 0 && (
                <>
                  <H1>{t('SHIFTS.SWAP_SHIFT.PENDING_REQUESTS')}</H1>
                  <FlatList
                    data={swapShiftsFiltered.swapShiftReceived}
                    renderItem={renderSwapShift}
                    keyExtractor={keyExtractorSent}
                    style={{ width: '100%', marginTop: 25 }}
                  />
                </>
              )}
              {swapShiftsFiltered.swapShiftSent.length > 0 && (
                <>
                  <H1>{t('SHIFTS.SWAP_SHIFT.SENT_SWAP_SHIFTS')}</H1>
                  <FlatList
                    data={swapShiftsFiltered.swapShiftSent}
                    renderItem={renderSentSwapShift}
                    keyExtractor={keyExtractor}
                    style={{ width: '100%', marginTop: 25 }}
                  />
                </>
              )}
            </View>
          )}
          {!has_data && <SIText style={{ textAlign: 'center' }}>{t('SHIFTS.SWAP_SHIFT.NO_SHIFT_REQUESTS')}</SIText>}
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blueExtraLight,
    padding: 25,
  },
  content: {
    borderRadius: 10,
  },
});

export default SwapShiftRequestsScreen;
