import ActionType from '../actions/signatureActions';
import produce from 'immer';
import { IDocument } from '../../types/document.model';

export type SignatureInitialState = {
  requiredSignatures: IDocument[];
};

const initialState: SignatureInitialState = {
  requiredSignatures: [],
};

export default function reducer(state = initialState, action: ActionType): SignatureInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'REFRESH_DATA_COMMIT': {
      return produce(state, (draftState) => {
        const { signaturesRequired } = action.payload.data;
        draftState.requiredSignatures = signaturesRequired || [];
      });
    }
    case 'SIGNATURE_SET_REQUIRED_SIGNATURES': {
      return produce(state, (draftState) => {
        draftState.requiredSignatures = action.payload || [];
      });
    }
    case 'SIGNATURE_CONTRACT_SIGNED': {
      return produce(state, (draftState) => {
        const documents = draftState.requiredSignatures;
        if (documents) {
          const index = documents.findIndex((document) => document.id === action.payload);
          if (~index) {
            documents.splice(index, 1);
          }
        }
      });
    }
    default: {
      return state;
    }
  }
}
