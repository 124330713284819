import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InitialState } from '../../../store';
import { ISwapShift } from '../../../types/swap-shift/swap-shift.model';
import Constants from 'expo-constants';
import { showMessage } from 'react-native-flash-message';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import SIText from '../../../components/SIText';
import moment from 'moment';
import colors from '../../../styles/colors';
import Icon from '../../../components/Icon';
import ModalDeleteSwapShift from './ModalDeleteSwapShift';
import Tag from '../../../components/Tag';

interface Props {
  departmentId?: string | null;
  row: ISwapShift;
}

const SentSwapShift: React.FC<Props> = ({ departmentId, row }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dataState = useSelector((store: InitialState) => store.data);
  const [modalDeleteSwapShiftVisible, setModalDeleteSwapShiftVisible] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onDelete = () => {
    setModalDeleteSwapShiftVisible(true);
  };

  return (
    <View style={styles.swap_shift_container}>
      <ModalDeleteSwapShift
        swapShift={row}
        loadingDelete={loadingDelete}
        setLoadingDelete={setLoadingDelete}
        visible={modalDeleteSwapShiftVisible}
        setVisible={setModalDeleteSwapShiftVisible}
      />
      <View style={styles.top_container}>
        <View>
          {!departmentId && (
            <SIText style={{ color: colors.grey, fontSize: 11 }}>
              {dataState.access_swap_shifts_departments &&
                dataState.access_swap_shifts_departments.find(
                  (department) => department.id == row.swapShift.department.id,
                )?.company}
            </SIText>
          )}
          <SIText style={{ color: colors.grey }}>{moment.unix(row.shift.start!).format('LL')}</SIText>
          <SIText style={{ color: colors.grey }}>
            {moment.unix(row.shift.start!).format('HH:mm')} - {moment.unix(row.shift.end!).format('HH:mm')}
          </SIText>
        </View>
        <View>
          <TouchableOpacity style={[styles.button, { backgroundColor: colors.red }]} onPress={onDelete}>
            <SIText style={styles.action_text}>
              <Icon name="trash-empty" size={18} />
            </SIText>
          </TouchableOpacity>
        </View>
      </View>
      {row.swapShift.users.map((user) => {
        let background = '#000';
        let status = t('SHIFTS.SWAP_SHIFT.STATUSES.NO_STATUS');

        if (user.admin_approved_at) {
          background = colors.green;
          status = t('SHIFTS.SWAP_SHIFT.STATUSES.APPROVED_BY_ADMIN');
        }
        if (!user.user_approved_at && !user.user_denied_at) {
          if (row.swapShift.active) {
            background = colors.orange;
            status = t('SHIFTS.SWAP_SHIFT.STATUSES.WAITING_FOR_USER');
          } else {
            background = colors.orange;
            status = t('SHIFTS.SWAP_SHIFT.STATUSES.INACTIVE_BY_ADMIN');
          }
        }
        if (user.user_approved_at) {
          background = colors.green;
          status = t('SHIFTS.SWAP_SHIFT.STATUSES.WAITING_FOR_ADMIN');
        }
        if (user.user_denied_at) {
          background = colors.red;
          status = t('SHIFTS.SWAP_SHIFT.STATUSES.USER_DENIED');
        }

        return (
          <View key={user.record_id} style={styles.user_container}>
            <SIText style={{ fontSize: 12, flex: 2 }}>{user.name || `${user.first_name} ${user.last_name}`}</SIText>
            <View style={{ flex: 3, marginRight: -5, alignItems: 'flex-end' }}>
              <Tag backgroundColor={background} color={'#fff'}>
                {status}
              </Tag>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  swap_shift_container: {
    padding: 25,
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: '#fff',
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
    elevation: 2,
  },
  top_container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
  },
  user_container: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  badge: {
    borderRadius: 50,
    paddingVertical: 5,
    paddingHorizontal: 15,
    alignSelf: 'flex-start',
  },
  badge_text: {
    color: '#fff',
  },
  button: {
    marginLeft: 5,
    padding: 10,
    borderRadius: 10,
    paddingHorizontal: 15,
  },
  action_text: {
    color: '#fff',
  },
});

export default SentSwapShift;
