import Constants from 'expo-constants';
import * as Device from 'expo-device';
import produce from 'immer';
import { Platform } from 'react-native';
import { IPicture } from '../../types/clocking.model';
import { IDepartment } from '../../types/department.model';
import { IFeature } from '../../types/feature.model';
import { IIndicator } from '../../types/indicators';
import { IShift } from '../../types/shift.model';
import { ISwapShiftData } from '../../types/swap-shift/data.model';
import { nowUnix } from '../../utils';
import ActionType from '../actions/dataActions';

export type DataInitialState = {
  shifts: IShift[];
  departments: IDepartment[];
  lastUpdate: number | null;
  clockinAccount: boolean;
  indicators: IIndicator[];
  oncall: boolean;
  swap_shift_requests: number;
  changes_pictures: IPicture[];
  fixed_view: boolean;
  swap_shifts_data: ISwapShiftData | null;
  qr_code_departments: IDepartment[];
  access_shifts_departments: IDepartment[];
  access_documents_departments: IDepartment[];
  access_dimona_departments: IDepartment[];
  access_swap_shifts_departments: IDepartment[];
  access_global_schedule_departments: IDepartment[];
  access_vacation_requests_departments: IDepartment[];
  access_clocking_departments: IDepartment[];
  admin_planning_hr_departments: IDepartment[];
  no_offline_clockings_departments: IDepartment[];
  freeclockin_departments: IDepartment[];
  shyfts_market_departments: IDepartment[];
  mobile_clocking_departments: IDepartment[];
  // allow_edit_info_departments: IDepartment[];
  platform: string;
  version?: string;
  brand: string | null;
  modelName: string | null;
  os: string | null;
  api: string | null;
  features: IFeature[];
};

const initialState: DataInitialState = {
  shifts: [],
  departments: [],
  indicators: [],
  features: [],
  lastUpdate: null,
  clockinAccount: false,
  oncall: false,
  swap_shift_requests: 0,
  changes_pictures: [],
  fixed_view: false,
  swap_shifts_data: null,
  qr_code_departments: [],
  access_shifts_departments: [],
  access_documents_departments: [],
  access_dimona_departments: [],
  access_swap_shifts_departments: [],
  access_global_schedule_departments: [],
  access_vacation_requests_departments: [],
  access_clocking_departments: [],
  admin_planning_hr_departments: [],
  no_offline_clockings_departments: [],
  freeclockin_departments: [],
  shyfts_market_departments: [],
  mobile_clocking_departments: [],
  // allow_edit_info_departments: [],
  platform: Platform.OS,
  version: Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version,
  brand: Device.brand,
  modelName: Device.modelName,
  os:
    Platform.OS == 'ios'
      ? Device.osName || Device.osVersion
        ? `${Device.osName} ${Device.osVersion}`
        : null
      : Platform.OS == 'android'
      ? `Android ${Device.platformApiLevel}`
      : 'web',
  api: null,
};

export default function reducer(state = initialState, action: ActionType): DataInitialState {
  switch (action.type) {
    case 'RESET_STORE': {
      return initialState;
    }
    case 'REFRESH_DATA_COMMIT': {
      return produce(state, (draftState) => {
        const { shifts, departments, clockinAccount, oncall, indicators, swapShiftsRequests, userDetails } =
          action.payload.data;
        draftState.lastUpdate = new Date().getTime();
        draftState.departments = departments.map((department: IDepartment) => {
          return {
            ...department,
            users: department.users?.sort((a, b) => a.displayName!.localeCompare(b!.displayName!)),
          };
        });
        draftState.shifts = shifts;
        draftState.clockinAccount = clockinAccount;
        draftState.oncall = oncall;
        draftState.indicators = indicators;
        draftState.swap_shift_requests = swapShiftsRequests;
        draftState.changes_pictures = [];
        draftState.fixed_view = (departments as IDepartment[])
          .map((department: IDepartment) => department.fixedScheduleAccount === true)
          .every((value) => value == true);
        draftState.qr_code_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.clockin &&
              department.clockinParams?.enableMobileClockin &&
              department.clockinParams.enableQrCode,
          ) || [];
        draftState.access_shifts_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => department.role !== 'USER' && department.access?.shifts,
          ) || [];
        draftState.access_documents_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.role !== 'USER' && department.modules?.documents && department.access?.documents,
          ) || [];
        draftState.access_dimona_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.role !== 'USER' && department.modules?.dimona && department.access?.dimona,
          ) || [];
        draftState.access_swap_shifts_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.swapShifts.enabled) || [];
        draftState.access_global_schedule_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.globalSchedule) || [];
        draftState.access_vacation_requests_departments =
          (departments as IDepartment[]).filter((department: IDepartment) =>
            department.features.find((feature) => feature.code == 'leaves-management'),
          ) || [];
        draftState.access_clocking_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.clockin) || [];
        draftState.admin_planning_hr_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.role !== 'USER') || [];
        // draftState.allow_edit_info_departments =
        //   (departments as IDepartment[]).filter((department: IDepartment) => department.params?.allowUsersEditInfo) ||
        //   [];
        draftState.no_offline_clockings_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => !department.clockinParams?.offlineClockings,
          ) || [];
        draftState.freeclockin_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.clockinParams?.freeclockin) ||
          [];
        draftState.shyfts_market_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              !department.fixedScheduleAccount &&
              department.modules?.freeshifts &&
              department.scheduleParams.shyftsMarket,
          ) || [];
        draftState.mobile_clocking_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => department.clockinParams?.enableMobileClockin,
          ) || [];
      });
    }
    case 'DATA_SET_DATA': {
      return produce(state, (draftState) => {
        const { shifts, departments, clockinAccount, oncall, indicators, swapShiftsRequests, userDetails } =
          action.payload;
        draftState.lastUpdate = new Date().getTime();
        draftState.departments = departments.map((department: IDepartment) => {
          return {
            ...department,
            users: department.users?.sort((a, b) => a.displayName!.localeCompare(b!.displayName!)),
          };
        });
        draftState.shifts = shifts;
        draftState.clockinAccount = clockinAccount;
        draftState.oncall = oncall;
        draftState.indicators = indicators;
        draftState.swap_shift_requests = swapShiftsRequests;
        draftState.changes_pictures = [];
        draftState.fixed_view = (departments as IDepartment[])
          .map((department: IDepartment) => department.fixedScheduleAccount === true)
          .every((value) => value == true);
        draftState.qr_code_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.clockin &&
              department.clockinParams?.enableMobileClockin &&
              department.clockinParams.enableQrCode,
          ) || [];
        draftState.access_shifts_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => department.role !== 'USER' && department.access?.shifts,
          ) || [];
        draftState.access_documents_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.role !== 'USER' && department.modules?.documents && department.access?.documents,
          ) || [];
        draftState.access_dimona_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              department.role !== 'USER' && department.modules?.dimona && department.access?.dimona,
          ) || [];
        draftState.access_swap_shifts_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.swapShifts.enabled) || [];
        draftState.access_global_schedule_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.globalSchedule) || [];
        draftState.access_vacation_requests_departments =
          (departments as IDepartment[]).filter((department: IDepartment) =>
            department.features.find((feature) => feature.code == 'leaves-management'),
          ) || [];
        draftState.access_clocking_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.clockin) || [];
        draftState.admin_planning_hr_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.role !== 'USER') || [];
        // draftState.allow_edit_info_departments =
        //   (departments as IDepartment[]).filter((department: IDepartment) => department.params?.allowUsersEditInfo) ||
        //   [];
        draftState.no_offline_clockings_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => !department.clockinParams?.offlineClockings,
          ) || [];
        draftState.freeclockin_departments =
          (departments as IDepartment[]).filter((department: IDepartment) => department.clockinParams?.freeclockin) ||
          [];
        draftState.shyfts_market_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) =>
              !department.fixedScheduleAccount &&
              department.modules?.freeshifts &&
              department.scheduleParams.shyftsMarket,
          ) || [];
        draftState.mobile_clocking_departments =
          (departments as IDepartment[]).filter(
            (department: IDepartment) => department.clockinParams?.enableMobileClockin,
          ) || [];
      });
    }
    case 'DATA_UPDATE_SHIFT': {
      return produce(state, (draftState) => {
        const { payload } = action;
        const { id } = payload;
        const shifts = draftState.shifts;
        const shiftIndex = shifts.findIndex((_shift) => _shift.id === id);
        if (~shiftIndex) {
          shifts[shiftIndex] = { ...payload };
        }
      });
    }
    case 'DATA_REMOVE_SHIFT': {
      return produce(state, (draftState) => {
        const { payload: shiftId } = action;
        draftState.shifts = draftState.shifts.filter((shift) => shift.id !== shiftId);
      });
    }
    case 'DATA_ADD_SHIFTS': {
      return produce(state, (drafState) => {
        const newShifts = action.payload;
        if (!!newShifts?.length) {
          drafState.shifts.push(...newShifts);
          drafState.shifts = drafState.shifts.sort((a, b) => (a.start! > b.start! ? 1 : -1));
        }
      });
    }
    case 'DATA_ADD_PICTURE': {
      return produce(state, (draftState) => {
        draftState.changes_pictures.push({
          uri: action.payload,
          unix: nowUnix(),
        });
      });
    }
    case 'DATA_REMOVE_PICTURE': {
      return produce(state, (draftState) => {
        const pictures = draftState.changes_pictures;
        if (pictures?.length) {
          const index = pictures.findIndex((picture) => picture.uri === action.payload);
          if (~index) {
            pictures.splice(index, 1);
          }
        }
      });
    }
    case 'DATA_SET_SWAP_SHIFTS_DATA': {
      return produce(state, (draftState) => {
        draftState.swap_shifts_data = action.payload;
      });
    }
    case 'DATA_SET_SWAP_SHIFTS_REQUESTS': {
      return produce(state, (draftState) => {
        draftState.swap_shift_requests = action.payload;
      });
    }
    case 'SET_FEATURES': {
      return produce(state, (draftState) => {
        draftState.features = action.payload;
      });
    }
    default: {
      return state;
    }
  }
}
