import Constants from 'expo-constants';
import { map, pick } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import SIButton from '../../../components/SIButton';
import SIText from '../../../components/SIText';
import { InitialState } from '../../../store';
import ClockingActionType from '../../../store/actions/clockingActions';
import colors from '../../../styles/colors';
import { IClocking, IClockingTask } from '../../../types/clocking.model';
import { ITask } from '../../../types/task.model';
import { nowUnix } from '../../../utils';

interface Props {
  task: ITask;
  currentClocking: IClocking | undefined;
}

const Task: React.FC<Props> = ({ task, currentClocking }) => {
  const reduxDispatch: Dispatch<ClockingActionType> = useDispatch();
  const offlineState = useSelector((store: InitialState) => store.offline);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const isOnline = !!offlineState?.online;
  const [foundTask, setFoundTask] = useState<IClockingTask | null | undefined>(null);

  const onEndTask = () => {
    const clockingId = currentClocking?.id || '$id';
    const found = currentClocking!.tasks?.find((t) => t.taskTypeId == task.taskTypeId);
    const tasks = [
      ...currentClocking!.tasks!.filter((t) => t.taskTypeId !== task.taskTypeId),
      { ...task, start: found ? found.start : task.start, end: nowUnix() },
    ];

    reduxDispatch({
      type: 'CLOCKING_END_CURRENT_TASK',
      payload: {
        ...task,
        id: task.id!,
        end: nowUnix(),
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              mobile: true,
              os: Platform.OS,
              tasks: map([...tasks], (task) =>
                pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
              ),
            },
          },
        },
      },
    });
  };

  const onStartShiftTask = () => {
    const clockingId = currentClocking?.id || '$id';
    const tasks = [
      ...currentClocking!.tasks!.filter((t) => t.taskTypeId !== task.taskTypeId),
      { ...task, start: nowUnix(), end: null },
    ];

    reduxDispatch({
      type: 'CLOCKING_ADD_CURRENT_TASK',
      payload: {
        ...task,
        id: task.id!,
        start: nowUnix(),
        end: undefined,
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              mobile: true,
              os: Platform.OS,
              tasks: map([...tasks], (task) =>
                pick(task, ['start', 'end', 'edited', 'taskTypeId', 'shiftTaskId', 'flowsResponse']),
              ),
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    setFoundTask(
      currentClocking && currentClocking.tasks
        ? currentClocking.tasks.find((t) => t.taskTypeId == task.taskTypeId)
        : null,
    );
  }, [currentClocking]);

  if (task.end && task.start && foundTask && foundTask.start && foundTask.end) {
    return (
      <View style={styles.container}>
        <View style={styles.subContainer}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <SIText style={{ flexGrow: 1 }}>{(task as any).task ? (task as any).task.name : task.name}</SIText>
            <SIText style={{ fontSize: 11 }}>{`${moment.unix(foundTask.start!).format('HH:mm')} - ${moment
              .unix(foundTask.end!)
              .format('HH:mm')}`}</SIText>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={styles.subContainer}>
          <View>
            <SIText>{(task as any).task ? (task as any).task.name : task.name}</SIText>
            <SIText style={{ fontSize: 11 }}>
              {`${moment.unix(task.start!).format('HH:mm')} - `}
              {task.end ? `${moment.unix(task.end!).format('HH:mm')}` : t('CLOCKING.ONGOING')}
            </SIText>
          </View>
          <View>
            {!foundTask ? (
              <SIButton
                title="Start"
                backgroundColor="white"
                color={colors.green}
                borderColor={colors.green}
                onPress={onStartShiftTask}
              />
            ) : (
              <View>
                {foundTask.start && (
                  <SIText style={{ fontSize: 11, color: colors.grey }}>
                    {moment.unix(foundTask.start!).format('HH:mm')} - __:__
                  </SIText>
                )}
                <SIButton
                  title={t('GENERAL.DONE')}
                  backgroundColor="white"
                  color={colors.red}
                  borderColor={colors.red}
                  onPress={onEndTask}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    borderBottomColor: colors.greyLight,
    borderBottomWidth: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  subContainer: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightActions: {
    alignContent: 'flex-end',
    justifyContent: 'center',
  },
  rightActionsText: {
    fontFamily: 'Poppins_700Bold',
    color: 'white',
    padding: 20,
  },
});

export default Task;
