import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { shallowEqual, useSelector } from 'react-redux';
import H1 from '../../../components/H1';
import Icon from '../../../components/Icon';
import NotificationBadge from '../../../components/NotificationBadge';
import SIText from '../../../components/SIText';
import { InitialState } from '../../../store';
import colors from '../../../styles/colors';
import { IIndicator } from '../../../types/indicators';
import { IUserDetails } from '../../../types/userDetails.model';
import Indicators from './Indicators';
import Shortcuts from './Shortcuts';

interface Props {
  isOnline: boolean;
  userDetails: null | IUserDetails;
  indicators?: IIndicator[];
  withoutShortcuts?: boolean;
}

const Overview: React.FC<Props> = ({ isOnline, userDetails, indicators, withoutShortcuts }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const navigation = useNavigation();

  return (
    <View style={[styles.container, { backgroundColor: isOnline ? colors.blueExtraLight : colors.red }]}>
      <View style={styles.top}>
        <H1 style={[styles.hello, { color: isOnline ? '#000' : '#fff' }]}>
          {t('GENERAL.HELLO')} {userDetails?.firstname || ''}
        </H1>
        <TouchableOpacity onPress={() => navigation.navigate('Me')}>
          {!!userDetails?.picture && (
            <Image
              style={styles.avatar}
              source={{
                uri: userDetails?.picture,
              }}
            />
          )}
          {!userDetails?.picture && (
            <View style={[styles.avatar, styles.initials]}>
              <SIText style={{ color: 'white' }}>{userDetails?.initials}</SIText>
            </View>
          )}
        </TouchableOpacity>
      </View>
      {!withoutShortcuts && <Shortcuts isOnline={isOnline} />}
      {isOnline && indicators && <Indicators indicators={indicators} />}
      {!isOnline && <SIText style={styles.offline}>{t('OFFLINE.YOU_ARE_OFFLINE')}</SIText>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.green,
    padding: 25,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hello: {
    fontSize: 25,
    lineHeight: 34,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  initials: {
    backgroundColor: colors.blue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offline: {
    color: 'white',
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 18,
  },
});

export default Overview;
