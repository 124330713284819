import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';

interface Props {
  style?: StyleProp<TextStyle>;
  children: string;
}

const H1: React.FC<Props> = ({ style = {}, children }) => <Text style={[styles.H1, style]}>{children}</Text>;

const styles = StyleSheet.create({
  H1: {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: 'Poppins_700Bold',
    textAlign: 'center',
  },
});

export default H1;
