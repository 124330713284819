import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import FormData from 'form-data';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { showMessage } from 'react-native-flash-message';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../components/Back';
import Checkbox from '../components/Checkbox';
import DateInput from '../components/DateInput';
import Pictures from '../components/Pictures';
import SIButton from '../components/SIButton';
import Select from '../components/Select';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IDayoff } from '../types/dayoff.model';

const defaultValues = () => {
  const date = moment().startOf('day').toDate();
  const startDate = moment().startOf('day').set('hour', 9).toDate();

  const endDate = moment().startOf('day').set('hour', 17).toDate();

  return {
    start: startDate,
    end: endDate,
    date,
  };
};

const ShiftRequestForHolidaysScreen: React.FC = () => {
  const { handleSubmit, control, watch, setValue, errors, reset } = useForm({
    defaultValues: defaultValues(),
  });
  const reduxDispatch: Dispatch<any> = useDispatch();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dayoffTypes, setDayoffTypes] = useState<IDayoff[]>();

  const { access_vacation_requests_departments } = useSelector((store: InitialState) => store.data);
  const holidayState = useSelector((store: InitialState) => store.holiday);

  const watchDepartmentId = watch('departmentId', null);
  const watchFullDay = watch('fullDay', null);
  const watchDate = watch('date', null);
  const watchStart = watch('start', null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [markedDates, setMarkedDates] = useState<any>({});
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  useFocusEffect(
    useCallback(() => {
      if (
        !watchDepartmentId &&
        access_vacation_requests_departments &&
        access_vacation_requests_departments.length === 1
      ) {
        setTimeout(() => {
          setValue('departmentId', access_vacation_requests_departments[0].id);
        }, 0);
      }
    }, []),
  );

  useEffect(() => {
    if (watchDate) {
      const startDate = moment(watchDate).startOf('day').set('hour', 8).toDate();
      const endDate = moment(watchDate).startOf('day').set('hour', 8).toDate();

      setValue('start', startDate);
      setValue('end', endDate);
    }
  }, [watchDate]);

  useEffect(() => {
    if (watchFullDay) {
      const startDate = moment(watchDate).startOf('day').toDate();
      setValue('start', startDate);

      const endDate = moment(watchDate).endOf('day').toDate();
      setValue('end', endDate);
    }
  }, [watchFullDay]);

  useEffect(() => {
    if (watchDepartmentId) {
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/dayoffs`,
        {
          params: {
            departmentId: watchDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          const { dayoffs } = response.data;
          setDayoffTypes(dayoffs.filter((x: IDayoff) => x.displayUser));
          setIsLoading(false);
        })
        .catch((error) => {
          setDayoffTypes([]);
          setIsLoading(false);
          if (!Axios.isCancel(error)) {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
            }
          }
        });

      return () => {
        cancelTokenSource.cancel();
      };
    } else {
      setDayoffTypes([]);
    }
  }, [watchDepartmentId]);

  const onSubmit = async (data: any) => {
    setIsSaving(true);
    const { start, end, dayoffType, fullDay } = data;
    const myFormData = new FormData();

    if ((!selectedDates || selectedDates.length == 0) && fullDay) {
      showMessage({
        message: t('DAYOFFS.MUST_SELECT_ONE_DATE'),
        type: 'danger',
      });
      setIsSaving(false);
      return;
    }

    myFormData.append('departmentId', watchDepartmentId!);
    myFormData.append('dayoffType', dayoffType);
    myFormData.append('fullDay', fullDay);
    if (fullDay) {
      for (let i = 0; i < selectedDates.length; i++) {
        const element = selectedDates[i];
        myFormData.append('dates[]', element);
      }
    } else {
      myFormData.append('start', moment(start).unix());
      myFormData.append('end', moment(end).unix());
    }

    const pictures = holidayState.pictures;
    if (pictures && pictures.length) {
      const picturesLength = pictures.length;
      for (let i = 0; i < picturesLength; i++) {
        const { uri, unix } = pictures[i];
        myFormData.append('pictures[]', {
          name: `${unix}`,
          uri: Platform.OS === 'android' ? uri : uri.replace('file://', ''),
          type: 'image/jpeg',
        });
      }
    }

    myFormData.append('mobile', true);
    myFormData.append('os', Platform.OS);

    Axios({
      url: `${
        Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/vacation-requests`,
      method: 'POST',
      data: myFormData,
      headers: {
        ...Axios.defaults.headers.common,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        setIsSaving(false);
        reduxDispatch({
          type: 'HOLIDAY_RESET',
        });
        reset(defaultValues());
        navigation.navigate('Home');
        setSelectedDates([]);
        setMarkedDates({});
        showMessage({
          message: t('DAYOFFS.SENT'),
          type: 'success',
        });
      })
      .catch((err) => {
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
        setIsSaving(false);
      });
  };

  const onDayPress = (day: any) => {
    const markedDatesCopy = { ...markedDates };
    const { dateString } = day;
    const selectedDatesCopy = [...selectedDates];

    const dateIndex = selectedDatesCopy.findIndex((x) => x === dateString);
    if (~dateIndex) {
      selectedDatesCopy.splice(dateIndex, 1);
    } else {
      selectedDatesCopy.push(dateString);
    }
    selectedDatesCopy.sort();

    if (markedDates[dateString]) {
      markedDates[dateString] = undefined;
    } else {
      markedDates[dateString] = {
        selected: true,
      };
    }

    setSelectedDates(selectedDatesCopy);
    setMarkedDates(markedDates);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('DAYOFFS.REQUEST_FOR_LEAVE')} />
      <ScrollView>
        <View style={styles.container}>
          <View
            style={{
              display:
                access_vacation_requests_departments && access_vacation_requests_departments.length == 1
                  ? 'none'
                  : 'flex',
            }}
          >
            <Select
              label={t('GENERAL.ACCOUNT')}
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              name="departmentId"
              items={
                access_vacation_requests_departments &&
                access_vacation_requests_departments.map((department) => ({
                  label: department.company!,
                  value: department.id!,
                }))
              }
              errors={errors}
            />
          </View>
          {watchDepartmentId && (
            <View>
              <Select
                label={t('DAYOFFS.DAYOFF_TYPE')}
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                name="dayoffType"
                items={(dayoffTypes || []).map((dayoffType) => ({
                  label: dayoffType.name!,
                  value: dayoffType.id!,
                }))}
                loading={isLoading}
                errors={errors}
              />
              <Checkbox control={control} label={t('GENERAL.FULL_DAY')} name="fullDay" style={{ marginBottom: 15 }} />
              {watchFullDay && (
                // <View>
                //   <DateInput
                //     control={control}
                //     name="start"
                //     label={t('DAYOFFS.DATE_FROM')}
                //     rules={{ required: true }}
                //     mode={'date'}
                //     watch={watch}
                //     errors={errors}
                //   />
                //   <DateInput
                //     control={control}
                //     name="end"
                //     label={t('DAYOFFS.DATE_TO_INCLUSIVE')}
                //     rules={{
                //       required: true,
                //       validate: (value) => {
                //         return watchStart < value;
                //       },
                //     }}
                //     mode={'date'}
                //     errors={errors}
                //     watch={watch}
                //   />
                // </View>
                <View>
                  <Calendar
                    current={moment(watchDate).startOf('day').format()}
                    minDate={moment(watchDate).startOf('day').format()}
                    onDayPress={onDayPress}
                    markedDates={markedDates}
                    displayLoadingIndicator={isLoading}
                    firstDay={1}
                    markingType={'multi-dot'}
                    theme={{
                      monthTextColor: 'black',
                      textMonthFontFamily: 'Poppins_500Medium',
                      textDayFontFamily: 'Poppins_500Medium',
                      textDayHeaderFontFamily: 'Poppins_500Medium',
                      dotColor: colors.green,
                      todayTextColor: colors.green,
                      arrowColor: colors.green,
                      selectedDayBackgroundColor: colors.green,
                    }}
                  />
                </View>
              )}
              {!watchFullDay && (
                <View>
                  <DateInput
                    control={control}
                    name="date"
                    label={t('GENERAL.DATE')}
                    rules={{ required: true }}
                    mode={'date'}
                    watch={watch}
                    errors={errors}
                  />
                  <DateInput
                    control={control}
                    name="start"
                    label={t('SHIFTS.START')}
                    rules={{ required: true }}
                    mode={'time'}
                    watch={watch}
                    errors={errors}
                  />
                  <DateInput
                    control={control}
                    name="end"
                    label={t('SHIFTS.END')}
                    rules={{
                      required: true,
                      validate: (value) => {
                        return watchStart < value;
                      },
                    }}
                    mode={'time'}
                    errors={errors}
                    watch={watch}
                  />
                </View>
              )}
              {!!watchDepartmentId && (
                <Pictures
                  pictures={[...(holidayState?.pictures || [])].reverse()}
                  addActionType="HOLIDAY_ADD_PICTURE"
                  removeActionType="HOLIDAY_REMOVE_PICTURE"
                />
              )}
              <View style={{ marginTop: 15, flex: 1 }}>
                <SIButton
                  loading={isSaving}
                  title={t('DAYOFFS.SEND_MY_REQUEST')}
                  size="large"
                  onPress={handleSubmit(onSubmit)}
                />
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: '#fff',
    margin: 25,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
});

export default ShiftRequestForHolidaysScreen;
