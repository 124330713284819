import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import colors from '../styles/colors';
import { IShift } from '../types/shift.model';
import H1 from './H1';
import PublicShiftCard from './PublicShiftCard';
import ShiftCard, { styles as shiftCardStyles } from './ShiftCard';
import SIText from './SIText';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  title?: string;
  style?: StyleProp<TextStyle>;
  shifts: IShift[];
  noDataTitle?: string;
  noDataMessage?: string;
  publicMode?: boolean;
  dates?: boolean;
}

const ShiftCards: React.FC<Props> = ({ title, style, shifts, noDataTitle, noDataMessage, publicMode, dates }) => {
  const timestamp = moment().unix();
  const { t } = useTranslation(undefined, { useSuspense: false });

  if (!noDataTitle) {
    noDataTitle = title;
  }

  if (shifts.length) {
    if (publicMode) {
      return (
        <View style={[style, styles.viewContainerMain]}>
          {title && <H1 style={{ textAlign: 'left', width: '100%', marginBottom: 10 }}>{title}</H1>}
          {shifts.map((shift) => (
            <PublicShiftCard key={`shift_${shift.id!}`} shift={shift} dates={dates} />
          ))}
        </View>
      );
    } else {
      return (
        <View style={[style, styles.viewContainerMain]}>
          {title && <H1 style={{ textAlign: 'left', width: '100%', marginBottom: 10 }}>{title}</H1>}
          {shifts.map((shift) => (
            <ShiftCard key={`shift_${shift.id!}`} shift={shift} timestamp={timestamp} />
          ))}
        </View>
      );
    }
  } else {
    return (
      <View style={[style, styles.viewContainerMain]}>
        {noDataTitle && <H1 style={{ textAlign: 'left', width: '100%', marginBottom: 10 }}>{noDataTitle}</H1>}
        {!noDataMessage && <SIText style={{ color: colors.grey }}>{t('GENERAL.NO_DATA')}</SIText>}
        {noDataMessage && (
          <View style={shiftCardStyles.shiftContainer}>
            <SIText style={{ color: colors.grey }}>{noDataMessage}</SIText>
          </View>
        )}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  viewContainerMain: {
    paddingTop: 20,
  },
});

export default ShiftCards;
