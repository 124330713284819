import axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import H1 from '../../components/H1';
import Select from '../../components/Select';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IShyftsMarketApplication } from '../../types/shyfts-market/application.model';
import ModalConfirmDeleteShyftsMarketApplication from './ModalConfirmDeleteShyftsMarketApplication';

interface Props {}

const ShyftsMarketPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { shyfts_market_departments } = useSelector((store: InitialState) => store.data);
  const { handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues: {
      departmentId: null,
    },
  });
  const watchDepartment = watch('departmentId', null);
  const [loadingApplications, setLoadingApplications] = useState<boolean>(false);
  const [upcomingApplications, setUpcomingApplications] = useState<IShyftsMarketApplication[]>([]);
  const [historyApplications, setHistoryApplications] = useState<IShyftsMarketApplication[]>([]);
  const [activeApplication, setActiveApplication] = useState<IShyftsMarketApplication | null>(null);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);

  const activeDepartment =
    shyfts_market_departments && shyfts_market_departments.length == 1
      ? shyfts_market_departments[0]
      : watchDepartment
      ? shyfts_market_departments.find((department) => department.id == watchDepartment)
      : null;

  useEffect(() => {
    getApplications();
  }, [watchDepartment]);

  const getApplications = () => {
    if (shyfts_market_departments && shyfts_market_departments.length !== 1 && watchDepartment == null) {
      return;
    }
    setLoadingApplications(true);
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/shyfts-market/user/applications`,
        {
          params: {
            departmentId: shyfts_market_departments.length === 1 ? shyfts_market_departments[0].id : watchDepartment,
          },
        },
      )
      .then(({ data }) => {
        const upcomingApplications: IShyftsMarketApplication[] = [];
        const historyApplications: IShyftsMarketApplication[] = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          const startdate = moment(element.shift.startdate);

          if (
            startdate.isAfter(moment.now()) &&
            !element.user_deleted_at &&
            !element.admin_denied_at &&
            element.shyft_market.status != 'SUCCESS'
          ) {
            upcomingApplications.push(element);
          } else {
            historyApplications.push(element);
          }
        }

        setUpcomingApplications(upcomingApplications);
        setHistoryApplications(historyApplications);
        setLoadingApplications(false);
      })
      .catch((err) => {
        console.log(err);
        showMessage({
          message: t('SHYFTS_MARKET.GET_ERROR'),
          type: 'danger',
        });
        setLoadingApplications(false);
      });
  };

  const onWantToDeleteApplication = (application: any) => {
    setActiveApplication(application);
    setModalDeleteVisible(true);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('SHYFTS_MARKET.TITLE')} />
      <ScrollView style={styles.container}>
        {shyfts_market_departments && shyfts_market_departments.length > 1 && (
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={
              shyfts_market_departments &&
              shyfts_market_departments.map((department) => ({
                label: department.company!,
                value: department.id!,
              }))
            }
            style={{ backgroundColor: 'white' }}
          />
        )}
        {(watchDepartment || (shyfts_market_departments && shyfts_market_departments.length == 1)) &&
        (upcomingApplications.length > 0 || historyApplications.length > 0) ? (
          <>
            <View style={styles.title}>
              <H1>{t('SHYFTS_MARKET.UPCOMING_APPLICATIONS')}</H1>
            </View>
            {upcomingApplications.length > 0 ? (
              <>
                {upcomingApplications.map((application) => (
                  <View key={application.id} style={styles.shift}>
                    <View style={{ padding: 25 }}>
                      <SIText>{moment(application.shift.startdate).format('DD/MM/YYYY')} </SIText>
                      <SIText>
                        {moment(application.shift.startdate).format('HH:mm')} -{' '}
                        {moment(application.shift.enddate).format('HH:mm')}
                      </SIText>
                      <SIButton
                        backgroundColor={colors.red}
                        title={t('SHYFTS_MARKET.REMOVE_APPLICATION')}
                        onPress={() => onWantToDeleteApplication(application)}
                        style={{ marginTop: 15 }}
                      />
                    </View>
                  </View>
                ))}
              </>
            ) : (
              <SIText style={{ textAlign: 'center', marginBottom: 15 }}>
                {t('SHYFTS_MARKET.NO_UPCOMING_APPLICATIONS')}
              </SIText>
            )}
            <View style={styles.applications_history_title}>
              <H1>{t('SHYFTS_MARKET.APPLICATIONS_HISTORY')}</H1>
            </View>
            {historyApplications.map((application) => (
              <View key={application.id} style={styles.shift}>
                <View style={{ padding: 25 }}>
                  <View style={styles.top}>
                    <View>
                      <SIText>{moment(application.shift.startdate).format('DD/MM/YYYY')}</SIText>
                      <SIText>
                        {moment(application.shift.startdate).format('HH:mm')} -{' '}
                        {moment(application.shift.enddate).format('HH:mm')}
                      </SIText>
                    </View>
                    {application.admin_denied_at && (
                      <SIText style={{ color: colors.red, fontSize: 11 }}>
                        {t('SHYFTS_MARKET.APPLICATION_STATUSES.DENIED')}
                      </SIText>
                    )}
                    {application.user_deleted_at && (
                      <SIText style={{ color: colors.red, fontSize: 11 }}>
                        {t('SHYFTS_MARKET.APPLICATION_STATUSES.DELETED_APPLICATION')}
                      </SIText>
                    )}
                    {!application.admin_denied_at &&
                      !application.user_deleted_at &&
                      application.shyft_market.status == 'SUCCESS' &&
                      application.shyft_market.select_rrid == activeDepartment?.userRecordId && (
                        <SIText style={{ color: colors.green, fontSize: 11 }}>
                          {t('SHYFTS_MARKET.APPLICATION_STATUSES.ACCEPTED')}
                        </SIText>
                      )}
                    {!application.admin_denied_at &&
                      !application.user_deleted_at &&
                      application.shyft_market.status == 'DELETED' && (
                        <SIText style={{ color: colors.red, fontSize: 11 }}>
                          {t('SHYFTS_MARKET.APPLICATION_STATUSES.DELETED')}
                        </SIText>
                      )}
                    {!application.admin_denied_at &&
                      !application.user_deleted_at &&
                      application.shyft_market.status == 'PENDING' &&
                      moment(application.shift.startdate).isBefore(moment.now()) && (
                        <SIText style={{ color: colors.orange, fontSize: 11 }}>
                          {t('SHYFTS_MARKET.APPLICATION_STATUSES.EXPIRED')}
                        </SIText>
                      )}
                    {!application.admin_denied_at &&
                      !application.user_deleted_at &&
                      application.shyft_market.status == 'SUCCESS' &&
                      application.shyft_market.select_rrid != activeDepartment?.userRecordId && (
                        <SIText style={{ color: colors.red, fontSize: 11 }}>
                          {t('SHYFTS_MARKET.APPLICATION_STATUSES.NOT_SELECTED')}
                        </SIText>
                      )}
                  </View>
                </View>
              </View>
            ))}
          </>
        ) : (
          <>
            {loadingApplications ? (
              <ActivityIndicator size="large" color={colors.green} />
            ) : (
              <>
                <SIText style={{ textAlign: 'center', marginBottom: 15 }}>
                  {t('SHYFTS_MARKET.NO_UPCOMING_APPLICATIONS')}
                </SIText>
              </>
            )}
          </>
        )}
      </ScrollView>
      <ModalConfirmDeleteShyftsMarketApplication
        application={activeApplication}
        visible={modalDeleteVisible}
        setVisible={setModalDeleteVisible}
        getApplications={getApplications}
        activeDepartment={activeDepartment}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 25,
    backgroundColor: colors.blueExtraLight,
  },
  title: { alignItems: 'flex-start', marginBottom: 25 },
  shift: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginBottom: 15,
  },
  applications_history_title: {
    marginTop: 10,
    alignItems: 'flex-start',
    marginBottom: 25,
  },
  top: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default ShyftsMarketPage;
