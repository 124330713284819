import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from './Icon';
import SIText from './SIText';

interface Props {
  title: string;
  icons?: any;
  color?: string;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Back: React.FC<Props> = ({ title, icons, color, setLoading }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onPress = () => {
    if (setLoading) {
      setLoading(false);
    }
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.title} onPress={onPress}>
        <Icon name="left-circled" size={24} style={{ color: color || '#000' }} />
        <SIText style={[styles.title_text, { color }]}>{title}</SIText>
      </TouchableOpacity>
      {icons && <View style={styles.icons}>{icons}</View>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 25,
    marginBottom: 0,
    justifyContent: 'space-between',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title_text: {
    fontSize: 16,
    lineHeight: 22,
    marginLeft: 15,
  },
  icon: {
    paddingRight: 15,
  },
  icons: {},
});

export default Back;
