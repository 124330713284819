import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import Constants from 'expo-constants';
import { showMessage } from 'react-native-flash-message';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import SIText from '../../../components/SIText';
import moment from 'moment';
import colors from '../../../styles/colors';
import Icon from '../../../components/Icon';
import { ISentSwapShift } from '../../../types/swap-shift/sent-swap-shift.model';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '../../../store';
import Tag from '../../../components/Tag';
import { getContrast } from '../../../utils';

interface Props {
  departmentId?: string | null;
  row: ISentSwapShift;
}

const SwapShift: React.FC<Props> = ({ departmentId, row }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dataState = useSelector((store: InitialState) => store.data);
  const reduxDispatch: Dispatch<any> = useDispatch();

  const onAccept = () => {
    if (departmentId) {
      axios
        .patch(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/swap-shift/${row.swapShift.id}/accept`,
          null,
          {
            params: {
              departmentId,
            },
          },
        )
        .then(({ data }) => {
          showMessage({
            message: t('SHIFTS.SWAP_SHIFT.ACCEPTED_MESSAGE'),
            type: 'success',
          });
          reduxDispatch({
            type: 'DATA_SET_SWAP_SHIFTS_REQUESTS',
            payload: dataState.swap_shift_requests - 1,
          });
        })
        .catch((err) => {
          if (err.response?.data?.errors) {
            if (Object.values(err.response.data.errors)[0]) {
              showMessage({
                message: Object.values(err.response.data.errors)[0] as string,
                type: 'danger',
              });
            }
          } else {
            showMessage({
              message: t('SHIFTS.SWAP_SHIFT.ACCEPT_ERROR'),
              type: 'danger',
            });
          }
        });
      navigation.navigate('Home');
    }
  };

  const onDeny = () => {
    if (departmentId) {
      axios
        .patch(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/swap-shift/${row.swapShift.id}/deny`,
          null,
          {
            params: {
              departmentId,
            },
          },
        )
        .then(({ data }) => {
          showMessage({
            message: t('SHIFTS.SWAP_SHIFT.DENIED_MESSAGE'),
            type: 'success',
          });
          reduxDispatch({
            type: 'DATA_SET_SWAP_SHIFTS_REQUESTS',
            payload: dataState.swap_shift_requests - 1,
          });
        })
        .catch((err) => {
          if (err.response?.data?.errors) {
            if (Object.values(err.response.data.errors)[0]) {
              showMessage({
                message: Object.values(err.response.data.errors)[0] as string,
                type: 'danger',
              });
            }
          } else {
            showMessage({
              message: t('SHIFTS.SWAP_SHIFT.DENY_ERROR'),
              type: 'danger',
            });
          }
        });
      navigation.navigate('Home');
    }
  };

  const right = () => {
    const { admin_approved_at, user_approved_at, user_denied_at } = row.swapShift;
    if (admin_approved_at || user_approved_at || user_denied_at) {
      let background = '#000';
      let status = t('SHIFTS.SWAP_SHIFT.STATUSES.NO_STATUS');
      if (admin_approved_at) {
        background = colors.green;
        status = t('SHIFTS.SWAP_SHIFT.STATUSES.APPROVED_BY_ADMIN');
      }

      if (user_approved_at) {
        background = colors.orange;
        status = t('SHIFTS.SWAP_SHIFT.STATUSES.WAITING_FOR_ADMIN');
      }

      if (user_denied_at) {
        background = colors.red;
        status = t('SHIFTS.SWAP_SHIFT.STATUSES.YOU_DENIED');
      }

      return (
        <View style={{ flex: 3, marginRight: -5, alignItems: 'flex-end' }}>
          <Tag backgroundColor={background} color={'#fff'}>
            {status}
          </Tag>
        </View>
      );
    } else {
      return (
        <View style={styles.actions_container}>
          <TouchableOpacity style={[styles.button, { backgroundColor: colors.green }]} onPress={onAccept}>
            <SIText style={styles.action_text}>
              <Icon name="ok" size={14} />
            </SIText>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.button, { backgroundColor: colors.red }]} onPress={onDeny}>
            <SIText style={styles.action_text}>
              <Icon name="trash-empty" size={14} />
            </SIText>
          </TouchableOpacity>
        </View>
      );
    }
  };

  return (
    <View style={styles.swap_shift_container}>
      <View>
        <SIText>
          {row.swapShift.profile.name || `${row.swapShift.profile.first_name} ${row.swapShift.profile.last_name}`}
        </SIText>
        {!departmentId && (
          <SIText style={{ color: colors.grey, fontSize: 11 }}>
            {dataState.access_swap_shifts_departments &&
              dataState.access_swap_shifts_departments.find(
                (department) => department.id == row.swapShift.department.id,
              )?.company}
          </SIText>
        )}
        <SIText style={{ color: colors.grey }}>{moment.unix(row.shift.start!).format('LL')}</SIText>
        <SIText style={{ color: colors.grey }}>
          {moment.unix(row.shift.start!).format('HH:mm')} - {moment.unix(row.shift.end!).format('HH:mm')}
        </SIText>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: 250 }}>
          {row.shift.section && (
            <Tag
              key={`shift_${row.shift.section.id}_dayoff_type`}
              backgroundColor={row.shift.section.background}
              color={getContrast(row.shift.section.background || '#FFF')}
            >
              {row.shift.section.name!}
            </Tag>
          )}
          {row.shift.attributes && (
            <>
              {row.shift.attributes.map((attribute) => (
                <Tag
                  key={`shift_${attribute.id}_dayoff_type`}
                  backgroundColor={attribute.background}
                  color={getContrast(attribute.background || '#FFF')}
                >
                  {attribute.name!}
                </Tag>
              ))}
            </>
          )}
          {row.shift.skills && (
            <>
              {row.shift.skills.map((skill) => (
                <Tag
                  key={`shift_${skill.id}_dayoff_type`}
                  backgroundColor={skill.background}
                  color={getContrast(skill.background || '#FFF')}
                >
                  {skill.name!}
                </Tag>
              ))}
            </>
          )}
          {row.shift.tasks && (
            <>
              {row.shift.tasks.map((task) => (
                <Tag
                  key={`shift_${task.id}_dayoff_type`}
                  backgroundColor={task.background}
                  color={getContrast(task.background || '#FFF')}
                >
                  {task.name!}
                </Tag>
              ))}
            </>
          )}
          {row.shift.comment && (
            <>
              <View style={{ width: '100%', height: 2, backgroundColor: colors.greyLight, marginVertical: 5 }}></View>
              <SIText>{row.shift.comment}</SIText>
            </>
          )}
        </View>
      </View>
      {right()}
    </View>
  );
};

const styles = StyleSheet.create({
  swap_shift_container: {
    padding: 25,
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: '#fff',
    marginBottom: 25,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
    elevation: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swap_shift_element: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  actions_container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'absolute',
    top: 15,
    right: 25,
  },
  badge: {
    marginLeft: 10,
    borderRadius: 50,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  badge_text: {
    color: '#fff',
  },
  button: {
    marginLeft: 5,
    padding: 5,
    borderRadius: 10,
    paddingHorizontal: 10,
  },
  action_text: {
    color: '#fff',
  },
});

export default SwapShift;
