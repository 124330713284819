import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { KeyboardType, StyleProp, StyleSheet, TextInput, TextStyle, View } from 'react-native';
import colors from '../styles/colors';
import Icon from './Icon';
import SIText from './SIText';

interface Props {
  label?: string;
  title?: string;
  control: Control<Record<string, any>>;
  name: string;
  rules?: {
    required?: boolean;
  };
  placeholder?: string;
  maxLength?: number;
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue: string | null;
  keyboardType?: KeyboardType;
  style?: StyleProp<TextStyle>;
  editable?: boolean;
  parent?: string;
}

const InputController: React.FC<Props> = ({
  label,
  title,
  control,
  name,
  rules,
  placeholder,
  defaultValue,
  maxLength,
  keyboardType = 'default',
  errors = {},
  editable = true,
  style,
  parent,
}) => {
  const error =
    errors &&
    (parent
      ? errors[parent]?.find((el: any) => el?.type === 'required' && el['ref'].name == name)
      : errors[name]?.type === 'required');
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return (
          <View>
            <View style={{ flexDirection: 'row' }}>
              {title && <SIText style={error ? styles.red : null}>{title}</SIText>}
            </View>
            <View
              style={[
                styles.inputContainer,
                error ? { borderColor: 'red' } : null,
                !editable ? { backgroundColor: '#F9F9F9' } : null,
                style,
              ]}
            >
              {label && (
                <View style={styles.label_container}>
                  {rules?.required && <SIText style={error ? styles.red : null}>* </SIText>}
                  <SIText style={styles.label}>{label}</SIText>
                  {!editable && <Icon name="lock" color={colors.grey} />}
                </View>
              )}
              <View style={styles.content}>
                <TextInput
                  keyboardType={keyboardType}
                  underlineColorAndroid="transparent"
                  placeholder={placeholder}
                  onChangeText={onChange}
                  maxLength={maxLength}
                  value={value}
                  placeholderTextColor="#cccccc"
                  editable={editable}
                  style={{
                    color: editable ? '#000' : colors.grey,
                    flex: 1,
                    paddingTop: 25,
                    marginTop: -25,
                  }}
                />
              </View>
            </View>
          </View>
        );
      }}
    />
  );
};

const Input: React.FC<Props> = (props) => {
  const { label, title, name, rules, errors = {} } = props;
  if (label) {
    const error = errors && errors[name]?.type === 'required';
    return (
      <View style={props.style}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {/* {title && <SIText style={error ? styles.red : null}>{label}</SIText>} */}
          {/* {rules?.required && <SIText style={error ? styles.red : null}> *</SIText>} */}
        </View>
        <InputController {...props} />
      </View>
    );
  } else {
    return <InputController {...props} />;
  }
};

const styles = StyleSheet.create({
  inputContainer: {
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  red: {
    color: 'red',
  },
  label_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    color: colors.grey,
    marginRight: 5,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default Input;
