import Axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import 'moment-timezone';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, SafeAreaView, StyleSheet, View } from 'react-native';
import Back from '../components/Back';
import SIText from '../components/SIText';
import colors from '../styles/colors';
import { ILog } from '../types/log.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface ItemProps {
  log: ILog;
}

const Item: React.FC<ItemProps> = ({ log }) => {
  const {
    departmentName,
    dateCreated,
    user: { name = '' },
    comment,
  } = log;
  return (
    <View style={styles.item}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
        <SIText>{departmentName}</SIText>
        <SIText style={{ fontSize: 11, color: colors.grey }}>{`${moment.unix(dateCreated).format('L HH:mm')}`}</SIText>
      </View>
      <SIText style={{ color: colors.grey }}>{name}</SIText>
      <SIText style={{ fontSize: 12 }}>{comment}</SIText>
    </View>
  );
};

const LogsScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<ILog[]>([]);

  const refreshData = () => {
    if (!isLoading) {
      setIsLoading(true);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/logs`,
      )
        .then((response) => {
          const { logs } = response.data;
          setLogs(logs);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  useLayoutEffect(() => {
    refreshData();
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('GENERAL.LOGS')} />
      <View style={{ backgroundColor: colors.blueExtraLight, flex: 1, padding: 25 }}>
        <FlatList
          contentContainerStyle={styles.contentContainerStyle}
          ItemSeparatorComponent={() => (
            <View style={{ flex: 1, height: 1, backgroundColor: colors.blueExtraLight, marginLeft: 10 }}></View>
          )}
          data={logs}
          renderItem={({ item }) => <Item log={item} />}
          refreshControl={
            <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
          }
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'white',
    padding: 25,
    flex: 1,
    borderRadius: 10,
    marginBottom: 25,
  },
  contentContainerStyle: {
    backgroundColor: colors.blueExtraLight,
  },
});

export default LogsScreen;
