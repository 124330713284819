import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import { TFunction } from 'i18next';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, RefreshControl, SafeAreaView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../components/Back';
import Icon from '../components/Icon';
import SISectionList from '../components/SISectionList';
import SIText from '../components/SIText';
import Tag from '../components/Tag';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IDepartment } from '../types/department.model';
import { IShift } from '../types/shift.model';
import { IUser } from '../types/user.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface ItemProps {
  shift: IShift;
  t: TFunction;
}

const Item: React.FC<ItemProps> = ({ shift, t }) => {
  const navigation = useNavigation();
  const {
    id,
    user,
    skills,
    start,
    end,
    tasks,
    section,
    dayoff,
    clocking,
    dimona,
    dimonaQualify,
    dimonaValid,
    shyftType,
    attributes,
  } = shift;
  const displayName = user?.displayName;
  const { access_shifts_departments, departments } = useSelector((state: InitialState) => state.data);

  const department = departments.find((department) => department.id == shift.departmentId);

  const onPress = () => {
    const can_edit_shift =
      access_shifts_departments &&
      access_shifts_departments.find(
        (department) => department.role !== 'USER' && department.id === shift.departmentId,
      );
    if (can_edit_shift && shift.shyftType == 1 && shift.userRecordId !== null) {
      navigation.navigate('CreateNewShift', { shift });
    }
  };

  return (
    <View
      style={{
        padding: 10,
        marginHorizontal: 10,
        backgroundColor: id ? (clocking ? colors.greenExtraLight : '#fff') : colors.redExtraLight,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {department &&
              department.features &&
              department.features.find((feature) => feature.code == 'dimona') &&
              dimona &&
              dimonaQualify &&
              !dimonaValid && <Icon name="attention" size={12} color={colors.red} style={{ marginRight: 5 }} />}
            <SIText style={{ fontSize: 11 }}>{displayName}</SIText>
          </View>
          {(section || skills || attributes) && (
            <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', paddingTop: 4 }}>
              {section && (
                <Tag backgroundColor={section.background} color={section.color}>
                  {section.name!}
                </Tag>
              )}
              {skills?.map((skill) => (
                <Tag key={`shift_${id}_skill_${skill.id}`} backgroundColor={skill.background} color={skill.color}>
                  {skill.name!}
                </Tag>
              ))}
              {attributes?.map((attribute) => (
                <Tag
                  key={`shift_${id}_attribute_${attribute.id}`}
                  backgroundColor={attribute.background}
                  color={attribute.color}
                >
                  {attribute.name!}
                </Tag>
              ))}
            </View>
          )}
        </View>
        <View style={{ justifyContent: 'space-between' }}>
          {!!id && dayoff && (
            <SIText style={{ fontSize: 11, textAlign: 'right', color: colors.grey }}>{dayoff.name}</SIText>
          )}
          {!!id && dayoff && dayoff?.fullDay && (
            <SIText style={{ fontSize: 11, textAlign: 'right' }}>{t('GENERAL.FULL_DAY')}</SIText>
          )}
          {!!id && !dayoff?.fullDay && shyftType !== 3 && (
            <SIText style={{ fontSize: 11, textAlign: 'right' }}>{`${
              start ? moment.unix(start).format('HH:mm') : '__:__'
            } - ${end ? moment.unix(end).format('HH:mm') : '__:__'}`}</SIText>
          )}
          {!!id && shyftType == 3 && (
            <View
              style={{
                backgroundColor: '#f4f4f4',
                borderWidth: 1,
                borderColor: '#d9d9d9',
                paddingVertical: 2,
                paddingHorizontal: 5,
                borderRadius: 5,
              }}
            >
              <SIText
                style={{
                  fontSize: 10,
                  textAlign: 'right',
                  color: '#8c8c8c',
                }}
              >
                {t('GENERAL.UNAVAILABILITY_SHORT')}
              </SIText>
              {moment.unix(start!).format('HH:mm') !== '00:00' && moment.unix(end!).format('HH:mm') !== '23:59' && (
                <SIText style={{ fontSize: 10, textAlign: 'right', color: '#8c8c8c' }}>
                  {moment.unix(start!).format('HH:mm')} - {moment.unix(end!).format('HH:mm')}
                </SIText>
              )}
            </View>
          )}
        </View>
      </View>
      {/* {active && (
        <View>
          {!!tasks?.length && (
            <React.Fragment>
              <View style={{ marginTop: 6 }}>
                {tasks?.map((task) => (
                  <View
                    key={`shift_${shift.id}_task_${task.id}`}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <SIText style={styles.tasks}>{task.name}</SIText>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <SIText style={styles.tasks}>{`${moment.unix(task.start!).format('HH:mm')}`}</SIText>
                      <SIText style={styles.tasks}>{` - ${moment.unix(task.end!).format('HH:mm')}`}</SIText>
                    </View>
                  </View>
                ))}
              </View>
            </React.Fragment>
          )}
        </View>
      )} */}
    </View>
  );
};

const TomorrowScreen: React.FC = () => {
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { access_shifts_departments } = useSelector((store: InitialState) => store.data);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useFocusEffect(
    useCallback(() => {
      setDepartments([]);
      refresh();
    }, []),
  );

  const refresh = () => {
    if (access_shifts_departments) {
      setDepartments([]);
      if (access_shifts_departments.length > 1) {
        access_shifts_departments.forEach((department) => {
          refreshData(department.id);
        });
      } else {
        refreshData(access_shifts_departments[0].id);
      }
    }
  };

  const refreshData = (departmentId: string | undefined) => {
    if (!isLoading) {
      setIsLoading(true);
      const cancelTokenSource = Axios.CancelToken.source();

      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts`,
        {
          params: {
            departmentId,
            startDate: moment().add(1, 'day').startOf('day').unix(),
            endDate: moment().add(1, 'day').endOf('day').unix(),
            forceClocking: true,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          const { departments, users, shifts }: { departments: IDepartment[]; users: IUser[]; shifts: IShift[] } =
            response.data;
          const departmentsMap = new Map<string, IDepartment>();
          const usersMap = new Map<string, IUser>();

          for (let i = 0; i < departments.length; i++) {
            const department = departments[i];
            department.shifts = [];
            departmentsMap.set(department.id!, department);
          }

          for (let i = 0; i < users.length; i++) {
            const user = users[i];
            usersMap.set(user.recordId!, user);
          }

          for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const department = departmentsMap.get(shift.departmentId!);
            const userRecordId = shift.userRecordId;

            if (userRecordId) {
              const user = usersMap.get(shift.userRecordId!);
              shift.user = user;
              department?.shifts?.push(shift);
            }
          }
          setDepartments((prev) => [...prev, ...departments]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!Axios.isCancel(error)) {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
            }
          }
          console.log(error);
        });
    }
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('GENERAL.TOMORROW')} />
      <View style={styles.container}>
        <SISectionList
          refreshControl={
            <RefreshControl onRefresh={refresh} refreshing={isLoading} style={{ flexDirection: 'column' }} />
          }
          sections={departments.map((department) => ({ title: department.company!, data: department.shifts || [] }))}
          renderItem={({ item }) => <Item shift={item} t={t} />}
          keyExtractor={(item, index) => `${index}`}
          headerStyle={{ backgroundColor: '#fff' }}
          contentContainerStyle={{
            flexGrow: 0,
            backgroundColor: '#fff',
            borderRadius: 10,
            paddingBottom: 5,
          }}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    paddingHorizontal: 20,
    flex: 1,
  },
  tasks: {
    fontSize: 11,
    lineHeight: 22,
    color: colors.grey,
  },
});

export default TomorrowScreen;
