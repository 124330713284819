import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { BarCodeScanningResult, Camera } from 'expo-camera';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Alert,
  BackHandler,
  Dimensions,
  Linking,
  Platform,
  SafeAreaView,
  ScaledSize,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Svg, { Defs, Mask, Path, Rect } from 'react-native-svg';
import Back from '../components/Back';
import H1 from '../components/H1';
import Icon from '../components/Icon';
import SIButton from '../components/SIButton';
import SIText from '../components/SIText';
import colors from '../styles/colors';
import { IClocking, IScanCode } from '../types/clocking.model';
import { IDepartment } from '../types/department.model';

type NavigationParams = {
  ScanQrCode: {
    scanCodes: IScanCode[];
    destination: string;
    screen?: string;
    callbackView?: string;
    callbackScreen?: string;
    cancelPopUp?: { title: string; message: string; ok: string };
    cancelText?: string;
    activeDepartment?: IDepartment | null;
    customClocking?: IClocking | null;
    onSuccess?: (clocking: IClocking) => void;
  };
};

type Props = BottomTabScreenProps<NavigationParams, 'ScanQrCode'>;

const ScanQrCodeScreen: React.FC<Props> = ({ route: { params } }) => {
  const [isScanned, setIsScanned] = useState<boolean>(false);
  const [alertIsVisible, setAlertIsVisible] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState<ScaledSize | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [hasPermission, requestPermission] = Camera.useCameraPermissions();
  const activeDepartment = params.activeDepartment;

  const navigation = useNavigation();

  useEffect(() => {
    setDimensions(Dimensions.get('window'));
  }, []);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      setIsScanned(false);
      const listener = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => {
        listener.remove();
      };
    }, []),
  );

  const handleBarCodeScanned = (barCodeEvent: BarCodeScanningResult) => {
    const { data, type } = barCodeEvent;
    const codeType = type != BarCodeScanner.Constants.BarCodeType.qr ? 'bar-code' : 'qr-code';
    const scannedCode = params.scanCodes.find((scanCode) => scanCode.value === data && scanCode.codeType === codeType);

    if (scannedCode) {
      setIsScanned(true);
      const { callbackView, callbackScreen } = params;
      if (params.screen) {
        navigation.navigate(params.destination, {
          screen: params.screen,
          params: { qrcode: scannedCode, callbackView, callbackScreen },
        });
      } else {
        if (params.onSuccess && params.customClocking) {
          const checkIn = params.customClocking.checkIn;
          const checkOut = params.customClocking.checkOut;
          if (checkIn && !checkOut) {
            params.onSuccess({
              ...params.customClocking,
              checkIn: { ...params.customClocking.checkIn, qrcode: scannedCode },
            });
          }
          if (checkOut) {
            params.onSuccess({
              ...params.customClocking,
              checkOut: { ...params.customClocking.checkOut, qrcode: scannedCode },
            });
          }
        }
        navigation.navigate(params.destination, { qrcode: scannedCode, callbackView, callbackScreen });
      }
    } else {
      if (!alertIsVisible) {
        setAlertIsVisible(true);
        if (Platform.OS === 'web') {
          const confirmation = confirm(
            `${t('CLOCKING.ALERTS.UNKNOWN_SCAN_CODE_TITLE')}.${t('CLOCKING.ALERTS.UNKNOWN_SCAN_CODE_MESSAGE')}`,
          );
          if (confirmation === true) {
            setAlertIsVisible(false);
          }
        } else {
          Alert.alert(
            t('CLOCKING.ALERTS.UNKNOWN_SCAN_CODE_TITLE'),
            t('CLOCKING.ALERTS.UNKNOWN_SCAN_CODE_MESSAGE'),
            [
              {
                text: 'Ok',
                onPress: () => {
                  setAlertIsVisible(false);
                },
              },
            ],
            { cancelable: false },
          );
        }
      }
    }
  };

  const onCancel = () => {
    const { cancelPopUp, destination } = params;
    if (cancelPopUp) {
      if (Platform.OS === 'web') {
        const confirmation = confirm(`${cancelPopUp.title}. ${cancelPopUp.message}`);
        if (confirmation === true) {
          navigation.navigate(destination, { cancelQrCode: true });
        }
      } else {
        Alert.alert(
          cancelPopUp.title,
          cancelPopUp.message,
          [
            {
              text: 'Ok',
              onPress: () => {
                navigation.navigate(destination, { cancelQrCode: true });
              },
            },
          ],
          {
            cancelable: true,
          },
        );
      }
    } else {
      navigation.goBack();
    }
  };

  const height = dimensions?.height || 0;
  const width = dimensions?.width || 0;
  const marginMask = 80;
  const strokeWidth = 10;
  const strokeColor = isScanned ? colors.green : 'white';
  const strokeCoef = 6;

  const cornerTopLeft = `M ${marginMask / 2} ${(height - width - marginMask) / 2 + width / strokeCoef} v -${
    width / strokeCoef
  } h ${width / strokeCoef}`;

  const cornerTopRight = `M ${width - marginMask / 2} ${(height - width - marginMask) / 2 + width / strokeCoef} v -${
    width / strokeCoef
  } h -${width / strokeCoef}`;

  const cornerBottomLeft = `M ${marginMask / 2} ${
    (height - width - marginMask) / 2 + width - marginMask - width / strokeCoef
  } v ${width / strokeCoef} h ${width / strokeCoef}`;

  const cornerBottomRight = `M ${width - marginMask / 2} ${
    (height - width - marginMask) / 2 + width - marginMask - width / strokeCoef
  } v ${width / strokeCoef} h -${width / strokeCoef}`;

  const onBack = () => {
    navigation.goBack();
    if (params.destination == 'ClockingCheckOut') {
      navigation.navigate('ClockingCheckOut', { removeLoading: true });
    }
  };

  if (hasPermission === null) {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <H1>{t('GENERAL.REQUESTING_CAMERA_PERMISSION')}</H1>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (hasPermission && hasPermission.granted === false && hasPermission.canAskAgain) {
    requestPermission();
  }

  if (
    activeDepartment &&
    activeDepartment.clockinParams?.qrCodeMandatory &&
    hasPermission.granted == false &&
    !hasPermission.canAskAgain &&
    params &&
    params.customClocking &&
    !params.customClocking.checkOut
  ) {
    return (
      <View>
        <Back title={t('GENERAL.BACK')} />
        <View style={{ margin: 25 }}>
          <H1>{t('CLOCKING.CAMERA_REQUIRED')}</H1>
          <SIText style={{ marginVertical: 20 }}>{t('CLOCKING.CAMERA_REQUIRED_DESCRIPTION')}</SIText>
          <SIButton
            title={t('CLOCKING.CAMERA_REQUIRED_BUTTON')}
            size="large"
            onPress={() => {
              onBack();
              Linking.openSettings();
            }}
          />
        </View>
      </View>
    );
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: 'black',
      }}
    >
      <TouchableOpacity
        style={{
          zIndex: 99,
          position: 'absolute',
          top: 25,
          left: 0,
          padding: 25,
          borderRadius: 50,
        }}
        onPress={onBack}
      >
        <Icon name="delete" size={30} color={colors.red} />
      </TouchableOpacity>
      <Camera
        onBarCodeScanned={!isScanned ? handleBarCodeScanned : undefined}
        style={StyleSheet.absoluteFillObject}
        ratio={Platform.OS == 'android' ? '16:9' : undefined}
      />
      <View style={StyleSheet.absoluteFillObject}>
        <Svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
          <Defs>
            <Mask id="mask" x="0" y="0" height={height} width="100%">
              <Rect height={height} width={width} fill="#fff" />
              <Rect
                x={marginMask / 2}
                y={(height - width - marginMask) / 2}
                height={width - marginMask}
                width={width - marginMask}
                fill="black"
              />
            </Mask>
          </Defs>
          <Rect height={height} width={width} fill="rgba(0, 0, 0, 0.5)" mask="url(#mask)" fill-opacity="0" />
          <Path d={cornerTopLeft} stroke={strokeColor} strokeWidth={strokeWidth}></Path>
          <Path d={cornerTopRight} stroke={strokeColor} strokeWidth={strokeWidth}></Path>
          <Path d={cornerBottomLeft} stroke={strokeColor} strokeWidth={strokeWidth}></Path>
          <Path d={cornerBottomRight} stroke={strokeColor} strokeWidth={strokeWidth}></Path>
        </Svg>
      </View>
      <View style={StyleSheet.absoluteFillObject}>
        <View
          style={{
            top: height / 2 + width / 2 - marginMask,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'rgba(0,0,0,0.8)',
              paddingHorizontal: 15,
              paddingVertical: 10,
              display: 'flex',
              borderRadius: 100,
            }}
          >
            <SIText style={{ color: 'white', textAlign: 'center' }}>
              {hasPermission ? t('CLOCKING.SCAN_TEXT') : t('GENERAL.NO_CAMERA_ACCESS')}
            </SIText>
          </View>
        </View>
      </View>
      {!activeDepartment?.clockinParams?.qrCodeMandatory ? (
        <View style={{ zIndex: 2, marginHorizontal: 10 }}>
          <SIButton
            title={params?.cancelText || t('GENERAL.BACK')}
            onPress={onCancel}
            backgroundColor={colors.red}
            size="normal"
          />
        </View>
      ) : (
        <View style={{ zIndex: 2, marginHorizontal: 10 }}>
          <SIButton title={t('GENERAL.CANCEL')} onPress={onBack} backgroundColor={colors.red} size="normal" />
        </View>
      )}
    </SafeAreaView>
  );
};

export default ScanQrCodeScreen;
