import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import Axios from 'axios';
import Constants from 'expo-constants';
import React, { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Platform, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../components/Back';
import Checkbox from '../components/Checkbox';
import Pictures from '../components/Pictures';
import SIButton from '../components/SIButton';
import Select from '../components/Select';
import TextArea from '../components/TextArea';
import { ActionType, InitialState } from '../store';
import colors from '../styles/colors';
import { IDayoff } from '../types/dayoff.model';
import { IDepartment } from '../types/department.model';
import { IShift } from '../types/shift.model';

type NavigationParams = {
  ShiftRequestForModifications: {
    shift: IShift;
  };
};

type Props = BottomTabScreenProps<NavigationParams, 'ShiftRequestForModifications'>;

const ShiftRequestForModificationsScreen: React.FC<Props> = ({ route: { params } }) => {
  const { departments, changes_pictures } = useSelector((state: InitialState) => state.data);
  const { handleSubmit, errors, control, watch } = useForm();
  const [dayoffTypes, setDayoffTypes] = useState<IDayoff[]>();
  const [department, setDepartment] = useState<IDepartment | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const navigation = useNavigation();

  const watchCancellationTypeRequestType = watch('cancellationType', null);

  useEffect(() => {
    const department = departments.find((department) => department.id === params.shift.departmentId!);
    setDepartment(department || null);
  }, [departments, params.shift.departmentId]);

  useEffect(() => {
    if (department?.vacationRequests) {
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);
      setDayoffTypes([]);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/dayoffs`,
        {
          params: {
            departmentId: params.shift.departmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          const { dayoffs } = response.data;
          setDayoffTypes(dayoffs.filter((x: IDayoff) => x.displayUser));
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!Axios.isCancel(error)) {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
            }
            navigation.goBack();
          }
        });
      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [department]);

  const onSubmit = (data: any) => {
    const { cancellationType, comment, fullDay } = data;
    setIsSaving(true);
    const myFormData = new FormData();

    myFormData.append('cancellationType', cancellationType);
    myFormData.append('comment', comment);
    if (fullDay) {
      myFormData.append('fullDay', fullDay);
    }
    if (params.shift?.id) {
      myFormData.append('shiftId', params.shift.id);
    }

    const pictures = changes_pictures;
    if (pictures && pictures.length) {
      const picturesLength = pictures.length;
      for (let i = 0; i < picturesLength; i++) {
        const { uri, unix } = pictures[i];
        myFormData.append('pictures[]', {
          name: `${unix}`,
          uri: Platform.OS === 'android' ? uri : uri.replace('file://', ''),
          type: 'image/jpeg',
        });
      }
    }

    Axios({
      url: `${
        Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/operations/request-shift`,
      method: 'POST',
      data: myFormData,
      headers: {
        ...Axios.defaults.headers.common,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const { pendingRequest } = response.data;
        reduxDispatch({
          type: 'DATA_UPDATE_SHIFT',
          payload: {
            ...params?.shift,
            pendingRequest: pendingRequest,
          },
        });
        setIsSaving(false);
        navigation.navigate('ShiftDetails', {
          shift: {
            ...params?.shift,
            pendingRequest: pendingRequest,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_SENDING_DATA')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_SENDING_DATA'));
        }
        setIsSaving(false);
      });
  };

  const cancellationTypes = (dayoffTypes || []).map((dayoffType) => ({
    label: dayoffType.name!,
    value: dayoffType.id!,
  }));

  cancellationTypes.unshift({
    label: t('SHIFTS.SHIFT_CANCELLATION'),
    value: 'cancelShift',
  });

  if (isLoading) {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" />
        </View>
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
        <Back title={t('SHIFTS.REQUEST_FOR_CHANGE')} />
        <ScrollView style={styles.container}>
          <View style={styles.content}>
            <Select
              label={t('SHIFTS.CANCELLATION_TYPE')}
              control={control}
              defaultValue={null}
              name="cancellationType"
              items={cancellationTypes}
              errors={errors}
              rules={{ required: true }}
            />
            {watchCancellationTypeRequestType && watchCancellationTypeRequestType !== 'cancelShift' && (
              <Checkbox control={control} label={t('GENERAL.FULL_DAY')} name="fullDay" style={{ marginBottom: 15 }} />
            )}
            <TextArea
              label={t('SHIFTS.JUSTIFY_THE_REQUEST')}
              control={control}
              name="comment"
              defaultValue={null}
              rules={{ required: true }}
              errors={errors}
            />
            <Pictures
              pictures={[...(changes_pictures || [])].reverse()}
              addActionType="DATA_ADD_PICTURE"
              removeActionType="DATA_REMOVE_PICTURE"
            />
            <View style={styles.buttons_container}>
              <SIButton
                title={t('GENERAL.CONFIRM')}
                size="large"
                style={{ marginBottom: 10 }}
                onPress={handleSubmit(onSubmit)}
                loading={isSaving}
              />
              {!isSaving && (
                <SIButton
                  backgroundColor={colors.red}
                  title={t('GENERAL.CANCEL')}
                  size="large"
                  onPress={() => navigation.goBack()}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 10,
  },
  textArea: {
    height: 100,
  },
  buttons_container: {
    marginTop: 25,
  },
});

export default ShiftRequestForModificationsScreen;
