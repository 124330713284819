import Axios from 'axios';
import Constants from 'expo-constants';
import * as Location from 'expo-location';
import { isEqual, isObject, transform } from 'lodash';
import moment from 'moment';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { IClocking } from './types/clocking.model';
import { IDepartment } from './types/department.model';
import { IShift } from './types/shift.model';

export const REFRESH_DATA_TIMEOUT = 30 * 60; // 30 minutes

export function minutesToHoursAndOrMinutes(_minutes: number): string {
  let result = '';
  const minutesAbs = Math.abs(_minutes);
  const hours = Math.trunc(minutesAbs / 60);
  const minutes = minutesAbs % 60;
  if (_minutes < 0) {
    result += '-';
  }
  if (hours) {
    result += `${hours}h`;
  }
  if (minutes) {
    result += `${minutes < 10 ? `0${minutes}` : minutes}${hours ? '' : 'm'}`;
  }
  return result;
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function setAxiosHeaders(accessToken: string | null): void {
  if (accessToken) {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    Axios.defaults.headers.common['Mobile-App-Version'] =
      Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.extra?.version;
    Axios.defaults.headers.common['Platform-OS'] = Platform.OS;
    Axios.defaults.headers.common['Platform-OS-Version'] = Platform.Version;
  } else {
    Axios.defaults.headers.common['Authorization'] = null;
    Axios.defaults.headers.common['Mobile-App-Version'] = null;
    Axios.defaults.headers.common['Platform-OS'] = null;
    Axios.defaults.headers.common['Platform-OS-Version'] = null;
  }
}

export function localDateToUnixUTC(date: Date): number {
  if (Platform.OS === 'web') {
    return moment(date).unix();
  }
  const local = moment(date);
  return moment(local.format('YYYY-MM-DD HH:mm:ss')).unix();
}

export function nowUnix(): number {
  const now = moment().local();
  return moment(now.format('YYYY-MM-DD HH:mm:ss')).unix();
}

export function generateOptimisticId(): string {
  return `_${new Date().getTime()}`;
}

export function valueForSearch(value: string): string {
  return value
    ? value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : value;
}

export function difference(object: any, base: any): any {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export const clockingFromShift = async (
  department: IDepartment,
  shift: IShift,
  userMainId: string,
): Promise<IClocking | null> => {
  try {
    const { id, departmentId, section, attributes, skills, tasks, homeworking } = shift;
    let location: Location.LocationObject | null = null;
    const enableGps = department?.clockinParams?.enableGps;

    if (enableGps) {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status === 'granted') {
        location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced });
      }
    }

    const currentClocking: IClocking = {
      shiftId: id,
      homeworking,
      departmentId,
      sectionId: section?.id,
      attributeIds: attributes ? attributes.map((attribut) => attribut.id!) : [],
      skillIds: skills ? skills.map((skill) => skill.id!) : [],
      tasks: [],
      // tasks:
      //   tasks?.map((task) => {
      //     return {
      //       ...task,
      //       task: department?.tasks?.find((_task) => _task.id === task.taskTypeId),
      //       shiftTaskId: task.id,
      //       notClocked: true,
      //     };
      //   }) || [],
      userMainId,
      checkIn: {
        start: nowUnix(),
        location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
      },
    };

    return currentClocking;
  } catch (error) {
    if (Platform.OS == 'web') {
      Sentry.Browser.captureException(error);
      Sentry.Browser.setTag('err', 'clockingFromShift');
    } else {
      Sentry.Native.captureException(error);
      Sentry.Native.setTag('err', 'clockingFromShift');
    }
    return null;
  }
};

export const getContrast = (hex: string): string => {
  if (hex === 'transparent') {
    hex = '#000';
  }

  if (hex === 'white') {
    hex = '#ffffff';
  }

  if (hex === 'black') {
    hex = '#000000';
  }

  if (hex) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  return 'black';
};

export const DIMONA_WORK_TYPES = [
  { id: 'A17', name: 'Article 17 - A17' },
  { id: 'OTH', name: 'Autre - OTH' },
  { id: 'STU', name: 'Étudiant - STU' },
  { id: 'FLX', name: 'Flexi-Job - FLX' },
  { id: 'IVT', name: 'Formation professionnelle - IVT' },
  { id: 'PMP', name: 'Mandat parlementaire - PMP' },
  { id: 'DWD', name: 'Non soumis aux cotisations - DWD' },
  { id: 'TRI', name: 'Stage de transition - TRI' },
  { id: 'STG', name: 'Stagiaire (reg.acc.trav) - STG' },
  { id: 'EXT', name: 'Travailleur occasionnel - EXT' },
];
export const DIMONA_FLX_DECLARATION_TYPES = ['range', 'day'];
export const DIMONA_EXT_DECLARATION_TYPES = ['hourly', 'daily'];
export const DIMONA_USER_TYPE = ['employee', 'worker'];
export const DIMONA_USER_STATUS = ['F1', 'F2'];

export const CONTRACTS_SALARY_PERIODS = ['0', '1', '2', '3'];
export const CONTRACTS_RENEW_PERIODS = ['DAY', 'MONTH'];
export const CONTRACTS_CREATE_DIMONA_USER_TYPES = [
  { id: 'OTH', name: 'Autre - OTH' },
  { id: 'STU', name: 'Étudiant - STU' },
  { id: 'FLX', name: 'Flexi-Job - FLX' },
];

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};
