import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import Constants from 'expo-constants';
import axios from 'axios';
import { useNavigation } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';
import colors from '../../../styles/colors';
import SIButton from '../../../components/SIButton';
import SIText from '../../../components/SIText';
import { ISwapShift } from '../../../types/swap-shift/swap-shift.model';

interface Props {
  swapShift: ISwapShift;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  loadingDelete: boolean;
  setLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDeleteSwapShift: React.FC<Props> = ({ swapShift, visible, loadingDelete, setVisible, setLoadingDelete }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onBackdropPress = () => {
    setVisible(false);
  };

  const onDelete = () => {
    setLoadingDelete(true);
    axios
      .delete(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/swap-shift/${swapShift.swapShift.id}`,
        {
          params: {
            departmentId: swapShift.swapShift.department.id,
          },
        },
      )
      .then(({ data }) => {
        setVisible(false);
        navigation.navigate('Home');
        showMessage({
          message: t('SHIFTS.SWAP_SHIFT.DELETED_MESSAGE'),
          type: 'success',
        });
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          if (Object.values(err.response.data.errors)[0]) {
            showMessage({
              message: Object.values(err.response.data.errors)[0] as string,
              type: 'danger',
            });
          }
        } else {
          showMessage({
            message: t('SHIFTS.SWAP_SHIFT.DELETE_ERROR'),
            type: 'danger',
          });
        }
        navigation.navigate('Home');
      });
  };

  return (
    <Modal animationIn={'slideInUp'} isVisible={visible} onBackdropPress={onBackdropPress}>
      <View style={styles.container}>
        <SIText style={styles.text}>{t('SHIFTS.SWAP_SHIFT.MODAL.ARE_U_SURE_TO_DELETE')}</SIText>
        <SIButton
          style={styles.button}
          backgroundColor={colors.red}
          title={t('GENERAL.REMOVE')}
          onPress={onDelete}
          loading={loadingDelete}
          disabled={loadingDelete}
        />
        <SIButton
          style={styles.button}
          backgroundColor="transparent"
          color={colors.black}
          title={t('GENERAL.CANCEL')}
          onPress={() => setVisible(false)}
          disabled={loadingDelete}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: 50,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    marginTop: 15,
  },
});

export default ModalDeleteSwapShift;
