import axios from 'axios';
import Constants from 'expo-constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import H1 from '../components/H1';
import SIButton from './SIButton';
import SIText from './SIText';
import { useSelector } from 'react-redux';
import { InitialState } from '../store';

let Modal: any;

if (Platform.OS !== 'web') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  Modal = require('react-native').Modal;
} else {
  Modal = require('modal-react-native-web');
}

interface Props {
  shiftId?: string;
  clockingId?: string;
  homeworking: boolean;
  padding?: boolean;
  afterWorkingHome: () => void;
}

const HomeWorking: React.FC<Props> = ({ shiftId, clockingId, homeworking, padding, afterWorkingHome }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dataState = useSelector((state: InitialState) => state.data);
  const [modalHomeWorkingVisible, setModalHomeWorkingVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const shift = dataState && dataState.shifts && dataState.shifts.find((shift) => shift.id == shiftId);

  const onPressHomeWorking = async () => {
    setModalHomeWorkingVisible(true);
  };

  const onConfirmHomeWorking = async () => {
    setIsSaving(true);
    axios
      .post(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/operations/homeworking`,
        {
          shiftId,
          clockingId,
          departmentId: shift?.departmentId,
        },
      )
      .then(() => {
        setIsSaving(false);
        setModalHomeWorkingVisible(false);
        afterWorkingHome();
      })
      .catch(() => {
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
        setIsSaving(false);
      });
  };

  const onRequestClose = () => {
    if (!isSaving) {
      setModalHomeWorkingVisible(false);
    }
  };

  return (
    <View style={{ paddingHorizontal: padding ? 10 : 0 }}>
      {!homeworking && (
        <View>
          <SIButton
            onPress={onPressHomeWorking}
            title={t('HOME_WORKING.BUTTON')}
            size="large"
            color={'white'}
            backgroundColor="#EFC200"
            style={styles.button}
            icon="home"
          />
          <Modal animationType="slide" transparent={true} visible={modalHomeWorkingVisible}>
            <TouchableOpacity onPressOut={onRequestClose} style={styles.centeredView}>
              <View style={styles.modalView}>
                <H1 style={{ marginBottom: 20 }}>{t('HOME_WORKING.MODAL.TITLE')}</H1>
                <SIText style={{ marginBottom: 20 }}>{t('HOME_WORKING.MODAL.DESCRIPTION')}</SIText>
                <SIButton
                  loading={isSaving}
                  width={'auto'}
                  onPress={onConfirmHomeWorking}
                  title={t('GENERAL.CONFIRM')}
                  color={'white'}
                />
              </View>
            </TouchableOpacity>
          </Modal>
        </View>
      )}
      {!!homeworking && (
        <SIButton
          style={styles.button}
          size="large"
          width={'auto'}
          disabled={true}
          title={t('HOME_WORKING.STATUS')}
          color="#C4C4C4"
          backgroundColor="#EFEFEF"
          borderColor="#D8D8D8"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
  },
  modalView: {
    display: 'flex',
    alignSelf: 'stretch',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default HomeWorking;
