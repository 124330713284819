import { useNavigation } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Touchable, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IDepartment } from '../types/department.model';
import { IShift } from '../types/shift.model';
import { minutesToHoursAndOrMinutes } from '../utils';
import SIText from './SIText';
import Tag from './Tag';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  shift: IShift;
  dates?: boolean;
}

const PublicShiftCard: React.FC<Props> = ({ shift, dates }) => {
  const navigation = useNavigation();
  const { departments, access_shifts_departments } = useSelector((state: InitialState) => state.data);
  const [department, setDepartment] = useState<IDepartment | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const colon = Localization.locale.substring(0, 2) === 'fr' ? ' :' : ':';

  useEffect(() => {
    const department = departments.find((department) => department.id === shift.departmentId!);
    setDepartment(department || null);
  }, [departments, shift.departmentId]);

  const onPress = () => {
    const can_edit_shift =
      access_shifts_departments &&
      access_shifts_departments.find(
        (department) => department.role !== 'USER' && department.id === shift.departmentId,
      );
    if (can_edit_shift && shift.shyftType == 1 && shift.userRecordId !== null) {
      navigation.navigate('CreateNewShift', { shift });
    }
  };

  useEffect(() => {
    const department = departments.find((department) => department.id === shift.departmentId!);
    setDepartment(department || null);
  }, [departments, shift.departmentId]);

  const { package: shiftPackage } = shift;
  const shiftStyle = shift.userRecordId
    ? !shift.dayoff
      ? styles.shiftContainer
      : styles.dayoffContainer
    : styles.freeShiftContainer;
  if (shiftPackage) {
    return (
      <TouchableOpacity style={shiftStyle} onPress={onPress}>
        <View style={styles.top}>
          <View>
            <SIText>{shift?.user?.displayName}</SIText>
            {dates && (
              <SIText style={{ fontSize: 12, color: colors.grey, marginBottom: 5 }}>
                {moment.unix(shift.start!).format('LL')}
              </SIText>
            )}
          </View>
          <View>
            {shift.dayoff?.name && (
              <View style={{ marginRight: -5 }}>
                <Tag key={`shift_${shift.id}_dayoff_type`} backgroundColor={colors.red} color={'#fff'}>
                  {shift.dayoff.name}
                </Tag>
              </View>
            )}
            {shift?.dayoff?.fullDay && <SIText style={styles.topRightText}>{t('GENERAL.FULL_DAY')}</SIText>}
            {!shift?.dayoff?.fullDay && (
              <SIText style={styles.topRightText}>{`${moment.unix(shift.start!).format('HH:mm')} ${t(
                'SHIFTS.HOURS_TO',
              )} ${moment.unix(shift.end!).format('HH:mm')}`}</SIText>
            )}
          </View>
        </View>
        <View style={styles.packageParentContainer}>
          <View style={[styles.packageContainer, { backgroundColor: shiftPackage.background }]}>
            <SIText style={[styles.package, { color: shiftPackage.color }]}>{shiftPackage.name}</SIText>
          </View>
        </View>
      </TouchableOpacity>
    );
  } else {
    const { start, end, pause } = shift;
    const duration = moment.duration(end! - start!, 'seconds');
    duration.subtract(pause!.unpaid!, 'seconds');
    const durationInMinutes = duration.asMinutes();

    const TopRight = !shift?.dayoff ? (
      <View>
        {!!department?.params?.showOnlyStartDate && (
          <SIText style={styles.topRightText}>
            {`${t('SHIFTS.START')}${colon} ${moment.unix(shift.start!).format('HH:mm')}`}
          </SIText>
        )}
        {!department?.params?.showOnlyStartDate && (
          <React.Fragment>
            {shift.shyftType == 3 ? (
              <View
                style={{
                  backgroundColor: '#f4f4f4',
                  borderWidth: 1,
                  borderColor: '#d9d9d9',
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                  borderRadius: 5,
                }}
              >
                <SIText
                  style={{
                    fontSize: 10,
                    textAlign: 'right',
                    color: '#8c8c8c',
                  }}
                >
                  {t('GENERAL.UNAVAILABILITY_SHORT')}
                </SIText>
                {moment.unix(start!).format('HH:mm') !== '00:00' && moment.unix(end!).format('HH:mm') !== '23:59' && (
                  <SIText style={{ fontSize: 10, textAlign: 'right', color: '#8c8c8c' }}>
                    {moment.unix(start!).format('HH:mm')} - {moment.unix(end!).format('HH:mm')}
                  </SIText>
                )}
              </View>
            ) : (
              <SIText style={styles.topRightText}>{`${moment.unix(shift.start!).format('HH:mm')} ${t(
                'SHIFTS.HOURS_TO',
              )} ${moment.unix(shift.end!).format('HH:mm')}`}</SIText>
            )}
            {shift.shyftType !== 3 && (
              <SIText style={styles.topRightText}>{`${t('SHIFTS.DURATION')}${colon} ${minutesToHoursAndOrMinutes(
                Math.round(durationInMinutes),
              )}`}</SIText>
            )}
          </React.Fragment>
        )}
      </View>
    ) : (
      <View>
        <SIText style={{ textAlign: 'right' }}>{shift?.dayoff?.name}</SIText>
        {shift?.dayoff?.fullDay && <SIText style={styles.topRightText}>{t('GENERAL.FULL_DAY')}</SIText>}
        {!shift?.dayoff?.fullDay && (
          <SIText style={styles.topRightText}>{`${moment.unix(shift.start!).format('HH:mm')} ${t(
            'SHIFTS.HOURS_TO',
          )} ${moment.unix(shift.end!).format('HH:mm')}`}</SIText>
        )}
      </View>
    );

    return (
      <TouchableOpacity style={shiftStyle} onPress={onPress}>
        <View style={styles.top}>
          <View>
            <SIText>{shift.user?.displayName || t('SHIFTS.FREE_SHIFT')}</SIText>
            {dates && (
              <SIText style={{ fontSize: 12, color: colors.grey, marginBottom: 5 }}>
                {moment.unix(shift.start!).format('LL')}
              </SIText>
            )}
          </View>
          {TopRight}
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {shift?.section && (
                <Tag
                  key={`shift_${shift.id}_section`}
                  backgroundColor={shift?.section?.background}
                  color={shift?.section?.color}
                >
                  {shift?.section.name || ''}
                </Tag>
              )}
              {shift.skills?.map((skill) => (
                <Tag key={`shift_${shift.id}_skill_${skill.id}`} backgroundColor={skill.background} color={skill.color}>
                  {skill.name || ''}
                </Tag>
              ))}
              {shift.attributes?.map((attribut) => (
                <Tag
                  key={`shift_${shift.id}_attribut_${attribut.id}`}
                  backgroundColor={attribut.background}
                  color={attribut.color}
                >
                  {attribut.name || ''}
                </Tag>
              ))}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
};
const styles = StyleSheet.create({
  shiftContainer: {
    width: '100%',
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 10,
  },
  freeShiftContainer: {
    width: '100%',
    backgroundColor: colors.greenExtraLight,
    padding: 15,
    borderRadius: 10,
    borderColor: colors.greenLight,
    borderWidth: 1,
    marginBottom: 10,
  },
  dayoffContainer: {
    width: '100%',
    backgroundColor: colors.redExtraLight,
    padding: 15,
    borderRadius: 10,
    borderColor: colors.redLight,
    borderWidth: 1,
    marginBottom: 10,
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },
  topRightText: {
    textAlign: 'right',
    fontSize: 11,
  },
  bottomText: {
    textAlign: 'left',
    fontSize: 11,
  },
  packageParentContainer: {
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  packageContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignSelf: 'flex-start',
  },
  package: {
    fontFamily: 'Poppins_700Bold',
  },
});

export { styles };
export default PublicShiftCard;
