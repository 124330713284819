import { Popover } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from '../../components/Icon';
import SIText from '../../components/SIText';
import Tag from '../../components/Tag';
import colors from '../../styles/colors';
import ModalConfirmDelete from './ModalConfirmDelete';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  unavailability: any;
  deleteable: boolean;
  onRemoveUnavailability: (id: string) => Promise<void>;
}

const UnavailabilityCard: React.FC<Props> = ({ unavailability, deleteable, onRemoveUnavailability }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] = useState<boolean>(false);

  const onRemove = () => {
    setModalConfirmDeleteVisible(true);
  };

  return (
    <View
      style={
        unavailability.shyftType == 2
          ? styles.dayoffContainer
          : unavailability.shyftType == 3
          ? styles.unavailabilityContainer
          : styles.shiftContainer
      }
    >
      <View>
        <SIText style={{ fontSize: 16, color: colors.grey }}>{moment.unix(unavailability.start!).format('LL')}</SIText>
        {unavailability?.fullDay && <SIText>All day</SIText>}
        {unavailability?.dayoffName && (
          <View style={{ alignItems: 'flex-start', marginTop: 5 }}>
            <Tag key={`shift_${unavailability.dayoffName}_dayoff_type`} backgroundColor={colors.red} color={'#fff'}>
              {unavailability.dayoffName}
            </Tag>
          </View>
        )}
        {!unavailability?.fullDay && (
          <SIText>{`${t('SHIFTS.HOURS_FROM')} ${unavailability.startHour} ${t('SHIFTS.HOURS_TO')} ${
            unavailability.endHour
          }`}</SIText>
        )}
      </View>
      {deleteable && (
        <TouchableOpacity style={{ alignSelf: 'center' }} onPress={onRemove}>
          <Icon name="trash-empty" size={28} color={colors.red} />
        </TouchableOpacity>
      )}
      {modalConfirmDeleteVisible && (
        <ModalConfirmDelete
          visible={modalConfirmDeleteVisible}
          setVisible={setModalConfirmDeleteVisible}
          unavailability={unavailability}
          onRemoveUnavailability={onRemoveUnavailability}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  shiftContainer: {
    width: '100%',
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dayoffContainer: {
    width: '100%',
    backgroundColor: colors.redExtraLight,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  unavailabilityContainer: {
    width: '100%',
    backgroundColor: colors.greyLight,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
});

export { styles };
export default UnavailabilityCard;
