import { useNavigation } from '@react-navigation/native';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { useDispatch } from 'react-redux';
import colors from '../styles/colors';
import { IScanCode } from '../types/clocking.model';
import { IDepartment } from '../types/department.model';
import SIButton from './SIButton';
import SIText from './SIText';

interface Props {
  tools: IScanCode[];
  department: IDepartment;
  removeActionType: string;
  callbackView: string;
  callbackScreen?: string;
}

const Tools: React.FC<Props> = ({ tools, department, removeActionType, callbackView, callbackScreen }) => {
  const navigation = useNavigation();
  const reduxDispatch: Dispatch<any> = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const toolNamesMap = new Map<string, string>();
  const attributes = department?.scanCodes?.attributes;

  for (let i = 0; i < (attributes?.length || 0); i++) {
    const attribute = attributes![i];
    if (attribute) {
      toolNamesMap.set(attribute.id!, attribute.name!);
    }
  }

  const onAddTool = () => {
    navigation.navigate('ToolsList', {
      attributes: department?.scanCodes?.attributes || [],
      callbackView,
      callbackScreen,
    });
  };

  const onRemoveTool = (index: number) => {
    reduxDispatch({
      type: removeActionType,
      payload: index,
    });
  };

  return (
    <View style={styles.container}>
      <View>
        {tools.map((tool, index) => (
          <>
            {department && department.clockinParams && department.clockinParams.enableMobileClockin ? (
              <Swipeable
                key={`tool_${index}`}
                renderRightActions={(_progress, dragX) => (
                  <RightActions title={t('GENERAL.REMOVE')} dragX={dragX} onPress={() => onRemoveTool(index)} />
                )}
              >
                <View style={styles.tool}>
                  <SIText>{toolNamesMap.get(tool.id)}</SIText>
                  <SIText style={{ fontSize: 16 }}>{tool?.quantity}</SIText>
                </View>
              </Swipeable>
            ) : (
              <View style={styles.tool}>
                <SIText>{toolNamesMap.get(tool.id)}</SIText>
                <SIText style={{ fontSize: 16 }}>{tool?.quantity}</SIText>
              </View>
            )}
          </>
        ))}
        {department && department.clockinParams && department.clockinParams.enableMobileClockin && (
          <View style={styles.subContainer}>
            <View style={{ justifyContent: 'center' }}>
              <SIText>{t('CLOCKING.ADD_TOOL')}</SIText>
            </View>
            <View>
              <SIButton
                title={t('GENERAL.ADD')}
                backgroundColor="white"
                color={colors.green}
                borderColor={colors.green}
                onPress={onAddTool}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const RightActions = ({ title, onPress, dragX }: any) => {
  const scale = dragX.interpolate({
    inputRange: [-100, 0],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.rightActions}>
        <Animated.Text style={[styles.rightActionsText, { transform: [{ scale }] }]}>{title}</Animated.Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  subContainer: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tool: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: colors.greyLight,
    padding: 15,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  rightActions: {
    backgroundColor: colors.red,
    alignContent: 'flex-end',
    justifyContent: 'center',
  },
  rightActionsText: {
    fontFamily: 'Poppins_700Bold',
    color: 'white',
    padding: 20,
  },
});

export default Tools;
