import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import SIText from '../../../components/SIText';
import colors from '../../../styles/colors';
import { IClockingPause } from '../../../types/clocking.model';
import { minutesToHoursAndOrMinutes } from '../../../utils';

interface Props {
  pauses: IClockingPause[];
}

const Pauses: React.FC<Props> = ({ pauses }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  if (pauses.length) {
    return (
      <View style={styles.container}>
        <SIText>{t('CLOCKING.PAUSES')}</SIText>
        <View style={{ marginTop: 10 }}>
          {pauses.map((pause, index) => {
            const { start = 0, end = 0 } = pause;
            const durationInMinutes = Math.ceil((end - start) / 60);
            return (
              <View key={`pause_${index}`} style={styles.pause}>
                <SIText style={styles.pauseText}>{`${moment.unix(pause.start!).format('HH:mm')} à ${moment
                  .unix(pause.end!)
                  .format('HH:mm')}`}</SIText>
                <SIText style={styles.pauseText}>{`${minutesToHoursAndOrMinutes(durationInMinutes)}`}</SIText>
              </View>
            );
          })}
        </View>
      </View>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    padding: 25,
  },
  pause: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
    alignItems: 'center',
  },
  pauseText: {
    color: colors.grey,
  },
});

export default Pauses;
